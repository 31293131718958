import React, { useState, useEffect, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Treatment from "./_treatment";
import Spinner from "react-bootstrap/Spinner";
import "./index.scss";
import PatientAppointmentService from "../../services/patient_appointment";
import ToastMessage from "../../components/toast-message";
import { CalendarOutlined, PlayCircleOutlined, BulbOutlined } from "@ant-design/icons";
import AppointmentModalForm from "./appointment-form";
import TraffictLightLegend from "./_legend";
import ServiceProviderService from "../../services/service_provider";
import moment from "moment";
import Telescope from "images/stethoscope";
import HospitalBed from "images/hospital-bed";
import PathwaySelection from "../patient/_pathway_selection";
import AppointmentPromForm from "./appointment-prom-form";

const PatientTreatmentIndex = ({
  appointments,
  reloadPatient,
  patient,
  patientId,
  scrollTo,
  newProviderId,
  filterType,
  scrollId,
  sameDateQuery,
  ...props
}) => {
  const [reload, setReload] = useState(false);
  const [allAppointments, setAllAppointments] = useState([]);
  const [allChildTreatments, setAllChildTreatments] = useState([]);
  const [canShowChoosePathwayButton, setCanShowChoosePathwayButton] = useState(false);
  const [appointmentsDate, setAppointmentsDate] = useState([]);
  const [appointmentsNonDate, setAppointmentsNonDate] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalPromShow, setModalPromShow] = useState(false);
  const [showOperationDate, setShowOperationDate] = useState(false);
  const [showTrafficLight, setShowTrafficLight] = useState(false);
  const [showPathwaySelection, setShowPathwaySelection] = useState(false);
  const [rightProgressLabel, setRightProgressLabel] = useState("");
  const [loading, setLoading] = useState(true);
  const [sameDateAppointmentIds, setSameDateAppointmentIds] = useState([]);

  useEffect(() => {
    setLoading(false);
    PatientAppointmentService.index(patientId, filterType).then(
      (response) => {
        setAllAppointments(response.data.appointments);
        setAllChildTreatments(
          response.data.appointments
            .map((a) => a.treatment?.treatment_ids)
            .flat()
            .filter(Number),
        );
        setAppointmentsDate(response.data.appointments.filter((a) => a.appointment_date));
        setAppointmentsNonDate(
          response.data.appointments.filter((a) => a.appointment_date == undefined || a.appointment_date == ""),
        );

        if (scrollTo) {
          const view = document.getElementById(scrollTo);
          view?.scrollIntoView();
        }
        setLoading(false);
      },
      (error) => {
        setError(error.meta.message);
      },
    );
  }, [reload, patientId, filterType]);

  useEffect(() => {
    setAppointmentsDate(allAppointments.filter((a) => a.appointment_date));
    setAppointmentsNonDate(allAppointments.filter((a) => a.appointment_date == undefined || a.appointment_date == ""));
  }, [allAppointments]);

  useEffect(() => {
    const today = moment();
    const result = [];
    if (sameDateQuery && allAppointments.length > 0) {
      const t = allAppointments.filter((a) => {
        if (a.appointment_time && moment(a.appointment_time).isAfter(today)) {
          if (allAppointments.filter((aa) => a.id != aa.id).find((aa) => aa.appointment_time == a.appointment_time)) {
            result.push(a);
          }
        }
      });
    }
    setSameDateAppointmentIds(result.map((r) => r.id));
  }, [sameDateQuery, allAppointments]);

  const activeOperation = async () => {
    if (!patient.treatment_has_operation) {
      PatientAppointmentService.activePathway(patientId).then(
        (res) => {
          setReload(!reload);
          ToastMessage.success(res.data.message);
        },
        (error) => {
          setReload(!reload);
          ToastMessage.error(error.meta.message);
        },
      );
    } else {
      setShowOperationDate(true);
    }
  };

  const checkLastItemDate = (index) => {
    return allAppointments.length - 1 == index;
  };

  const progressPercent = () => {
    const p = Math.round((currentAppointmentDisplay() / allApppointmentDisplay()) * 100);
    if (p == 100) {
      return 95; // for better styling
    }
    return p;
  };

  const [providers, setProviders] = useState([]);
  useEffect(() => {
    if (providers.length == 0) {
      ServiceProviderService.index().then(
        (response) => {
          const p = response.data.service_providers;
          setProviders(p);
        },
        (error) => {},
      );
    }
  }, [appointments]);

  const operationDateFormat = () => {
    const app = allAppointments.find((a) => a.is_operation);
    return moment(app.appointment_date).format("DD.MM.YYYY");
  };

  const leftProgressLabel = () => {
    const app = allAppointments.find((a) => a.is_operation);
    if (!app) return;
    if (moment(app.appointment_date).isBefore(moment())) {
      return I18n.t("form.appointment.after_operation_date_header_right");
    } else {
      return I18n.t("form.appointment.before_operation_date_header_right");
    }
  };

  const currentAppointmentDisplay = () => {
    const app = allAppointments.find((a) => a.is_operation);
    if (!app) {
      return;
    }

    const allAppp = allAppointments.filter((a) => !a.is_operation);
    if (moment(app.appointment_date).isBefore(moment())) {
      return allAppp
        .filter((a) => moment(a.appointment_date).isAfter(app.appointment_date))
        .filter((a) => a.poll.color == "green").length;
    } else {
      return allAppp
        .filter((a) => moment(a.appointment_date).isBefore(app.appointment_date))
        .filter((a) => a.poll.color == "green").length;
    }
  };

  const allApppointmentDisplay = () => {
    const app = allAppointments.find((a) => a.is_operation);
    if (!app) return;

    const allAppp = allAppointments.filter((a) => !a.is_operation);
    if (moment(app.appointment_date).isBefore(moment())) {
      return allAppp.filter((a) => moment(a.appointment_date).isAfter(app.appointment_date)).length;
    } else {
      return allAppp.filter((a) => moment(a.appointment_date).isBefore(app.appointment_date)).length;
    }
  };

  if (scrollTo) {
    const view = document.getElementById(scrollTo);
    view?.scrollIntoView();
  }

  const reloadAll = () => {
    if (appointments.length == 0) {
      window.location.reload();
    } else {
      reload(!reload);
    }
  };

  const operationAppointment = () => {
    return allAppointments.find((a) => a.is_operation) || patient;
  };

  const hasOperation = () => {
    return allAppointments.find((a) => a.is_operation);
  };

  const canChoosePathway = () => {
    return (
      allAppointments.map((a) => a.poll.color).every((e) => e == "green") &&
      !patient.activated_operation_appointment &&
      patient.is_default_pathway
    );
  };

  useEffect(() => {
    const op = allAppointments.find((a) => a.is_operation);
    const canShowChoosePathwayButton = !op && patient.is_default_pathway && !patient.activated_operation_appointment;
    setCanShowChoosePathwayButton(canShowChoosePathwayButton);
  }, [allAppointments, patient]);

  useEffect(() => {
    const app = allAppointments.find((a) => a.is_operation);
    if (!app) {
      setRightProgressLabel("");
    } else {
      let first = I18n.t("form.appointment.prov");
      if (!app.appointment_date) {
        setRightProgressLabel(
          `${first}. ${I18n.t("form.appointment.before_operation_date_header_left")} ${I18n.t(
            "form.appointment.op_not_set_date",
          )}`,
        );
      } else {
        let result = "";
        if (moment(app.appointment_date).isBefore(moment())) {
          result = `${I18n.t("form.appointment.after_operation_date_header_left")}`;
        } else {
          result = `${I18n.t("form.appointment.before_operation_date_header_left")} ${operationDateFormat()}`;
        }

        if (!patient.is_final_operation) {
          setRightProgressLabel(first + ". " + result);
        } else {
          setRightProgressLabel(result);
        }
      }
    }
  }, [patient, reload, patientId, allAppointments]);

  const isOnlyPromPathway = patient?.treatment_pathway?.only_prom == true;
  const isHybridPromPathway = patient?.treatment_pathway?.is_hybrid_prom == true;
  const initAppoinmentText = () => {
    if (isOnlyPromPathway) {
      return I18n.t("form.appointment.initial_appointment_prom");
    } else if (isHybridPromPathway) {
      return I18n.t("form.appointment.new_appointment_hybrid");
    } else {
      return I18n.t("form.appointment.initial_appointment");
    }
  };

  const handleModalShow = () => {
    if (isOnlyPromPathway || isHybridPromPathway) {
      setModalPromShow(true);
    } else {
      setModalShow(true);
    }
  };

  const showCreateAppointment = () => {
    if (!operationAppointment() || operationAppointment().rule.show_init_appointment) {
      return (
        <button className="create-appointment-btn aaa" onClick={() => handleModalShow(true)}>
          <CalendarOutlined />
          {initAppoinmentText()}
        </button>
      );
    }
    if (operationAppointment() && operationAppointment().rule.show_create_appointment) {
      return (
        <button className="create-appointment-btn" onClick={() => setModalShow(true)}>
          <CalendarOutlined />
          {I18n.t("form.appointment.new_appointment")}
        </button>
      );
    }
  };

  return loading && allAppointments ? (
    <div className="text-center">
      <Spinner className="mt-5" animation="border" variant="primary" />
    </div>
  ) : (
    <div className="patient-treatment-index mb-5 mt-3">
      <div className="appointment d-flex align-items-center justify-content-center">
        <div className="create-appointment">
          {showCreateAppointment()}
          <PathwaySelection
            reload={() => setReload(!reload)}
            reloadPatient={() => reloadPatient()}
            patientId={patientId}
            show={showPathwaySelection}
            onHide={() => setShowPathwaySelection(false)}
          />
          <AppointmentModalForm
            reload={() => reloadAll()}
            show={modalShow}
            onHide={() => setModalShow(false)}
            patient={patient}
            provider={patient.provider}
            appointments={allAppointments}
            noDocument={allAppointments.length > 0}
            appointmentName={allAppointments.length > 0 ? "" : I18n.t("form.appointment.initial_appointment")}
            isConsultationAppointment={allAppointments.length == 0}
          />
          <AppointmentPromForm
            reload={() => reloadAll()}
            show={modalPromShow}
            onHide={() => setModalPromShow(false)}
            patient={patient}
            provider={patient.provider}
            appointments={allAppointments}
            noDocument={allAppointments.length > 0}
            appointmentName={allAppointments.length > 0 ? "" : I18n.t("form.appointment.initial_appointment")}
            isConsultationAppointment={allAppointments.length == 0}
          />
        </div>
      </div>
      {allAppointments.length > 0 && (
        <>
          <h1 className="text-center mb-3">{I18n.t("form.treatment.manage")}</h1>
          {hasOperation() && (
            <div id="treatment-progress" className="treatment-progress mb-3 row">
              <div className="label col-md-4">
                <div className="header">
                  <img width="25" height="25" src={Telescope} />
                  <span>{leftProgressLabel()}</span>
                </div>
                <div className="label-wrapper">
                  <span className="archive">{currentAppointmentDisplay()}</span>/
                  <span className="waiting">{allApppointmentDisplay()}</span>
                  {I18n.t("form.appointment.treatment_done_label")}
                </div>
              </div>

              <div
                style={{ padding: "0px" }}
                className="d-flex col-md-4 flex-column justify-content-start position-relative"
              >
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${progressPercent()}%` }}
                    aria-valuenow={progressPercent()}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div style={{ left: `${progressPercent() - 1}%` }} className="circle-wrapper"></div>
              </div>

              <div className="label col-md-4 align-items-start">
                <div className="header">
                  {rightProgressLabel == I18n.t("form.appointment.after_operation_date_header_left") ? (
                    <img width="25" height="25" src={Telescope} />
                  ) : (
                    <img width="25" height="25" src={HospitalBed} />
                  )}
                  <span>{rightProgressLabel}</span>
                </div>
              </div>
            </div>
          )}
          <div className="d-flex flex-column align-items-end mb-5">
            <BulbOutlined
              //onMouseEnter={() => setShowTrafficLight(true)}
              //onMouseLeave={() => setShowTrafficLight(false)}
              onClick={() => setShowTrafficLight(!showTrafficLight)}
            />
            {showTrafficLight && <TraffictLightLegend />}
          </div>

          {canShowChoosePathwayButton && (
            <div className="mb-5 d-flex justify-content-end">
              <button
                disabled={!canChoosePathway()}
                className="operation-btn"
                onClick={() => setShowPathwaySelection(true)}
              >
                <PlayCircleOutlined />
                {I18n.t("form.appointment.treatment_no_operation")}
              </button>
            </div>
          )}

          {!patient.activated_operation_appointment && !patient.is_default_pathway && (
            <div className="mb-5 d-flex justify-content-end">
              <button
                disabled={operationAppointment() && !operationAppointment().rule.show_active_operation}
                className="operation-btn"
                onClick={activeOperation}
              >
                <PlayCircleOutlined />
                {patient.treatment_has_operation && (
                  <>
                    {I18n.t("form.appointment.prov")}. {I18n.t("form.appointment.operation_button")}
                  </>
                )}
                {!patient.treatment_has_operation && I18n.t("form.appointment.treatment_no_operation")}
              </button>
            </div>
          )}

          <div className="steps">
            {allAppointments.map((appointment, index) => {
              return (
                <React.Fragment key={index}>
                  <Treatment
                    scrollFirst={scrollId == "first" && index == 0}
                    scrollId={scrollId}
                    scrollTo={scrollTo}
                    index={index + 1}
                    lastItem={checkLastItemDate(index)}
                    patientId={patientId}
                    patient={patient}
                    appointment={appointment}
                    appointments={allAppointments}
                    providers={providers}
                    newProviderId={newProviderId}
                    changeOperationAppointment={
                      operationAppointment() && operationAppointment().rule.can_change_operation_appointment
                    }
                    showOperationDate={showOperationDate}
                    reload={() => setReload(!reload)}
                    reloadPatient={() => reloadPatient()}
                    setAllAppointments={(e) => setAllAppointments(e)}
                    hasBorder={sameDateAppointmentIds.includes(appointment.id)}
                    isChildren={allChildTreatments.includes(appointment.treatment?.id)}
                    {...props}
                  />
                </React.Fragment>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default PatientTreatmentIndex;
