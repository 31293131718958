import httpClient from '../utils/http-common';
const API_URL = '/audits';

const index = (type, page) => {
  return httpClient.get(`${API_URL}?auditable_type=${type}&page=${page}`);
};

const show = (id, type) => {
  return httpClient.get(`${API_URL}/${id}?auditable_type=${type}`);
};

const mail = (page, type) => {
  return httpClient.get(`${API_URL}/mail?page=${page}&auditable_type=${type}`);
};


const AuditService = {
  index,
  show,
  mail,
};

export default AuditService;
