import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import authClient from "../utils/auth-common";
import toastMessage from "./toast-message";
import Logo from "images/logo-color-no-bg.svg";
import { VerifiedOutlined } from "@ant-design/icons";
import VerifyOtpModal from "../screens/user/verify-otp-modal";
import VerifyBackupOtpModal from "../screens/user/verify-backup-otp-modal";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Login = () => {
  let navigate = useHistory();

  const form = useRef();
  const checkBtn = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [showVerifyBackupOtp, setShowVerifyBackupOtp] = useState(false);

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const submitCode = (code) => {
    authClient
      .emailSignIn({
        email: email,
        password: password,
        code: code,
      })
      .then((_) => {
        navigate.push("/");
        window.location.reload();
      })
      .fail((error) => {
        setLoading(false);
        toastMessage.error(error.data.message || error.reason);
      });
  };

  const submitBackupCode = (code) => {
    authClient
      .emailSignIn({
        email: email,
        password: password,
        backup_code: code,
      })
      .then((_) => {
        navigate.push("/");
        window.location.reload();
      })
      .fail((error) => {
        setLoading(false);
        toastMessage.error(error.data.message || error.reason);
      });
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      authClient
        .emailSignIn({
          email: email,
          password: password,
        })
        .then((_) => {
          navigate.push("/");
          window.location.reload();
        })
        .fail((error) => {
          setLoading(false);
          if (error.data.code == 1111) {
            setShowVerifyOtp(true);
          } else {
            toastMessage.error(error.data.message || error.reason);
          }
        });
    } else {
      setLoading(false);
    }
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    navigate.push("/forgot-password");
  };

  return (
    <div className="row mt-5">
      <div className="col-md-4"></div>
      <div className="col-md-4 card card-container p-3">
        <img src={Logo} className="img-fluid mb-5" />
        <h1 className="text-center">Login</h1>
        <VerifyOtpModal
          setShowVerifyBackupOtp={(e) => setShowVerifyBackupOtp(e)}
          submitCode={(code) => submitCode(code)}
          show={showVerifyOtp}
          onHide={() => setShowVerifyOtp(false)}
          showBackupCode={true}
        />
        <VerifyBackupOtpModal
          submitBackupCode={(code) => submitBackupCode(code)}
          show={showVerifyBackupOtp}
          onHide={() => setShowVerifyBackupOtp(false)}
        />
        <Form onSubmit={handleLogin} ref={form} className="p-3">
          <div className="form-group">
            <label htmlFor="email">{I18n.t("activerecord.attributes.user.email")}</label>
            <Input
              type="text"
              className="form-control"
              name="email"
              value={email}
              onChange={onChangeEmail}
              validations={[required]}
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">{I18n.t("activerecord.attributes.user.password")}</label>
            <Input
              type="password"
              className="form-control"
              name="password"
              value={password}
              onChange={onChangePassword}
              validations={[required]}
            />
          </div>

          <div className="form-group">
            <button className="btn btn-primary btn-block mt-3" disabled={loading}>
              {loading && <span className="spinner-border spinner-border-sm"></span>}
              <span>{I18n.t("devise.sessions.new.sign_in")}</span>
            </button>

            <button onClick={handleForgotPassword} className="btn btn-link btn-block mt-3 float-end">
              <span>{I18n.t("devise.shared.links.forgot_your_password")}</span>
            </button>
          </div>
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default Login;
