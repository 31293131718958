import React, { useState, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import { LANGUAGE_OPTIONS, ROLE_OPTIONS } from "../../constants/index";
import UserService from "../../services/user";
import TenantService from "../../services/tenant";
import AuthService from "../../services/auth";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const UserUpdate = () => {
  let navigate = useHistory();

  const form = useRef();
  const checkBtn = useRef();

  const { id } = useParams();

  const [error, setError] = useState("");
  const [tenants, setTenants] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [tenantId, setTenantId] = useState(undefined);
  const [departmentId, setDepartmentId] = useState(undefined);
  const [userId, setUserId] = useState(id);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [language, setLanguage] = useState(LANGUAGE_OPTIONS[0]["value"]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [gender, setGender] = useState("male");

  useEffect(() => {
    UserService.show(userId).then(
      (response) => {
        const user = response.data.user;
        setEmail(user.email);
        setRole(user.role);
        setLanguage(user.language);
        setTenantId(user.tenant?.id);
        setDepartmentId(user.department?.id);
        setGender(user.gender || "male");

        TenantService.index().then(
          (response) => {
            const tenants = response.data.tenants;
            setTenants(tenants);
            setTenantId(user.tenant.id);
            const departments = tenants.find((i) => i.id == user.tenant.id).departments;
            setDepartments(departments);
          },
          (error) => {
            setError(error.meta.message);
          },
        );
      },
      (error) => {
        setMessage(error.meta.message);
      },
    );
  }, []);

  const onChangeEmail = (e) => {
    const name = e.target.value;
    setEmail(name);
  };

  const onChangePassword = (e) => {
    const pass = e.target.value;
    setPassword(pass);
  };

  const onChangeLanguage = (e) => {
    const lang = e.target.value;
    setLanguage(lang);
  };

  const onChangeRole = (e) => {
    const role = e.target.value;
    setRole(role);
  };

  const handleCreate = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("role", role);
      formData.append("gender", gender);
      if (departmentId !== undefined) {
        formData.append("department_id", departmentId);
      }
      if (tenantId !== undefined) {
        formData.append("tenant_id", tenantId);
      }
      if (password) {
        formData.append("password", password);
      }
      formData.append("language", language);
      UserService.update(id, formData).then(
        (res) => {
          navigate.push({ pathname: "/users", state: { name: "actionSuccess", message: res.data.message } });
        },
        (error) => {
          setLoading(false);
          setMessage(error.meta.message);
        },
      );
    } else {
      setLoading(false);
    }
  };

  const onChangeTenant = (e) => {
    setTenantId(e.target.value);

    const departments = tenants.find((t) => t.id == e.target.value)?.departments;
    if (departments.length == 0) {
      setDepartments([]);
    } else {
      setDepartments(departments);
      setDepartmentId(departments[0].id);
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className="card card-container w-50">
        <Form onSubmit={handleCreate} ref={form} className="p-3">
          <div className="form-group">
            <label htmlFor="Tenant">{I18n.t("activerecord.models.tenant")}</label>
            <Select
              name="lang"
              className="form-control"
              title="Tenant"
              value={tenantId}
              disabled={AuthService.isSuperAdmin() ? false : true}
              validations={[]}
              onChange={onChangeTenant}
            >
              {tenants.map((l) => (
                <option key={l.id} value={l.id}>
                  {l.company_name}
                </option>
              ))}
            </Select>
          </div>

          <div className="form-group">
            <label htmlFor="department">{I18n.t("activerecord.models.department")}</label>
            <Select
              name="lang"
              className="form-control"
              title="department"
              value={departmentId}
              validations={[]}
              onChange={(val) => setDepartmentId(val.target.value)}
            >
              {departments.map((l) => (
                <option key={l.id} value={l.id}>
                  {l.company_name}
                </option>
              ))}
            </Select>
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <Input
              type="text"
              className="form-control"
              disabled={true}
              name="email"
              value={email}
              onChange={onChangeEmail}
              validations={[required]}
            />
          </div>

          <div className="form-group">
            <label>{I18n.t("activerecord.attributes.patient.gender")}</label>
            <select
              name="gender"
              className="selection form-control"
              onChange={(e) => setGender(e.target.value)}
              value={gender}
            >
              <option value="male">{I18n.t("form.patient.male")}</option>
              <option value="female">{I18n.t("form.patient.female")}</option>
              <option value="other">{I18n.t("form.patient.other")}</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="language">Language</label>
            <Select
              name="lang"
              className="form-control"
              title="Language"
              value={language}
              validations={[required]}
              onChange={onChangeLanguage}
            >
              <option value> -- Select Language -- </option>
              {LANGUAGE_OPTIONS.map((l) => (
                <option key={l.value} value={l.value}>
                  {l.label}
                </option>
              ))}
            </Select>
          </div>

          <div className="form-group">
            <label htmlFor="role">Role</label>
            <Select
              name="role"
              className="form-control"
              title="Role"
              value={role}
              validations={[required]}
              onChange={onChangeRole}
            >
              <option value> -- Select Role -- </option>
              {ROLE_OPTIONS.map((l) => (
                <option key={l.value} value={l.value}>
                  {l.label}
                </option>
              ))}
            </Select>
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <Input
              type="password"
              className="form-control"
              name="password"
              value={password}
              onChange={onChangePassword}
              validations={[]}
            />
          </div>

          <div className="form-group">
            <button className="btn btn-primary btn-block mt-3" disabled={loading}>
              {loading && <span className="spinner-border spinner-border-sm"></span>}
              <span>Update</span>
            </button>
          </div>

          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default UserUpdate;
