import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import "./appointment-form.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarTwoTone, EditOutlined, FilePdfOutlined } from "@ant-design/icons";
import AlertMessage from "../../components/alert-message";
import ToastMessage from "../../components/toast-message";
import PatientAppointmentService from "../../services/patient_appointment";
import ServiceProviderSelection from "../../components/form/service_provider_selection";
import { components } from "react-select";
import toastMessage from "../../components/toast-message";
import { Tooltip } from "react-tooltip";

const AppointmentPromForm = ({
  appointmentId,
  appointmentDate: date,
  appointmentName: aName,
  treatmentId,
  defaultProviderSelection,
  reload,
  isConsultationAppointment,
  noDocument,
  ...props
}) => {
  const modalRef = useRef(null);
  const [appointmentName, setAppointmentName] = useState(aName);
  const [appointmentDate, setAppointmentDate] = useState(date || "");
  const [provider, setProvider] = useState(props.provider);
  const [patient, setPatient] = useState(props.patient);
  const [loading, setLoading] = useState(false);
  const [isInternalType, setIsInternalType] = useState(props.provider?.is_internal);

  useEffect(() => {
    setPatient(props.patient);
  }, [props.patient]);

  useEffect(() => {
    setAppointmentName(aName);
  }, [aName]);

  const handleForm = (e) => {
    e.preventDefault();

    const formData = new FormData();
    let hours;
    let minutes;
    if (appointmentDate) {
      hours = appointmentDate.getHours();
      minutes = appointmentDate.getMinutes();
    }

    const time = appointmentDate;

    formData.append("appointment[name]", appointmentName);
    formData.append("appointment[patient_id]", patient.id);
    formData.append("appointment[appointment_time]", appointmentDate);

    formData.append(
      "appointment[service_provider_id]",
      provider?.id || provider?.value || provider || defaultProviderSelection.value,
    );

    if (!appointmentDate) {
      toastMessage.error(I18n.t("form.appointment.enter_valid_date"));
      return;
    }
    if (!time) {
      toastMessage.error(I18n.t("form.appointment.enter_valid_datetime"));
      return;
    }

    if (!appointmentId) {
      PatientAppointmentService.create(props.patient.id, formData).then(
        (res) => {
          ToastMessage.success(res.data.message);
          window.location.reload();
        },
        (error) => {
          ToastMessage.error(error.meta.message);
        },
      );
    } else {
      PatientAppointmentService.update(props.patient.id, appointmentId, formData).then(
        (res) => {
          ToastMessage.success(res.data.message);
          window.location.reload();
        },
        (error) => {
          ToastMessage.error(error.meta.message);
        },
      );
    }
  };

  const onDateChange = (val) => {
    setAppointmentDate(val);
  };

  return (
    props.patient &&
    Object.keys(props.patient).length > 0 && (
      <Modal {...props} size="lg" ref={modalRef} aria-labelledby="contained-modal-title-vcenter" centered>
        <Tooltip id="tooltip-kom-package" content={I18n.t("tooltip.kom_package")} />
        <Modal.Header closeButton className="modal-header">
          <Modal.Title id={`${appointmentName}-modal-title-vcenter`}>
            {!appointmentId && I18n.t("form.appointment.new_appointment")}
            {appointmentId && (
              <div style={{ gap: "5px" }} className="d-flex align-items-center">
                <EditOutlined />
                {I18n.t("form.appointment.name")}
              </div>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!loading ? (
            <div className="appointment-create-box">
              <div className="box-body">
                <div className="attachment">
                  <span className="label">{I18n.t("form.appointment.for_patient")}</span>
                </div>
                <div className="input row">
                  <div className="col-md-4 label">
                    <span className="text-capitalize">
                      {I18n.t(`enumerize.defaults.gender.${props.patient.gender}`)}
                    </span>
                  </div>
                  <div className="col-md-7">
                    <input readOnly type="text" defaultValue={`${patient.first_name}, ${patient.last_name}`} />
                  </div>
                </div>
                <div className="input row">
                  <div className="col-md-4 label">
                    <span>{I18n.t("activerecord.attributes.appointment.attachments")}</span>
                  </div>
                  <div className="col-md-7">
                    <ServiceProviderSelection
                      className="selection form-control"
                      isInternal={isConsultationAppointment}
                      setProvider={setProvider}
                      setIsInternalType={(v) => setIsInternalType(v)}
                      provider={
                        defaultProviderSelection
                          ? defaultProviderSelection
                          : provider
                          ? { label: provider.display_name, id: provider.id }
                          : null
                      }
                    />
                  </div>
                </div>

                {!isInternalType && (
                  <div className="attachment">
                    <span className="label">{I18n.t("form.appointment.new_forecast_date")}</span>
                  </div>
                )}

                <div className="row">
                  <div className="col-md-6 datepicker datepicker-date">
                    <CalendarTwoTone />
                    <DatePicker
                      placeholderText="TT.MM.JJJJ"
                      dropdownMode="select"
                      minDate={new Date()}
                      dateFormat={"dd.MM.yyyy"}
                      // filterDate={Utils.isWeekday}
                      className="form-control"
                      selected={appointmentDate}
                      onChange={onDateChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center">
              <Spinner className="mt-5" animation="border" variant="primary" />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div
            onClick={handleForm}
            className="create-patient d-flex align-items-baseline justify-content-between btn btn-lg mt-3 fw-bolder float-end bg-green"
          >
            <span>{I18n.t("form.create")}</span>
          </div>
        </Modal.Footer>
      </Modal>
    )
  );
};

export default AppointmentPromForm;
