import React from "react";
import Pagination from "react-bootstrap/Pagination";

const PaginationNew = ({ meta, onChangePage }) => {
  const handleClick = (e, page) => {
    e.preventDefault();
    onChangePage(page);
  };

  const nextPage = (e) => {
    e.preventDefault();
    if (meta.next_page) {
      onChangePage(meta.next_page);
    }
  };

  const previousPage = (e) => {
    e.preventDefault();
    if (meta.prev_page) {
      onChangePage(meta.prev_page);
    }
  };

  const { current_page, total_pages } = meta;
  const items = [];

  if (current_page - 1 > 0) {
    items.push(
      <Pagination.Item key={current_page - 1} onClick={(e) => handleClick(e, current_page - 1)} active={false}>
        {current_page - 1}
      </Pagination.Item>,
    );
  }
  items.push(
    <Pagination.Item key={current_page} onClick={(e) => handleClick(e, current_page)} active={true}>
      {current_page}
    </Pagination.Item>,
  );

  if (current_page + 1 < total_pages) {
    items.push(
      <Pagination.Item key={current_page + 1} onClick={(e) => handleClick(e, current_page + 1)} active={false}>
        {current_page + 1}
      </Pagination.Item>,
    );
  }

  if (current_page - 1 == 0 && total_pages > 1) {
    items.push(
      <Pagination.Item key={current_page + 2} onClick={(e) => handleClick(e, current_page + 2)} active={false}>
        {current_page + 2}
      </Pagination.Item>,
    );
  }

  if (current_page != total_pages) {
    items.push(<Pagination.Ellipsis key="ellipsis" />);
    items.push(
      <Pagination.Item key={total_pages} onClick={(e) => handleClick(e, total_pages)} active={false}>
        {total_pages}
      </Pagination.Item>,
    );
  }
  return (
    <Pagination>
      <Pagination.First onClick={(e) => handleClick(e, 1)} />
      <Pagination.Prev onClick={previousPage} />
      {items}
      <Pagination.Next onClick={nextPage} />
      <Pagination.Last onClick={(e) => handleClick(e, meta.total_pages)} />
    </Pagination>
  );
};

export default PaginationNew;
