import authClient from 'j-toker';

authClient.configure({
    apiUrl: `${process.env.APP_URL}/api/v1`,

    handleLoginResponse: function (resp) {
        if (resp.user) {
            localStorage.setItem("user", JSON.stringify(resp.user));
            localStorage.setItem("developer", resp.user.email.startsWith('developer'));
        }
        return resp;
    }
});

export default authClient;
