import React, { useState, useRef, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import {
  MessageTwoTone,
  LoginOutlined,
  LogoutOutlined,
  PhoneOutlined,
  SendOutlined,
  DeleteOutlined,
  MoreOutlined,
  PaperClipOutlined,
  CheckOutlined,
  MessageOutlined,
  FileExclamationOutlined,
  MailOutlined,
  MailTwoTone,
  SaveTwoTone,
  PlusCircleOutlined,
  ProfileOutlined,
  WarningOutlined,
  ExclamationOutlined,
  DoubleRightOutlined,
  DownloadOutlined,
  EyeOutlined,
  PrinterOutlined,
  FilePdfOutlined,
  FilePdfTwoTone,
} from "@ant-design/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";
import PatientAppointmentService from "../../services/patient_appointment";
import ToastMessage from "../../components/toast-message";
import Description from "./_description";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import ServiceProviderSelection from "../../components/form/service_provider_selection";
import Utils from "../../utils/index";
import AppointmentNotificationService from "../../services/appointment_notification";
import Input from "react-phone-number-input/input";
import AppointmentAttachment from "./_attachment";
import Spinner from "react-bootstrap/Spinner";
import PatientService from "../../services/patient";
import AppointmentModalForm from "./appointment-form";
import * as moment from "moment";
import LogfileModal from "./_logfile_modal";
import { Tooltip, TooltipWrapper } from "react-tooltip";
import AttachImage from "images/link-three";
import ParentIcon from "images/parent-icon";
import ChildIcon from "images/child-icon";
import ConfirmPopup from "../../components/confirm-popup";
import convertToRoman from "../../utils/roman-number-convert";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    style={{ color: "#4B4583", marginBottom: "5px" }}
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <MoreOutlined />
    {children}
  </a>
));

const Treatment = ({
  hasBorder,
  scrollTo,
  scrollFirst,
  reload,
  newProviderId,
  providers,
  setAllAppointments,
  patient,
  ...props
}) => {
  const [isForecast, setIsForecast] = useState(false);
  const [appointment, setAppointment] = useState(props.appointment);
  const [appointmentDate, setAppointmentDate] = useState(undefined);
  const [forecastDate, setForecastDate] = useState(undefined);
  const [forecastTime, setForecastTime] = useState(undefined);
  const [appointmentTime, setAppointmentTime] = useState(undefined);
  const [resetDateTime, setResetDateTime] = useState(false);
  const [scrollBorderClass, setScrollBorderClass] = useState("");
  const [confirmMessage, setConfirmMessage] = useState("");
  const [confirmPopupValue, setConfirmPopupValue] = useState(undefined);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [confirmAppointmentIds, setConfirmAppointmentIds] = useState([]);

  const resetAppointmentDateTime = () => {
    const date =
      appointment.status != "active"
        ? undefined
        : appointment.appointment_date
        ? new Date(appointment.appointment_date)
        : undefined;
    const time =
      appointment.status != "active"
        ? undefined
        : appointment.poll.color == "orange"
        ? setHours(setMinutes(new Date(), 0), 0)
        : appointment.appointment_time
        ? new Date(appointment.appointment_time)
        : undefined;

    setAppointmentDate(date);
    setAppointmentTime(time);

    if (appointment.status != "active") {
      setForecastDate(appointment.forecast_appointment_time ? new Date(appointment.forecast_appointment_time) : "");
      // setForecastTime(appointment.forecast_appointment_time ? new Date(appointment.forecast_appointment_time) : "");
      if (!appointment.is_consultation) {
        setIsForecast(true);
      }
    } else {
      setForecastDate(appointment.forecast_appointment_time ? new Date(appointment.forecast_appointment_time) : "");
    }
  };

  useEffect(() => {
    resetAppointmentDateTime();
  }, [appointment, resetDateTime]);

  useEffect(() => {
    setAppointment(props.appointment);
  }, [props.appointment]);

  const [receivedResult, setReceiveResult] = useState(appointment.receive_result);
  const [active, _active] = useState(props.status == "archived");
  const [provider, setProvider] = useState(
    appointment.service_provider?.id
      ? {
          value: appointment.service_provider.id,
          label: appointment.service_provider.display_name,
        }
      : providers[0]?.id,
  );
  const [editDescription, setEditDescription] = useState(false);
  const [editAttachment, setEditAttachment] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [showOperationDate, setShowOperationDate] = useState(false);
  const [draft, setDraft] = useState(false);
  const [loading, setShowLoading] = useState(false);
  const [showLogfile, setShowLogfile] = useState(false);

  const spRef = useRef(null);
  const operationDateRef = useRef(null);

  useEffect(() => {
    setShowOperationDate(props.showOperationDate);
  }, [props.showOperationDate]);

  const updateAppointmentDate = (e) => {
    setDraft(true);
    if (e) {
      setAppointmentDate(e);

      if (e && appointmentTime && appointmentTime.getHours() > 0) {
        // handleUpdateTime(e, setHours(setMinutes(new Date(), 0), 6));
      } else {
        // setAppointmentTime(setHours(setMinutes(new Date(), 0), 6));
      }
    } else {
      setAppointmentDate(undefined);
      setAppointmentTime(undefined);
    }
  };

  const updateAppointmentTime = (e) => {
    setAppointmentTime(e);

    // handleUpdateTime(appointmentDate, e);
    setDraft(true);
  };

  const updateProvider = async (val) => {
    if (provider?.value === val?.value) return;
    setProvider(val);
    try {
      setShowLoading(true);
      const formData = new FormData();
      formData.append("appointment[service_provider_id]", val.value);
      const response = await PatientAppointmentService.updateServiceProvider(patient.id, appointment.id, formData);
      ToastMessage.success(response.data.message);
      setAppointment(response.data.appointment);
      reload();
      setShowLoading(false);
    } catch (error) {
      ToastMessage.error(error.meta.message);
      setShowLoading(false);
    }
  };

  const toggleResultResult = async (e) => {
    setReceiveResult(e.target.checked);
    try {
      setShowLoading(true);
      const formData = new FormData();
      formData.append("appointment[receive_result]", !appointment.receive_result);
      const response = await PatientAppointmentService.update(patient.id, appointment.id, formData);
      setAppointment(response.data.appointment);
      ToastMessage.success(response.data.message);
      reload();
      setShowLoading(false);
    } catch (error) {
      ToastMessage.error(error.meta.message);
      setShowLoading(false);
    }
  };

  const handleUpdateTime = (date, time) => {
    const formData = new FormData();
    const forecastDate = moment(forecastDate);
    const changeDate = moment(date);
    const diff = Math.abs(forecastDate.diff(changeDate, "days"));

    let actualDiff = 0;
    if (appointment.status == "active") {
      const actualDiff = Math.abs(moment(appointment.appointment_date).diff(changeDate, "days"));
    } else {
      actualDiff = diff;
    }
    if (confirmPopupValue == true && appointment.is_after_operation && diff > 10 && actualDiff > 0) {
      formData.append("appointment[is_recalculate]", true);
      formData.append("appointment[confirmed_appointment_ids]", confirmAppointmentIds);
    }
    if (confirmPopupValue == false && appointment.is_after_operation && diff > 10 && actualDiff > 0) {
      formData.append("appointment[is_recalculate]", false);
    }

    if (
      confirmPopupValue == true &&
      appointment.is_after_operation &&
      diff < 10 &&
      actualDiff > 0 &&
      appointment.treatment?.treatment_ids?.length > 0
    ) {
      formData.append("appointment[is_recalculate]", true);
      formData.append("appointment[confirmed_appointment_ids]", confirmAppointmentIds);
    }

    if (
      confirmPopupValue == false &&
      appointment.is_after_operation &&
      diff < 10 &&
      actualDiff > 0 &&
      appointment.treatment?.treatment_ids?.length > 0
    ) {
      formData.append("appointment[is_recalculate]", false);
    }

    if (confirmPopupValue == undefined) {
      if (appointment.is_after_operation && diff > 10 && actualDiff > 0) {
        setConfirmMessage(I18n.t("form.treatment.recalculate_pathway"));
        setShowConfirmPopup(true);
        return;
      } else if (
        appointment.is_after_operation &&
        diff < 10 &&
        actualDiff > 0 &&
        appointment.treatment?.treatment_ids?.length > 0
      ) {
        setConfirmMessage(I18n.t("form.treatment.recalculate_pathway"));
        setShowConfirmPopup(true);
        return;
      }
    }

    if (!date) {
      date = new Date(appointment.appointment_date);
    }

    const formTime = date;

    let hours;
    let minutes;
    if (time) {
      hours = time.getHours();
      minutes = time.getMinutes();
    } else {
      hours = date.getHours();
      minutes = date.getMinutes();
    }
    formTime.setHours(hours);
    formTime.setMinutes(minutes);

    if (isDisableTimepicker()) {
      formTime.setHours(0);
    }

    formData.append("appointment[appointment_time]", formTime);
    setShowLoading(true);
    PatientAppointmentService.updateTime(patient.id, appointment.id, formData).then(
      (res) => {
        ToastMessage.success(res.data.message);
        reload();
        setIsForecast(false);
        if (appointment.is_operation) {
          props.reloadPatient();
        }
        setShowLoading(false);
        setDraft(false);
      },
      (error) => {
        ToastMessage.error(error.meta.message);
        setShowLoading(false);
        setDraft(false);
        reload();
      },
    );
  };

  useEffect(() => {
    if (confirmPopupValue == false || confirmPopupValue == true) {
      handleUpdateTime(appointmentDate, appointmentTime);
      setConfirmPopupValue(undefined);
    }
  }, [confirmPopupValue]);

  const handleDeleteAppointment = async () => {
    const message = appointment.status != "archived" ? I18n.t("form.appointment.confirm_delete") : "Undo delete";
    if (window.confirm(message)) {
      try {
        setShowLoading(true);
        const response = await PatientAppointmentService.destroy(patient.id, appointment.id);
        window.location.reload();
        ToastMessage.success(response.data.message);
        setShowLoading(false);
      } catch (error) {
        ToastMessage.error(error.meta.message);
        setShowLoading(false);
      }
    }
  };

  const reloadAppointment = async () => {
    try {
      setShowLoading(true);
      const response = await PatientAppointmentService.show(patient.id, appointment.id);
      setAppointment(response.data.appointment);
      setShowLoading(false);
    } catch (error) {
      ToastMessage.error(error.meta.message);
      setShowLoading(false);
    }
  };

  const toggleNeedResult = async () => {
    try {
      const formData = new FormData();
      formData.append("appointment[need_result]", !appointment.need_result);
      const response = await PatientAppointmentService.update(patient.id, appointment.id, formData);
      setAppointment(response.data.appointment);
      ToastMessage.success(response.data.message);
    } catch (error) {
      ToastMessage.error(error.meta.message);
    }
  };

  const sendEmailToServiceProvider = async () => {
    if (appointment.appointment_notifications.length > 0) {
      if (window.confirm(I18n.t("form.appointment_notification.confirm_resend_email"))) {
        setShowLoading(true);
        const formData = new FormData();
        formData.append("appointment_id", appointment.id);
        const response = await AppointmentNotificationService.create(formData);
        setAppointment(response.data.appointment);
        ToastMessage.success(response.data.message);
        setShowLoading(false);
      }
    } else {
      const formData = new FormData();
      formData.append("appointment_id", appointment.id);

      try {
        setShowLoading(true);
        const response = await AppointmentNotificationService.create(formData);
        setAppointment(response.data.appointment);
        ToastMessage.success(response.data.message);
        setShowLoading(false);
      } catch (error) {
        ToastMessage.error(error.meta.message);
        setShowLoading(false);
      }
    }
  };

  const sendAllEmailToServiceProvider = async () => {
    if (window.confirm(I18n.t("form.appointment_notification.confirm_send_all_mail"))) {
      setShowLoading(true);
      const formData = new FormData();
      formData.append("patient_id", patient.id);
      const response = await AppointmentNotificationService.createBatch(formData);
      reload();
      ToastMessage.success(response.data.message);
      setShowLoading(false);
    }
  };

  const sendEmailToPatient = async () => {
    let confirm = false;
    if (appointment.has_notified_patient) {
      if (window.confirm(I18n.t("form.appointment_notification.confirm_resend_email"))) {
        confirm = true;
      }
    } else {
      confirm = true;
    }

    if (confirm) {
      setShowLoading(true);
      try {
        const response = await PatientAppointmentService.remindPatientAppointment(patient.id, appointment.id);
        ToastMessage.success(response.data.message);
        setShowLoading(false);
        setAllAppointments(response.data.appointments);
      } catch (error) {
        ToastMessage.error(error.meta.message);
        setShowLoading(false);
      }
    }
  };

  const sendConfirmAllPatient = async () => {
    setShowLoading(true);
    try {
      const response = await PatientService.remindPatientAppointment(patient.id);
      ToastMessage.success(response.data.message);
      setShowLoading(false);
      setAppointment(response.data.appointment);
    } catch (error) {
      ToastMessage.error(error.meta.message);
      setShowLoading(false);
    }
  };

  const sendConfirmAllAfterOpPatient = async () => {
    setShowLoading(true);
    try {
      const response = await PatientService.remindPatientAppointmentAfterOp(patient.id);
      ToastMessage.success(response.data.message);
      setShowLoading(false);
      setAppointment(response.data.appointment);
    } catch (error) {
      ToastMessage.error(error.meta.message);
      setShowLoading(false);
    }
  };

  const showSendEmailToPatient = () => {
    if (patient.communication_path != "email") {
      return false;
    }
    if (appointment.is_prom && appointmentTime) {
      return true;
    }

    if (patient.treatment_pathway?.is_hybrid_prom && appointmentTime) {
      return true;
    }

    if (isGreaterThanMidnight() == false || appointment.status != "active") {
      return false;
    }

    if (appointment.is_consultation) {
      return true;
    }
    if (appointment.is_after_operation) {
      return true;
    }

    return false;
  };

  const showPhoneNumber = () => {
    let phone_number = "";
    if (appointment.is_prom) {
      phone_number = patient.phone_number;
    } else if (appointment.service_provider && appointment.service_provider.is_internal) {
      phone_number = appointment.service_provider.phone_number;
    } else if (appointment.service_provider && !appointment.service_provider.is_internal) {
      phone_number = appointment.service_provider.phone_number_direct || appointment.service_provider.phone_number;
    }

    return (
      <Input
        className="phone-number-format"
        disabled
        country="CH"
        withCountryCallingCode
        international
        onChange={() => console.log(patient.phone_number)}
        value={"+" + phone_number}
      />
    );
  };

  const isGreaterThanMidnight = () => {
    return moment(appointmentTime).hours() > 0;
  };

  useEffect(() => {
    if (scrollTo) {
      const view = document.getElementById(`appointment-${appointment.id}`);
      view?.scrollIntoView();
    }
  }, [scrollTo]);

  const onHideEditAttachment = () => {
    setEditAttachment(false);
  };

  const isDeleted = () => {
    return appointment.status == "archived";
  };

  const isInternal = () => appointment.service_provider && appointment.service_provider.is_internal;

  const showSendMailOutsideServiceProvider = () => {
    if (isInternal() || appointment.is_prom) {
      return false;
    }
    return appointment.poll.color == "grey";
  };

  const hideDateTime = () => {
    return false;
    /*if (appointment.is_prom) {
      return false;
    }
    return (
      (appointment.poll.color == "grey" || appointment.poll.color == "orange") &&
      !appointment.service_provider?.is_internal
    );*/
  };

  const hideTime = () => {
    if (appointment.is_prom) {
      return false;
    }
    return (
      (appointment.poll.color == "grey" || appointment.poll.color == "orange") &&
      !appointment.service_provider?.is_internal
    );
  };

  const consultationCount = () => {
    const allAppointments = props.appointments;
    return allAppointments.filter((a) => a.is_consultation == true).length + 1;
  };

  const isDisableDatepicker = () => {
    if (appointment.is_prom && appointment.has_notified_patient) {
      return true;
    }

    if (appointment.is_after_operation && appointment.poll.color == "grey") {
      return false;
    }
    if (appointment.is_prom) {
      return false;
    }
    if (appointment.status == "archived") {
      return true;
    }
    return appointment.service_provider ? !appointment.service_provider.is_internal : true;
  };

  const isDisableTimepicker = () => {
    if (appointment.is_prom && appointment.has_notified_patient) {
      return true;
    }

    if (!appointment.service_provider?.is_internal && appointment.poll.color == "grey") {
      return true;
    }
    if (appointment.is_after_operation && appointment.poll.color == "grey") {
      return false;
    }
    if (appointment.is_prom) {
      return false;
    }
    if (appointment.status == "archived") {
      return true;
    }
    return appointment.service_provider ? !appointment.service_provider.is_internal : true;
  };

  const showDescription = () => {
    if (appointment.is_prom) {
      return false;
    }
    if (isDeleted()) {
      return false;
    }

    if (appointment.poll.color == "orange") {
      return false;
    }

    // if (appointment.service_provider && !appointment.service_provider.is_internal) {
    //   if (appointment.poll.color == "blue") {
    //     return false;
    //   }
    // }

    if (appointment.is_consultation) {
      return true;
      //return appointment.has_notified_patient ? false : true;
    } else if (appointment.is_part_2) {
      return true;
      //return patient.has_sent_mail_before_operation ? false : true;
    } else if (appointment.is_after_operation) {
      return true;
      //return appointment.has_notified_patient ? false : true;
    }
  };

  const hasNotifiedPatient = () => {
    if (patient.treatment_pathway?.is_hybrid_prom && appointment.has_notified_patient) {
      return true;
    }
    if (appointment.is_prom && appointment.has_notified_patient) {
      return true;
    }
    if (appointment.is_prom && !appointment.has_notified_patient) {
      return false;
    }
    if (appointment.service_provider?.is_internal) {
      if (appointment.is_consultation) {
        return appointment.has_notified_patient ? true : false;
      } else if (appointment.is_part_2) {
        return patient.has_sent_mail_before_operation ? true : false;
      } else if (appointment.is_after_operation) {
        return appointment.has_notified_patient ? true : false;
      }
    } else {
      if (appointment.is_after_operation) {
        return appointment.has_notified_patient;
      }
      return appointment.poll.color == "blue" ? true : false;
    }
  };

  const patientInactive = !patient.active || patient.active == false;
  const toggleIsFinalTime = async (e) => {
    try {
      setShowLoading(true);
      const formData = new FormData();
      formData.append("appointment[is_final_time]", e.target.checked);
      const response = await PatientAppointmentService.update(patient.id, appointment.id, formData);
      ToastMessage.success(response.data.message);
      reload();
      setShowLoading(false);
      props.reloadPatient();
    } catch (error) {
      ToastMessage.error(error.meta.message);
      setShowLoading(false);
    }
  };
  const toggleIsFinalDate = async (e) => {
    try {
      setShowLoading(true);
      const formData = new FormData();
      formData.append("appointment[is_final_date]", e.target.checked);
      const response = await PatientAppointmentService.update(patient.id, appointment.id, formData);
      ToastMessage.success(response.data.message);
      reload();
      setShowLoading(false);
      props.reloadPatient();
    } catch (error) {
      console.log(error);
      ToastMessage.error(error.meta.message);
      setShowLoading(false);
    }
  };

  const showFinalDateTime = () => {
    return appointment.treatment?.has_final_date && appointment.poll.color == "blue";
  };

  const downloadPdfPatient = async () => {
    try {
      setShowLoading(true);
      const response = await PatientService.getPdf(patient.id);
      const { url } = response.data;
      window.open(url, "_blank", "noopener,noreferrer");
      reload();
      setShowLoading(false);
      props.reloadPatient();
    } catch (error) {
      console.log(error);
      ToastMessage.error(error.meta.message);
      setShowLoading(false);
    }
  };
  const downloadPdfPatientAfterOP = async () => {
    try {
      setShowLoading(true);
      const response = await PatientService.getPdfAfterOp(patient.id);
      const { url } = response.data;
      window.open(url, "_blank", "noopener,noreferrer");
      reload();
      setShowLoading(false);
      props.reloadPatient();
    } catch (error) {
      console.log(error);
      ToastMessage.error(error.meta.message);
      setShowLoading(false);
    }
  };

  const maxDateOption = () => {
    if (appointment.is_prom && patient.treatment_pathway?.only_prom) {
      const futureDate = new Date();
      futureDate.setFullYear(futureDate.getFullYear() + 100);

      // Return the future date
      return futureDate;
    }
    if (appointment.is_after_operation) {
      return undefined;
    }
    if (appointment.forecast_appointment_time) {
      return new Date(appointment.forecast_appointment_time);
    } else {
      if (appointment.is_consultation) {
        return undefined;
      }
    }
  };

  const minDateOption = () => {
    if (appointment.is_prom && patient.treatment_pathway?.only_prom) {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
      const day = today.getDate().toString().padStart(2, "0");

      return new Date(`${year}/${month}/${day}`);
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    if (hasBorder && appointment.status == "active" && appointment.appointment_date) {
      setScrollBorderClass("blue-border");
      const view = document.getElementById(`appointment-${appointment.id}`);
      view?.scrollIntoView();
    }
    if (props.scrollId == appointment.id) {
      setScrollBorderClass("blue-border");
      const view = document.getElementById(`appointment-${appointment.id}`);
      view?.scrollIntoView();
    }
  }, [props.scrollId, hasBorder]);

  useEffect(() => {
    if (scrollFirst) {
      const view = document.getElementById(`appointment-${appointment.id}`);
      setScrollBorderClass("blue-border");
      view?.scrollIntoView();
    }
  }, [scrollFirst]);

  const showTime = () => {
    if (appointment.is_prom) {
      return false;
    }
    if (appointment.service_provider?.is_internal) {
      return true;
    }
    if (appointment.active) {
      return true;
    }
    return false;
  };

  const renderShowEmailPatient = () => {
    if (showSendEmailToPatient()) {
      if (!hasNotifiedPatient()) {
        return (
          <TooltipWrapper tooltipId="tooltip-send-mail-to-patient">
            <a onClick={sendEmailToPatient} eventKey="3">
              <span className="d-flex" style={{ gap: 0, cursor: "pointer" }}>
                {appointment.kom_package_number > 0 ? (
                  appointment.can_show_kom_package_email && (
                    <>
                      <ExclamationOutlined className="blink_me" style={{ color: "red" }} />
                      <FilePdfOutlined className="blink_me" />
                    </>
                  )
                ) : (
                  <>
                    <ExclamationOutlined className="blink_me" style={{ color: "red" }} />
                    <MailOutlined className="blink_me" />
                  </>
                )}
              </span>
            </a>
          </TooltipWrapper>
        );
      } else {
        return (
          <TooltipWrapper tooltipId="tooltip-sent-mail-to-patient">
            <a onClick={sendEmailToPatient} eventKey="3">
              <span className="d-flex" style={{ gap: 0, cursor: "pointer" }}>
                <ExclamationOutlined className="blink_me" style={{ color: "white" }} />
                {appointment.kom_package_number > 0 ? <FilePdfTwoTone /> : <MailTwoTone />}
              </span>
            </a>
          </TooltipWrapper>
        );
      }
    } else {
      return <p></p>;
    }
  };

  const showRelationIcon = () => {
    if (appointment.treatment?.treatment_ids?.length > 0) {
      return <img src={ParentIcon} width={20} height={20} />;
    }
    if (props.isChildren) {
      return <img src={ChildIcon} width={20} height={20} />;
    }

    return <p></p>;
  };

  const showSelectedDate = () => {
    if (appointment.prom_responsed_time) {
      return new Date(appointment.prom_responsed_time);
    }
    return appointmentDate || forecastDate;
  };

  const showSelectedTime = () => {
    if (appointment.prom_responsed_time) {
      return new Date(appointment.prom_responsed_time);
    }
    return appointmentTime || forecastTime;
  };

  const hideDateTimeClass = () => {
    if (appointment.is_done_prom) {
      return "";
    }
    if (appointment.is_prom && appointment.has_notified_patient && !appointment.prom_responsed_time) {
      return "d-none";
    } else {
      return "";
    }
  };

  const hideTimeClass = () => {
    if (appointment.is_prom) {
      return "d-none";
    } else {
      return "";
    }
  };

  return (
    <div className={`step ${isDeleted() ? "deleted-step" : ""}`} id={`appointment-${appointment.id}`}>
      <Tooltip id="tooltip-more-function" content={I18n.t("tooltip.more_function")} />
      <Tooltip id="tooltip-mail-to-provider" content={I18n.t("tooltip.send_mail_to_service_provider")} />
      <Tooltip id="tooltip-mail-to-all-provider" content={I18n.t("tooltip.send_mail_to_all_service_provider")} />
      <Tooltip id="tooltip-provider-document" content={I18n.t("tooltip.document_service_provider")} />
      <Tooltip id="tooltip-description" content={I18n.t("activerecord.attributes.appointment.description")} />
      <Tooltip id={`tooltip-poll-color`} content={I18n.t("tooltip.poll_color")} />
      <Tooltip id="appointment-final-date" content={I18n.t("tooltip.appointment_final_date")} />
      <Tooltip id={`tooltip-send-mail-to-patient`} content={I18n.t("tooltip.send_mail_to_patient")} />
      <Tooltip id={`tooltip-sent-mail-to-patient`} content={I18n.t("tooltip.sent_mail_to_patient")} />
      <Tooltip id={`tooltip-is-done-prom`} content={I18n.t("tooltip.is_done_prom")} />
      <Tooltip id={`tooltip-part-2`} content={I18n.t("tooltip.part_2")} />

      <ConfirmPopup
        updatePopupValue={(e) => setConfirmPopupValue(e)}
        metaType={"child-treatment"}
        metaData={appointment.treatment?.treatment_ids?.length > 0 ? appointment.treatment?.treatment_ids : []}
        appointments={props.appointments}
        question={confirmMessage}
        setConfirmAppointmentIds={(e) => setConfirmAppointmentIds(e)}
        show={showConfirmPopup}
        onHide={() => setShowConfirmPopup(false)}
      />

      <span className={`index ${active ? "index-active" : ""}`}>{props.index}</span>
      <TooltipWrapper tooltipId="tooltip-poll-color">
        <div className={`circle-base poll-${appointment.poll.color} ${patientInactive && `poll-grey`}`}></div>
      </TooltipWrapper>
      <div
        className={`${props.appointments.length > 1 ? "vertical-line" : ""} ${props.firstItem ? "first-step" : ""} ${
          props.lastItem ? (props.isDraggable ? "last-step-drag" : "last-step") : ""
        }`}
      ></div>
      <TooltipWrapper tooltipId="tooltip-poll-color">
        {appointment.poll.check && patient.active == true && <CheckOutlined />}
      </TooltipWrapper>
      <div className={`step-row row  ${scrollBorderClass}`}>
        <div className="name field col-md-3">
          {appointment.treatment?.icon?.url && <img src={appointment.treatment?.icon?.url} width="25" height="25" />}
          {appointment.treatment?.name || appointment.name}
        </div>
        {!appointment.is_operation && !appointment.is_prom && (
          <div ref={spRef} className="metadata field col-md-3">
            <Tooltip
              anchorId={`tooltip-provider-${appointment.id}`}
              content={
                appointment.service_provider?.is_internal
                  ? I18n.t("enumerize.service_provider.service_type.internal")
                  : I18n.t("enumerize.service_provider.service_type.external")
              }
            />
            {appointment.service_provider?.is_internal ? (
              <LoginOutlined id={`tooltip-provider-${appointment.id}`} />
            ) : (
              <LogoutOutlined id={`tooltip-provider-${appointment.id}`} />
            )}
            {providers.length > 0 && (
              <ServiceProviderSelection
                data={providers}
                groupData={appointment.treatment?.selected_service_providers || []}
                className="selection form-control form-select"
                setProvider={updateProvider}
                isInternal={appointment.is_consultation || patient.treatment_pathway?.is_hybrid_prom}
                provider={provider}
                goBack={`/patients/${patient.id}/profile`}
                appointmentId={appointment.id}
                createNew={true}
                isDisabled={isDeleted()}
              />
            )}
          </div>
        )}
        {(appointment.is_operation || appointment.is_prom) && <div className="col-md-3"></div>}
        {((patient.activated_operation_appointment && appointment.is_operation) ||
          (appointment.is_operation && showOperationDate) ||
          (appointment.is_operation && appointmentDate)) && (
          <div
            className={`datetime field col-md-3 ${
              appointment.poll.color == "grey" || appointment.poll.color == "orange" ? "datetime-italic" : ""
            }`}
          >
            <DatePicker
              ref={operationDateRef}
              placeholderText="TT.MM.JJJJ"
              disabled={!props.changeOperationAppointment}
              dropdownMode="select"
              minDate={new Date()}
              dateFormat={"dd.MM.yyyy"}
              className="form-control date"
              // filterDate={Utils.isWeekday}
              selected={appointmentDate}
              onChange={updateAppointmentDate}
              autoFocus={showOperationDate}
            />
            {showFinalDateTime() && (
              <TooltipWrapper tooltipId="appointment-final-date">
                <input
                  className="form-check-input input-operation"
                  type="checkbox"
                  role="switch"
                  onChange={toggleIsFinalDate}
                  defaultChecked={appointment.is_final_date}
                />
              </TooltipWrapper>
            )}
          </div>
        )}

        {!patient.activated_operation_appointment && appointment.is_operation && <div className="col-md-3"></div>}
        {!appointment.is_operation && (
          <div
            className={`datetime field col-md-3 ${isForecast && "forecast-datetime"} ${
              appointment.status != "active" && "datetime-italic"
            }`}
            style={{
              cursor: props.disableDateTime ? "not-allowed" : "pointer",
            }}
          >
            <div className={`wrapper  ${hideDateTimeClass()}`}>
              <DatePicker
                disabled={isDisableDatepicker()}
                placeholderText="TT.MM.JJJJ"
                dropdownMode="select"
                maxDate={maxDateOption()}
                minDate={minDateOption()}
                dateFormat={"dd.MM.yyyy"}
                filterDate={Utils.isWeekday}
                className="form-control date"
                selected={showSelectedDate()}
                onChange={updateAppointmentDate}
              />
              {showFinalDateTime() && (
                <TooltipWrapper tooltipId="appointment-final-date">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    onChange={toggleIsFinalDate}
                    defaultChecked={appointment.is_final_date}
                  />
                </TooltipWrapper>
              )}
            </div>
            <div className={`wrapper  ${hideTimeClass()}`}>
              <DatePicker
                disabled={isDisableTimepicker()}
                showTimeSelect
                placeholderText={showTime() ? "00:00" : ""}
                showTimeSelectOnly
                timeIntervals={30}
                timeCaption="Time"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                minTime={setHours(setMinutes(new Date(), 0), 6)}
                maxTime={setHours(setMinutes(new Date(), 0), 22)}
                className={`form-control time`}
                selected={showSelectedTime()}
                onChange={updateAppointmentTime}
              />
              {showFinalDateTime() && (
                <TooltipWrapper tooltipId="appointment-final-date">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    onChange={toggleIsFinalTime}
                    defaultChecked={appointment.is_final_time}
                  />
                </TooltipWrapper>
              )}
            </div>
          </div>
        )}
        {appointment.is_prom && <div className="col-md-1"></div>}
        {!appointment.is_operation && !appointment.is_prom && !patientInactive && (
          <div className="attachment field col-md-1">
            <AppointmentAttachment
              allowEdit={!hasNotifiedPatient()}
              reload={() => reload()}
              setAppointment={(a) => setAppointment(a)}
              attachments={appointment.attachments}
              patientId={patient.id}
              appointmentId={appointment.id}
              show={editAttachment}
              reloadAppointment={() => reloadAppointment()}
              onHide={() => onHideEditAttachment()}
            />
            {isDeleted() ? (
              <TooltipWrapper tooltipId="tooltip-provider-document">
                <PaperClipOutlined />
              </TooltipWrapper>
            ) : (
              <TooltipWrapper tooltipId="tooltip-provider-document">
                <PaperClipOutlined
                  style={{
                    color: appointment.has_attachments ? "#1772fd" : "",
                  }}
                  onClick={() => setEditAttachment(true)}
                />
              </TooltipWrapper>
            )}
            {appointment.is_part_2 && appointment.treatment?.name != "Spitaleintritt" ? (
              <TooltipWrapper tooltipId="tooltip-part-2">
                <img src={AttachImage} width="15" height="15" />
              </TooltipWrapper>
            ) : (
              !appointment.is_after_operation && <span style={{ width: 15 }}></span>
            )}

            {appointment.kom_package_number > 0 && (
              <TooltipWrapper tooltipId="tooltip-part-2">
                <div style={{ cursor: "help", width: "15px", display: "flex", justifyContent: "center" }}>
                  {convertToRoman(appointment.kom_package_number)}
                </div>
              </TooltipWrapper>
            )}
            {appointment.is_after_operation && <div style={{ width: 15 }}></div>}
            {appointment.need_result && !patientInactive && (
              <>
                <input
                  className="form-check-input"
                  id={`tooltip-need-result-${appointment.id}`}
                  type="checkbox"
                  role="switch"
                  onChange={toggleResultResult}
                  defaultChecked={receivedResult}
                />
                <Tooltip
                  anchorId={`tooltip-need-result-${appointment.id}`}
                  content={I18n.t("tooltip.receive_result")}
                />
              </>
            )}
          </div>
        )}
        {patient.activated_operation_appointment && appointment.is_operation && !patientInactive && (
          <div className="action field col-md-3" style={{ padding: "5px 20px", justifyContent: "space-between" }}>
            {appointment.can_send_treatment_step_2_mail && patient.communication_path == "email" && (
              <span
                onClick={() => sendConfirmAllPatient()}
                style={{ gap: 5, cursor: "pointer" }}
                className={`d-flex align-items-center ${!appointment.has_sent_mail_before_operation && "blink_me"}`}
              >
                <span className="d-flex align-items-center">
                  {appointment.has_sent_mail_before_operation ? (
                    <SendOutlined style={{ color: "#0d6efd" }} />
                  ) : (
                    <WarningOutlined style={{ color: "red" }} />
                  )}
                </span>
                <span style={{ fontSize: "0.8rem" }}>
                  {appointment.has_sent_mail_before_operation
                    ? I18n.t("form.appointment.resend_mail_to_patient")
                    : I18n.t("form.appointment.send_mail_to_patient")}
                </span>
              </span>
            )}
            {appointment.can_send_treatment_step_2_mail && patient.communication_path == "post" && (
              <span
                onClick={() => downloadPdfPatient()}
                style={{ gap: 5, cursor: "pointer" }}
                className={`d-flex align-items-center ${!appointment.has_sent_mail_before_operation && "blink_me"}`}
              >
                <span className="d-flex align-items-center">
                  {appointment.has_sent_mail_before_operation ? (
                    <DownloadOutlined style={{ color: "#0d6efd" }} />
                  ) : (
                    <DownloadOutlined style={{ color: "red" }} />
                  )}
                </span>
                <span style={{ fontSize: "0.8rem" }}>{I18n.t("form.appointment.download_pdf_for_patient")}</span>
              </span>
            )}
            <TooltipWrapper tooltipId="tooltip-more-function" className="tooltip-more-function-op">
              <Dropdown className="dropdown-appointment">
                <Dropdown.Toggle as={CustomToggle} />
                <Dropdown.Menu>
                  {/* <Dropdown.Item onClick={() => clearDateTime()} eventKey={`${appointment.id}-0`}>
                      <FileExclamationOutlined />
                      {I18n.t("form.toggle")} {I18n.t("activerecord.attributes.treatment.need_result")}
                    </Dropdown.Item> */}

                  <Dropdown.Item onClick={() => setShowLogfile(true)} eventKey={`${appointment.id}-5`}>
                    <ProfileOutlined />
                    Log
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </TooltipWrapper>
          </div>
        )}
        {!appointment.is_operation && !patientInactive && (
          <div className="action field col-md-2">
            <div className="row">
              <div className="col-md-2" style={{ paddingLeft: 0, marginTop: 5, minWidth: 31 }}>
                {showRelationIcon()}
              </div>
              <div className="col-md-3" style={{ minWidth: 31 }}>
                {showSendMailOutsideServiceProvider() ? (
                  <>
                    <TooltipWrapper tooltipId="tooltip-mail-to-provider">
                      <SendOutlined onClick={sendEmailToServiceProvider} />
                    </TooltipWrapper>
                    {appointment.is_part_2 && (
                      <TooltipWrapper tooltipId="tooltip-mail-to-all-provider">
                        <DoubleRightOutlined onClick={sendAllEmailToServiceProvider} />
                      </TooltipWrapper>
                    )}
                  </>
                ) : (
                  <p></p>
                )}
              </div>
              <div className="col-md-2" style={{ minWidth: 31 }}>
                {showDescription() && appointment.description ? (
                  hasNotifiedPatient() ? (
                    <TooltipWrapper tooltipId="tooltip-description">
                      <MessageOutlined
                        id={`tooltip-description-${appointment.id}`}
                        onClick={() => setEditDescription(true)}
                      />
                    </TooltipWrapper>
                  ) : (
                    <TooltipWrapper tooltipId="tooltip-description">
                      <MessageTwoTone
                        id={`tooltip-description-${appointment.id}`}
                        onClick={() => setEditDescription(true)}
                      />
                    </TooltipWrapper>
                  )
                ) : (
                  <p></p>
                )}
                <Description
                  show={editDescription}
                  allowEdit={!hasNotifiedPatient()}
                  onHide={() => setEditDescription(false)}
                  patientId={patient.id}
                  id={appointment.id}
                  attachments={appointment.attachments}
                  description={appointment.description}
                  setAppointment={(e) => setAppointment(e)}
                />
              </div>
              <div className="col-md-2" style={{ minWidth: 31 }}>
                {renderShowEmailPatient()}
              </div>
              <div className="col-md-3" style={{ minWidth: 31 }}></div>
            </div>
            <div className="action-btn">
              <TooltipWrapper tooltipId="tooltip-more-function">
                <Dropdown className="dropdown-appointment">
                  <Dropdown.Toggle as={CustomToggle} />
                  <Dropdown.Menu>
                    {!isDeleted() && !appointment.is_prom && (
                      <Dropdown.Item onClick={() => toggleNeedResult()} eventKey={`${appointment.id}-0`}>
                        <FileExclamationOutlined />
                        {I18n.t("form.toggle")} {I18n.t("activerecord.attributes.treatment.need_result")}
                      </Dropdown.Item>
                    )}
                    {!appointment.is_prom && (
                      <Dropdown.Item
                        className={showDescription() && !hasNotifiedPatient() ? undefined : "disabled"}
                        onClick={() => setEditDescription(true)}
                        eventKey={`${appointment.id}-1`}
                      >
                        <MessageOutlined /> {I18n.t("form.appointment.new_description")}
                      </Dropdown.Item>
                    )}
                    {appointment.is_prom && (
                      <Dropdown.Item
                        eventKey={`${appointment.id}-1`}
                        onClick={() => window.open(appointment.survey_url)}
                      >
                        <EyeOutlined /> {I18n.t("form.appointment.view")}
                      </Dropdown.Item>
                    )}
                    {appointment.is_prom && (
                      <Dropdown.Item
                        eventKey={`${appointment.id}-1`}
                        onClick={() => window.open(appointment.survey_url + "?download=true")}
                      >
                        <DownloadOutlined /> Download
                      </Dropdown.Item>
                    )}
                    {!appointment.is_prom && (
                      <Dropdown.Item onClick={() => alert("Calling")} eventKey={`${appointment.id}-2`}>
                        <PhoneOutlined /> {showPhoneNumber()}{" "}
                      </Dropdown.Item>
                    )}

                    {(appointment.poll.color == "orange" || appointment.poll.color == "blue") &&
                      !appointment.service_provider?.is_internal && (
                        <Dropdown.Item onClick={sendEmailToServiceProvider} eventKey={`${appointment.id}-3`}>
                          <SendOutlined className="blink_me" />
                          {I18n.t("form.service_provider.send_mail")}
                        </Dropdown.Item>
                      )}
                    {
                      <Dropdown.Item onClick={handleDeleteAppointment} eventKey={`${appointment.id}-4`}>
                        <DeleteOutlined style={{ color: isDeleted() ? "#0d6efd" : "" }} /> {I18n.t("form.delete")}{" "}
                      </Dropdown.Item>
                    }
                    <Dropdown.Item onClick={() => setShowLogfile(true)} eventKey={`${appointment.id}-5`}>
                      <ProfileOutlined />
                      Log
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </TooltipWrapper>
            </div>
          </div>
        )}

        {loading && <Spinner animation="border" variant="primary" />}

        {appointment.is_first_appointment && !patient.activated_operation_appointment && (
          <span className="new-consultation">
            <span className="d-flex align-items-center" onClick={() => setModalShow(true)}>
              <PlusCircleOutlined />
              <span>{I18n.t("form.treatment.new_consultation")}</span>
            </span>
            <AppointmentModalForm
              noDocument={true}
              reload={() => reload()}
              show={modalShow}
              onHide={() => setModalShow(false)}
              patient={patient}
              defaultProviderSelection={provider}
              appointmentName={consultationCount() + ". " + I18n.t("form.appointment.new_consultation")}
              isConsultationAppointment={true}
            />
          </span>
        )}
      </div>
      <LogfileModal
        show={showLogfile}
        onHide={() => setShowLogfile(false)}
        target_id={appointment.id}
        type="Appointment"
      />
      {draft && !loading && (
        <>
          <Tooltip anchorId={`tooltip-save-change-${appointment.id}`} content={I18n.t("tooltip.save_change")} />
          <SaveTwoTone
            id={`tooltip-save-change-${appointment.id}`}
            onClick={() => handleUpdateTime(appointmentDate, appointmentTime)}
            className="draft"
          />
        </>
      )}
    </div>
  );
};

export default Treatment;
