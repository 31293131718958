import httpClient from '../utils/http-common';

const API_URL = '/storages';

const destroy = (id) => {
  return httpClient.delete(`${API_URL}/${id}`);
};

const StorageService = {
  destroy
};

export default StorageService;
