import React, { } from "react";
import DepartmentForm from "./_form";

const DepartmentCreate = (props) => {

    return (
        <DepartmentForm
            title={''}
            {...props}
            departmentId={undefined}
        />
    );
};

export default DepartmentCreate;