import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import toastMessage from "./toast-message";
import UserService from "../services/user";

const SupportMail = () => {
  let navigate = useHistory();

  const [email, setEmail] = useState("");

  const [placeholder, _] =
    useState(`Bitte formulieren Sie Ihre Anfrage möglichst detailliert.\nVielen Dank\n\nIhr EFFIPATH Team
`);
  const [loading, setLoading] = useState(false);

  const handleSupportMail = async (e) => {
    setLoading(true);
    try {
      const response = await UserService.supportMail({ content: email });
      setLoading(false);
      toastMessage.success(response.data.message);
      navigate.push("/");
    } catch (error) {
      setLoading(false);
      toastMessage.error(error.meta.message);
    }
  };

  return (
    <div className="row mt-5 text-center">
      <div className="col-md-4"></div>
      <div className="col-md-4 p-3">
        {/* <h4 className="text-center">{I18n.t("devise.passwords.new.forgot_your_password")}</h4> */}
        <div className="form-group">
          <textarea
            rows="9"
            className="form-control"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={placeholder}
          />
        </div>

        <button onClick={handleSupportMail} className="btn btn-primary mt-3" disabled={loading}>
          {loading && <span className="spinner-border spinner-border-sm"></span>}
          <span>{I18n.t("form.appointment_notification.submit")}</span>
        </button>
      </div>
    </div>
  );
};

export default SupportMail;
