import React, { } from "react";
import { useParams } from 'react-router-dom';
import TreatmentPathwayForm from "./_form";

const TreatmentPathwayUpdate = (props) => {
    const { id } = useParams()

    return (
        <TreatmentPathwayForm
            treatmentId={id}
            {...props}
        />
    )
};

export default TreatmentPathwayUpdate;