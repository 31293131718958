import React, { useState, useEffect } from "react";
import toastMessage from "../../components/toast-message";
import Select from "react-select";
import ServiceProviderService from "../../services/service_provider";
import SpecialistService from "../../services/specialist";
import TreatmentService from "../../services/treatment-pathway";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { filter } from "lodash";

const DashboardAgendaSelectedFilter = ({
  queries,
  setSelectedFilters,
  setQueries,
  selectedFilter,
  filterType,
  ...props
}) => {
  const [selectValues, setSelectValues] = useState(undefined);
  const [options, setOptions] = useState([]);
  const [isMulti, setIsMulti] = useState(true);
  const [deadline, setDeadline] = useState();
  const [dateFrom, setDateFrom] = useState(undefined);
  const [dateTo, setDateTo] = useState(undefined);

  const mapQuery = {
    deadline: "deadline",
    case_guide: "service_provider_id_in",
  };

  const onChange = (e, triggerAction) => {
    if (triggerAction.name == "date-from" || triggerAction.name == "date-to") {
      if (triggerAction.name == "date-from") {
        setDateFrom(e);
      } else {
        setDateTo(e);
      }
    }
    setSelectValues(e);
    const newq = { ...queries };
    let values = Array.isArray(e) ? e.map((e) => e.value) : e.value;
    const local = {};
    local[mapQuery[filterType]] = values;

    if (triggerAction == "clear" || e.length == 0) {
      delete newq[mapQuery[filterType]];
      setQueries(newq);
      setSelectedFilters(filterType);
      return;
    }

    if (filterType == "deadline") {
      const newq = { ...queries };
      const dateString = `${e.getFullYear()}-${String(e.getMonth() + 1).padStart(2, "0")}-${String(
        e.getDate(),
      ).padStart(2, "0")}`;

      // Convert the local date string to a UTC date
      const utcDate = new Date(dateString + "T00:00:00Z");
      if (triggerAction.name == "date-from") {
        setQueries({
          ...newq,
          from: utcDate,
          prom: true,
        });
      } else {
        setQueries({
          ...newq,
          to: utcDate,
          prom: true,
        });
      }
      return;
    }

    if (mapQuery[filterType]) {
      setQueries({
        ...newq,
        ...local,
      });
      return;
    }
  };

  useEffect(() => {
    setSelectValues(null);
    if (filterType == "case_guide") {
      setIsMulti(true);
      ServiceProviderService.index().then(
        (response) => {
          let p = response.data.service_providers;
          setOptions(
            p
              .filter((pp) => pp.is_internal)
              .map((pp) => {
                return {
                  value: pp.id,
                  label: pp.display_name,
                };
              }),
          );
        },
        (error) => {
          toastMessage.error(error.meta.message);
        },
      );
    }
  }, [filterType]);

  const onChangeDeadline = (date) => {
    setDeadline(date);
    const newq = { ...queries };
    setQueries({
      ...newq,
      deadline: date,
    });
  };

  return (
    <div className="selected-filter-item col">
      <span>{selectedFilter?.translation || I18n.t(`dashboard.${selectedFilter?.key}`)}</span>
      <div className="col">
        {selectedFilter?.key != "deadline" && (
          <Select
            value={selectValues}
            style={{ width: "100%" }}
            onChange={onChange}
            options={options}
            className="react-select-dashboard"
            classNamePrefix="react-select"
            placeholder=""
            isMulti={isMulti}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
        )}

        {selectedFilter.key == "deadline" && (
          <div className="prom d-flex" style={{ gap: 20 }}>
            <DatePicker
              placeholderText={I18n.t("dashboard.date_from")}
              dropdownMode="select"
              dateFormat={"dd.MM.yyyy"}
              className="form-control"
              id="date-from"
              name="date-from"
              selected={dateFrom}
              onChange={(date) => onChange(date, { id: "date-from", name: "date-from" })}
            />
            ~
            <DatePicker
              placeholderText={I18n.t("dashboard.date_to")}
              dropdownMode="select"
              dateFormat={"dd.MM.yyyy"}
              className="form-control"
              id="date-to"
              name="date-to"
              selected={dateTo}
              onChange={(date) => onChange(date, { id: "date-to", name: "date-to" })}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardAgendaSelectedFilter;
