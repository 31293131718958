import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const VerifyBackupOtpModal = ({ submitBackupCode, ...props }) => {
  const [code, setCode] = useState("");

  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton className="modal-header">
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Backup Code</Form.Label>
            <Form.Control value={code} onChange={(e) => setCode(e.target.value)} type="text" autoFocus />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "space-between" }}>
        <Button type="submit" variant="primary" onClick={() => submitBackupCode(code)}>
          {I18n.t("form.update")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VerifyBackupOtpModal;
