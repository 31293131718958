import React, { useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import './create.scss'
import ServiceProviderForm from "./_form";

const ServiceProviderCreate = (props) => {
    let navigate = useHistory();
    const { id } = useParams();
    const [error, setError] = useState("");

    const [providerId, setProviderId] = useState(id);
    return (
        <ServiceProviderForm
            {...props}
            providerId={providerId}
            title={I18n.t('form.service_provider.new_service_provider')}
            submitLabel={I18n.t('form.create')}
        />
    )
}

export default ServiceProviderCreate;