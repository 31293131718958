import React from 'react';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader';
import authClient from '../../utils/auth-common';
import ToastMessage from '../../components/toast-message';
import { DeleteOutlined, FileAddFilled, UploadOutlined } from '@ant-design/icons';
import StorageService from '../../services/storage';

const Preview = ({ meta }) => {
    const { name, percent, status } = meta
    return (
        <span style={{ alignSelf: 'flex-start', margin: '10px 3%', fontFamily: 'Helvetica' }}>
            {name}, {Math.round(percent)}%, {status}
        </span>
    )
}

export default ({ path, serverFiles, accept, ...props }) => {
    const getUploadParams = () => {
        const headers = Object.assign({
            'X-Tenant-Id': {
                toString() {
                    return localStorage.getItem('X-Tenant-Id')
                }
            }
        }, authClient.retrieveData('authHeaders'))
        return { url: `${process.env.APP_URL}/api/v1` + path, headers }
    }

    const handleChangeStatus = ({ meta, remove }, status) => {
        if (status === 'headers_received') {
            props.reload();
            ToastMessage.success(I18n.t('form.treatment.upload_document_success'));
            remove()
        }
    }

    const removeFileServer = async (id) => {
        const response = await StorageService.destroy(id)
        if (response.data.success) {
            ToastMessage.success(response.data.message)
            props.reload();
        }
    }

    return (
        <>
            <Dropzone
                accept={accept}
                getUploadParams={getUploadParams}
                inputWithFilesContent={<FileAddFilled />}
                onChangeStatus={handleChangeStatus}
                PreviewComponent={Preview}
                inputContent={<UploadOutlined />}
                key={props.key}
                disabled={files => files.some(f => ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status))}
            />
            {serverFiles && serverFiles.length > 0 && <ul className="card p-3 mt-3">
                <div className="existed-files">
                    {serverFiles.map(f => {
                        return (
                            <div key={`${f.id}`} className="preview-item d-flex justify-content-between">
                                <li><a target="_blank" href={f.url}>{f.filename}</a></li>
                                <DeleteOutlined onClick={() => removeFileServer(f.id)} className="mr-3" />
                            </div>)
                    })}
                </div>
            </ul>}
        </>
    )
}