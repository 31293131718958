import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import "./appointment-form.scss";
import "react-datepicker/dist/react-datepicker.css";
import ToastMessage from "../../components/toast-message";
import PatientAppointmentService from "../../services/patient_appointment";
import Uploader from "../../components/uploader";
import { useState } from "react";
import toastMessage from "../../components/toast-message";
import Spinner from "react-bootstrap/Spinner";

const AppointmentAttachment = ({
  allowEdit,
  patientId,
  appointmentId,
  reloadAppointment,
  setAppointment,
  ...props
}) => {
  const [files, setFiles] = useState([]);
  const [fileIds, setFileIds] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (props.show) {
      PatientAppointmentService.show(patientId, appointmentId).then(
        (response) => {
          setAttachments(response.data.appointment.attachments);
          setLoading(false);
          props.reload();
        },
        (error) => {
          toastMessage.error(error.meta.message);
        },
      );
    }
  }, [props.show, reload]);

  const handleCreate = (e) => {
    e.preventDefault();

    const formData = new FormData();

    if (fileIds.length == 0) {
      props.onHide();
      return;
    }
    for (let i = 0; i < fileIds.length; i++) {
      formData.append("appointment[file_ids][]", fileIds[i].id);
    }

    Object.keys(files).forEach((f) => {
      formData.append("appointment[attachments][]", files[f]);
    });

    PatientAppointmentService.uploadDocuments(patientId, appointmentId, formData).then(
      (res) => {
        props.onHide();
        setAppointment(res.data.appointment);
        ToastMessage.success(res.data.message);
        setFileIds([]);
      },
      (error) => {
        ToastMessage.error(error.meta.message);
      },
    );
  };

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton className="modal-header">
        <Modal.Title id="contained-modal-title-vcenter">{I18n.t("form.treatment.document")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && (
          <div className="text-center">
            <Spinner className="mt-5" animation="border" variant="primary" />
          </div>
        )}
        <div className="appointment-create-box">
          <div className="box-body">
            <Uploader
              allowEdit={allowEdit}
              reload={() => setReload(!reload)}
              serverFiles={attachments}
              departmentId={window.cookies["cookies"]["last_department_id"]}
              multiple={true}
              id={appointmentId}
              api={PatientAppointmentService}
              setFileIds={(e) => setFileIds(e)}
              documentType="pdf"
              setFiles={(e) => setFiles(e)}
              preview={true}
              path={`/patients/${patientId}/appointments/${appointmentId}/upload_document`}
            />
            {!allowEdit && <p>{I18n.t("form.appointment.document_no_edit")}</p>}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!allowEdit && (
          <Button variant="secondary" onClick={props.onHide}>
            {I18n.t("form.abort")}
          </Button>
        )}
        {allowEdit && (
          <div
            onClick={handleCreate}
            className="create-patient d-flex align-items-baseline justify-content-between btn btn-lg mt-3 fw-bolder float-end bg-green"
          >
            <span>{I18n.t("form.update")}</span>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AppointmentAttachment;
