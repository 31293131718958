import React, { } from "react";
import TenantForm from './_form'

const TenantCreate = (props) => {
    return (
        <TenantForm
            {...props}
            title={I18n.t('form.tenant.new_tenant')}
            submitLabel={I18n.t('form.create')}
        />
    );
};

export default TenantCreate;