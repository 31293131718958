import React from "react";

const OtherGenderIcon = ({ color }) => {
    return (<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="40px" viewBox="0 0 225.000000 225.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
            fill={color ? color : '#4B4583'} stroke="none">
            <path d="M480 1777 l0 -153 45 -44 45 -44 0 119 0 120 80 -80 80 -79 -60 -61
   c-33 -33 -60 -65 -60 -70 0 -5 12 -22 27 -37 l27 -28 66 65 66 65 33 -34 33
   -34 -25 -34 c-46 -60 -62 -115 -62 -213 0 -76 5 -99 27 -147 47 -101 150 -184
   251 -203 l37 -7 0 -59 0 -59 -90 0 -90 0 0 -40 0 -39 88 -3 87 -3 3 -92 3 -93
   39 0 39 0 3 93 3 92 88 3 87 3 0 39 0 39 -87 3 -88 3 0 54 0 53 57 18 c75 23
   118 51 172 111 65 72 89 142 84 250 -3 73 -8 94 -37 153 l-35 67 157 157 157
   157 0 -125 0 -124 40 39 40 39 0 153 0 153 -153 0 -153 0 -39 -40 -39 -40 117
   0 c64 0 117 -3 117 -8 0 -4 -67 -75 -150 -157 l-150 -149 -67 33 c-86 43 -172
   51 -259 26 -32 -9 -70 -23 -83 -32 -22 -14 -25 -13 -60 21 l-36 36 60 60 59
   60 -32 32 -32 32 -60 -59 -60 -60 -83 83 -82 82 125 0 124 0 -44 45 -44 45
   -153 0 -153 0 0 -153z m730 -278 c61 -18 131 -78 162 -138 90 -172 -40 -391
   -232 -391 -170 0 -294 132 -277 295 17 168 186 282 347 234z"/>
        </g>
    </svg>)
}

export default OtherGenderIcon;

