import React, { useState, useEffect } from "react";
import TenantService from "../../services/tenant";
import AlertMessage from "../../components/alert-message";
import { Link, useLocation, useHistory } from "react-router-dom";
import AuthService from "../../services/auth";
import Pagination from "../../components/pagination";
import TenantCache from "../../cache/tenant_cache";
import Card from "react-bootstrap/Card";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import ClinicImage from "images/Clinic.png";
import "./index.scss";
import toastMessage from "../../components/toast-message";

export default (props) => {
  const [message, setMessage] = useState({});
  const [reload, setReload] = useState(undefined);

  const [currentPage, setCurrentPage] = useState(1);
  const [tenants, setTenants] = useState([]);
  const [meta, setPaginations] = useState({});

  const location = useLocation();

  useEffect(() => {
    if (location.state?.name == "actionSuccess") {
      setMessage({ content: location.state.message, variant: "success" });
    }
  }, [location]);

  useEffect(() => {
    TenantService.index(currentPage).then(
      (response) => {
        setTenants(response.data.tenants);
        TenantCache.setTenants(response.data.tenants);
        setPaginations(response.data.meta);
      },
      (error) => {
        setMessage({ content: error.meta.message, variant: "danger" });
      },
    );
  }, [reload, currentPage]);

  const handleDelete = async (id) => {
    if (window.confirm(I18n.t("form.tenant.confirm_delete"))) {
      try {
        const response = await TenantService.destroy(id, props.departmentId);
        setReload(!reload);
        toastMessage.success(response.data.message);
      } catch (error) {
        setReload(!reload);
        toastMessage.success(error.meta.message);
      }
    }
  };

  const onChangePage = (page) => {
    if (page != currentPage) {
      setCurrentPage(page);
    }
  };

  const navigate = useHistory();

  return (
    <div className="tenants-index">
      <h1 className="text-center">{I18n.t("activerecord.models.tenant")}</h1>
      {Object.keys(message).length > 0 && <AlertMessage variant={message.variant} message={message.content} />}
      <div className="">
        {AuthService.isSuperAdmin() && (
          <Link className="btn btn-primary mb-3" to="/tenant_create">
            {I18n.t("form.tenant.new_tenant")}
          </Link>
        )}

        <div style={{ gap: 20 }} className="d-flex flex-wrap justify-content-start mb-5">
          {tenants.map((t) => {
            return (
              <Card className="small-card">
                <Card.Img variant="top" src={t.logo.original || ClinicImage} height="150" width="200" />
                <Card.Body>
                  <Card.Title>
                    {I18n.t("activerecord.models.tenant")}: {t.company_name}
                  </Card.Title>
                  <Card.Text>
                    <span>
                      {I18n.t("activerecord.attributes.tenant.short_name")}: {t.short_name}
                    </span>
                    <br />
                    <span>
                      {I18n.t("activerecord.attributes.department.language")}:{" "}
                      {I18n.t(`enumerize.defaults.language.${t.language}`)}
                    </span>
                    <br />
                    <div className="btn-group">
                      <button className="btn btn-primary">
                        <EditOutlined onClick={() => navigate.push(`/tenants/${t.id}/edit`)} />
                      </button>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          handleDelete(t.id);
                        }}
                        className="btn btn-danger"
                      >
                        <DeleteOutlined />
                      </button>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            );
          })}
        </div>
        <Pagination className="mt-5" meta={meta} onChangePage={onChangePage} />
      </div>
    </div>
  );
};
