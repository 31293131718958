import React, { useState, useEffect } from "react";

import toastMessage from "../../components/toast-message";
import ProviderSelectedFilter from "./_provider_selected_filters";
import DashboardProviderService from "../../services/dashboard_provider";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Button } from "react-bootstrap";
import { DownloadOutlined } from "@ant-design/icons";
import DashboardAgendaSelectedFilter from "./_agenda_selected_filters";

const DashboardAgendaFilter = ({ data, setData, selectedFilters, setSelectedFilters, ...props }) => {
  const [queries, setQueries] = useState({});
  const [onSubmit, setOnSubmit] = useState(false);

  const handleSearch = async () => {
    if (Object.keys(queries).length == 0) {
      setData([]);
    }
    setOnSubmit(true);
    try {
      const service = DashboardProviderService.patient(queries);
      const response = await service;
      setData(response.data.data);
      setOnSubmit(false);
    } catch (error) {
      console.log(error);
      toastMessage.error(error?.meta?.message || error);
      setOnSubmit(false);
    }
  };

  useEffect(() => {
    if (Object.keys(queries).length > 0) {
      handleSearch();
    } else {
      setData([]);
    }
  }, [queries]);

  return (
    <div className="patient-dashboard-filter container">
      <div className="row row-cols-3 g-2">
        {selectedFilters.map((s) => {
          return (
            <DashboardAgendaSelectedFilter
              key={s.key}
              handleSearch={() => handleSearch()}
              queries={queries}
              setQueries={(e) => setQueries(e)}
              selectedFilter={s}
              filterType={s.key}
              setSelectedFilters={(e) => setSelectedFilters(e)}
            />
          );
        })}
      </div>
      <div className="action-btn">
        <div className="export-btn">
          {data?.length > 0 && (
            <div className="download-action c">
              <Button filename={`${"result"}.pdf`} ariant="success" onClick={() => props.handleDownload()}>
                <DownloadOutlined />
                PDF
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardAgendaFilter;
