import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import DepartmentForm from "./_form";

const DepartmentUpdate = (props) => {
    const { id } = useParams();

    const [departmentId, setDepartmentId] = useState(id);
    return (
        <DepartmentForm
            {...props}
            title={''}
            departmentId={departmentId}
        />
    );
};

export default DepartmentUpdate;