import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import "./appointment-form.scss";
import "react-datepicker/dist/react-datepicker.css";
import ToastMessage from "../../components/toast-message";
import PatientAppointmentService from "../../services/patient_appointment";

const Description = ({ setAppointment, patientId, allowEdit, ...props }) => {
  const [error, setError] = useState("");
  const [description, setDescription] = useState(props.description || "");

  const handleCreate = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("appointment[description]", description);

    PatientAppointmentService.update(patientId, props.id, formData).then(
      (res) => {
        props.onHide();
        setAppointment(res.data.appointment);
        ToastMessage.success(res.data.message);
      },
      (error) => {
        props.onHide();
        ToastMessage.error(error.meta.message);
      },
    );
  };

  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton className="modal-header">
        <Modal.Title id="contained-modal-title-vcenter">
          {I18n.t("activerecord.attributes.appointment.description")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="appointment-create-box">
          <div className="box-body">
            <div style={{ height: "200px" }} className="input row">
              <textarea
                disabled={!allowEdit}
                style={{ height: "100%" }}
                autoFocus
                rows="4"
                cols="50"
                type="textarea"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            <div className="row">{error.length > 0 && <AlertMessage variant="danger" message={error} />}</div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={props.onHide}>Close</Button> */}
        {allowEdit && (
          <div
            onClick={handleCreate}
            className="create-patient d-flex align-items-baseline justify-content-between btn btn-lg mt-3 fw-bolder float-end bg-green"
          >
            <span>{I18n.t("form.update")}</span>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default Description;
