import React, { useState, useEffect } from "react";
import {
  CaretRightOutlined,
  CaretDownOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  FileUnknownOutlined,
  DeleteOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import TreatmentService from "../../services/treatment";
import toastMessage from "../../components/toast-message";
import ServiceProviderDD from "./_service_provider";
import DataStorage from "../../components/data-storage";
import TreatmentDocuments from "./_documents";
import { Tooltip, TooltipWrapper } from "react-tooltip";
import ChildTreatment from "./_child_treatment";
import ParentIcon from "images/parent-icon.svg";
import ChildIcon from "images/child-icon.svg";
import convertToRoman from "../../utils/roman-number-convert";

const TreatmentItem = ({ setIsDragDisabled, attachmentOptions, setActivePathway, treatments, ...props }) => {
  const [reload, setReload] = useState(false);
  const [onSubmit, setOnSubmit] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showProvider, setShowProvider] = useState(true);
  const [showDocument, setShowDocument] = useState(false);
  const [showChildTreatment, setShowChildTreatment] = useState(false);

  const [treatment, setTreatment] = useState(props?.treatment);
  const [providers, setProviders] = useState(props?.treatment?.service_providers);
  const [needResult, setNeedResult] = useState(treatment?.need_result || false);
  const [komPackageNumber, setKomPackageNumber] = useState(treatment?.kom_package_number || 0);
  const [hasFinalDate, setHasFinalDate] = useState(treatment?.has_final_date || false);
  const [workingDay, setWorkingDay] = useState(treatment?.working_day || 0);
  const [name, setName] = useState(treatment?.name || "");
  const [rule, setRule] = useState(treatment?.rule || 1);
  const [initTreatmentIds, _setTreatmentIds] = useState(treatment?.treatment_ids || []);
  const [treatmentIds, setTreatmentIds] = useState(treatment?.treatment_ids || []);
  const [metalink, setMetalink] = useState(treatment?.metalink || "");
  const [treatmentType, setTreatmentType] = useState(treatment?.treatment_type || "default");
  const [selectedProviders, setSelectedProviders] = useState(treatment?.service_providers_treatments || []);

  useEffect(() => {
    if (showDetail) {
      setIsDragDisabled(true);
    } else {
      setIsDragDisabled(false);
    }
  }, [showDetail]);

  useEffect(() => {
    setTreatment(props.treatment);
    setProviders(props.treatment.service_providers || []);
    setSelectedProviders(props.treatment.service_providers_treatments || []);

    setTreatmentType(props.treatment.treatment_type);
  }, [props.treatment]);

  useEffect(() => {
    if (reload == true) {
      TreatmentService.show(treatment.id).then(
        (response) => {
          setTreatment(response.data.treatment);
        },
        (error) => {
          toastMessage.error(error.meta.message);
        },
      );
    }
  }, [reload]);

  const handleDelete = async () => {
    if (window.confirm(I18n.t("form.treatment.confirm_delete"))) {
      try {
        const response = await TreatmentService.destroy(treatment.id);
        toastMessage.success(response.data.message);
        props.reloadAll();
      } catch (error) {
        toastMessage.error(error.meta.message);
      }
    }
  };

  const handleSubmit = async () => {
    setOnSubmit(true);
    try {
      const data = {
        treatment: {
          name: name,
          rule: rule,
          treatment_type: treatmentType,
          need_result: needResult,
          working_day: workingDay,
          service_providers_treatments_attributes: selectedProviders,
          has_final_date: hasFinalDate,
          metalink: metalink,
          treatment_ids: treatmentIds,
          kom_package_number: komPackageNumber == 0 ? undefined : komPackageNumber,
        },
      };
      const response = await TreatmentService.update(treatment.id, data);
      toastMessage.success(response.data.message);
      if (treatment.order != response.data.treatment.order) {
        setActivePathway();
        props.reloadAll();
      }

      if (initTreatmentIds != treatmentIds) {
        setActivePathway();
        props.reloadAll();
      }
      setTreatment(response.data.treatment);
      setProviders(response.data.treatment.service_providers);
      setOnSubmit(false);
    } catch (e) {
      toastMessage.error(e.meta.message);
      setOnSubmit(false);
    }
  };

  const handleCopy = async () => {
    setOnSubmit(true);
    try {
      const response = await TreatmentService.copy(treatment.id);
      toastMessage.success(response.data.message);
      props.reloadAll();
      setOnSubmit(false);
    } catch (e) {
      toastMessage.error(e.meta.message);
      setOnSubmit(false);
    }
  };

  const treatmentColor = () => {
    if (treatmentType == "consultation") {
      return "#e5de00";
    }
    if (treatmentType == "after_operation") {
      if (komPackageNumber > 0) {
        return "#4D934D";
      }
      return "#90EE90";
    }
    if (treatmentType == "operation") {
      return "cyan";
    }

    if (treatmentType == "prom") {
      return "red";
    }

    return "orange";
  };

  const getWorkingDate = () => {
    if (treatmentType == "consultation") {
      return undefined;
    }
    if (treatmentType == "default") {
      return `n - ${workingDay}`;
    }
    if (treatmentType == "operation") {
      return "n = 0";
    }

    return `n + ${workingDay}`;
  };

  return (
    treatment && (
      <div className="item">
        <Tooltip id="tooltip-treatment-pathway" content={I18n.t("tooltip.treatment_pathway")} />
        <Tooltip id="tooltip-treatment-rule" content={I18n.t("tooltip.treatment_rule")} />
        <Tooltip id="tooltip-treatment-logo" content={I18n.t("tooltip.treatment_logo")} />
        <Tooltip id="tooltip-treatment-service-provider" content={I18n.t("tooltip.treatment_service_provider")} />
        <Tooltip id="tooltip-treatment-final-date" content={I18n.t("tooltip.treatment_has_final_date")} />
        <Tooltip id="tooltip-treatment-has-result" content={I18n.t("tooltip.treatment_has_result")} />
        <Tooltip id="tooltip-delete-treatment" content={I18n.t("tooltip.delete_treatment")} />
        <Tooltip id="tooltip-copy-treatment" content={I18n.t("tooltip.copy_treatment")} />
        <Tooltip id="tooltip-kom-package" content={I18n.t("tooltip.kom_package_in_setting")} />
        <Tooltip id="tooltip-treatment-document" content={I18n.t("tooltip.treatment_document")} />
        <Tooltip id="tooltip-treatment-delete" content={I18n.t("tooltip.treatment_delete")} />
        <Tooltip id="tooltip-treatment-copy" content={I18n.t("tooltip.treatment_copy")} />
        <Tooltip id="tooltip-treatment-weblink" content={I18n.t("tooltip.treatment_weblink")} />
        <Tooltip id="tooltip-treatment-working-date" content={I18n.t("tooltip.treatment_working_date")} />
        <div className={`general ${showDetail ? "focus" : undefined}`} onClick={() => setShowDetail(!showDetail)}>
          <div className="name">
            {treatment.icon?.url ? <img src={treatment.icon?.url} width="25" /> : <FileUnknownOutlined />}
            {treatment.name}
          </div>
          <div className="action">
            {komPackageNumber > 0 && (
              <div style={{ backgroundColor: treatmentColor() }} className="treatment-type-preview">
                {I18n.t("activerecord.attributes.treatment.kom_package_number", {
                  number: convertToRoman(komPackageNumber),
                })}
              </div>
            )}
            {(treatment.treatment_ids.length > 0 || props.haveRelation) && (
              <div style={{ backgroundColor: "lightblue" }} className="treatment-type-preview">
                <span>{treatment.treatment_ids.length > 0 && <img src={ParentIcon} width={25} />}</span>
                <span>{props.haveRelation && <img src={ChildIcon} width={25} />}</span>
              </div>
            )}
            <div style={{ backgroundColor: treatmentColor() }} className="treatment-type-preview">
              <span>{getWorkingDate()}</span>
            </div>
            <div aria-controls="show-treatment-detail" aria-expanded={open}>
              {!showDetail ? <CaretRightOutlined /> : <CaretDownOutlined />}
            </div>
          </div>
        </div>

        <div className="detail" style={{ display: showDetail ? undefined : "none" }}>
          <Collapse in={showDetail}>
            <div id="show-treatment-detail" className="row">
              <div className="left col-md-5">
                <div className="input row">
                  <div className="col-md-4 label">
                    <TooltipWrapper tooltipId="tooltip-treatment-pathway">
                      <label htmlFor={`${treatment.id}-name`}>{I18n.t("form.treatment_pathway.treatment")}</label>
                    </TooltipWrapper>
                  </div>

                  <div className="col-md-8 value">
                    <input
                      onChange={(e) => setName(e.target.value)}
                      id={`${treatment.id}-name`}
                      value={name}
                      type="text"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="input row">
                  <div className="col-md-4 label">
                    <label htmlFor={`${treatment.id}-consultation`}>
                      {I18n.t("activerecord.attributes.treatment.treatment_type")}
                    </label>
                  </div>
                  <div className="col-md-8 value">
                    <select
                      name="treatmentType"
                      className="selection form-control"
                      onChange={(e) => setTreatmentType(e.target.value)}
                      value={treatmentType}
                    >
                      <option value="default">{I18n.t("activerecord.models.treatment_pathway")}</option>
                      <option value="consultation">{I18n.t("enumerize.treatment.treatment_type.consultation")}</option>
                      <option value="prom">{I18n.t("enumerize.treatment.treatment_type.prom")}</option>
                      <option value="operation">{I18n.t("enumerize.treatment.treatment_type.operation")}</option>
                    </select>
                  </div>
                </div>
                <div className="input row">
                  <div className="col-md-4 label">
                    <TooltipWrapper tooltipId="tooltip-treatment-rule">
                      <label htmlFor={`${treatment.id}-rule`}>{I18n.t("activerecord.attributes.treatment.rule")}</label>
                    </TooltipWrapper>
                  </div>

                  <div className="col-md-8 value">
                    <input
                      onChange={(e) => setRule(e.target.value)}
                      id={`${treatment.id}-rule`}
                      value={rule}
                      type="number"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="input row">
                  <div className="col-md-4 label">
                    <TooltipWrapper tooltipId="tooltip-treatment-logo">
                      <label htmlFor={`${treatment.id}-logo`}>Logo</label>
                    </TooltipWrapper>
                  </div>

                  <div className="col-md-8 value">
                    <DataStorage
                      serverFiles={treatment.icon ? [treatment.icon] : []}
                      departmentId={props.departmentId}
                      attachmentOptions={attachmentOptions}
                      multiple={false}
                      id={treatment.id}
                      api={TreatmentService}
                      documentType="image"
                      reload={() => setReload(true)}
                    />
                  </div>
                </div>
                {treatmentType != "prom" && (
                  <div className="input row">
                    <div className="col-md-4 label">
                      <TooltipWrapper tooltipId="tooltip-treatment-final-date">
                        <label htmlFor={`${treatment.id}-has-final-date`}>
                          {I18n.t("activerecord.attributes.treatment.has_final_date")}
                        </label>
                      </TooltipWrapper>
                    </div>

                    <div className="col-md-8 value">
                      <input
                        onChange={(e) => setHasFinalDate(e.target.checked)}
                        style={{ marginLeft: 10 }}
                        defaultChecked={hasFinalDate}
                        id={`${treatment.id}-has-final-date`}
                        value={hasFinalDate}
                        type="checkbox"
                      />
                    </div>
                  </div>
                )}
                {treatmentType !== "operation" && treatmentType !== "prom" && (
                  <div className="input row">
                    <div className="col-md-4 label">
                      <TooltipWrapper tooltipId="tooltip-treatment-has-result">
                        <label htmlFor={`${treatment.id}-need-result`}>
                          {I18n.t("activerecord.attributes.treatment.need_result")}
                        </label>
                      </TooltipWrapper>
                    </div>

                    <div className="col-md-8 value">
                      <input
                        onChange={(e) => setNeedResult(e.target.checked)}
                        style={{ marginLeft: 10 }}
                        defaultChecked={needResult}
                        id={`${treatment.id}-need-result`}
                        value={needResult}
                        type="checkbox"
                      />
                    </div>
                  </div>
                )}
                {treatmentType == "after_operation" && (
                  <div className="input row">
                    <div className="col-md-4 label">
                      <TooltipWrapper tooltipId="tooltip-kom-package">
                        <label title="Hello This Will Have Some Value" htmlFor={`${treatment.id}-kom-package-number`}>
                          {I18n.t("activerecord.attributes.treatment.is_kom_package")}
                        </label>
                      </TooltipWrapper>
                    </div>

                    <div className="col-md-8 value">
                      <input
                        onChange={(e) => setKomPackageNumber(e.target.value)}
                        className="form-control"
                        min="1"
                        pattern="[1-9]*"
                        id={`${treatment.id}-kom-package-number`}
                        value={komPackageNumber}
                        type="number"
                      />
                    </div>
                  </div>
                )}
                {(treatmentType == "default" || treatmentType == "after_operation" || treatmentType == "prom") && (
                  <div className="input row">
                    <div className="col-md-4 label">
                      <TooltipWrapper tooltipId="tooltip-treatment-working-date">
                        <label htmlFor={`${treatment.id}-working-day`}>
                          {I18n.t("activerecord.attributes.treatment.working_day")}
                        </label>
                      </TooltipWrapper>
                    </div>

                    <div className="col-md-8 value">
                      <input
                        type="number"
                        pattern="[0-9]*"
                        className="form-control"
                        id={`${treatment.id}-working-day`}
                        value={workingDay}
                        onChange={(e) => setWorkingDay(e.target.value)}
                      />
                    </div>
                  </div>
                )}
                {treatmentType !== "operation" && treatmentType !== "prom" && (
                  <div className="input row">
                    <div className="col-md-4 label">
                      <TooltipWrapper tooltipId="tooltip-treatment-service-provider">
                        {I18n.t("form.service_provider.name")}
                      </TooltipWrapper>
                    </div>
                    <div className="col-md-8 value">
                      <div
                        onClick={() => {
                          setShowProvider(!showProvider);
                          setShowDocument(false);
                        }}
                      >
                        {showProvider ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                      </div>
                    </div>
                  </div>
                )}

                {treatmentType !== "prom" && (
                  <div className="input row">
                    <div className="col-md-4 label">
                      <TooltipWrapper tooltipId="tooltip-treatment-document">
                        {I18n.t("form.treatment.document")}
                      </TooltipWrapper>
                    </div>
                    <div className="col-md-8 value">
                      <div
                        onClick={() => {
                          setShowDocument(!showDocument);
                          setShowProvider(false);
                        }}
                      >
                        {showDocument ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                      </div>
                    </div>
                  </div>
                )}

                {treatmentType == "prom" && (
                  <div className="input row">
                    <div className="col-md-4 label">
                      <TooltipWrapper tooltipId="tooltip-treatment-weblink">
                        <label htmlFor={`${treatment.id}-link`}>Weblink</label>
                      </TooltipWrapper>
                    </div>

                    <div className="col-md-8 value">
                      <input
                        type="text"
                        className="form-control"
                        id={`${treatment.id}-link`}
                        value={metalink}
                        onChange={(e) => setMetalink(e.target.value)}
                      />
                    </div>
                  </div>
                )}
                {treatmentType == "after_operation" && (
                  <div className="input row">
                    <div className="col-md-4 label">{I18n.t("form.treatment.child_treatment")}</div>
                    <div className="col-md-8 value">
                      <div
                        onClick={() => {
                          setShowChildTreatment(!showChildTreatment);
                          setShowProvider(false);
                          setShowDocument(false);
                        }}
                      >
                        {showChildTreatment ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                      </div>
                    </div>
                  </div>
                )}
                <div className="save-btn">
                  <TooltipWrapper tooltipId="tooltip-delete-treatment">
                    <Button className="float-start" onClick={handleDelete} variant="danger">
                      <TooltipWrapper tooltipId="tooltip-treatment-delete">
                        <DeleteOutlined />
                      </TooltipWrapper>
                    </Button>
                  </TooltipWrapper>
                  <Button onClick={handleSubmit} variant="primary" disabled={onSubmit}>
                    {onSubmit && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                    <span className={onSubmit ? "visually-hidden" : undefined}>{I18n.t("form.update")} </span>
                  </Button>

                  <TooltipWrapper tooltipId="tooltip-copy-treatment">
                    <Button onClick={handleCopy} variant="secondary" disabled={onSubmit}>
                      {onSubmit && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                      <TooltipWrapper tooltipId="tooltip-treatment-copy">
                        <CopyOutlined />
                      </TooltipWrapper>
                    </Button>
                  </TooltipWrapper>
                </div>
              </div>

              <div className="right col-md-7">
                {treatmentType !== "operation" && treatmentType !== "prom" && showProvider && (
                  <div className="provider">
                    <h5 className="text-center">{I18n.t("form.service_provider.name")}</h5>
                    <ServiceProviderDD
                      providers={providers}
                      selectedProviders={treatment.selected_service_providers}
                      setSelectedProviders={(e) => setSelectedProviders(e)}
                    />
                  </div>
                )}
                {treatmentType !== "operation" && showDocument && (
                  <div className="provider">
                    <TreatmentDocuments attachmentOptions={attachmentOptions} treatment={treatment} />
                  </div>
                )}
                {treatmentType == "after_operation" && showChildTreatment && (
                  <div className="child-treatment">
                    <ChildTreatment
                      setTreatmentIds={(ids) => setTreatmentIds(ids)}
                      currentTreatment={treatment}
                      treatments={treatments}
                    />
                  </div>
                )}
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    )
  );
};

export default TreatmentItem;
