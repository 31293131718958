import React, { useState, useEffect } from "react";
import AlertMessage from "../../components/alert-message";
import { Link, useHistory, useLocation } from "react-router-dom";
import DepartmentService from "../../services/department";
import Pagination from "../../components/pagination";
import Input from "react-phone-number-input/input";
import Card from "react-bootstrap/Card";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import "./index.scss";
import TenantCache from "../../cache/tenant_cache";

export default (props) => {
  const [message, setMessage] = useState({});
  const [departments, setDepartments] = useState([]);
  const [reload, setReload] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [meta, setPaginations] = useState({});
  const location = useLocation();

  useEffect(() => {
    if (location.state?.name == "actionSuccess") {
      setMessage({ content: location.state.message, variant: "success" });
      TenantCache.clear();
    }
  }, [location]);

  useEffect(() => {
    DepartmentService.index(currentPage).then(
      (response) => {
        setDepartments(response.data.departments);
        setPaginations(response.data.meta);
      },
      (error) => {
        setMessage({ content: error.meta.message, variant: "danger" });
      },
    );
  }, [props.tenantId, reload, currentPage]);

  const handleDelete = async (id) => {
    if (window.confirm(I18n.t("form.department.confirm_delete"))) {
      try {
        const response = await DepartmentService.destroy(id);
        setReload(!reload);
        setMessage({ content: response.data.message, variant: "success" });
      } catch (error) {
        setReload(!reload);
        setMessage({ content: error.meta.message, variant: "danger" });
      }
    }
  };

  const onChangePage = (page) => {
    if (page != currentPage) {
      setCurrentPage(page);
    }
  };

  const navigate = useHistory();

  return (
    <div className="clinic-index">
      <h1 className="text-center">{I18n.t("activerecord.models.department")}</h1>
      {Object.keys(message).length > 0 && <AlertMessage variant={message.variant} message={message.content} />}
      <Link className="btn btn-primary mb-3" to="/department_create">
        {I18n.t("form.department.new_department")}
      </Link>
      <div style={{ gap: 20 }} className="d-flex flex-wrap justify-content-start">
        {departments.map((t) => {
          return (
            <Card className="small-card">
              <Card.Img variant="top" src={t.logo.original} height="150" width="200" />
              <Card.Body>
                <Card.Title>{t.company_name}</Card.Title>
                <Card.Text>
                  <span>
                    {I18n.t("activerecord.attributes.department.short_name")}: {t.short_name}
                  </span>
                  <br />
                  <span>
                    {I18n.t("activerecord.attributes.department.street_number")}: {t.street_number}
                  </span>
                  <br />
                  <span>
                    {I18n.t("activerecord.attributes.department.house_number")}: {t.house_number}
                  </span>
                  <br />
                  <span>
                    {I18n.t("activerecord.attributes.department.zip")}: {t.zip}
                  </span>
                  <br />
                  <span>
                    {I18n.t("activerecord.attributes.department.place")}: {t.place}
                  </span>
                  <br />
                  <span>
                    {I18n.t("activerecord.attributes.department.email_technic")}: {t.email_technic}
                  </span>
                  <br />
                  <span>
                    {I18n.t("activerecord.attributes.department.phone_number_technic")}:{" "}
                    <Input
                      style={{ backgroundColor: "inherit", border: "none" }}
                      disabled
                      withCountryCallingCode
                      international
                      onChange={() => console.log("")}
                      value={t.phone_number_technic}
                    />
                  </span>
                  <br />
                  <span>
                    {I18n.t("activerecord.attributes.department.email_clinic")}: {t.email_clinic}
                  </span>
                  <br />
                  <span>
                    {I18n.t("activerecord.attributes.department.phone_number_clinic")}:{" "}
                    <Input
                      style={{ backgroundColor: "inherit", border: "none" }}
                      disabled
                      withCountryCallingCode
                      international
                      onChange={() => console.log("")}
                      value={t.phone_number_clinic}
                    />
                  </span>
                  <br />
                  <span>
                    {I18n.t("activerecord.attributes.department.language")}:{" "}
                    {I18n.t(`enumerize.defaults.language.${t.language}`)}
                  </span>
                  <br />
                  <div className="btn-group">
                    <button className="btn btn-primary">
                      <EditOutlined onClick={() => navigate.push(`/departments/${t.id}/edit`)} />
                    </button>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        handleDelete(t.id);
                      }}
                      className="btn btn-danger"
                    >
                      <DeleteOutlined />
                    </button>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          );
        })}
      </div>
      <div className="mt-3">
        <Pagination meta={meta} onChangePage={onChangePage} />
      </div>
    </div>
  );
};
