import authClient from '../utils/auth-common';
import httpClient from '../utils/http-common';

const logout = () => {
  authClient.signOut();
  window.cookies.remove('locale');
  localStorage.removeItem('X-Tenant-Id');
  localStorage.removeItem('X-Pathway-Id');
  localStorage.removeItem('user');
  localStorage.removeItem('developer');
  localStorage.removeItem('tenants');
};

const getCurrentUser = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return user;
};

const isSuperAdmin = () => {
  return getCurrentUser().role == "super_admin";
}

const isGeneralAdmin = () => {
  return getCurrentUser().role == "super_admin" || getCurrentUser().role == "admin";
}

const isLoggedIn = () => {
  if (getCurrentUser()) {
    return true;
  }

  return false;
};

const isMpa = () => {
  return getCurrentUser().role == 'mpa';
};

const AuthService = {
  logout,
  getCurrentUser,
  isMpa,
  isSuperAdmin,
  isGeneralAdmin,
  isLoggedIn,
};

export default AuthService;
