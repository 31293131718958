import React, { useState, useEffect } from "react";
import toastMessage from "../../components/toast-message";
import DashboardService from "../../services/dashboard";
import DashboardSelectedFilters from "./_selected_filters";
import DashboardDownload from "./_download_result";

const DashboardPatientFilter = ({ data, setData, selectedFilters, setSelectedFilters, ...props }) => {
  const [queries, setQueries] = useState({});
  const [onSubmit, setOnSubmit] = useState(false);

  const handleSearch = async () => {
    if (Object.keys(queries).length == 0) {
      setData([]);
    }
    setOnSubmit(true);
    try {
      const response = await DashboardService.patient(queries);
      setData(response.data.data);
      setOnSubmit(false);
    } catch (error) {
      console.log(error);
      toastMessage.error(error?.meta?.message || error);
      setOnSubmit(false);
    }
  };

  useEffect(() => {
    if (Object.keys(queries).length > 0) {
      handleSearch();
    } else {
      setData([]);
    }
  }, [queries]);

  return (
    <div className="patient-dashboard-filter container">
      <div className="row row-cols-3 g-2">
        {selectedFilters.map((s) => {
          return (
            <DashboardSelectedFilters
              key={s.key}
              handleSearch={() => handleSearch()}
              queries={queries}
              setQueries={(e) => setQueries(e)}
              selectedFilter={s}
              filterType={s.key}
              setSelectedFilters={(e) => setSelectedFilters(e)}
            />
          );
        })}
      </div>
      <div className="action-btn">
        <div className="export-btn">
          {data?.length > 0 && <DashboardDownload data={data.map(({ problem, ...keep }) => keep)} action="patient" />}
        </div>
      </div>
    </div>
  );
};

export default DashboardPatientFilter;
