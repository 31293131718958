import React from "react";
import "./create.scss";
import PatientSearch from "./patient-search";

const PatientIndex = (props) => {
  return (
    <>
      <PatientSearch {...props} />
    </>
  );
};

export default PatientIndex;
