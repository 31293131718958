import React from "react";
import icon from 'images/woman.svg'

const FemaleIcon = ({ color }) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="35px" viewBox="0 0 225.000000 225.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
                fill={color ? color : '#4B4583'} stroke="none">
                <path d="M950 1844 c-303 -101 -461 -431 -350 -728 62 -165 216 -305 383 -346
       l47 -12 0 -99 0 -99 -90 0 -90 0 0 -90 0 -90 90 0 90 0 0 -95 0 -95 95 0 95 0
       0 95 0 95 90 0 90 0 0 90 0 90 -90 0 -90 0 0 99 0 99 48 12 c166 41 320 181
       382 346 111 298 -48 628 -352 728 -108 36 -241 36 -348 0z m325 -188 c78 -35
       156 -113 191 -189 26 -55 29 -73 29 -157 0 -85 -3 -101 -29 -155 -38 -76 -110
       -149 -184 -183 -50 -24 -69 -27 -157 -27 -88 0 -107 3 -157 27 -74 34 -146
       107 -184 183 -26 54 -29 70 -29 155 0 84 3 102 29 157 45 99 150 187 253 213
       64 16 176 5 238 -24z"/>
            </g>
        </svg>
    )
}

export default FemaleIcon;

