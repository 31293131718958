import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { COUNTRY_OPTIONS, LANGUAGE_OPTIONS } from "../../constants/index";
import { CloseOutlined, RightOutlined, DownOutlined, DeleteOutlined, UserAddOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import PatientService from "../../services/patient";
import AlertMessage from "../../components/alert-message";
import TreatmentService from "../../services/treatment-pathway";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./create.scss";
import PatientLanguageService from "../../services/patient_language";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Select from "react-select";
import ManIcon from "../../components/icons/male-icon";
import FemaleIcon from "../../components/icons/female-icon";
import OtherGenderIcon from "../../components/icons/other-gender-icon";
import toastMessage from "../../components/toast-message";
import LastTreatmentModal from "./_last_treatment_modal";
import ServiceProviderService from "../../services/service_provider";
import { debounce } from "lodash";

const PatientForm = (props) => {
  let navigate = useHistory();

  const [error, setError] = useState("");

  const [patientId, _] = useState(props.patientId);

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [patientActive, setPatientActive] = useState(false);
  const [createSupervisor, setCreateSupervisor] = useState(props.location?.query?.expendSupervisor || false);
  const [supervisor, setSupervisor] = useState({});
  const [supervisorId, setSupervisorId] = useState(undefined);
  const [email, setEmail] = useState("");
  const [kissId, setKissId] = useState("");
  const [birthday, setBirthday] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("male");
  const [communication, setCommunication] = useState("post");
  const [communicationOptions, setCommunicationOptions] = useState([
    I18n.t("enumerize.defaults.communication_path.post"),
  ]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [insurranceNumber, setInsurranceNumber] = useState("");
  const [treatment, setTreatment] = useState("");
  const [languages, setLanguages] = useState([]);
  const [language, setLanguage] = useState(undefined);
  const [country, setCountry] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [zip, setZip] = useState("");
  const [place, setPlace] = useState("");
  const [patientInfo, setPatientInfo] = useState("");
  const [careerChanger, setCareerChanger] = useState(I18n.t("enumerize.defaults.yes_no.false"));
  const [appointments, setAppointments] = useState([]);

  const [supLanguage, setSupLanguage] = useState(undefined);
  const [supCountry, setSupCountry] = useState(COUNTRY_OPTIONS[0].value);
  const [supFirstName, setSupFirstName] = useState("");
  const [supLastName, setSupLastName] = useState("");
  const [supEmail, setSupEmail] = useState("");
  const [supPhoneNumber, setSupPhoneNumber] = useState("");
  const [supGender, setSupGender] = useState("male");
  const [supBirthday, setSupBirthday] = useState("");
  const [supCommunication, setSupCommunication] = useState("post");
  const [supCommunicationOptions, setSupCommunicationOptions] = useState([
    I18n.t("enumerize.defaults.communication_path.post"),
  ]);
  const [supStreetNumber, setSupStreetNumber] = useState("");
  const [supHouseNumber, setSupHouseNumber] = useState("");
  const [supPlace, setsupPlace] = useState("");
  const [supZip, setSupZip] = useState("");
  const [supRelationType, setSupRelationType] = useState("");
  const [provider, setProvider] = useState({});
  const [hasActiveTreatment, setHasActiveTreatment] = useState(false);
  const [showLastTreatmentModal, setShowLastTreatmentModal] = useState(false);
  const [lastTreatmentId, setLastTreatmentId] = useState(undefined);
  const [providers, setProviders] = useState([]);

  const [treatments, setTreatments] = useState([]);
  useEffect(() => {
    TreatmentService.index(true).then(
      (response) => {
        let pathways = response.data.treatment_pathways.filter((p) => p.is_default);
        if (careerChanger == I18n.t("enumerize.defaults.yes_no.true")) {
          pathways = response.data.treatment_pathways;
        }
        if (patientId) {
          setTreatments(response.data.treatment_pathways);
        } else {
          setTreatments(pathways);
        }
        if (!treatment) {
          setTreatment(pathways[0]?.id);
        }
      },
      (error) => {
        toastMessage.error(error.meta.message);
      },
    );
  }, [careerChanger]);

  const debouncedCallBackend = useCallback(
    debounce((newValue) => {
      setSubmitting(true);
      PatientService.getVekaData(newValue)
        .then(({ data }) => {
          const patientInfo = data.data;
          setFirstName(patientInfo["first_name"]);
          setLastName(patientInfo["last_name"]);
          setEmail(patientInfo["email"]);
          // setPhoneNumber(patientInfo["phone"]);

          if (patientInfo["birthday"]) {
            setBirthday(new Date(patientInfo["birthday"]));
          }
          const patientAddress = patientInfo["address"];
          if (patientAddress) {
            setZip(patientAddress.zip);
            const streetNumber = patientAddress.street.toString().match(/\d+/g);
            const streetName = patientAddress.street.toString().match(/[A-Za-z\s]+/g);
            if (streetNumber) {
              setHouseNumber(streetNumber[0]);
              setStreetNumber(streetName.join(" "));
            } else {
              setStreetNumber(patientAddress.street);
            }
            setPlace(patientAddress.city);
          }
          setSubmitting(false);
        })
        .catch((e) => {
          console.log(e);
          toastMessage.error(e.response?.data?.message || "Unexpected Error");
          setSubmitting(false);
        });
      // Place your backend call here
    }, 500), // 500ms delay
    [], // This empty array ensures the debounced function is created only once
  );

  useEffect(() => {
    PatientLanguageService.index().then(
      (response) => {
        const p = response.data.patient_languages.filter((p) => p.active === true);
        setLanguages(p);

        if (p.length > 0) {
          setLanguage(p[0].id);
          setSupLanguage(p[0].id);
        }
      },
      (error) => {
        setError(error.meta.message);
      },
    );
  }, [props.departmentId]);

  useEffect(() => {
    if (patientId) {
      PatientService.show(patientId).then(
        (response) => {
          const patient = response.data.patient;
          setFirstName(patient.first_name || "");
          setLastName(patient.last_name || "");
          setEmail(patient.email || "");
          setPhoneNumber(patient.phone_number ? `+${patient.phone_number}` : "");
          setKissId(patient.kiss_id || "");
          setBirthday(new Date(patient.birthday));
          setCommunication(patient.communication_path || "");
          setCommunicationOptions([I18n.t(`enumerize.defaults.communication_path.${patient.communication_path}`)]);
          setInsurranceNumber(patient.insurance_number || "");
          setGender(patient.gender || "");
          setLanguage(patient.patient_language_id || languages[0]?.id);
          setCountry(patient.country || COUNTRY_OPTIONS[0]?.value);
          setStreetNumber(patient.street_number || "");
          setHouseNumber(patient.house_number || "");
          setPlace(patient.place || "");
          setZip(patient.zip || "");
          setPatientInfo(patient.patient_info || "");
          setPatientActive(patient.active || "");
          setHasActiveTreatment(patient.activated_operation_appointment);
          setAppointments(patient.appointments);
          setLastTreatmentId(patient.last_treatment_id);

          if (patient.career_changer) {
            const cc =
              patient.career_changer == "no"
                ? I18n.t("enumerize.defaults.yes_no.false")
                : I18n.t("enumerize.defaults.yes_no.true");
            setCareerChanger(cc);
          } else {
            setCareerChanger(I18n.t("enumerize.defaults.yes_no.false"));
          }
          // Treatment
          setTreatment(patient.treatment_pathway?.id);
          // Provider
          setProvider(
            patient.service_provider_id
              ? {
                  value: patient.service_provider_id,
                  label: patient.provider.display_name,
                }
              : {},
          );
          // Supervisor
          const supervisor = patient.supervisor;
          setSupervisorId(supervisor?.id);
          setSupervisor(supervisor || {});
          setSupLastName(supervisor?.last_name || "");
          setSupFirstName(supervisor?.first_name || "");
          setSupBirthday(supervisor?.birthday ? new Date(supervisor?.birthday) : "");
          setSupGender(supervisor?.gender || "male");
          setSupCommunication(supervisor?.communication_path || "");
          setSupCommunicationOptions(
            supervisor?.communication_path?.length > 0
              ? [I18n.t(`enumerize.defaults.communication_path.${supervisor.communication_path}`)]
              : [I18n.t("enumerize.defaults.communication_path.post")],
          );
          setSupLanguage(supervisor?.patient_language_id || languages[0]?.id);
          setSupCountry(supervisor?.country || COUNTRY_OPTIONS[0]?.id);
          setSupZip(supervisor?.zip || "");
          setSupStreetNumber(supervisor?.street_number || "");
          setSupHouseNumber(supervisor?.house_number || "");
          setsupPlace(supervisor?.place || "");
          setSupRelationType(supervisor?.relation_type || "");
          setSupPhoneNumber(supervisor?.phone_number ? `+${supervisor.phone_number}` : "");
          setSupEmail(supervisor?.email || "");

          if (providers.length == 0) {
            ServiceProviderService.index().then(
              (response) => {
                let p = response.data.service_providers.filter((s) => s.is_internal);
                let options = p.map((pp) => {
                  return {
                    value: pp.id,
                    label: pp.display_name,
                  };
                });
                setProviders(options);
              },
              (error) => {
                toastMessage.error(error.meta.message);
              },
            );
          }
        },
        (error) => {
          setError(error.meta.message);
        },
      );
    }
  }, [props.patientId]);

  // -- Start Supervison --
  const onChangeSupLanguage = (e) => {
    const val = e.target.value;
    setSupLanguage(val);
  };
  const onChangeSupZip = (e) => {
    const val = e.target.value;
    setSupZip(val);
  };
  const onChangeSupStreetNumber = (e) => {
    const val = e.target.value;
    setSupStreetNumber(val);
  };
  const onChangesupPlace = (e) => {
    const val = e.target.value;
    setsupPlace(val);
  };
  const onChangeSupPhoneNumber = (val) => {
    if (val == undefined) {
      val = "";
    }
    setSupPhoneNumber(val);

    if (val.length > 0 && supEmail.length > 0) {
      setSupCommunicationOptions([I18n.t("enumerize.defaults.communication_path.email")]);
      setSupCommunication("email");
    } else if (supEmail.length > 0) {
      setSupCommunicationOptions([I18n.t("enumerize.defaults.communication_path.email")]);
      setSupCommunication("email");
    } else if (val.length > 0) {
      setSupCommunicationOptions([I18n.t("enumerize.defaults.communication_path.post")]);
      setSupCommunication("post");
    } else if (val.length == 0 && supEmail.length == 0) {
      setSupCommunicationOptions([I18n.t("enumerize.defaults.communication_path.post")]);
      setSupCommunication("post");
    }
  };
  const onChangeSupGender = (e) => {
    const val = e.target.value;
    setSupGender(val);
  };
  const onChangeSupFirstName = (e) => {
    const val = e.target.value;
    setSupFirstName(val);
  };
  const onChangeSupLastName = (e) => {
    const val = e.target.value;
    setSupLastName(val);
  };
  const onChangeSupCommunication = (e) => {
    const val = e.target.value;
    setSupCommunication(val);
  };
  const onChangeSupHouseNumber = (e) => {
    const val = e.target.value;
    setSupHouseNumber(val);
  };
  const onChangeSupEmail = (e) => {
    const email = e.target.value;

    setSupEmail(email);

    if (supPhoneNumber.length > 0 && email.length > 0) {
      setSupCommunicationOptions([I18n.t("enumerize.defaults.communication_path.email")]);
      setSupCommunication("email");
    } else if (email.length > 0) {
      setSupCommunicationOptions([I18n.t("enumerize.defaults.communication_path.email")]);
      setSupCommunication("email");
    } else if (supPhoneNumber.length > 0) {
      setSupCommunicationOptions([I18n.t("enumerize.defaults.communication_path.post")]);
      setSupCommunication("post");
    } else if (supPhoneNumber.length == 0 && email.length == 0) {
      setSupCommunicationOptions([I18n.t("enumerize.defaults.communication_path.post")]);
      setSupCommunication("post");
    } else if (email.length == 0) {
      setSupCommunicationOptions([I18n.t("enumerize.defaults.communication_path.post")]);
      setSupCommunication("post");
    }
  };

  // -- End Supervison --

  const onChangeLanguage = (e) => {
    const val = e.target.value;
    setLanguage(val);
  };

  const onChangeTreatment = (e) => {
    const val = e.target.value;
    setTreatment(val);
  };

  const onChangeGender = (e) => {
    const val = e.target.value;
    setGender(val);
  };

  const onChangeEmail = (e) => {
    const val = e.target.value;
    setEmail(val);
    if (phoneNumber.length > 0 && val.length > 0) {
      setCommunicationOptions([I18n.t("enumerize.defaults.communication_path.email")]);
      setCommunication("email");
    } else if (val.length > 0) {
      setCommunicationOptions([I18n.t("enumerize.defaults.communication_path.email")]);
      setCommunication("email");
    } else if (phoneNumber.length > 0) {
      setCommunicationOptions([I18n.t("enumerize.defaults.communication_path.post")]);
      setCommunication("post");
    } else if (phoneNumber.length == 0 && val.length == 0) {
      setCommunicationOptions([I18n.t("enumerize.defaults.communication_path.post")]);
      setCommunication("post");
    }
  };

  const onChangePhoneNumber = (val) => {
    if (val == undefined) {
      val = "";
      toastMessage.error(I18n.t("form.common_error.area_code_blank"), "area_code_blank");
    }
    setPhoneNumber(val);

    if (val.length > 0 && email.length > 0) {
      setCommunicationOptions([I18n.t("enumerize.defaults.communication_path.email")]);
      setCommunication("email");
    } else if (email.length > 0) {
      setCommunicationOptions([I18n.t("enumerize.defaults.communication_path.email")]);
      setCommunication("email");
    } else if (val.length > 0) {
      setCommunicationOptions([I18n.t("enumerize.defaults.communication_path.post")]);
      setCommunication("post");
    } else if (val.length == 0 && email.length == 0) {
      setCommunicationOptions([I18n.t("enumerize.defaults.communication_path.post")]);
      setCommunication("post");
    }
  };

  const onChangeInsurranceNumber = (e) => {
    const { value } = e.target;
    setInsurranceNumber(value);
    debouncedCallBackend(value);
  };

  const onChangeActive = (e) => {
    setPatientActive(e.currentTarget.checked);
  };

  const onChangeKissId = (e) => {
    const id = e.target.value;
    setKissId(id);
  };

  const onChangeFirstName = (e) => {
    const name = e.target.value;
    setFirstName(name);
  };

  const onChangeLastName = (e) => {
    const name = e.target.value;
    setLastName(name);
  };

  const onChangeCommunication = (e) => {
    const val = e.target.value;
    setCommunication(val);
  };

  const handleCreateSupervisor = (e) => {
    setCreateSupervisor(!createSupervisor);
  };

  useEffect(() => {
    if (providers.length == 0 && !patientId) {
      ServiceProviderService.index().then(
        (response) => {
          let p = response.data.service_providers.filter((s) => s.is_internal);
          let options = p.map((pp) => {
            return {
              value: pp.id,
              label: pp.display_name,
            };
          });
          setProviders(options);
        },
        (error) => {
          toastMessage.error(error.meta.message);
        },
      );
    }
  }, []);

  const handleDeleteSupervisor = async () => {
    if (window.confirm(I18n.t("form.patient.confirm_delete_supervisor"))) {
      const res = await PatientService.destroy(supervisorId);
      navigate.push({
        pathname: `/patients/${patientId}/edit`,
        state: { name: "actionSuccess", message: res.data.message },
      });
      window.location.reload();
    }
  };

  if (props.location?.query?.expendSupervisor && createSupervisor) {
    const view = document.getElementById("supevisorEditView");
    view?.scrollIntoView();
  }

  const handleCreate = (e) => {
    e.preventDefault();

    if (!lastTreatmentId && careerChanger == I18n.t("enumerize.defaults.yes_no.true")) {
      setShowLastTreatmentModal(true);
      return;
    }

    setSubmitting(true);
    const supervisor = {
      id: supervisorId,
      first_name: supFirstName,
      last_name: supLastName,
      email: supEmail,
      phone_number:
        supPhoneNumber.substring(0, 1) == "+" ? supPhoneNumber.substring(1, supPhoneNumber.length) : supPhoneNumber,
      gender: supGender,
      communication_path: supCommunication,
      birthday: supBirthday,
      patient_language_id: supLanguage || languages[0].id,
      street_number: supStreetNumber,
      house_number: supHouseNumber,
      zip: supZip,
      place: supPlace,
    };

    const patient = {
      patient: {
        treatment_pathway_id: treatment,
        kiss_id: kissId,
        first_name: firstName,
        last_name: lastName,
        insurance_number: insurranceNumber,
        phone_number: phoneNumber.substring(0, 1) == "+" ? phoneNumber.substring(1, phoneNumber.length) : phoneNumber,
        birthday: birthday,
        gender: gender,
        patient_language_id: language,
        email: email,
        communication_path: communication,
        supervisor_attributes: createSupervisor ? supervisor : null,
        service_provider_id: provider.value,
        street_number: streetNumber,
        house_number: houseNumber,
        zip: zip,
        place: place,
        patient_info: patientInfo,
        career_changer: careerChanger == I18n.t("enumerize.defaults.yes_no.true") ? "yes" : "no",
        last_treatment_id: lastTreatmentId,
      },
    };

    if (patientId) {
      PatientService.update(patientId, patient).then(
        (res) => {
          navigate.push({
            pathname: `/patients/${patientId}/profile`,
            state: { name: "actionSuccess", message: res.data.message },
          });
          toastMessage.success(res.data.message);
          setSubmitting(false);
        },
        (error) => {
          toastMessage.error(error.meta.message);
          setSubmitting(false);
        },
      );
    } else {
      PatientService.create(patient).then(
        (res) => {
          navigate.push({
            pathname: `/patients/${res.data.patient.id}/profile`,
            state: { name: "actionSuccess", message: res.data.message },
          });
          toastMessage.success(res.data.message);
          setSubmitting(false);
        },
        (error) => {
          toastMessage.error(error.meta.message);
          setSubmitting(false);
        },
      );
    }
  };

  return (
    <div className="row">
      <div className="col-md-1"></div>
      <div className="col-md-10 mt-5">
        {error.length > 0 && <AlertMessage variant="danger" message={error} />}
        <div id="patient-create">
          <LastTreatmentModal
            setLastTreatmentId={(id) => setLastTreatmentId(id)}
            show={showLastTreatmentModal}
            onHide={() => setShowLastTreatmentModal(false)}
          />
          <div className="patient-create-box">
            <div className="box-header">
              {patientId == undefined && <UserAddOutlined />}
              {patientId !== undefined && gender == "male" && <ManIcon />}
              {patientId !== undefined && gender == "female" && <FemaleIcon />}
              {patientId !== undefined && gender == "other" && <OtherGenderIcon />}
              <span>{props.title}</span>
              {patientId !== undefined && (
                <Link
                  to={`/patients/${
                    props.location.query?.expendSupervisor ? supervisorId || patientId : patientId
                  }/profile`}
                >
                  <CloseOutlined />
                </Link>
              )}
              {patientId == undefined && (
                <Link to={`/patients`}>
                  <CloseOutlined />
                </Link>
              )}
            </div>
            <div className="box-body row">
              <div className="input required">
                <div className="col-md-5 label">
                  <span>{I18n.t("activerecord.attributes.patient.insurance_number")}</span>
                </div>
                <div className="col-md-7">
                  <input type="text" onChange={onChangeInsurranceNumber} value={insurranceNumber} />
                </div>
              </div>
              <div className="input required">
                <div className="col-md-5 label">
                  <span>{I18n.t("activerecord.attributes.patient.kiss_id")}</span>
                </div>
                <div className="col-md-7">
                  <input type="text" onChange={onChangeKissId} value={kissId} />
                </div>
              </div>
              <div className="input">
                <div className="col-md-5 label">
                  <span>{I18n.t("activerecord.attributes.patient.last_name")}</span>
                </div>
                <div className="col-md-7">
                  <input type="text" onChange={onChangeLastName} value={lastName} />
                </div>
              </div>
              <div className="input">
                <div className="col-md-5 label">
                  <span>{I18n.t("activerecord.attributes.patient.first_name")}</span>
                </div>
                <div className="col-md-7">
                  <input type="text" value={firstName} onChange={onChangeFirstName} />
                </div>
              </div>
              <div className="input required">
                <div className="col-md-5 label">
                  <span>{I18n.t("form.patient.birthday")}</span>
                </div>
                <div className="col-md-7">
                  <DatePicker
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    maxDate={new Date()}
                    dateFormat={"dd.MM.yyyy"}
                    className="form-control"
                    selected={birthday}
                    onChange={(date) => setBirthday(date)}
                  />
                </div>
              </div>
              <div className="input">
                <div className="col-md-5 label">
                  <span>{I18n.t("activerecord.attributes.patient.gender")}</span>
                </div>
                <div className="col-md-7">
                  <select name="gender" className="selection form-control" onChange={onChangeGender} value={gender}>
                    <option value="male">{I18n.t("form.patient.male")}</option>
                    <option value="female">{I18n.t("form.patient.female")}</option>
                    <option value="other">{I18n.t("form.patient.other")}</option>
                  </select>
                </div>
              </div>
              <div className="input required">
                <div className="col-md-5 label">
                  <span>{I18n.t("activerecord.attributes.patient.street_number")}</span>
                </div>
                <div className="col-md-7">
                  <input type="text" onChange={(e) => setStreetNumber(e.target.value)} value={streetNumber} />
                </div>
              </div>
              <div className="input required">
                <div className="col-md-5 label">
                  <span>{I18n.t("form.patient.house_number")}</span>
                </div>
                <div className="col-md-7">
                  <input type="text" onChange={(e) => setHouseNumber(e.target.value)} value={houseNumber} />
                </div>
              </div>
              <div className="input required">
                <div className="col-md-5 label">
                  <span>{I18n.t("activerecord.attributes.patient.zip")}</span>
                </div>
                <div className="col-md-7">
                  <input type="text" onChange={(e) => setZip(e.target.value)} value={zip} />
                </div>
              </div>
              <div className="input required">
                <div className="col-md-5 label">
                  <span>{I18n.t("form.patient.place")}</span>
                </div>
                <div className="col-md-7">
                  <input type="text" onChange={(e) => setPlace(e.target.value)} value={place} />
                </div>
              </div>
              <div className="input required">
                <div className="col-md-5 label">
                  <span>{I18n.t("activerecord.attributes.patient.phone_number")}</span>
                </div>
                <div className="col-md-7">
                  <PhoneInput
                    international
                    defaultCountry="CH"
                    value={phoneNumber}
                    onChange={(val) => onChangePhoneNumber(val)}
                  />
                </div>
              </div>

              <div className="input required">
                <div className="col-md-5 label">
                  <span>{I18n.t("activerecord.attributes.patient.email")}</span>
                </div>
                <div className="col-md-7">
                  <input type="email" onChange={onChangeEmail} value={email} />
                </div>
              </div>
              <div className="input required">
                <div className="col-md-5 label">
                  <span>{I18n.t("activerecord.models.treatment_pathway")}</span>
                </div>
                <div className="col-md-7">
                  {hasActiveTreatment ? (
                    <input disabled type="text" value={treatments.find((t) => t.id == treatment)?.name_de} />
                  ) : (
                    <select
                      name="treatment"
                      className="selection form-control"
                      value={treatment}
                      onChange={onChangeTreatment}
                    >
                      {treatments.map((t) => (
                        <option value={t.id} key={t.id}>
                          {t.name_de}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </div>
              <div className="input">
                <div className="col-md-5 label">
                  <span>{I18n.t("activerecord.attributes.patient.communication_path")}</span>
                </div>
                <div className="col-md-7">
                  <select
                    name="communication"
                    className="selection form-control text-uppercase"
                    onChange={onChangeCommunication}
                    value={communication}
                  >
                    {communicationOptions.map((c) => (
                      <option key={c}>{c}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="input">
                <div className="col-md-5 label">
                  <span>{I18n.t("activerecord.attributes.patient.career_changer")}</span>
                </div>
                <div className="col-md-7">
                  {appointments.length > 0 ? (
                    <input disabled value={careerChanger} />
                  ) : (
                    <select
                      name="career_changer"
                      className="selection form-control"
                      value={careerChanger}
                      onChange={(e) => setCareerChanger(e.target.value)}
                    >
                      <option>{I18n.t("enumerize.defaults.yes_no.true")}</option>
                      <option>{I18n.t("enumerize.defaults.yes_no.false")}</option>
                    </select>
                  )}
                </div>
              </div>
              <div className="input">
                <div className="col-md-5 label">
                  <span>{I18n.t("activerecord.attributes.patient.language")}</span>
                </div>
                <div className="col-md-7">
                  <select
                    name="language"
                    className="selection form-control"
                    value={language}
                    onChange={onChangeLanguage}
                  >
                    {languages.map((l) => (
                      <option key={l.id} value={l.id}>
                        {l.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="input required">
                <div className="col-md-5 label">
                  <span>{I18n.t("form.service_provider.name_in")}</span>
                </div>
                <div className="col-md-7">
                  {providers.length > 0 && (
                    <Select
                      style={{ width: "100%" }}
                      defaultValue={provider}
                      onChange={(e) => setProvider(e)}
                      options={providers}
                      className="react-select-provider"
                      classNamePrefix="react-select"
                      placeholder=""
                    />
                  )}
                </div>
              </div>

              <div className="input-textarea">
                <div className="col-md-5 label">
                  <span>{I18n.t("activerecord.attributes.patient.patient_info")}</span>
                </div>
                <div className="col-md-7">
                  <textarea onChange={(e) => setPatientInfo(e.target.value)} value={patientInfo} />
                </div>
              </div>
              <div
                style={{ width: "20%" }}
                className="med-btn create-supervisor float-left"
                onClick={handleCreateSupervisor}
              >
                <span>
                  {Object.keys(supervisor).length > 0
                    ? I18n.t("form.patient.update_supervisor")
                    : I18n.t("form.patient.create_supervisor")}
                </span>
                {!createSupervisor && <RightOutlined />}
                {createSupervisor && <DownOutlined />}
              </div>

              <div className="mb-5"></div>
              {createSupervisor && (
                <>
                  <div className="input">
                    <div className="col-md-5 label">
                      <span>{I18n.t("activerecord.attributes.patient.last_name")}</span>
                    </div>
                    <div className="col-md-7">
                      <input type="text" onChange={onChangeSupLastName} value={supLastName} />
                    </div>
                  </div>
                  <div className="input">
                    <div className="col-md-5 label">
                      <span>{I18n.t("activerecord.attributes.patient.first_name")}</span>
                    </div>
                    <div className="col-md-7">
                      <input type="text" value={supFirstName} onChange={onChangeSupFirstName} />
                    </div>
                  </div>
                  <div className="input">
                    <div className="col-md-5 label">
                      <span>{I18n.t("activerecord.attributes.patient.gender")}</span>
                    </div>
                    <div className="col-md-7">
                      <select
                        name="gender"
                        className="selection form-control"
                        onChange={onChangeSupGender}
                        value={supGender}
                      >
                        <option value="male">{I18n.t("form.patient.male")}</option>
                        <option value="female">{I18n.t("form.patient.female")}</option>
                        <option value="other">{I18n.t("form.patient.other")}</option>
                      </select>
                    </div>
                  </div>
                  <div className="input required">
                    <div className="col-md-5 label">
                      <span>{I18n.t("activerecord.attributes.patient.phone_number")}</span>
                    </div>
                    <div className="col-md-7">
                      <PhoneInput
                        international
                        defaultCountry="CH"
                        value={supPhoneNumber}
                        onChange={(val) => onChangeSupPhoneNumber(val)}
                      />
                    </div>
                  </div>
                  <div className="input required">
                    <div className="col-md-5 label">
                      <span>{I18n.t("activerecord.attributes.patient.email")}</span>
                    </div>
                    <div className="col-md-7">
                      <input type="email" onChange={onChangeSupEmail} value={supEmail} />
                    </div>
                  </div>
                  <div className="input">
                    <div className="col-md-5 label">
                      <span>{I18n.t("activerecord.attributes.patient.language")}</span>
                    </div>
                    <div className="col-md-7">
                      <select
                        name="supLanguage"
                        className="selection form-control"
                        value={supLanguage}
                        onChange={onChangeSupLanguage}
                      >
                        {languages.map((l) => (
                          <option key={l.id} value={l.id}>
                            {l.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="input">
                    <div className="col-md-5 label">
                      <span>{I18n.t("activerecord.attributes.patient.communication_path")}</span>
                    </div>
                    <div className="col-md-7">
                      <select
                        name="supCommunication"
                        className="selection form-control text-uppercase"
                        onChange={onChangeSupCommunication}
                        value={supCommunication}
                      >
                        {supCommunicationOptions.map((c) => (
                          <option key={c}>{c}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {supervisorId && (
                    <>
                      <button
                        style={{ width: "5%" }}
                        onClick={handleDeleteSupervisor}
                        className="btn btn-danger float-end mb-3 width-25"
                      >
                        <DeleteOutlined />
                      </button>
                    </>
                  )}
                  <div className="mb-5" id="supevisorEditView"></div>
                </>
              )}
            </div>
            <button
              disabled={submitting}
              className="mb-3 aa create-patient d-flex align-items-baseline justify-content-between btn btn-lg mt-3 fw-bolder float-end bg-green"
              onClick={handleCreate}
            >
              <span>{I18n.t("form.create")}</span>
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-1"></div>
    </div>
  );
};

export default PatientForm;
