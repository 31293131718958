import React, { useState, useEffect } from "react";
import "./index.scss";
import Logo from "images/logo-color-no-bg.svg";
import Spinner from "react-bootstrap/Spinner";
import ServiceProviderTable from "./service_provider_table";
import DashboardProviderFilter from "./_provider_filter_select";
import { CalendarOutlined, ForkOutlined, LogoutOutlined, RadarChartOutlined } from "@ant-design/icons";
import TopServiceProviderTable from "./top_service_provider_table";

const DashboardTopServiceProvider = (props) => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const filters = [
    { key: "deadline", icon: <CalendarOutlined />, translation: I18n.t("dashboard.provider_deadline") },
    { key: "case_guide", icon: <ForkOutlined />, translation: I18n.t("form.service_provider.name_in") },
    {
      key: "treatment_pathway",
      icon: <RadarChartOutlined />,
      translation: I18n.t("activerecord.models.treatment_pathway"),
    },
    { key: "area_of_expertise", icon: <RadarChartOutlined /> },
    {
      key: "service_provider",
      icon: <LogoutOutlined />,
      translation: I18n.t("activerecord.models.service_provider"),
    },
  ];

  const onChangeFilter = (key) => {
    const s = [...selectedFilters];
    if (selectedFilters.indexOf(key) != -1) {
      setSelectedFilters(s.filter((f) => f != key));
    } else {
      setSelectedFilters([...s, key]);
    }
  };

  useEffect(() => {
    if (selectedFilters.length == 0) {
      setData([]);
    }
  }, [selectedFilters]);

  return (
    <div className="dashboard">
      <img className="img-fluid mx-auto d-block mb-3 logo" src={Logo} width="45%" />
      <h1 className="text-center mt-3">Top 3 {I18n.t("activerecord.models.service_provider")}</h1>
      <div className="header container mb-5">
        <div className="row row-cols-3 g-3">
          {filters.map((f) => {
            return (
              <div onClick={() => onChangeFilter(f.key)} key={f.key} className="col">
                <div className={`dashboard-item p-4 ${selectedFilters.indexOf(f.key) != -1 ? "selected" : ""}`}>
                  <div className="icon">{f.icon}</div>
                  <div className="content">{f.translation || I18n.t(`dashboard.${f.key}`)}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {loading && (
        <div className="text-center">
          <Spinner className="mt-5" animation="border" variant="primary" />
        </div>
      )}
      <div className="body p-1 container mb-5">
        <DashboardProviderFilter
          data={data}
          selectedFilters={filters.filter((f) => selectedFilters.indexOf(f.key) != -1)}
          setSelectedFilters={(k) => onChangeFilter(k)}
          setData={(d) => setData(d)}
          viewType="topOverview"
        />
        <h3 className="text-center m-5">{I18n.t("dashboard.provider_overview")}</h3>
        <TopServiceProviderTable data={data} rowClasses={"d-fex justify-content-between"} />
      </div>
    </div>
  );
};

export default DashboardTopServiceProvider;
