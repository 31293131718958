import React, { useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import './create.scss'
import ServiceProviderForm from "./_form";

const ServiceProviderUpdate = (props) => {
    let navigate = useHistory();
    const { id } = useParams();
    const [error, setError] = useState("");

    const [providerId, setProviderId] = useState(id);
    return (
        <ServiceProviderForm
            {...props}
            providerId={providerId}
            title={I18n.t('form.service_provider.update')}
            submitLabel={I18n.t('form.update')}
        />
    )
}

export default ServiceProviderUpdate;