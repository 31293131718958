import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import PatientLanguageService from "../../services/patient_language";

import LanguageForm from "./form";

const PatientLanguageUpdate = () => {
  return <LanguageForm text={I18n.t("form.patient_language.update")} />;
};

export default PatientLanguageUpdate;
