import React, { useState, useRef, useEffect } from "react";
import toastMessage from "../../components/toast-message";
import SurveyService from "../../services/survey";
import { usePDF } from "react-to-pdf";
import "./index.scss";
import * as moment from "moment";
import { WarningOutlined } from "@ant-design/icons";

const PublicSurveyShow = ({ data }) => {
  const { toPDF, targetRef } = usePDF({ filename: data.pdf_file_name });

  const [form, _] = useState(data.form);
  const [answer, setAnswer] = useState({});
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [checkedList, setCheckedList] = useState({});

  const [questionKeys, setQuestionKeys] = useState([]);
  const [errorQuestionKeys, setErrorQuestionKeys] = useState([]);

  const downloadRef = useRef(undefined);

  useEffect(() => {
    if (data.user_answer) {
      const _answer = {};
      data.user_answer.forEach((a) => {
        const qId = a[0].toString();
        const aId = a[1];
        _answer[qId] = aId;
      });
      setAnswer(_answer);
    }
  }, []);

  useEffect(() => {
    const _keys = [];
    if (data.form) {
      data.form.forEach((q) => {
        if (q.sub_question) {
          _keys.push(...q.sub_question.map((s) => s[0]));
        } else {
          _keys.push(q.id);
        }
      });

      const _checkList = {};
      data.form.forEach((d) => {
        if (d.answer.length > 0) {
          d.answer.forEach((a) => {
            _checkList[a[0]] = a[2];
          });
        } else {
          d.sub_question.forEach((a) => {
            a[2].forEach((aa) => {
              _checkList[aa[0]] = aa[2];
            });
          });
        }
      });
      setCheckedList(_checkList);
    }
    setQuestionKeys(_keys);
  }, []);

  useEffect(() => {
    if (Object.keys(answer).length > 0) {
      const isDownload = window.location.search.includes("download=true");
      if (isDownload) {
        downloadRef.current.click();
      }
    }
  }, [answer]);

  const handleSubmit = async () => {
    const hasError = validateAll();

    if (hasError.length > 0) {
      toastMessage.error("Es müssen alle Fragen beantwortet werden bevor Sie das Dokument versenden können");
      return;
    }
    SurveyService.create({ answer: { ...answer }, code: data.code }).then(
      (res) => {
        setUpdateSuccess(true);
      },
      (error) => {
        toastMessage.error(error.meta.message);
      },
    );
  };

  const handleInputChange = (questionId, answerId) => {
    const _answer = { ...answer };
    _answer[questionId] = answerId;
    setAnswer(_answer);
  };

  const validateAll = () => {
    const set1 = new Set([...questionKeys]);
    const set2 = new Set([...Object.keys(answer)].map((a) => Number(a)));
    const difference = [...set1].filter((element) => !set2.has(element));

    setErrorQuestionKeys(difference);

    return difference;
  };

  return form?.length > 0 && !updateSuccess ? (
    <div className="container mt-5 survey-show">
      <div className="d-flex flex-column justify-content-center">
        {document.cookie.includes("authHeaders") && (
          <div style={{ width: "100%" }} className="download-btn">
            <button ref={downloadRef} className="btn btn-primary float-end m-3" onClick={() => toPDF()}>
              Download PDF
            </button>
          </div>
        )}
        <div className="card p-5" ref={targetRef}>
          {data.responsed_time && (
            <div>
              <p className="float-end font-italic">
                <strong>
                  <small>Sent data: {moment(data.responsed_time).format("DD.MM.YYYY")}</small>
                </strong>
              </p>
            </div>
          )}
          <h1 className="text-center mb-5">{data.survey.title}</h1>
          {form &&
            form.map((q, index) => {
              return (
                <div className="content" key={q.id}>
                  <div className="question">
                    <h5 className={errorQuestionKeys.includes(Number(q.id)) && "text-danger"}>
                      {index + 1}. {q.question}
                    </h5>
                    {q.sub_question && (
                      <div className="sub-question">
                        <div className="row mb-2">
                          <div className="col-6"></div>
                          <div className="col-6">
                            <div className="answer-group">
                              <div className="row">
                                {q.sub_question[0][2].map((a) => {
                                  return (
                                    <div className="col" key={a[0]}>
                                      <label htmlFor={`answer-${a[0]}-${q.id}`}>{a[1]}</label>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                        {q.sub_question.map((sq) => {
                          return (
                            <div className="container" key={sq.id}>
                              <div className="row">
                                <div className="col-6">
                                  <p className={errorQuestionKeys.includes(Number(sq[0])) && "text-danger"}>{sq[1]}</p>
                                </div>
                                <div className="col-6">
                                  <div className="answer-inline row">
                                    {sq[2].map((a, index) => {
                                      return (
                                        <div className="input col" key={a[0]}>
                                          <input
                                            id={`answer-${a[0]}-${sq[0]}`}
                                            onChange={() => handleInputChange(sq[0], a[0])}
                                            name={`answer-${sq[0]}`}
                                            type="radio"
                                            value={a[0]}
                                            defaultChecked={answer[sq[0]] == a[0]}
                                            checked={answer[sq[0]] == a[0]}
                                          />
                                          <label
                                            className={`${
                                              checkedList[a[0]] == true &&
                                              document.cookie.includes("authHeaders") &&
                                              answer[sq[0]] == a[0]
                                                ? "text-warning"
                                                : ""
                                            } d-flex`}
                                            htmlFor={`answer-${a[0]}-${sq[0]}`}
                                            style={{ gap: 5 }}
                                          >
                                            {index + 1}
                                            {checkedList[a[0]] == true &&
                                              document.cookie.includes("authHeaders") &&
                                              answer[sq[0]] == a[0] && <WarningOutlined />}
                                          </label>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}

                    {!q.sub_question && (
                      <div className="answer">
                        {q.answer.map((a) => {
                          return (
                            <div className="input" key={a[0]}>
                              <input
                                id={`answer-${a[0]}-${q.id}`}
                                onChange={() => handleInputChange(q.id, a[0])}
                                name={`answer-${q.id}`}
                                type="radio"
                                value={a[0]}
                                defaultChecked={answer[q.id] == a[0]}
                                checked={answer[q.id] == a[0]}
                              />
                              <label
                                className={`d-flex ${
                                  checkedList[a[0]] == true &&
                                  document.cookie.includes("authHeaders") &&
                                  answer[q.id] == a[0]
                                    ? "text-warning"
                                    : ""
                                }`}
                                htmlFor={`answer-${a[0]}-${q.id}`}
                              >
                                {a[1]}
                                {checkedList[a[0]] == true &&
                                  document.cookie.includes("authHeaders") &&
                                  answer[q.id] == a[0] && <WarningOutlined />}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <hr></hr>
                </div>
              );
            })}
        </div>
        {!document.cookie.includes("authHeaders") && !data.responsed_time && (
          <div className="d-flex align-items-center m-3">
            <button onClick={handleSubmit} className="btn btn-primary btn-lg">
              Senden
            </button>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="public-appointment-success-preview d-flex flex-column align-items-center mt-5">
      <div className="content">
        <p>Vielen Dank für das Ausfüllen der Umfrage.</p>
        <p>Wir wünschen Ihnen noch einen schönen Tag</p>
      </div>
    </div>
  );
};

export default PublicSurveyShow;
