import { MailOutlined, DatabaseOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import Hospital from "images/hospital.png";

const LogfileFilter = ({ setFilterType, ...props }) => {
  const [error, setError] = useState("");
  const [filter, setFilter] = useState({});
  const [showPrivate, setShowPrivate] = useState(true);
  const [showMail, setShowMail] = useState(false);
  const [showPublic, setShowPublic] = useState(false);

  const privateOptions = [
    { value: "private", label: "All" },
    { value: "ActiveStorage::Attachment", label: "Attachment" },
    { value: "Patient", label: "Patient" },
    { value: "Appointment", label: "Appointment" },
    { value: "ServiceProvider", label: I18n.t("activerecord.models.service_provider") },
    { value: "ServiceProviderAcademicTitle", label: I18n.t("activerecord.models.academic_title") },
    { value: "Specialist", label: I18n.t("activerecord.models.specialist") },
    { value: "PatientLanguage", label: I18n.t("activerecord.models.patient_language") },
    { value: "Treatment", label: I18n.t("activerecord.models.treatment_pathway") },
  ];

  const publicOptions = [
    { value: "public", label: "All" },
    { value: "Tenant", label: I18n.t("form.tenant.name") },
    { value: "Department", label: I18n.t("form.department.name") },
    { value: "User", label: I18n.t("activerecord.models.user.other") },
  ];

  const mailOptions = [
    { value: "mail", label: "All" },
    { value: "Patient", label: "Patient" },
    { value: "ServiceProvider", label: I18n.t("activerecord.models.service_provider") },
    { value: "User", label: "Support" },
  ];

  const options = showPrivate ? privateOptions : showPublic ? publicOptions : mailOptions;

  const onChange = (e) => {
    setFilterType(e.value);
    setFilter(e);
  };

  const toggleShow = (showPrivate, showMail, showPublic) => {
    setShowPrivate(showPrivate);
    setShowMail(showMail);
    setShowPublic(showPublic);

    if (showMail) {
      onChange({ value: "mail", label: "All" });
      props.setShowMail(true);
    }

    if (showPublic) {
      onChange({ value: "public", label: "All" });
      props.setShowMail(false);
    }

    if (showPrivate) {
      onChange({ value: "private", label: "All" });
      props.setShowMail(false);
    }
  };

  return (
    <div className="row mb-3">
      <div className="create-type m-3">
        <div className="custom-control custom-radio custom-control-inline">
          <input
            checked={showPrivate}
            value={showPrivate}
            onChange={() => toggleShow(true, false, false)}
            type="radio"
            id="showPrivate"
            name="customRadioInline1"
            className="custom-control-input"
          />
          <DatabaseOutlined />
          <label className="custom-control-label" htmlFor="showPrivate">
            {I18n.t("activerecord.models.patient")}/Appointment/{I18n.t("activerecord.models.service_provider")}/...
          </label>
        </div>
        <div className="custom-control custom-radio custom-control-inline">
          <input
            checked={showMail}
            value={showMail}
            onChange={() => toggleShow(false, true, false)}
            type="radio"
            id="showMail"
            name="customRadioInline1"
            className="custom-control-input"
          />
          <MailOutlined />
          <label className="custom-control-label" htmlFor="showMail">
            Mail
          </label>
        </div>

        <div className="custom-control custom-radio custom-control-inline">
          <input
            checked={showPublic}
            value={showPublic}
            onChange={() => toggleShow(false, false, true)}
            type="radio"
            id="showPublic"
            name="customRadioInline1"
            className="custom-control-input"
          />
          <img className="setting-icon-dropdown" src={Hospital} width="25" height="25" />
          <label className="custom-control-label" htmlFor="showPublic">
            {I18n.t("form.tenant.name")}/{I18n.t("form.department.name")}/{I18n.t("activerecord.models.user.other")}
          </label>
        </div>
      </div>

      <div className="col-md-4">
        <Select
          defaultValue={options[0]}
          value={filter}
          onChange={onChange}
          options={options}
          className="react-select"
          classNamePrefix="react-select"
          placeholder=""
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
      </div>
    </div>
  );
};

export default LogfileFilter;
