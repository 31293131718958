import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import ServiceProviderService from "../../services/service_provider";
import AlertMessage from "../../components/alert-message";
import {
  UserOutlined,
  HomeOutlined,
  DownOutlined,
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
  ConsoleSqlOutlined,
} from "@ant-design/icons";
import AuthService from "../../services/auth";
import "./profile.scss";
import ManIcon from "../../components/icons/male-icon";
import FemaleIcon from "../../components/icons/female-icon";
import OtherGenderIcon from "../../components/icons/other-gender-icon";
import Input from "react-phone-number-input/input";

const ServiceProviderProfile = (props) => {
  let navigate = useHistory();
  const [error, setError] = useState("");

  const { id } = useParams();

  const [currentTab, setCurrentTab] = useState("profile");
  const [providerId, setProviderId] = useState(id);
  const [service_provider, setProvider] = useState({});
  const [service_providers, setProviders] = useState([]);

  useEffect(() => {
    ServiceProviderService.index().then(
      (response) => {
        setProviders(response.data.service_providers);
      },
      (error) => {
        setError(error.meta.message);
      },
    );
  }, [props.departmentId]);

  useEffect(() => {
    ServiceProviderService.show(providerId).then(
      (response) => {
        setProvider(response.data.service_provider);
      },
      (error) => {
        setError(error.meta.message);
      },
    );
  }, [providerId, props.departmentId]);

  const onChangePatient = (e) => {
    setProviderId(e.target.value);
    navigate.push({ pathname: `/service_providers/${e.target.value}/profile` });
  };

  const goToSearch = () => {
    navigate.push({ pathname: "/service_providers" });
  };

  const fullNameDisplay = (firstName, lastName) => {
    if (firstName.length == 0 && lastName.length == 0) {
      return <HomeOutlined />;
    } else {
      return `${firstName} ${lastName}`;
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm(I18n.t("form.service_provider.confirm_delete"))) {
      try {
        const res = await ServiceProviderService.destroy(id);
        navigate.push({ pathname: "/service_providers", state: { name: "actionSuccess", message: res.data.message } });
      } catch (error) {
        setError(error.meta.message);
      }
    }
  };

  const displayName = (sp) => {
    if (!sp.first_name && !sp.last_name) {
      return sp.company_name;
    } else {
      return `${sp.first_name} ${sp.last_name}`;
    }
  };

  return (
    <div className="row mt-5 position-relative">
      <div id="service-provider-switch">
        <div className={"admin-view"}>
          <UserOutlined />
          <select value={providerId} id="patientFilter" className="tenant-select" onChange={onChangePatient}>
            {service_providers.map((p) => (
              <option value={p.id} key={p.id}>
                {p.display_name}
              </option>
            ))}
          </select>
          <DownOutlined />
          <SearchOutlined className="mr-1" onClick={goToSearch} />
        </div>
      </div>
      <div id="provider-profile-tab">
        <div onClick={() => setCurrentTab("profile")} className={currentTab == "profile" ? "active" : ""}>
          {service_provider?.is_internal
            ? I18n.t("form.service_provider.internal")
            : I18n.t("form.service_provider.external")}
        </div>
        <div className="profile-action">
          {service_provider && (
            <Link to={`/service_providers/${service_provider.id}/edit`} className="btn btn-primary fs-5">
              <EditOutlined />
            </Link>
          )}
          {service_provider && service_provider.can_delete && (
            <a
              onClick={(e) => {
                e.preventDefault();
                handleDelete(service_provider.id);
              }}
              className="btn btn-danger delete-service_provider fs-5"
            >
              <DeleteOutlined />
            </a>
          )}
        </div>
      </div>
      {currentTab == "profile" && (
        <div className="col-md-12" id="service-provider-profile">
          {error.length > 0 && <AlertMessage variant="danger" message={error} />}
          {Object.keys(service_provider).length > 0 && (
            <div className="header">
              <span className="name-holder">
                {service_provider.first_name &&
                  service_provider.last_name &&
                  service_provider.first_name.length > 0 &&
                  service_provider.last_name.length > 0 && (
                    <>
                      {service_provider.gender == "male" && <ManIcon color="#48F0C6" />}
                      {service_provider.gender == "female" && <FemaleIcon color="#48F0C6" />}
                      {service_provider.gender == "other" && <OtherGenderIcon color="#48F0C6" />}
                    </>
                  )}
                {fullNameDisplay(service_provider.first_name || "", service_provider.last_name || "")}
                <div className="treatment-holder">
                  <div style={{ color: "#747e84" }} className="label">
                    {I18n.t("form.treatment.name")}
                  </div>
                  <div style={{ color: "#48F0C6" }} className="content">
                    {service_provider.specialist?.name}
                  </div>
                </div>
              </span>

              {service_provider && service_provider.is_internal == false && (
                <div className="form-check form-switch">
                  <label className="form-check-label" htmlFor="activeUser">
                    {I18n.t("activerecord.attributes.service_provider.patient_appointment_reminder")}
                  </label>
                  <input
                    className="form-check-input"
                    disabled
                    type="checkbox"
                    role="switch"
                    id="activeUser"
                    defaultChecked={service_provider.patient_appointment_reminder}
                  />
                </div>
              )}
            </div>
          )}
          {Object.keys(service_provider).length > 0 && (
            <div className="body mt-3">
              {service_provider.service_type == "internal" && (
                <>
                  <div className="field">
                    <div className="label">{I18n.t("activerecord.attributes.service_provider.company_name")}</div>
                    <div className="content">{service_provider.company_name}</div>
                  </div>
                  <div className="field">
                    <div className="label">{I18n.t("activerecord.attributes.service_provider.academic_title")}</div>
                    <div className="content text-capitalize">{service_provider.academic_title?.name}</div>
                  </div>
                  <div className="field">
                    <div className="label">{I18n.t("activerecord.attributes.patient.gender")}</div>
                    <div className="content text-capitalize">
                      {service_provider.gender ? I18n.t(`enumerize.defaults.gender.${service_provider.gender}`) : ""}
                    </div>
                  </div>
                  <div className="field">
                    <div className="label">{I18n.t("activerecord.attributes.service_provider.email")}</div>
                    <div className="content">{service_provider.email}</div>
                  </div>
                  <div className="field">
                    <div className="label">{I18n.t("activerecord.attributes.service_provider.phone_number")}</div>
                    <div className="content phone-number">
                      {service_provider.phone_number && (
                        <Input
                          disabled
                          withCountryCallingCode
                          international
                          onChange={() => console.log(service_provider.phone_number)}
                          value={"+" + service_provider.phone_number}
                        />
                      )}
                    </div>
                  </div>
                  <div className="field">
                    <div className="label">{I18n.t("activerecord.attributes.service_provider.meeting_point")}</div>
                    <div className="content">{service_provider.meeting_point}</div>
                  </div>
                </>
              )}
            </div>
          )}

          {Object.keys(service_provider).length > 0 && (
            <div className="body mt-3">
              {service_provider.service_type == "external" && (
                <>
                  <div className="field">
                    <div className="label">{I18n.t("activerecord.attributes.service_provider.company_name")}</div>
                    <div className="content">{service_provider.company_name}</div>
                  </div>
                  <div className="field">
                    <div className="label">{I18n.t("activerecord.attributes.service_provider.department")}</div>
                    <div className="content">{service_provider.department}</div>
                  </div>
                  <div className="field">
                    <div className="label">{I18n.t("activerecord.attributes.service_provider.email")}</div>
                    <div className="content">{service_provider.email}</div>
                  </div>
                  <div className="field">
                    <div className="label">{I18n.t("activerecord.attributes.service_provider.phone_number")}</div>
                    <div className="content phone-number">
                      {service_provider.phone_number && (
                        <Input
                          disabled
                          withCountryCallingCode
                          international
                          onChange={() => console.log(service_provider.phone_number)}
                          value={"+" + service_provider.phone_number}
                        />
                      )}
                    </div>
                  </div>

                  <div className="field">
                    <div className="label">{I18n.t("activerecord.attributes.service_provider.street_number")}</div>
                    <div className="content text-capitalize">{service_provider.street_number}</div>
                  </div>
                  <div className="field">
                    <div className="label">{I18n.t("activerecord.attributes.service_provider.house_number")}</div>
                    <div className="content text-capitalize">{service_provider.house_number}</div>
                  </div>
                  <div className="field">
                    <div className="label">{I18n.t("activerecord.attributes.service_provider.zip")}</div>
                    <div className="content text-capitalize">{service_provider.zip}</div>
                  </div>
                  <div className="field">
                    <div className="label">{I18n.t("activerecord.attributes.service_provider.place")}</div>
                    <div className="content text-capitalize">{service_provider.place}</div>
                  </div>

                  <div className="field">
                    <div className="label">
                      {I18n.t("activerecord.attributes.service_provider.phone_number_direct")}
                    </div>
                    <div className="content phone-number">
                      {service_provider.phone_number_direct && (
                        <Input
                          disabled
                          country="CH"
                          withCountryCallingCode
                          international
                          onChange={() => console.log(service_provider.phone_number_direct)}
                          value={"+" + service_provider.phone_number_direct}
                        />
                      )}
                    </div>
                  </div>

                  <div className="field">
                    <div className="label">{I18n.t("activerecord.attributes.service_provider.communication_path")}</div>
                    <div className="content text-uppercase">
                      {I18n.t(`enumerize.defaults.communication_path.${service_provider.communication_path}`)}
                    </div>
                  </div>
                  <div className="field">
                    <div className="label">{I18n.t("activerecord.attributes.patient.language")}</div>
                    <div className="content">{service_provider.patient_language?.name}</div>
                  </div>
                  <div className="field">
                    <div className="label">{I18n.t("activerecord.attributes.patient.gender")}</div>
                    <div className="content text-capitalize">
                      {service_provider.gender ? I18n.t(`enumerize.defaults.gender.${service_provider.gender}`) : ""}
                    </div>
                  </div>
                  <div className="field">
                    <div className="label">{I18n.t("activerecord.attributes.service_provider.meeting_point")}</div>
                    <div className="content">{service_provider.meeting_point}</div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ServiceProviderProfile;
