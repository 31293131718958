import React, { useState, useEffect } from "react";
import Logo from "images/logo-color-no-bg.svg";
import "./index.scss";
import {
  CalendarOutlined,
  FileProtectOutlined,
  SwapOutlined,
  InfoCircleOutlined,
  SoundOutlined,
} from "@ant-design/icons";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import DashboardService from "../../services/dashboard";
import toastMessage from "../../components/toast-message";
import Spinner from "react-bootstrap/Spinner";
import TodoTable from "./_todo_table";
import DashboardDownload from "./_download_result";

const Todo = (props) => {
  const filters = [
    { key: "appointment_not_confirm", icon: <CalendarOutlined /> },
    { key: "appoinment_missed_result", icon: <FileProtectOutlined /> },
    { key: "same_date_appointment", icon: <SwapOutlined /> },
    { key: "appointment_not_send_mail", icon: <InfoCircleOutlined /> },
    { key: "patient_threshold", icon: <SoundOutlined /> },
  ];
  const [loading, setLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [dateParams, setDataParams] = useState(0);
  const [isWarning, setIsWarning] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (selectedFilter == "appointment_not_confirm" || selectedFilter == "appoinment_missed_result") {
      setDataParams(12);
    }

    if (selectedFilter == "appointment_not_send_mail") {
      setDataParams(60);
    }
  }, [selectedFilter]);

  const [checkTodo, setCheckTodo] = useState({});
  useEffect(() => {
    DashboardService.checkTodo().then(
      (response) => {
        setCheckTodo(response.data.check);
      },
      (error) => {
        toastMessage.error(error.meta.message);
      },
    );
  }, []);

  const onChangeFilter = async (key) => {
    let date;
    if (key == "appointment_not_confirm" || key == "appoinment_missed_result") {
      date = 12;
    }
    if (key == "appointment_not_send_mail") {
      date = 60;
    }
    if (selectedFilter == key) {
      setSelectedFilter("");
    } else {
      setSelectedFilter(key);
      setLoading(true);
      try {
        const response = await DashboardService.todo(key, selectedFilter, date);
        setData(response.data.data);
        setLoading(false);
      } catch (error) {
        toastMessage.error(error.meta.message);
        setLoading(false);
      }
    }
  };

  const onChangeDateParams = async (e) => {
    const value = e.target.value;
    setDataParams(value);
    setLoading(true);
    try {
      const response = await DashboardService.todo(selectedFilter, selectedFilter, value);
      setData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toastMessage.error(error?.meta?.message);
      setLoading(false);
    }
  };

  const onChangeCheckbox = async (e) => {
    console.log(e);
    setIsWarning(e.target.checked);
    setLoading(true);
    try {
      const response = await DashboardService.todo(selectedFilter, selectedFilter, e.target.checked);
      setData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toastMessage.error(error?.meta?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedFilter.length == 0) {
      setData([]);
    }
  }, [selectedFilter]);

  return (
    <div className="dashboard">
      <img className="img-fluid mx-auto d-block mb-3 logo" src={Logo} width="45%" />
      <h1 className="text-center">{I18n.t("dashboard.todo_title")}</h1>
      <div className="header container mb-5">
        <div className="row row-cols-3 g-3">
          {filters.map((f) => {
            return (
              <div onClick={() => onChangeFilter(f.key)} key={f.key} className="col">
                <div className={`dashboard-item p-4 ${selectedFilter == f.key ? "selected" : ""}`}>
                  <div className="icon">{f.icon}</div>
                  <div className={`content ${checkTodo[f.key] ? "notice" : ""}`}>{I18n.t(`dashboard.${f.key}`)}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="body p-5 container mb-5">
        {data?.length > 0 && (
          <DashboardDownload
            selectedFilter={selectedFilter}
            action="todo"
            data={data.map(({ next_appointment, ...keep }) => keep)}
          />
        )}

        <div className="mb-5 w-25">
          {(selectedFilter == "appointment_not_confirm" ||
            selectedFilter == "appoinment_missed_result" ||
            selectedFilter == "appointment_not_send_mail") && (
            <>
              {selectedFilter == "appointment_not_confirm" ? "in " : ""} {I18n.t(`dashboard.number_of_days_overdue`)}
              <input onChange={onChangeDateParams} value={dateParams} type="number" className="form-control" />
            </>
          )}
          {selectedFilter == "patient_threshold" && (
            <div className="d-flex" style={{ gap: 10 }}>
              <input
                onChange={onChangeCheckbox}
                value={isWarning}
                checked={isWarning}
                type="checkbox"
                name="input-warning"
                id="input-warning"
              />
              <label htmlFor="input-warning">{I18n.t(`dashboard.checkbox_warning`)}</label>
            </div>
          )}
        </div>
        {data.length > 0 && <TodoTable isWarning={isWarning} selectedFilter={selectedFilter} data={data} />}
        {loading && (
          <div className="text-center">
            <Spinner className="mt-5" animation="border" variant="primary" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Todo;
