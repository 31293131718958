import React, { useState } from "react";
import { Route } from "react-router-dom";
import Header from "../components/layout/header";

export default ({ exact, path, hideFilter, hideSidebar, component: Component }) => {
  const [tenantId, setTenantId] = useState(undefined);
  const [departmentId, setDepartmentId] = useState(undefined);
  const [pathwayId, setPathwayId] = useState(undefined);
  const [reloadTenants, setReloadTenants] = useState(false);

  const onChangeTenantId = (id) => {
    setTenantId(id);
  };

  const onChangeDepartmentId = (id) => {
    setDepartmentId(id);
  };

  const onChangePathwayId = (id) => {
    setPathwayId(id);
  };

  const onReloadTenants = (e) => {
    setReloadTenants(e);
  };

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => (
        <>
          <Header
            hideFilter={hideFilter}
            onChangeTenantId={onChangeTenantId}
            onChangePathwayId={onChangePathwayId}
            onChangeDepartmentId={onChangeDepartmentId}
            onReloadTenants={onReloadTenants}
          />
          <Component pathwayId={pathwayId} tenantId={tenantId} departmentId={departmentId} {...props} />
        </>
      )}
    />
  );
};
