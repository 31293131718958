import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthService from "../../services/auth";
import SpecialistService from "../../services/specialist";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import toastMessage from "../../components/toast-message";

export default (props) => {
  const [reload, setReload] = useState(undefined);

  const [specialists, setSpecialists] = useState([]);

  const tableColunms = [
    {
      dataField: "id",
      text: "#",
      sort: true,
    },
    {
      dataField: "name",
      text: I18n.t("activerecord.models.specialist"),
      sort: true,
    },
    {
      dataField: "Edit Delete",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <div style={{ gap: 20 }} className="d-flex align-items-center">
            <Link to={`/specialists/${row.id}/edit`}>
              <EditOutlined />
            </Link>
            <a
              href="#!"
              onClick={(e) => {
                e.preventDefault();
                handleDelete(row.id);
              }}
            >
              <DeleteOutlined />
            </a>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    SpecialistService.index().then(
      (response) => {
        setSpecialists(response.data.specialists);
      },
      (error) => {
        toastMessage.error(error.meta.message);
      },
    );
  }, [reload, props.departmentId]);

  const handleDelete = async (id) => {
    if (window.confirm(I18n.t("form.specialist.confirm_delete"))) {
      try {
        const response = await SpecialistService.destroy(
          id,
          props.departmentId,
        );
        setReload(!reload);
        toastMessage.success(response.data.message);
      } catch (error) {
        setReload(!reload);
        toastMessage.error(error.meta.message);
      }
    }
  };

  return (
    <div className="row p-5 mt-5 specialist-index">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <div className="card p-5">
          <h1 className="text-center">
            {I18n.t("activerecord.models.specialist")}
          </h1>
          {AuthService.isSuperAdmin() && (
            <Link
              style={{ width: "40%" }}
              className="btn btn-primary mb-3"
              to="/specialist_create"
            >
              {I18n.t("form.specialist.new")}
            </Link>
          )}
          <BootstrapTable
            keyField="id"
            data={specialists}
            columns={tableColunms}
          />
        </div>
      </div>
    </div>
  );
};
