import React, { useState, useEffect } from "react";
import toastMessage from "../../components/toast-message";
import Select from "react-select";
import ServiceProviderService from "../../services/service_provider";
import SpecialistService from "../../services/specialist";
import TreatmentService from "../../services/treatment-pathway";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { filter } from "lodash";

const DashboardChartSelectedFilter = ({
  queries,
  setSelectedFilters,
  setQueries,
  selectedFilter,
  filterType,
  ...props
}) => {
  const [selectValues, setSelectValues] = useState(undefined);
  const [options, setOptions] = useState([]);
  const [isMulti, setIsMulti] = useState(true);
  const [deadline, setDeadline] = useState();

  const onChange = (e, triggerAction) => {
    setSelectValues(e);
    if (triggerAction?.action == "clear") {
      props.setPathwayFilter("");
      props.setProviderId("");
      return;
    }
    console.log("filterType", filterType);
    if (filterType == "treatment_pathway") {
      props.setPathwayFilter(e.value);
    }
    if (filterType == "case_guide") {
      props.setProviderId(e.value);
    }
  };

  useEffect(() => {
    setSelectValues(null);
    if (filterType == "case_guide") {
      setIsMulti(true);
      ServiceProviderService.index().then(
        (response) => {
          let p = response.data.service_providers;
          setOptions(
            p
              .filter((pp) => pp.is_internal)
              .map((pp) => {
                return {
                  value: pp.id,
                  label: pp.display_name,
                };
              }),
          );
        },
        (error) => {
          toastMessage.error(error.meta.message);
        },
      );
    } else if (filterType == "service_provider") {
      setIsMulti(true);
      ServiceProviderService.index().then(
        (response) => {
          let p = response.data.service_providers;
          setOptions(
            p
              .filter((pp) => !pp.is_internal)
              .map((pp) => {
                return {
                  value: pp.id,
                  label: pp.display_name,
                };
              }),
          );
        },
        (error) => {
          toastMessage.error(error.meta.message);
        },
      );
    } else if (filterType == "area_of_expertise") {
      setIsMulti(true);
      SpecialistService.index().then(
        (response) => {
          let p = response.data.specialists;
          setOptions(
            p.map((pp) => {
              return {
                value: pp.id,
                label: pp.name,
              };
            }),
          );
        },
        (error) => {
          toastMessage.error(error.meta.message);
        },
      );
    } else if (filterType == "treatment_pathway") {
      setIsMulti(true);
      TreatmentService.index().then(
        (response) => {
          let p = response.data.treatment_pathways;
          setOptions(
            p.map((pp) => {
              return {
                value: pp.id,
                label: pp.name_de,
              };
            }),
          );
        },
        (error) => {
          toastMessage.error(error.meta.message);
        },
      );
    }
  }, [filterType]);

  const onChangeDeadline = (date) => {
    props.setDateFilter(moment(date).format("YYYY-MM-DD"));
    setDeadline(date);
  };

  return (
    <div className="selected-filter-item col">
      <span>{selectedFilter?.translation || I18n.t(`dashboard.${selectedFilter?.key}`)}</span>
      <div className="col">
        {selectedFilter?.key != "deadline" && (
          <Select
            value={selectValues}
            style={{ width: "100%" }}
            onChange={onChange}
            options={options}
            className="react-select-dashboard"
            classNamePrefix="react-select"
            placeholder=""
            isMulti={false}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            isClearable={true}
          />
        )}

        {selectedFilter?.key == "deadline" && (
          <DatePicker
            peekNextMonth
            dropdownMode="select"
            maxDate={new Date()}
            dateFormat={"dd.MM.yyyy"}
            className="form-control"
            selected={deadline}
            onChange={(date) => onChangeDeadline(date)}
          />
        )}
      </div>
    </div>
  );
};

export default DashboardChartSelectedFilter;
