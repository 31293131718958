import { DownloadOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { downloadXLSFile } from "../../services/download";

const DashboardDownload = ({ action, ...props }) => {
  const handleDownload = async (type = "csv") => {
    try {
      let url = `/patients.${type}`;
      if (action == "service_provider" && props.viewType == "overview") {
        url = `/service_provider_overview/overview.${type}`;
      }
      if (action == "service_provider" && props.viewType == "topOverview") {
        url = `/service_provider_overview/top_overview.${type}`;
      }
      if (props.selectedFilter == "patient_threshold") {
        await downloadXLSFile(url, { patientIds: props.data.map((d) => d.patient_id), is_prom: true }, type);
      } else {
        await downloadXLSFile(url, { patientIds: props.data.map((d) => d.patient_id) }, type);
      }
    } catch (e) {}
  };

  return (
    action && (
      <div className="download-action a">
        {props.selectedFilter != "patient_threshold" && (
          <Button filename={`${action}.csv`} ariant="success" onClick={() => handleDownload("csv")}>
            <DownloadOutlined />
            CSV
          </Button>
        )}

        <Button filename={`${action}.xls`} ariant="success" onClick={() => handleDownload("xls")}>
          <DownloadOutlined />
          XLSX
        </Button>
      </div>
    )
  );
};

export default DashboardDownload;
