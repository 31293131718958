import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TreatmentPathwayService from "../../services/treatment-pathway";
import "./index.scss";
import TreatmentItem from "./treatment_item";
import NewTreatment from "./_new_treatment";
import TreatmentService from "../../services/treatment";
import toastMessage from "../../components/toast-message";
import TreatmentPathwayItem from "./treatment_pathway_item";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import Spinner from "react-bootstrap/Spinner";
import DepartmentService from "../../services/department";
import Button from "react-bootstrap/Button";
import NewTreatmentPathway from "./_new_treatment_pathway";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
const getItemStyle = (isDragging, draggableStyle, index) => ({
  userSelect: "none",
  background: isDragging ? "#48F0C6" : "white",
  ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({});

export default (props) => {
  const [message, setMessage] = useState({});
  const [reload, setReload] = useState(undefined);
  const [isDragDisabled, setIsDragDisabled] = useState(false);
  const [orderChanged, setOrderChanged] = useState(false);

  const [childIds, setChildIds] = useState([]);
  const [newTreatmentShow, setNewTreatmentShow] = useState(false);
  const [newPathwayShow, setNewPathwayShow] = useState(false);
  const [treatments, setTreatments] = useState([]);
  const [activePathway, setActivePathway] = useState(undefined);
  const [pathways, setPathways] = useState([]);
  const [loading, setLoading] = useState(true);

  const [attachmentOptions, setAttachmentOptions] = useState([]);
  useEffect(() => {
    if (attachmentOptions.length == 0) {
      const departmentId = cookies.cookies["last_department_id"];
      DepartmentService.show(departmentId).then(
        (response) => {
          const p = response.data.department;
          const all = [...p.department_setting.documents, ...p.department_setting.icons];
          setAttachmentOptions(all);
        },
        (error) => {
          setError(error.meta.message);
        },
      );
    }
  }, []);

  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(treatments, result.source.index, result.destination.index);
    setTreatments(items);

    setOrderChanged(true);
  };

  useEffect(() => {
    TreatmentPathwayService.index().then(
      (response) => {
        if (!activePathway?.id) {
          setActivePathway(response.data.treatment_pathways[0]);
        }
        setPathways(response.data.treatment_pathways);
      },
      (error) => {
        setMessage({ content: error.meta.message, variant: "danger" });
      },
    );
  }, [reload, props.departmentId]);

  const handleSwapOrder = async () => {
    try {
      const order = {};
      treatments.forEach((t, index) => {
        order[t.id] = index;
      });

      const data = {
        order,
      };

      const response = await TreatmentService.swapOrder(data);
      toastMessage.success(response.data.message);
      setOrderChanged(false);
      setReload(!reload);
    } catch (e) {
      console.log(e);
      toastMessage.error(e.meta.message);
      return treatments;
    }
  };

  useEffect(() => {
    if (activePathway?.id) {
      setLoading(true);
      TreatmentService.index(activePathway.id).then(
        (response) => {
          setTreatments(response.data.treatments);

          const allChildIds = [];
          response.data.treatments.forEach((t) => allChildIds.push(...t.treatment_ids));
          setChildIds(allChildIds);

          setLoading(false);
        },
        (error) => {
          setMessage({ content: error.meta.message, variant: "danger" });
          setLoading(false);
        },
      );
    }
  }, [activePathway, reload]);

  return pathways.length > 0 ? (
    <div id="treatment_pathway" className="container">
      {pathways.map((p) => {
        return (
          <TreatmentPathwayItem
            loading={loading}
            key={p.id}
            isSelected={p.id == activePathway.id}
            treatment={p}
            setActivePathway={(p) => setActivePathway(p)}
          />
        );
      })}
      <div className="create-btn d-flex justify-content-between">
        <button onClick={() => setNewTreatmentShow(true)} className="btn btn-outline-info mt-3 mb-3 btn-sm">
          {I18n.t("form.treatment.new_treatment")}
        </button>
        <button onClick={() => setNewPathwayShow(true)} className="btn btn-outline-info mt-3 mb-3 btn-sm">
          {I18n.t("form.treatment_pathway.new_treatment_pathway")}
        </button>
      </div>
      <NewTreatment
        treatments={treatments}
        pathwayId={activePathway?.id}
        show={newTreatmentShow}
        onHide={() => setNewTreatmentShow(false)}
        reloadAll={() => setReload(!reload)}
      />
      <NewTreatmentPathway
        show={newPathwayShow}
        onHide={() => setNewPathwayShow(false)}
        reloadAll={() => setReload(!reload)}
        setActivePathway={(e) => setActivePathway(e)}
      />
      {!loading ? (
        treatments.length > 0 && (
          <div className="treatments">
            {orderChanged && (
              <div className="action-order">
                <CheckOutlined onClick={handleSwapOrder} />
              </div>
            )}
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppableTreatment">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {treatments.map((item, index) => (
                      <Draggable isDragDisabled={isDragDisabled} key={item.id} draggableId={`${item.id}`} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <TreatmentItem
                              reloadAll={() => setReload(!reload)}
                              treatment={item}
                              haveRelation={childIds.includes(item.id)}
                              setIsDragDisabled={setIsDragDisabled}
                              attachmentOptions={attachmentOptions}
                              setActivePathway={() => setActivePathway(activePathway)}
                              treatments={treatments}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )
      ) : (
        <div className="text-center">
          <Spinner className="mt-5" animation="border" variant="primary" />
        </div>
      )}
    </div>
  ) : (
    <div className="text-center">
      <Spinner className="mt-5" animation="border" variant="primary" />
    </div>
  );
};
