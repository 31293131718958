import httpClient from '../utils/http-common';

const API_URL = '/dashboard';

const index = (key, page=1) => {
  return httpClient.get(API_URL + `?filter=${key}&page=${page}`);
};


const patient = (query, page=1) => {
  return httpClient.post(API_URL + `/patient?&page=${page}`, {query});
};

const checkTodo = (query={}) => {
  return httpClient.post(API_URL + `/check_todo`, {query});
};

const todo = (key, data, value, page=1) => {
  return httpClient.post(API_URL + `/todo?filter=${key}&page=${page}&value=${value}`, {query: data});
};

const token = () => {
  return httpClient.get(API_URL + '/embedded_token');
}

const patientCSV = (query) => {
  return httpClient.post(API_URL + `/patient.csv`, {query});
};

const todoCSV = (key, data) => {
  return httpClient.post(API_URL + `/todo.csv?filter=${key}`, {query: data} );
};

const DashboardService = {
  index,
  patient,
  todo,
  token,
  patientCSV,
  todoCSV,
  checkTodo,
};

export default DashboardService;
