import React, { useState, useEffect } from "react";
import toastMessage from "../../components/toast-message";
import Select from "react-select";
import ServiceProviderService from "../../services/service_provider";
import SpecialistService from "../../services/specialist";
import TreatmentService from "../../services/treatment-pathway";
import moment from "moment";
import DatePicker from "react-datepicker";
import { el } from "date-fns/esm/locale";

const DashboardSelectedFilters = ({
  queries,
  setSelectedFilters,
  setQueries,
  selectedFilter,
  filterType,
  ...props
}) => {
  const [selectValues, setSelectValues] = useState(undefined);
  const [options, setOptions] = useState([]);
  const [isMulti, setIsMulti] = useState(true);
  const [dateFrom, setDateFrom] = useState(undefined);
  const [dateTo, setDateTo] = useState(undefined);

  const mapQuery = {
    case_guide: "appointments_service_provider_id_in",
    service_provider: "appointments_service_provider_id_in",
    treatment_pathway: "treatment_pathway_id_in",
    active_passive: "active_in",
    area_of_expertise: "appointments_service_provider_specialist_id_in",
  };

  useEffect(() => {
    if (selectedFilter.key == "prom") {
      setQueries({ prom: true });
    }
  }, []);

  const onChange = (e, triggerAction) => {
    if (triggerAction?.name == "date-from" || triggerAction?.name == "date-to") {
      if (triggerAction.name == "date-from") {
        setDateFrom(e);
      } else {
        setDateTo(e);
      }
    }
    setSelectValues(e);
    const newq = { ...queries };
    let values = Array.isArray(e) ? e.map((e) => e.value) : e.value;
    const local = {};
    local[mapQuery[filterType]] = values;

    if (triggerAction == "clear" || e.length == 0) {
      delete newq[mapQuery[filterType]];
      setQueries(newq);
      setSelectedFilters(filterType);
      return;
    }

    if (mapQuery[filterType]) {
      setQueries({
        ...newq,
        ...local,
        appointments_appointment_time_gt: moment(),
      });
      return;
    }

    if (filterType == "history_future") {
      const newq = { ...queries };
      delete newq.appointments_appointment_time_lt;
      delete newq.appointments_appointment_time_gt;
      if (values == "past") {
        setQueries({
          ...newq,
          history: true,
        });
      } else {
        setQueries({
          ...newq,
          history: false,
        });
      }
    }

    if (filterType == "prom") {
      const newq = { ...queries };
      if (triggerAction?.name == "date-from" || triggerAction?.name == "date-to") {
        const dateString = `${e.getFullYear()}-${String(e.getMonth() + 1).padStart(2, "0")}-${String(
          e.getDate(),
        ).padStart(2, "0")}`;
        const utcDate = new Date(dateString + "T00:00:00Z");
        if (triggerAction.name == "date-from") {
          setQueries({
            ...newq,
            from: utcDate,
            prom: true,
          });
        } else {
          setQueries({
            ...newq,
            to: utcDate,
            prom: true,
          });
        }
      } else {
        // checkbox
        setQueries({
          ...newq,
          prom: true,
          is_done_prom: e.target.checked,
        });
      }
    }
  };

  useEffect(() => {
    setSelectValues(null);
    if (filterType == "case_guide") {
      setIsMulti(true);
      ServiceProviderService.index().then(
        (response) => {
          let p = response.data.service_providers;
          setOptions(
            p
              .filter((pp) => pp.is_internal)
              .map((pp) => {
                return {
                  value: pp.id,
                  label: pp.display_name,
                };
              }),
          );
        },
        (error) => {
          toastMessage.error(error.meta.message);
        },
      );
    } else if (filterType == "service_provider") {
      setIsMulti(true);
      ServiceProviderService.index().then(
        (response) => {
          let p = response.data.service_providers;
          setOptions(
            p.map((pp) => {
              return {
                value: pp.id,
                label: pp.display_name,
              };
            }),
          );
        },
        (error) => {
          toastMessage.error(error.meta.message);
        },
      );
    } else if (filterType == "active_passive") {
      setIsMulti(false);
      setSelectValues({
        label: I18n.t("activerecord.attributes.patient.active"),
        value: true,
      });
      setOptions([
        {
          label: "All",
          value: [true, false],
        },
        {
          label: I18n.t("activerecord.attributes.patient.active"),
          value: [true],
        },
        {
          label: I18n.t("activerecord.attributes.patient.inactive"),
          value: [false],
        },
      ]);
      setQueries({
        ...queries,
        active_in: [true],
      });
      if (Object.keys(queries).length == 0) {
        props.handleSearch();
      }
    } else if (filterType == "area_of_expertise") {
      setIsMulti(true);
      SpecialistService.index().then(
        (response) => {
          let p = response.data.specialists;
          setOptions(
            p.map((pp) => {
              return {
                value: pp.id,
                label: pp.name,
              };
            }),
          );
        },
        (error) => {
          toastMessage.error(error.meta.message);
        },
      );
    } else if (filterType == "treatment_pathway") {
      setIsMulti(true);
      TreatmentService.index().then(
        (response) => {
          let p = response.data.treatment_pathways;
          setOptions(
            p.map((pp) => {
              return {
                value: pp.id,
                label: pp.name_de,
              };
            }),
          );
        },
        (error) => {
          toastMessage.error(error.meta.message);
        },
      );
    } else if (filterType == "history_future") {
      setIsMulti(false);
      setOptions([
        {
          label: "Historie",
          value: "past",
        },
        {
          label: "Zukunft",
          value: "future",
        },
      ]);
    }
  }, [filterType]);

  return (
    <div className="selected-filter-item col">
      <span>{selectedFilter?.translation || I18n.t(`dashboard.${selectedFilter?.key}`)}</span>
      <div className="col">
        {selectedFilter.key != "prom" && (
          <Select
            value={selectValues}
            style={{ width: "100%" }}
            onChange={onChange}
            options={options}
            className="react-select-dashboard"
            classNamePrefix="react-select"
            placeholder=""
            isMulti={isMulti}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
        )}

        {selectedFilter.key == "prom" && (
          <div className="prom d-flex flex-column" style={{ gap: 20 }}>
            <div className="date-filter d-flex" style={{ gap: 20 }}>
              <DatePicker
                isClearable={true}
                placeholderText={I18n.t("dashboard.date_from")}
                dropdownMode="select"
                dateFormat={"dd.MM.yyyy"}
                className="form-control"
                id="date-from"
                name="date-from"
                selected={dateFrom}
                onChange={(date) => onChange(date, { id: "date-from", name: "date-from" })}
              />
              ~
              <DatePicker
                isClearable={true}
                placeholderText={I18n.t("dashboard.date_to")}
                dropdownMode="select"
                dateFormat={"dd.MM.yyyy"}
                className="form-control"
                id="date-to"
                name="date-to"
                selected={dateTo}
                onChange={(date) => onChange(date, { id: "date-to", name: "date-to" })}
              />
            </div>
            <div className="checkbox d-flex" style={{ gap: 5 }}>
              <input id="prom-done" type="checkbox" onChange={onChange} />
              <label htmlFor="prom-done">{I18n.t("dashboard.survey_done")}</label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardSelectedFilters;
