import React, { useEffect, useState } from "react";
import DashboardService from "../../services/dashboard";
import toastMessage from "../../components/toast-message";
import "./chart.scss";
import Logo from "images/logo-color-no-bg.svg";
import { CalendarOutlined, ForkOutlined, SwapOutlined } from "@ant-design/icons";
import DashboardChartFilter from "./_chart_filter_select";
import moment from "moment";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";

const DashboardChart = (props) => {
  const [accessToken, setAccessToken] = useState("");
  const [error, setError] = useState("");
  const [dateFilter, setDateFilter] = useState(moment().format("YYYY-MM-DD"));
  const [pathwayFilter, setPathwayFilter] = useState("");
  const [providerId, setProviderId] = useState("");
  const [canShow, setCanShow] = useState(false);
  const [htmlData, setHtmlData] = useState(undefined);
  useEffect(() => {
    if (accessToken == "" || error.length > 0) {
      DashboardService.token().then(
        (response) => {
          if (response.data.token) {
            setAccessToken(response.data.token);
            setError("");
          } else {
            toastMessage.error("Wrong Credential");
          }
        },
        (error) => {
          toastMessage.error(error.meta.message);
        },
      );
    }
  }, [accessToken, error]);
  const filters = [
    { key: "deadline", icon: <CalendarOutlined />, translation: I18n.t("dashboard.deadline") },
    { key: "case_guide", icon: <ForkOutlined />, translation: I18n.t("form.service_provider.name_in") },
    { key: "treatment_pathway", icon: <SwapOutlined />, translation: I18n.t("activerecord.models.treatment_pathway") },
  ];
  const [selectedFilters, setSelectedFilters] = useState([]);

  const onChangeFilter = (key) => {
    const s = [...selectedFilters];
    if (selectedFilters.indexOf(key) != -1) {
      setSelectedFilters(s.filter((f) => f != key));
    } else {
      setSelectedFilters([...s, key]);
    }
  };

  useEffect(() => {
    let params = "";
    if (dateFilter) {
      params += `&date=${dateFilter}`;
    }
    if (pathwayFilter) {
      params += `&treatment_pathway_id=${pathwayFilter}`;
    }
    if (providerId) {
      params += `&service_provider_id=${providerId}`;
    }

    if (accessToken.length > 0) {
      const url = `${process.env.SUPERSET_BASE_URL}/dashboard?tenant=` + localStorage.getItem("X-Tenant-Id") + params;
      axios.get(url, { headers: { Authorization: "Bearer " + accessToken, "Accept-Language": I18n._locale } }).then(
        (response) => {
          setHtmlData(response.data);
          setCanShow(true);
        },
        (error) => {
          console.log(error);
          toastMessage.error(JSON.stringify(error?.response?.data?.detail));
        },
      );
    }
  }, [dateFilter, pathwayFilter, providerId, accessToken, props.departmentId]);

  return (
    <div className="dashboard">
      <img className="img-fluid mx-auto d-block mb-3 logo" src={Logo} width="45%" />
      <h1 className="text-center">{I18n.t("dashboard.embedded")}</h1>
      <div className="header container mb-5">
        <div className="row row-cols-3 g-3">
          {filters.map((f) => {
            return (
              <div onClick={() => onChangeFilter(f.key)} key={f.key} className="col">
                <div className={`dashboard-item p-4 ${selectedFilters.indexOf(f.key) != -1 ? "selected" : ""}`}>
                  <div className="icon">{f.icon}</div>
                  <div className="content">{f.translation || I18n.t(`dashboard.${f.key}`)}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* <p id="dashboard-embedded-container"></p> */}
      <div style={{ height: "100%" }} className="body p-3 mb-3 container mb-5">
        <DashboardChartFilter
          selectedFilters={filters.filter((f) => selectedFilters.indexOf(f.key) != -1)}
          setSelectedFilters={(k) => onChangeFilter(k)}
          setDateFilter={(d) => setDateFilter(d)}
          setPathwayFilter={(d) => setPathwayFilter(d)}
          setProviderId={(d) => setProviderId(d)}
        />
        {canShow && accessToken.length > 0 && htmlData ? (
          <iframe height="101%" width="100%" srcDoc={htmlData}></iframe>
        ) : (
          <div className="text-center">
            <Spinner className="mt-5" animation="border" variant="primary" />
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardChart;
