import React, { useState, useEffect, useRef } from "react";
import TenantService from "../services/tenant";
import AuthService from "../services/auth";
import { ConsoleSqlOutlined, DownOutlined } from "@ant-design/icons";
import TenantCache from "../cache/tenant_cache";
import TreatmentService from "../services/treatment-pathway";
import Hospital from "images/hospital.png";
import Clinic from "images/Clinic.png";

const AdminFilter = (props) => {
  const [error, setError] = useState("");
  const [currentUser, setCurrentUser] = useState(undefined);

  const [tenants, setTenants] = useState([]);
  const [tenantId, setTenantId] = useState(undefined);

  const [departments, setDepartments] = useState([]);
  const [departmentId, setDepartmentId] = useState(undefined);

  const [pathways, setPathways] = useState([]);
  const [pathwayId, setPathwayId] = useState(undefined);

  const selectTenant = useRef(null);

  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      setCurrentUser(AuthService.getCurrentUser());
    }
  }, []);

  useEffect(() => {
    if (currentUser && !AuthService.isMpa()) {
      TenantService.index().then(
        (response) => {
          if (response.data.tenants.length > 0) {
            let tenants = response.data.tenants;
            setTenants(tenants);
            prepareData(tenants);
          }
        },
        (error) => {
          setError(error.meta.message);
        },
      );
    }
  }, [currentUser, props.reloadTenants]);

  useEffect(() => {
    if (currentUser) {
      TreatmentService.index().then(
        (response) => {
          setPathways(response.data.treatment_pathways);

          const storage = localStorage.getItem("X-Pathway-Id");
          if (!storage) {
            localStorage.setItem("X-Pathway-Id", response.data.treatment_pathways[0]?.id);
          }
        },
        (error) => {
          setError(error.meta.message);
        },
      );
    }
  }, [currentUser, props.reloadTenants, tenantId, departmentId]);

  const prepareData = (tenants) => {
    setTenants(tenants);
    const lastTenantId = window.cookies.cookies.last_tenant_id;
    const lastDepartment = window.cookies.cookies.last_department_id;

    if (lastTenantId) {
      setTenantId(lastTenantId);

      const departments = tenants.find((i) => i.id == lastTenantId).departments;
      setDepartments(departments);
      if (lastDepartment) {
        setDepartments(
          departments.map((d) => {
            if (d.company_name.length > 17) {
              d.company_name = d.company_name.substring(0, 17) + "...";
              return d;
            } else {
              return d;
            }
          }),
        );
        setDepartmentId(lastDepartment);
      } else {
        setDepartmentId(departments[0]?.id);
      }

      if (lastDepartment) {
        const d = departments.find((d) => d.id == lastDepartment);
        localStorage.setItem("X-Tenant-Id", d?.subdomain);
      } else {
        localStorage.setItem("X-Tenant-Id", departments[0]?.subdomain);
      }
    } else {
      const t = tenants[0];
      setTenantId(t.id);
      const departments = tenants.find((i) => i.id == t.id).departments;
      setDepartments(
        departments.map((d) => {
          if (d.company_name.length > 17) {
            d.company_name = d.company_name.substring(0, 17) + "...";
            return d;
          } else {
            return d;
          }
        }),
      );

      localStorage.setItem("X-Tenant-Id", departments[0]?.subdomain);
    }
  };

  const onChangeTenantId = (e) => {
    const id = e.target.value;
    setTenantId(id);

    const departments = tenants.find((i) => i.id == id).departments;
    setDepartments(
      departments.map((d) => {
        if (d.company_name.length > 17) {
          d.company_name = d.company_name.substring(0, 17) + "...";
          return d;
        } else {
          return d;
        }
      }),
    );
    setDepartmentId(departments[0]?.id);

    props.onChangeDepartmentId(departments[0]?.id);
    localStorage.setItem("X-Tenant-Id", departments[0]?.subdomain);
    props.onChangeTenantId(id);
  };

  const onChangeDepartmentId = (e) => {
    const id = e.target.value;
    setDepartmentId(id);
    const department = departments.find((i) => i.id == id);
    localStorage.setItem("X-Tenant-Id", department?.subdomain);
    props.onChangeDepartmentId(id);
  };

  const openTenant = (e) => {
    selectTenant.current.focus();
    selectTenant.current.click();
  };

  return (
    <div>
      {currentUser && !AuthService.isMpa() && (
        <>
          <div className="header-tenant-filter">
            {!props.noLabel && <label htmlFor="tenantFilter">{I18n.t("activerecord.models.tenant")}</label>}
            <img className="clinic-image" src={Hospital} width="25" height="25" />
            <img className="hospital-image" src={Clinic} width="25" height="25" />
            {!AuthService.isMpa() && (
              <div>
                <select
                  ref={selectTenant}
                  value={tenantId}
                  id="tenantFilter"
                  className="tenant-select"
                  onChange={onChangeTenantId}
                >
                  {tenants.length > 0 &&
                    tenants.map((l) => (
                      <option key={l.id} value={l.id}>
                        {l.company_name}
                      </option>
                    ))}
                  {tenants.length == 0 && <option>{I18n.t("activerecord.models.tenant") + " - "}</option>}
                </select>
                <DownOutlined onClick={openTenant} />
              </div>
            )}

            {!props.noLabel && <label htmlFor="departmentFilter">{I18n.t("activerecord.models.department")} </label>}
            {props.onChangeDepartmentId !== undefined && !AuthService.isMpa() && (
              <div>
                <select
                  value={departmentId}
                  id="departmentFilter"
                  className="tenant-select"
                  onChange={onChangeDepartmentId}
                >
                  {departments.length > 0 &&
                    departments.map((l) => (
                      <option key={l.id} value={l.id}>
                        {l.company_name}
                      </option>
                    ))}
                  {departments.length == 0 && <option>{I18n.t("activerecord.models.department") + " - "}</option>}
                </select>
                <DownOutlined onClick={onChangeDepartmentId} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AdminFilter;
