import React, { } from "react";
import './create.scss'
import PatientForm from "./_form";

const PatientCreate = (props) => {

    return (
        <PatientForm
            title={I18n.t('form.patient.new_patient')}
            {...props}
        />
    );
};

export default PatientCreate;
