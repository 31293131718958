import httpClient from '../utils/http-common';

const API_URL = '/release_notes';

const index = () => {
  return httpClient.get(`${API_URL}`);
};

const ChangelogService = {
  index,
};

export default ChangelogService;
