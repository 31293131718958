import httpClient from '../utils/http-common';

const API_URL = '/users';

const index = (page) => {
  if (page == undefined) {
    page = 1
  }
  return httpClient.get(`${API_URL}?page=${page}`);
};

const create = (data) => {
  return httpClient.post(API_URL, data);
};

const show = (id) => {
  return httpClient.get(`${API_URL}/${id}`);
};

const update = (id, data) => {
  return httpClient.put(`${API_URL}/${id}`, data);
};

const destroy = (id) => {
  return httpClient.delete(`${API_URL}/${id}`);
};

const supportMail = (data) => {
  return httpClient.post(`${API_URL}/support_mail`, data);
};

const UserService = {
  index,
  show,
  create,
  update,
  destroy,
  supportMail,
};

export default UserService;
