import axios from "axios";

const axiosClient = axios.create({
  baseURL: `${process.env.APP_URL}/`,
  timeout: 10000,
  headers: Object.assign({
    "Content-Type": "application/json",
    Accept: "application/json",
  }),
});

export default axiosClient;
