import React, { useState, useEffect } from "react";
import AuditService from "../../services/audit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import Spinner from "react-bootstrap/Spinner";
import { FolderOpenOutlined } from "@ant-design/icons";
import PaginationNew from "../../components/pagination-new";
import LogfileFilter from "./_filter";
import LogfileMailer from "./_mailer";

const Logfile = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [audits, setAudits] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [meta, setPaginations] = useState({});
  const [filterType, setFilterType] = useState("private");
  const [showMail, setShowMail] = useState(false);

  const onChangePage = (page) => {
    if (page != currentPage) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (props.all == true) {
      if (filterType != "mail" && !showMail) {
        AuditService.index(filterType, currentPage).then(
          (response) => {
            if (props.type == "Appointment") {
              // Igore non-required fields
              setAudits(
                response.data.audits.filter((a) => {
                  return Object.keys(a.meta).length > 0;
                }),
              );
            } else {
              setAudits(response.data.audits);
            }

            setPaginations(response.data.meta);
            setLoading(false);
          },
          (error) => {
            setError(error.meta.message);
          },
        );
      } else if (showMail) {
        AuditService.mail(currentPage, filterType).then(
          (response) => {
            setAudits(response.data.sendgrid_histories);
            setPaginations(response.data.meta);
            setLoading(false);
          },
          (error) => {
            setError(error.meta.message);
          },
        );
      }
    } else {
      AuditService.show(props.target_id, props.type).then(
        (response) => {
          setAudits(response.data.audits);
          setPaginations(response.data.meta);
          setLoading(false);
        },
        (error) => {
          setError(error.meta.message);
        },
      );
    }
  }, [currentPage, filterType, props.departmentId]);

  const tableColunms = [
    {
      dataField: "at",
      text: "At",
      sort: true,
    },
    {
      dataField: "user.email",
      text: I18n.t("activerecord.models.user.other"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
    {
      dataField: "auditable_type",
      text: "Type",
      sort: true,
      formatter: (cellContent, row) => {
        let href = "";
        if (row.auditable_type == "ServiceProviderAcademicTitle") {
          return I18n.t("activerecord.models.academic_title");
        } else if (row.auditable_type == "Specialist") {
          return I18n.t("activerecord.models.specialist");
        } else if (row.auditable_type == "Appointment") {
          return "Appointment";
        } else if (row.auditable_type == "ServiceProvider") {
          return I18n.t("activerecord.models.service_provider");
        } else if (row.auditable_type == "PatientLanguage") {
          return I18n.t("activerecord.models.patient_language");
        } else if (row.auditable_type == "Treatment") {
          return I18n.t("activerecord.models.treatment_pathway");
        } else {
          return row.auditable_type;
        }
      },
    },
    {
      dataField: "auditable_id",
      text: "Id",
      formatter: (cellContent, row) => {
        let href = "";
        if (row.auditable_type == "ServiceProviderAcademicTitle") {
          href = `/academic_titles/${row.auditable_id}/edit`;
        } else if (row.auditable_type == "Specialist") {
          href = `/specialists/${row.auditable_id}/edit`;
        } else if (row.auditable_type == "Appointment") {
          href = `/patients/${row.meta?.patient_id}/profile`;
        } else if (row.auditable_type == "Patient") {
          href = `/patients/${row.auditable_id}/profile`;
        } else if (row.auditable_type == "ServiceProvider") {
          href = `/service_providers/${row.auditable_id}/profile`;
        } else if (row.auditable_type == "PatientLanguage") {
          href = `/patient_languages/${row.auditable_id}/edit`;
        } else if (row.auditable_type == "Treatment") {
          href = `/treatment_pathways_v2`;
        } else if (row.auditable_type == "ActiveStorage::Attachment") {
          if (row.meta["target_name"] == "DepartmentSetting") {
            href = `departments/${row.meta["current_tenant"]}/edit`;
          } else {
            href = row.meta["target_url"];
          }
        }
        return (
          <div style={{ gap: 20, cursor: "pointer" }} className="d-flex align-items-center justify-content-center">
            <span>{row.auditable_id} </span>
            <a target="_blank" href={href}>
              <FolderOpenOutlined />
            </a>
          </div>
        );
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cellContent, row) => {
        let href = "";
        if (row.action == "create") {
          return I18n.t("form.create");
        } else if (row.action == "update") {
          return I18n.t("form.update");
        } else {
          return I18n.t("form.delete");
        }
      },
    },
    {
      dataField: "audited_changes",
      text: "Changes",
      headerStyle: (colum, colIndex) => {
        return { width: "35%" };
      },
      formatter: (cellContent, row) => {
        if (row.auditable_type == "ActiveStorage::Attachment") {
          const display = [];
          display.push({ url: row.meta["attachment_url"], name: row.meta["attachment_name"] });
          return display.map((k) => <p> {k["name"]}</p>);
        } else {
          const data = row.audited_changes;
          if (data instanceof String) {
            return <span>{data}</span>;
          } else if (Array.isArray(data)) {
            return data.map((d) => {
              return <p>{d}</p>;
            });
          } else {
            return <span>{data}</span>;
          }
        }
      },
    },
  ];

  return (
    <div>
      {props.all && <LogfileFilter setFilterType={(e) => setFilterType(e)} setShowMail={(e) => setShowMail(e)} />}
      {!loading ? (
        !showMail ? (
          <>
            <BootstrapTable keyField="id" data={audits} columns={tableColunms} />
            {audits.length > 0 && <PaginationNew meta={meta} onChangePage={onChangePage} />}
          </>
        ) : (
          <LogfileMailer data={audits} />
        )
      ) : (
        <Spinner className="mt-5" animation="border" variant="primary" />
      )}
    </div>
  );
};

export default Logfile;
