import React, { useState, useEffect, useRef, useCallback } from "react";
import AlertMessage from "../../components/alert-message";
import { Link, useLocation } from "react-router-dom";
import ServiceProviderService from "../../services/service_provider";
import { SearchOutlined, PlusOutlined, RightOutlined, HomeOutlined } from "@ant-design/icons";
import ManIcon from "../../components/icons/male-icon";
import FemaleIcon from "../../components/icons/female-icon";
import OtherGenderIcon from "../../components/icons/other-gender-icon";
import "./index.scss";
import { debounce } from "lodash";

export default (props) => {
  const [message, setMessage] = useState({});
  const [departmentId, setDepartmentId] = useState(props.departmentId);
  const [destroyedId, setDestroyId] = useState(undefined);
  const [internalProviders, setInternalProviders] = useState([]);
  const [externalProviders, setExternalProviders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [meta, setPaginations] = useState({});
  const [query, setQuery] = useState("");

  const fullNameDisplay = (firstName, lastName, companyName) => {
    if (firstName.length > 0 && lastName.length > 0) {
      return `${firstName} ${lastName}`;
    } else {
      return `${companyName}`;
    }
  };

  const debounceSearch = useCallback(
    debounce((nextValue) => search(nextValue), 800),
    [],
  );

  const search = (nextQuery) => {
    if (nextQuery?.length == 0) {
      setInternalProviders([]);
      setExternalProviders([]);
      return;
    }

    ServiceProviderService.index(nextQuery).then(
      (response) => {
        setInternalProviders(response.data.service_providers.filter((p) => p.service_type == "internal"));
        setExternalProviders(response.data.service_providers.filter((p) => p.service_type == "external"));
        setPaginations(response.data.meta);
      },
      (error) => {
        setMessage({ content: error.meta.message, variant: "danger" });
      },
    );
  };

  useEffect(() => {
    if (query?.length > 0) {
      search();
    } else {
      setInternalProviders([]);
      setExternalProviders([]);
    }
  }, [departmentId, props.departmentId, destroyedId, currentPage]);

  const location = useLocation();
  useEffect(() => {
    if (location.state?.name == "actionSuccess") {
      setMessage({ content: location.state.message, variant: "success" });
    }
  }, [location]);

  const searchQuery = useRef(null);

  const onChangeQuery = (e) => {
    const q = e.target.value;
    setQuery(q);
    debounceSearch(q);
  };

  const handleKey = (key) => {
    if (key.code == "Enter") {
      search();
    }
  };
  return (
    <>
      <div id="patient-search" className="provider-search">
        {Object.keys(message).length > 0 && <AlertMessage variant={message.variant} message={message.content} />}
        <div className="patient-search-box">
          <div className="box-header">
            <span className="search-icon">
              <SearchOutlined />
            </span>
            <input
              ref={searchQuery}
              placeholder={I18n.t("form.service_provider.search")}
              autoFocus
              onKeyDown={handleKey}
              className="search-box"
              type="text"
              value={query}
              onChange={onChangeQuery}
            ></input>
          </div>
          {internalProviders.length > 0 && (
            <div className="box-body">
              {internalProviders.length > 0 && (
                <div className="content">
                  <div className="top">{I18n.t("form.service_provider.internal")}</div>
                  <ul>
                    {internalProviders.map((p) => (
                      <li key={p.id} className="user-info">
                        <span className={`avatar ${p.gender}`}>
                          {p.gender == "male" && <ManIcon style={{ marginLeft: "6px" }} color="#ADD8E6" />}
                          {p.gender == "female" && <FemaleIcon color="#ADD8E6" />}
                          {p.gender == "other" && <OtherGenderIcon color="#ADD8E6" />}
                          <span className="username">
                            {p.first_name} {p.last_name} {p.specialist?.name ? ` | ${p.specialist.name}` : ""}
                          </span>
                        </span>
                        <span className="action">
                          <Link className="patient-profile-btn" to={`/service_providers/${p.id}/profile`}>
                            {I18n.t("form.service_provider.profile")}
                          </Link>
                          <RightOutlined />
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}

          {externalProviders.length > 0 && (
            <div className="box-body">
              {externalProviders.length > 0 && (
                <div className="content">
                  <div className="top">{I18n.t("form.service_provider.external")}</div>
                  <ul>
                    {externalProviders.map((p) => (
                      <li key={p.id} className="user-info">
                        <span className={`avatar ${p.gender}`}>
                          {p.gender == "male" && <ManIcon style={{ marginLeft: "6px" }} color="#ADD8E6" />}
                          {p.gender == "female" && <FemaleIcon color="#ADD8E6" />}
                          {p.gender == "other" && <OtherGenderIcon color="#ADD8E6" />}
                          {(!p.gender || p.gender == "") && (
                            <HomeOutlined
                              style={{ color: "#ADD8E6", fontSize: "1.5rem", marginLeft: "5px", marginRight: "12px" }}
                            />
                          )}
                          {fullNameDisplay(p.first_name || "", p.last_name || "", p.company_name).length > 0 && (
                            <div className="username">
                              {fullNameDisplay(p.first_name || "", p.last_name || "", p.company_name)}{" "}
                            </div>
                          )}
                          {fullNameDisplay(p.first_name || "", p.last_name || "", p.company_name).length > 0 && (
                            <span className="treatment">
                              &nbsp;{p.specialist?.name ? " | " + p.specialist.name : ""}
                            </span>
                          )}
                          {fullNameDisplay(p.first_name || "", p.last_name || "", p.company_name).length == 0 && (
                            <span className="treatment">{p.specialist?.name ? p.specialist.name : ""}</span>
                          )}
                          <span className="place">&nbsp;{p.place ? ` | ${p.place}` : ""}</span>
                        </span>
                        <span className="action">
                          <Link className="patient-profile-btn" to={`/service_providers/${p.id}/profile`}>
                            {I18n.t("form.service_provider.profile")}
                          </Link>
                          <RightOutlined />
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="box-end">
          <Link
            className="create-patient d-flex align-items-baseline justify-content-between btn btn-lg mt-3 fw-bolder float-end bg-green"
            to="/service_provider_create"
          >
            <PlusOutlined />
            <span>{I18n.t("form.service_provider.new_service_provider")}</span>
          </Link>
        </div>
      </div>
    </>
  );
};
