import { SettingOutlined } from "@ant-design/icons";
import React, { useState, useRef, useEffect } from "react";
import toastMessage from "../../components/toast-message";
import UserProfileService from "../../services/user-profile";
import "./setting.scss";
import VerifyOtpModal from "./verify-otp-modal";
import Form from "react-bootstrap/Form";
import DownloadBackupCode from "./download-backup-code";

const UserSetting = () => {
  const [reload, setReload] = useState(true);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDownloadCode, setIsDownloadCode] = useState(false);
  const [initOtp, setInitOtp] = useState(false);
  const [otpEnabled, setOtpEnabled] = useState(false);
  const [otpImage, setOtpImg] = useState("");
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [backupCodes, setBackupCodes] = useState([]);
  const [showBackupCode, setShowBackupCode] = useState(false);

  useEffect(() => {
    setLoading(true);
    UserProfileService.show().then(
      (response) => {
        const user = response.data.user;
        setEmail(user.email);
        setInitOtp(user.otp_enabled);
        setOtpEnabled(user.otp_enabled);
        setOtpImg(user.otp_qr_code?.url || "");
        setIsDownloadCode(user.is_downloads_backup_codes);
        setLoading(false);
      },
      (error) => {
        toastMessage.error(error.meta.message);
      },
    );
  }, [reload, showVerifyOtp]);

  useEffect(() => {
    UserProfileService.qrCode().then(
      (response) => {
        const svg = new Blob([response.data.url], { type: "image/svg+xml" });
        const url = URL.createObjectURL(svg);
        setOtpImg(url);
      },
      (error) => {
        toastMessage.error(error.meta.message);
      },
    );
  }, [loading]);

  const submitCode = async (code) => {
    try {
      const response = await UserProfileService.updateOtp(code);
      setReload(!reload);
      toastMessage.success(response.data.message);
      setOtpEnabled(false);
      setShowVerifyOtp(false);

      setBackupCodes(response.data.backup_codes);
      setShowBackupCode(true);
    } catch (error) {
      toastMessage.error(error.meta.message);
    }
  };

  const handleOtpChange = async (e) => {
    const val = e.target.checked;
    setOtpEnabled(val);
    if (!initOtp && val) {
      setShowVerifyOtp(true);
      return;
    }

    setLoading(true);
    if (initOtp && !val) {
      UserProfileService.update({ otp_enabled: false }).then(
        (res) => {
          toastMessage.success(res.data.message);
          setReload(!reload);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
        },
      );
    }
  };

  return (
    !loading && (
      <div className="user-setting d-flex align-items-center justify-content-center flex-column">
        <h1 className="header">
          <SettingOutlined /> Setting
        </h1>
        <div className="card card-container w-75 p-5 container mb-3">
          <div className="field">
            <span className="label">{I18n.t("activerecord.attributes.user.email")}</span>
            <input disabled className="form-control input" type="text" value={email} />
          </div>

          <div className="field">
            <span className="label">2FA</span>
            <Form.Check onChange={handleOtpChange} defaultChecked={otpEnabled} type="switch" />
          </div>

          <VerifyOtpModal
            submitCode={(code) => submitCode(code)}
            show={showVerifyOtp}
            onHide={() => setShowVerifyOtp(false)}
          />

          <DownloadBackupCode backupCodes={backupCodes} show={showBackupCode} onHide={() => setShowBackupCode(false)} />

          <div className="text-center">{otpImage && !initOtp && <img width="200" src={`${otpImage}`} />}</div>

          {!initOtp && (
            <div className="mt-5">
              <p className="bold fs-4">{I18n.t("form.user.2fa_guideline_1")}</p>
              <p className="fs-6">{I18n.t("form.user.2fa_guideline_2")}</p>
              <span dangerouslySetInnerHTML={{ __html: I18n.t("form.user.2fa_guideline_3") }}></span>
              <br></br>
              <span dangerouslySetInnerHTML={{ __html: I18n.t("form.user.2fa_guideline_4") }}></span>
              <br></br>
              <span dangerouslySetInnerHTML={{ __html: I18n.t("form.user.2fa_guideline_5") }}></span>
              <br></br>
              <span dangerouslySetInnerHTML={{ __html: I18n.t("form.user.2fa_guideline_6") }}></span>
              <br></br>
              <span dangerouslySetInnerHTML={{ __html: I18n.t("form.user.2fa_guideline_7") }}></span>
              <br></br>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default UserSetting;
