import httpClient from '../utils/http-common';

const API_URL = '/patient_languages';

const index = (query) => {
  if (query == undefined) query = ''
  return httpClient.get(`${API_URL}?query=${query}`);
};

const create = (data) => {
  return httpClient.post(API_URL, data);
};

const show = (id) => {
  return httpClient.get(`${API_URL}/${id}`);
};

const update = (id, data) => {
  return httpClient.put(`${API_URL}/${id}`, data);
};

const toggleActive = (id) => {
  return httpClient.put(`${API_URL}/${id}/toggle_active`);
};

const destroy = (id) => {
  return httpClient.delete(`${API_URL}/${id}`);
}

const PatientLanguageService = {
  index,
  show,
  create,
  update,
  destroy,
  toggleActive,
};

export default PatientLanguageService;
