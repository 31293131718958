import React from "react";
import DataStorage from "../../components/data-storage";
import TreatmentService from "../../services/treatment";

const TreatmentDocuments = (props) => {
  return (
    <DataStorage
      serverFiles={props.treatment.documents}
      departmentId={props.departmentId}
      multiple={true}
      id={props.treatment.id}
      api={TreatmentService}
      attachmentOptions={props.attachmentOptions}
      documentType="pdf"
      //   reload={() => toggleReload()}
      preview={true}
      openIcon="upload"
    />
  );
};

export default TreatmentDocuments;
