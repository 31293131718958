import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Form from "react-validation/build/form";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import PatientLanguageService from "../../services/patient_language";
import ISO6391 from "iso-639-1";

const LanguageForm = (props) => {
  let navigate = useHistory();

  const { id } = useParams();
  const [languageId, setLanguageId] = useState(id);

  useEffect(() => {
    PatientLanguageService.show(languageId).then(
      (response) => {
        const l = response.data.patient_language;
        setShortName(l.short_name);
      },
      (error) => {
        setMessage(error.response.data.message);
      },
    );
  }, []);

  const form = useRef();
  const checkBtn = useRef();

  const [error, setError] = useState("");

  const [shortName, setShortName] = useState(ISO6391.getAllCodes()[0]);

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleForm = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    if (!languageId) {
      form.current.validateAll();
      const formData = new FormData();
      formData.append("short_name", shortName);
      PatientLanguageService.create(formData).then(
        (res) => {
          navigate.push({
            pathname: "/patient_languages",
            state: { name: "actionSuccess", message: res.data.message },
          });
          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

          setLoading(false);
          setMessage(resMessage);
        },
      );
    } else {
      const formData = new FormData();
      formData.append("short_name", shortName);
      PatientLanguageService.update(languageId, formData).then(
        (res) => {
          navigate.push({
            pathname: "/patient_languages",
            state: { name: "actionSuccess", message: res.data.message },
          });
          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

          setLoading(false);
          setMessage(resMessage);
        },
      );
    }
  };

  return (
    <div className="d-flex align-items-center flex-column justify-content-center mt-2">
      <h1>{props.text}</h1>
      <div className="card card-container w-50 mb-5">
        <Form onSubmit={handleForm} ref={form} className="p-3">
          <div className="form-group">
            <label htmlFor="short_name">Short Name</label>
            <Select
              name="lang"
              className="form-control"
              title="short_name"
              value={shortName}
              validations={[]}
              onChange={(val) => setShortName(val.target.value)}
            >
              {ISO6391.getAllNames().map((l) => (
                <option key={l} value={ISO6391.getCode(l)}>
                  {l} - {ISO6391.getCode(l)}
                </option>
              ))}
            </Select>
          </div>

          <div className="form-group">
            <button className="btn btn-primary btn-block mt-3" disabled={loading}>
              {loading && <span className="spinner-border spinner-border-sm"></span>}
              <span>{!languageId ? I18n.t("form.create") : I18n.t("form.update")}</span>
            </button>
            <button onClick={() => navigate.push("/patient_languages")} className="btn btn-secondary mt-3 abort-btn">
              <span>{I18n.t("form.abort")}</span>
            </button>
          </div>

          {message && (
            <div className="form-group mt-2">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default LanguageForm;
