import React, { } from "react";
import TreatmentPathwayForm from "./_form";

const TreatmentPathwayCreate = (props) => {
    return (
        <TreatmentPathwayForm
            {...props}
        />
    );
};

export default TreatmentPathwayCreate;