import React, { useEffect, useState } from "react";

const ChildTreatment = (props) => {
  const [afterOPTreatments, setAfterOPTreatments] = useState([]);
  const [checked, setChecked] = useState(props.currentTreatment.treatment_ids);

  useEffect(() => {
    setAfterOPTreatments(
      props.treatments.filter(
        (t) =>
          t.treatment_type == "after_operation" &&
          t.id != props.currentTreatment.id &&
          t.order < props.currentTreatment.order,
      ),
    );
  }, [props.treatments]);

  const onChangeCheckbox = (e) => {
    let _checked = [...checked];
    const val = e.target?.value;
    if (e.target.checked) {
      _checked = [..._checked, val];
      setChecked(_checked);
      props.setTreatmentIds(_checked);
    } else {
      _checked = _checked.filter((c) => c.toString() != val.toString());
      setChecked(_checked);
      props.setTreatmentIds(_checked);
    }
  };

  return afterOPTreatments.map((t) => {
    return (
      <div className="provider-item" key={t.id}>
        <input
          onChange={onChangeCheckbox}
          style={{ marginRight: 5 }}
          defaultChecked={checked.includes(t.id)}
          value={t.id}
          name={`treatment-${t.id}`}
          type="checkbox"
        />
        <label htmlFor={`checkbox`}>{" " + t.name}</label>
      </div>
    );
  });
};

export default ChildTreatment;
