import httpClient from '../utils/http-common';

const API_URL = '/user_profile';


const show = () => {
  return httpClient.get(`${API_URL}`);
};

const update = (data) => {
  return httpClient.put(`${API_URL}`, data);
};

const qrCode = () => {
  return httpClient.get(`user_qr_code`);
};

const updateOtp = (code) => {
  return httpClient.put(`update_otp`, {code});
};


const UserProfileService = {
  show,
  update,
  qrCode,
  updateOtp,
};

export default UserProfileService;
