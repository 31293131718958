import React, { useState, useEffect } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import { EyeOutlined } from "@ant-design/icons";

const PatientInfoTable = ({ filterType, ...props }) => {
  const tableColunms = [
    {
      dataField: "first_name",
      text: I18n.t("activerecord.attributes.patient.first_name"),
      sort: true,
    },
    {
      dataField: "last_name",
      text: I18n.t("activerecord.attributes.patient.last_name"),
      sort: true,
    },
    {
      dataField: "birthday",
      text: I18n.t("activerecord.attributes.patient.birthday"),
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === "asc") {
          return new Date(b) - new Date(a);
        }

        return new Date(a) - new Date(b);
      },

      formatter: (cellContent, row) => {
        return moment(row.birthday).format("DD.MM.YYYY");
      },
    },
    {
      dataField: "kiss_id",
      text: I18n.t("activerecord.attributes.patient.kiss_id"),
      sort: true,
    },
    {
      dataField: "next_appointment",
      text: I18n.t("dashboard.next_appointment"),
      sort: true,
    },

    {
      dataField: "Edit Delete",
      isDummyField: true,
      text: props.actionLabel == "View it" ? "" : "Problem",
      headerStyle: (colum, colIndex) => {
        return { width: "80px", textAlign: "center" };
      },
      formatter: (cellContent, row) => {
        let href = `/patients/${row.patient_id}/profile`;
        let scroll;

        // if (filterType.length == 1 && (filterType[0] == "treatment_pathway" || filterType[0] == "active_passive")) {
        //   scroll = `?scrollId=first`;
        // } else {
        scroll = `?scrollId=${row.appointment_id}`;
        // }
        href += scroll;
        return (
          <div style={{ gap: 20 }} className="text-center">
            <a target="_blank" href={href} style={{ color: "blue" }}>
              <EyeOutlined />
            </a>
          </div>
        );
      },
    },
  ];

  return <BootstrapTable keyField="uid" data={props.data} columns={tableColunms} />;
};

export default PatientInfoTable;
