import React from "react";
import { createRoot } from "react-dom/client";
import App from "../components/App";
import "./index.scss";
import I18n from "i18n-js/index.js.erb";
import Cookies from "universal-cookie";
import "react-tooltip/dist/react-tooltip.css";
import { TooltipProvider } from "react-tooltip";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import Logo from "images/logo-color-no-bg.svg";

window.cookies = new Cookies();
window.I18n = I18n;
let locale = window.cookies.get("locale");
if (locale === undefined && localStorage.user) {
  locale = JSON.parse(localStorage.user).language;
}
if (!i18n.available_locales.includes(locale)) {
  locale = i18n.defaultLocale;
}
I18n.locale = locale;

const isDeveloper = localStorage.developer == "true";
window.isDeveloper = isDeveloper;

const isDevEnv = window.location.href.includes("dev");

document.addEventListener("DOMContentLoaded", () => {
  const container = document.getElementById("main-content");
  const root = createRoot(container);
  if (isMobile) {
    root.render(
      <div className="is-mobile text-center">
        <img className="img-fluid mx-auto d-block mb-3" src={Logo} width="45%" />
        <p>This Service is not enabled for this device</p>
      </div>,
    );
  } else {
    root.render(
      <>
        <TooltipProvider>
          <App />
        </TooltipProvider>
        {isDeveloper && (
          <span style={{ color: "red", position: "sticky", right: "10px", bottom: "10px", fontSize: "1.3rem" }}>
            Developer Mode
          </span>
        )}

        {isDevEnv && (
          <span style={{ color: "red", position: "sticky", right: "10px", bottom: "10px", fontSize: "1.3rem" }}>
            Development environment
          </span>
        )}
      </>,
    );
  }
});
