import React, { useState, useRef, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import AlertMessage from '../../components/alert-message';
import AcademicTitleService from "../../services/academic_title";
import TenantService from "../../services/tenant";
import AuthService from "../../services/auth";
import toastMessage from "../../components/toast-message";

const AcademicTitleForm = (props) => {
    let navigate = useHistory();

    const form = useRef();
    const checkBtn = useRef();


    const [error, setError] = useState("");
    const [titleId, _] = useState(props.titleId);
    const [name, setName] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [tenants, setTenants] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [tenantId, setTenantId] = useState(undefined);
    const [departmentId, setDepartmentId] = useState(undefined);

    useEffect(() => {
        if (titleId) {
            AcademicTitleService.show(titleId).then(
                (response) => {
                    const data = response.data.academic_title
                    setName(data.name)
                },
                (error) => { setError(error.meta.message) }
            )
        }
    }, []);

    const handleCreate = (e) => {
        e.preventDefault();

        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            const formData = new FormData()
            formData.append('name', name);
            if (titleId) {
                AcademicTitleService.update(titleId, formData).then(
                    (res) => {
                        navigate.push({ pathname: "/academic_titles" });
                        toastMessage.success(res.data.message)
                    },
                    (error) => {
                        setLoading(false);
                        toastMessage.error(error.meta.message)
                    }
                );
            } else {
                AcademicTitleService.create(formData).then(
                    (res) => {
                        navigate.push({ pathname: "/academic_titles" });
                        toastMessage.success(res.data.message)
                    },
                    (error) => {
                        setLoading(false);
                        toastMessage.error(error.meta.message)
                    }
                );
            }
        } else {
            setLoading(false);
        }
    };

    useEffect(() => {
        TenantService.index().
            then(
                (response) => {
                    setTenants(response.data.tenants);
                    if (response.data.tenants.length > 0) {
                        const id = response.data.tenants[0].id;
                        setTenantId(id)

                        const departments = response.data.tenants.find(i => i.id == id).departments
                        setDepartments(departments);
                        setDepartmentId(departments[0]?.id);

                    }
                },
                (error) => { setError(error.meta.message) }
            );
    }, []);

    const onChangeTenant = (e) => {
        setTenantId(e.target.value);

        const departments = tenants.find(t => t.id == e.target.value)?.departments
        if (departments.length == 0) {
            setDepartments([]);
        } else {
            setDepartments(departments);
            setDepartmentId(departments[0].id)
        }
    }

    return (
        <div className="mt-5">
            <div className="d-flex flex-column align-items-center justify-content-center">
                <h1>{props.title}</h1>
                {error.length > 0 && <AlertMessage variant="danger" message={error} />}
                <div className="card card-container w-50">
                    <Form onSubmit={handleCreate} ref={form} className="p-3">
                        {/* <div className="form-group">
                            <label htmlFor="Tenant">{I18n.t('activerecord.models.tenant')}</label>
                            <Select
                                name="lang"
                                className="form-control"
                                title="Tenant"
                                value={tenantId}
                                disabled={AuthService.isSuperAdmin() ? false : true}
                                validations={[]}
                                onChange={onChangeTenant}
                            >
                                {tenants.map(l => <option key={l.id} value={l.id}>{l.company_name}</option>)}
                            </Select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="department">{I18n.t('activerecord.models.department')}</label>
                            <Select
                                name="lang"
                                className="form-control"
                                title="department"
                                value={departmentId}
                                validations={[]}
                                onChange={(val) => setDepartmentId(val.target.value)}
                            >
                                {departments.map(l => <option key={l.id} value={l.id}>{l.company_name}</option>)}
                            </Select>
                        </div> */}

                        <div className="form-group">
                            <label htmlFor="company_short_name">{I18n.t('activerecord.attributes.academic_title.name')}</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="company_short_name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                validations={[]}
                            />
                        </div>

                        <div className="form-group">
                            <button className="btn btn-primary btn-block mt-3" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span>{I18n.t('form.update')}</span>
                            </button>

                            <button onClick={() => navigate.push('/academic_titles')} className="btn btn-secondary mt-3 abort-btn">
                                <span>{I18n.t('form.abort')}</span>
                            </button>
                        </div>

                        {message && (
                            <div className="form-group mt-3">
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            </div>
                        )}
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                </div>
            </div>

        </div>
    )
};

export default AcademicTitleForm;