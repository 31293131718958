import httpClient from '../utils/http-common';

const API_URL = '/treatments';

const index = (pathwayId) => {
  return httpClient.get(API_URL + `?treatment_pathway_id=${pathwayId}`);
};

const documents = () => {
  return httpClient.get(API_URL + '/documents');
};

const show = (id) => {
  return httpClient.get(API_URL + `/${id}`);
};

const create = (data) => {
  return httpClient.post(API_URL, data);
};

const update = (id, data) => {
  return httpClient.put(API_URL + `/${id}`, data);
};

const copy = (id) => {
  return httpClient.post(API_URL + `/${id}/copy`);
};

const swapOrder = (data) => {
  return httpClient.put(API_URL + `/swap_order`, data);
};

const uploadDocuments = (id, data) => {
  return httpClient.post(API_URL + `/${id}` + '/upload_document', data);
};

const destroy = (id) => {
  return httpClient.delete(`${API_URL}/${id}`);
};

const TreatmentService = {
  index,
  create,
  show,
  update,
  destroy,
  documents,
  uploadDocuments,
  swapOrder,
  copy
};

export default TreatmentService;
