const isSubdomain = () => {
    var host = window.location.host;
    var subdomain = host.split(".")[0];

    return subdomain.length > 0
};

const formatPhoneNumber = (input, prevVal) => {
    var reg = /^\+?\d*$/;
    let result = ''
    if (input.length > 0 && input.substring(0, 1) != '+') {
        input = '+' + input
    }
    if (reg.test(input)) {
        result = input;
    } else {
        result = prevVal
    }
    if (input.length == 0) {
        result = ''
    }

    return result;
};

const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
};

const Utils = {
    isSubdomain,
    formatPhoneNumber,
    isWeekday
};

export default Utils;