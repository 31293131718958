import React, { useState, useEffect } from "react";
import "./index.scss";
import Logo from "images/logo-color-no-bg.svg";
import Spinner from "react-bootstrap/Spinner";
import { CalendarOutlined, ForkOutlined, LogoutOutlined, RadarChartOutlined } from "@ant-design/icons";
import AgendaTable from "./agenda_table";
import DashboardAgendaFilter from "./_agenda_filter_select";
import jsPDF from "jspdf";
import "jspdf-autotable";

const DashboardAgenda = (props) => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const filters = [
    { key: "deadline", icon: <CalendarOutlined />, translation: I18n.t("dashboard.provider_deadline") },
    { key: "case_guide", icon: <ForkOutlined />, translation: I18n.t("form.service_provider.name_in") },
  ];

  const onChangeFilter = (key) => {
    const s = [...selectedFilters];
    if (selectedFilters.indexOf(key) != -1) {
      setSelectedFilters(s.filter((f) => f != key));
    } else {
      setSelectedFilters([...s, key]);
    }
  };

  useEffect(() => {
    if (selectedFilters.length == 0) {
      setData([]);
    }
  }, [selectedFilters]);

  const handleDownload = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "report";
    const headers = [
      [
        "Date",
        "Time",
        "Patient first, last name",
        I18n.t("form.patient.birthday"),
        I18n.t("activerecord.attributes.patient.kiss_id"),
        I18n.t("form.service_provider.name_in"),
        I18n.t("activerecord.models.treatment_pathway"),
      ],
    ];

    const body = data.map((d) => [
      d.date,
      d.time,
      d.patient_name,
      d.patient_birthday,
      d.patient_kiss_id,
      d.service_provider_name,
      d.treatment,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: body,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf");
  };

  return (
    <div className="dashboard">
      <img className="img-fluid mx-auto d-block mb-3 logo" src={Logo} width="45%" />
      <h1 className="text-center mt-3">Agenda</h1>
      <div className="header container mb-5">
        <div className="row row-cols-3 g-3">
          {filters.map((f) => {
            return (
              <div onClick={() => onChangeFilter(f.key)} key={f.key} className="col">
                <div className={`dashboard-item p-4 ${selectedFilters.indexOf(f.key) != -1 ? "selected" : ""}`}>
                  <div className="icon">{f.icon}</div>
                  <div className="content">{f.translation || I18n.t(`dashboard.${f.key}`)}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {loading && (
        <div className="text-center">
          <Spinner className="mt-5" animation="border" variant="primary" />
        </div>
      )}
      <div className="body p-5 container mb-5">
        <DashboardAgendaFilter
          data={data}
          selectedFilters={filters.filter((f) => selectedFilters.indexOf(f.key) != -1)}
          setSelectedFilters={(k) => onChangeFilter(k)}
          setData={(d) => setData(d)}
          handleDownload={() => handleDownload()}
        />
        <div className="mt-3">
          <AgendaTable data={data} rowClasses={"d-fex justify-content-between"} />
        </div>
      </div>
    </div>
  );
};

export default DashboardAgenda;
