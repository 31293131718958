import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import moment from "moment";

const ConfirmPopup = ({ metaType, metaData, ...props }) => {
  const [confirmAppointments, setConfirmAppointments] = useState([]);
  const [confirmAppointmentIds, setConfirmAppointmentIds] = useState([]);

  const handleCancel = () => {
    props.updatePopupValue(false);
    props.onHide();
  };

  const handleAccept = () => {
    props.updatePopupValue(true);
    props.onHide();
  };

  useEffect(() => {
    if (props.appointments?.length > 0 && metaData?.length > 0) {
      const _confirmAppointments = props.appointments.filter(
        (a) => metaData.includes(a.treatment?.id) && a.poll.color != "grey" && !a.treatment?.is_internal,
      );
      setConfirmAppointments(_confirmAppointments);
    }
  }, [props.appointments]);

  const handleCheck = (e) => {
    if (e.target.checked) {
      props.setConfirmAppointmentIds([...confirmAppointmentIds, e.target.value]);
      setConfirmAppointmentIds([...confirmAppointmentIds, e.target.value]);
    } else {
      let _ids = [...confirmAppointmentIds];
      _ids = _ids.filter((i) => i != e.target.value);
      props.setConfirmAppointmentIds(_ids);
      setConfirmAppointmentIds(_ids);
    }

    console.log("al", confirmAppointmentIds);
  };

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Medtronic</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.question}</h4>

        {metaData?.length > 0 && metaType == "child-treatment" && (
          <div className="appointment-confirm mt-3">
            {confirmAppointments.map((a) => {
              return (
                <div className="row">
                  <div className="col-3">{a.treatment?.name}</div>
                  <div className="col-4">{a.service_provider?.display_name}</div>
                  <div className="col">{moment(a.appointment_date).format("DD.MM.YYYY")}</div>
                  <div className="col">
                    <input type="checkbox" value={a.id} onChange={handleCheck} />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button size="md" variant="secondary" onClick={handleCancel}>
          {I18n.t("enumerize.defaults.yes_no.false")}
        </Button>
        <Button size="md" onClick={handleAccept}>
          {I18n.t("enumerize.defaults.yes_no.true")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmPopup;
