import React from "react";

const Pagination = ({ meta, onChangePage }) => {
  const handleClick = (e, page) => {
    e.preventDefault();
    onChangePage(page);
  };

  const nextPage = (e) => {
    e.preventDefault();
    if (meta.next_page) {
      onChangePage(meta.next_page);
    }
  };

  const previousPage = (e) => {
    e.preventDefault();
    if (meta.prev_page) {
      onChangePage(meta.prev_page);
    }
  };

  return (
    Object.keys(meta).length > 0 && (
      <nav aria-label="Page navigation">
        <ul className="pagination">
          <li className="page-item">
            <a onClick={previousPage} className="page-link" href="#" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          {Array.from(Array(meta.total_pages).keys()).map((p) => (
            <li key={p} className={p + 1 == meta.current_page ? "page-item active" : "page-item"}>
              <a onClick={(e) => handleClick(e, p + 1)} className="page-link" href="#">
                {p + 1}
              </a>
            </li>
          ))}
          <li className="page-item">
            <a onClick={nextPage} className="page-link" href="#" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
    )
  );
};

export default Pagination;
