import React, { useState, useEffect } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import { EyeOutlined } from "@ant-design/icons";

const AgendaTable = ({ filterType, ...props }) => {
  const tableColunms = [
    {
      dataField: "date",
      text: I18n.t("dashboard.date"),
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex justify-content-between">
            <span>{row.date}</span>
          </div>
        );
      },
    },
    {
      dataField: "time",
      text: I18n.t("dashboard.time"),
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex justify-content-between">
            <span>{row.time}</span>
          </div>
        );
      },
    },
    {
      dataField: "patient_name",
      text:
        I18n.t("activerecord.attributes.patient.first_name") +
        ", " +
        I18n.t("activerecord.attributes.patient.last_name"),
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex justify-content-between">
            <span>{row.patient_name}</span>
          </div>
        );
      },
    },
    {
      dataField: "patient_birthday",
      text: I18n.t("form.patient.birthday"),
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex justify-content-between">
            <span>{row.patient_birthday}</span>
          </div>
        );
      },
    },
    {
      dataField: "patient_kiss_id",
      text: I18n.t("activerecord.attributes.patient.kiss_id"),
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex justify-content-between">
            <span>{row.patient_kiss_id}</span>
          </div>
        );
      },
    },
    {
      dataField: "service_provider_name",
      text: I18n.t("form.service_provider.name_in"),
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex justify-content-between">
            <span>{row.service_provider_name}</span>
          </div>
        );
      },
    },
    {
      dataField: "treatment",
      text: I18n.t("activerecord.models.treatment_pathway"),
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex justify-content-between">
            <span>{row.treatment}</span>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "",
      formatter: (cellContent, row) => {
        let href = `/patients/${row.patient_id}/profile?scrollId=${row.id}`;
        return (
          <div style={{ gap: 20 }} className="text-center">
            <a target="_blank" href={href} style={{ color: "red" }}>
              <EyeOutlined />
            </a>
          </div>
        );
      },
    },
    ,
  ];

  return <BootstrapTable keyField="id" data={props.data} columns={tableColunms} className="mt-3" />;
};

export default AgendaTable;
