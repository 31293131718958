import React, { useState, useEffect } from "react";
import DepartmentService from "../services/department";
import { FilePdfOutlined, FileImageOutlined, UploadOutlined, DeleteOutlined, FileAddFilled } from "@ant-design/icons";
import toastMessage from "./toast-message";
import StorageService from "../services/storage";
import "react-dropzone-uploader/dist/styles.css";

function Uploader({
  api,
  id,
  serverFiles,
  setFileIds,
  departmentId,
  multiple,
  documentType,
  preview,
  openIcon,
  path,
  setFiles,
  allowEdit,
  ...props
}) {
  const [attachmentOptions, setAttachmentOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [localServerFiles, _] = useState(serverFiles || []);
  const [showDataStorage, setShowDataStorage] = useState(true);

  const initSelectedFiles = (all) => {
    const selectedFile = [];
    const serverFileSum = localServerFiles.map((s) => s.sum);
    all.forEach((a) => {
      if (serverFileSum.includes(a.sum)) {
        selectedFile.push(a);
      }
    });

    setSelected(selectedFile);
  };

  useEffect(() => {
    if (departmentId) {
      DepartmentService.show(departmentId).then(
        (response) => {
          const p = response.data.department;
          const all = p.department_setting.documents;
          setAttachmentOptions(all);
          initSelectedFiles(all);
        },
        (error) => {
          setError(error.meta.message);
        },
      );
    }
  }, [departmentId]);

  const onChecked = (e) => {
    const id = e.target.value;
    const file = attachmentOptions.find((a) => a.id == id);
    const select = [...selected];
    if (e.target.checked) {
      if (multiple) {
        select.push(file);
        setSelected(Array.from(new Set(select)));
        setFileIds(Array.from(new Set(select)));
      } else {
        setSelected([file]);
        setFileIds(file);
      }
    } else {
      if (multiple) {
        const newSelect = select.filter((s) => s.id != id);
        setSelected(Array.from(new Set(newSelect)));
        setFileIds(Array.from(new Set(newSelect)));
      } else {
        setSelected([]);
        setFileIds([]);
      }
    }
  };

  const removeFileServer = async (id) => {
    const response = await StorageService.destroy(id);
    if (response.data.success) {
      toastMessage.success(response.data.message);
      props.reload();
    }
  };

  const setLocalFiles = (e) => {
    const filesInput = e.target.files;
    setFiles(filesInput); // TODO: make sure this actually works (not working on local)
  };

  return (
    <div className="uploader-com d-flex justify-content-center flex-column align-items-center">
      <div className="server-files align-self-stretch">
        {serverFiles && serverFiles.length > 0 && (
          <ul className="p-3 mt-3">
            <div className="existed-files">
              {serverFiles.map((f) => {
                return (
                  <div key={`${f.id}`} className="preview-item d-flex justify-content-between">
                    <li>
                      <a style={{ gap: 5 }} className="d-flex align-items-center" target="_blank" href={f.url}>
                        <FilePdfOutlined /> {f.filename}
                      </a>
                    </li>
                    {allowEdit && <DeleteOutlined onClick={() => removeFileServer(f.id)} className="mr-3" />}
                  </div>
                );
              })}
            </div>
          </ul>
        )}
      </div>

      <hr></hr>

      {allowEdit && (
        <div className="create-type mb-3">
          <div className="custom-control custom-radio custom-control-inline">
            <input
              checked={showDataStorage}
              value={showDataStorage}
              onChange={(e) => setShowDataStorage(!showDataStorage)}
              type="radio"
              id="customRadioInline1"
              name="customRadioInline1"
              className="custom-control-input"
            />
            <FilePdfOutlined />
            <label className="custom-control-label" htmlFor="customRadioInline1">
              {I18n.t("form.data_storage")}
            </label>
          </div>
          <div className="custom-control custom-radio custom-control-inline">
            <input
              checked={!showDataStorage}
              value={!showDataStorage}
              onChange={() => setShowDataStorage(!showDataStorage)}
              type="radio"
              id="customRadioInline2"
              name="customRadioInline1"
              className="custom-control-input"
            />
            <UploadOutlined />
            <label className="custom-control-label" htmlFor="customRadioInline2">
              {I18n.t("form.user_upload")}
            </label>
          </div>
        </div>
      )}
      <div style={{ gap: 50 }} className="user-form d-flex align-items-baseline justify-content-center">
        {showDataStorage && allowEdit && (
          <div className="from-server">
            {attachmentOptions.map((a) => {
              return (
                <div key={a.id} className="document-item d-flex align-items-center overflow-auto">
                  <input
                    onChange={onChecked}
                    style={{ marginRight: 5 }}
                    value={a.id}
                    id={a.id}
                    type="checkbox"
                    defaultChecked={localServerFiles.find((s) => s.sum == a.sum)}
                  />
                  {a.filetype == "application/pdf" ? <FilePdfOutlined /> : <FileImageOutlined />}
                  <label style={{ marginLeft: 5 }}>
                    <a target="_blank" href={a.url}>
                      {a.filename}
                    </a>
                  </label>
                </div>
              );
            })}
          </div>
        )}
        {!showDataStorage && (
          <div className="from-computer" style={{ minWidth: 300 }}>
            <input type="file" multiple accept="application/pdf" onChange={setLocalFiles} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Uploader;
