import React, { useState, useEffect } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";

const TopServiceProviderTable = ({ filterType, ...props }) => {
  const tableColunms = [
    {
      dataField: "name",
      text: "",
      sort: true,
    },
    {
      dataField: "top_1",
      text: "Top 1",
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex justify-content-between">
            <span>{row.top_1?.name}</span>
            <span>{row.top_1?.data}</span>
          </div>
        );
      },
    },
    {
      dataField: "top_2",
      text: "Top 2",
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex justify-content-between">
            <span>{row.top_2?.name}</span>
            <span>{row.top_2?.data}</span>
          </div>
        );
      },
    },
    {
      dataField: "top_3",
      text: "Top 3",
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex justify-content-between">
            <span>{row.top_3?.name}</span>
            <span>{row.top_3?.data}</span>
          </div>
        );
      },
    },
    ,
  ];

  return <BootstrapTable keyField="uid" data={props.data} columns={tableColunms} />;
};

export default TopServiceProviderTable;
