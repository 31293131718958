import { DownOutlined, UserOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import PatientService from "../../services/patient";

const PatientSelect = ({ onChangePatient, selectedPatientId, selectedPatientName, ...props }) => {
  const [options, setOptions] = useState([]);
  const [page, setPage] = useState(1);
  const [defaultValue, setDefaultValue] = useState({});
  const [meta, setPaginations] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (selectedPatientId) {
      setDefaultValue({ value: selectedPatientId, label: selectedPatientName });
    }
  }, [selectedPatientId, selectedPatientName]);

  useEffect(() => {
    PatientService.index("", page).then(
      (response) => {
        setPaginations(response.data.meta);
        const patients = response.data.patients.filter((p) => p.role == "patient" && p.active);
        const data = patients.map((patient) => ({
          value: patient.id,
          label: patient.last_name + " " + patient.first_name,
        }));

        setOptions((prevOptions) => {
          // Create a new set to keep track of values and ensure uniqueness
          const valuesSet = new Set(prevOptions.map((option) => option.value));

          // Filter out any data options already present in the prevOptions by their value
          const newData = data.filter((option) => !valuesSet.has(option.value));

          // Optionally, remove defaultValue if not needed in every update
          // If defaultValue should always be present, ensure it's not duplicated
          if (!valuesSet.has(defaultValue.value)) {
            valuesSet.add(defaultValue.value); // Ensure defaultValue is tracked for uniqueness
            return [defaultValue, ...prevOptions, ...newData];
          } else {
            return [...prevOptions, ...newData];
          }
        });

        //const filteredOptions = options.filter((option) => option.value !== defaultValue.value);
        //options = [defaultValue, ...filteredOptions];
      },
      (error) => {
        console.log(error);
        setError(error.meta.message);
      },
    );
  }, [props.departmentId, page, defaultValue]);

  const handleChange = (selectedOption) => {
    onChangePatient(selectedOption);
  };

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Adjust zIndex as needed
    control: (provided) => ({
      ...provided,
      backgroundColor: "#48F0C6",
      border: 0,
      boxShadow: "none",
      width: 300,
      borderRadius: 10,
      cursor: "pointer",
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: "35px", // Adjust based on the size of your icon
    }),
    singleValue: (provided) => ({
      ...provided,
      position: "relative",
      marginLeft: "0px", // Ensure text doesn't shift too much to the right
    }),
    // You can add more custom styles for other parts of the select here
  };

  const { ValueContainer } = components;
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <UserOutlined style={{ marginRight: "8px" }} /> {/* Render icon */}
        {children}
      </ValueContainer>
    );
  };

  const customComponents = {
    DropdownIndicator: () => <DownOutlined />, // Hide the dropdown indicator
    IndicatorSeparator: () => null, // Hide the separator (optional)
    ValueContainer: CustomValueContainer,
  };

  const handleScrollToEnd = () => {
    if (meta?.next_page) {
      setPage((prevPage) => prevPage + 1);
    }
  };


  return (
    <Select
      placeholder=""
      defaultValue={defaultValue}
      // className="tenant-select"
      options={options.filter((option) => option && option.value !== undefined)}
      onChange={handleChange}
      styles={customStyles}
      components={customComponents}
      value={defaultValue}
      onMenuScrollToBottom={handleScrollToEnd}
      menuPortalTarget={document.body}
      menuPlacement="auto"

      // menuPosition="fixed" // Helps with positioning in a scrolled container
    />
  );
};

export default PatientSelect;
