import React, { useState, useEffect } from "react";
import Logo from "images/logo-color-no-bg.svg";
import "./index.scss";
import {
  SwapOutlined,
  SoundOutlined,
  ForkOutlined,
  ClockCircleOutlined,
  LoginOutlined,
  RadarChartOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import Spinner from "react-bootstrap/Spinner";
import PatientInfoTable from "./_patient_table";
import DashboardPatientFilter from "./_patient_filter_select";

const DashboardPatient = (props) => {
  const filters = [
    { key: "case_guide", icon: <ForkOutlined />, translation: I18n.t("form.service_provider.name_in") },
    { key: "area_of_expertise", icon: <RadarChartOutlined /> },
    { key: "treatment_pathway", icon: <SwapOutlined />, translation: I18n.t("activerecord.models.treatment_pathway") },
    {
      key: "service_provider",
      icon: <LoginOutlined />,
      translation: I18n.t("activerecord.models.service_provider"),
    },
    { key: "active_passive", icon: <CheckCircleOutlined /> },
    { key: "history_future", icon: <ClockCircleOutlined /> },
    { key: "prom", icon: <SoundOutlined /> },
  ];

  const [loading, setLoading] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [data, setData] = useState([]);

  const onChangeFilter = (key) => {
    const s = [...selectedFilters];
    if (selectedFilters.indexOf(key) != -1) {
      setSelectedFilters(s.filter((f) => f != key));
    } else {
      setSelectedFilters([...s, key]);
    }
  };

  useEffect(() => {
    if (selectedFilters.length == 0) {
      setData([]);
    }
  }, [selectedFilters]);

  return (
    <div className="dashboard">
      <img className="img-fluid mx-auto d-block mb-3 logo" src={Logo} width="45%" />
      <h1 className="text-center">{I18n.t("dashboard.patient_title")}</h1>
      <div className="header container mb-5">
        <div className="row row-cols-3 g-3">
          {filters.map((f) => {
            return (
              <div onClick={() => onChangeFilter(f.key)} key={f.key} className="col">
                <div className={`dashboard-item p-4 ${selectedFilters.indexOf(f.key) != -1 ? "selected" : ""}`}>
                  <div className="icon">{f.icon}</div>
                  <div className="content">{f.translation || I18n.t(`dashboard.${f.key}`)}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {loading && (
        <div className="text-center">
          <Spinner className="mt-5" animation="border" variant="primary" />
        </div>
      )}
      {(selectedFilters.length > 0 || data.length > 0) && (
        <div className="body p-3 container mb-5">
          <DashboardPatientFilter
            data={data}
            selectedFilters={filters.filter((f) => selectedFilters.indexOf(f.key) != -1)}
            setSelectedFilters={(k) => onChangeFilter(k)}
            setData={(d) => setData(d)}
          />
          {data.length > 0 && (
            <PatientInfoTable filterType={selectedFilters} className="mt-5" data={data} actionLabel="View it" />
          )}
        </div>
      )}
    </div>
  );
};

export default DashboardPatient;
