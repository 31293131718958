import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { BookOutlined, CloseOutlined, LoginOutlined, LogoutOutlined } from "@ant-design/icons";
import ServiceProviderService from "../../services/service_provider";
import AlertMessage from "../../components/alert-message";
import AuthService from "../../services/auth";
import "./create.scss";
import PatientLanguageService from "../../services/patient_language";
import TreatmentService from "../../services/treatment";
import "react-phone-number-input/style.css";
import AcademicTitleService from "../../services/academic_title";
import PhoneInput from "react-phone-number-input";
import SpecialistService from "../../services/specialist";
import CreatableSelect from "react-select/creatable";
import toastMessage from "../../components/toast-message";
import "./form.scss";

const ServiceProviderForm = (props) => {
  let navigate = useHistory();
  const [error, setError] = useState("");

  const {
    location: { state },
  } = props;

  const [goBack, _] = useState(state?.goBack || false);
  const [patientActive, setPatientActive] = useState(false);
  const [showInternal, toggleInternal] = useState(true);
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [academicTitle, setAcademicTitle] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [place, setPlace] = useState("");
  const [zip, setZip] = useState("");
  const [meetingPoint, setMeetingPoint] = useState("");
  const [firstName, setFirstName] = useState(state?.name || "");
  const [phoneNumberDirect, setPhoneNumberDirect] = useState("");
  const [lastName, setLastName] = useState("");
  const [communication, setCommunication] = useState("post");
  const [communicationOptions, setCommunicationOptions] = useState([
    I18n.t("enumerize.defaults.communication_path.email"),
  ]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [department, setDepartment] = useState("");
  const [language, setLanguage] = useState("");
  const [isLoadingSpecialist, setIsLoadingSpecialist] = useState(false);
  const [reminderReport, setReminderReport] = useState("");
  const [reminderDate, setReminderDate] = useState("");

  const [languages, setLanguages] = useState([]);
  const [specialist, setSpecialist] = useState("");
  const [specialistValue, setSpecialistValue] = useState("");
  const [specialists, setSpecialists] = useState([]);
  const [treatment, setTreatment] = useState("");
  const [treatments, setTreatments] = useState([]);
  const [academicTitles, setAcademicTitles] = useState([]);

  useEffect(() => {
    PatientLanguageService.index().then(
      (response) => {
        const p = response.data.patient_languages.filter((p) => p.active === true);
        setLanguages(p);
        setLanguage(p[0]?.id);
      },
      (error) => {
        setError(error.meta.message);
      },
    );
  }, [props.departmentId]);

  useEffect(() => {
    TreatmentService.index().then(
      (response) => {
        const p = response.data.treatments;
        setTreatments(p);
      },
      (error) => {
        setError(error.meta.message);
      },
    );
  }, [props.departmentId]);

  useEffect(() => {
    AcademicTitleService.index().then(
      (response) => {
        const p = response.data.service_provider_academic_titles;
        setAcademicTitles(p);
      },
      (error) => {
        setError(error.meta.message);
      },
    );
  }, [props.departmentId]);

  useEffect(() => {
    if (props.providerId) {
      ServiceProviderService.show(props.providerId).then(
        (response) => {
          const provider = response.data.service_provider;
          setAcademicTitle(provider.academic_title_id);
          setFirstName(provider.first_name || "");
          setLastName(provider.last_name || "");
          setCompanyName(provider.company_name || "");
          setMeetingPoint(provider.meeting_point || "");
          setGender(provider.gender || "");
          setEmail(provider.email || "");

          setPhoneNumber(provider.phone_number ? `+${provider.phone_number}` : "");
          setPhoneNumberDirect(provider.phone_number_direct ? `+${provider.phone_number_direct}` : "");
          setPatientActive(provider.patient_appointment_reminder == "yes" ? true : false);
          setLanguage(provider.language_id || languages[0]?.id);

          setCommunication(provider.communication_path);
          if (provider.phone_number.length > 0 && provider.email.length > 0) {
            setCommunicationOptions([I18n.t("enumerize.defaults.communication_path.email")]);
            setCommunication("email");
          } else if (provider.email.length > 0) {
            setCommunicationOptions([I18n.t("enumerize.defaults.communication_path.email")]);
            setCommunication("email");
          } else if (provider.phone_number.length > 0) {
            setCommunicationOptions([I18n.t("enumerize.defaults.communication_path.email")]);
            setCommunication("sms");
          } else if (provider.phone_number.length == 0 && provider.email.length == 0) {
            setCommunicationOptions(["post"]);
            setCommunication("post");
          }

          setStreetNumber(provider.street_number || "");
          setHouseNumber(provider.house_number || "");
          setPlace(provider.place || "");
          setZip(provider.zip || "");
          setDepartment(provider.department || "");
          setTreatment(provider.treatment_id || "");
          setSpecialist(provider.specialist_id || "");

          if (provider.specialist_id) {
            setSpecialistValue({ value: provider.specialist.id, label: provider.specialist.name });
          }

          toggleInternal(provider.service_type == "internal" ? true : false);
        },
        (error) => {
          toastMessage.error(error.meta.message);
        },
      );
    }
  }, [props.departmentId]);

  useEffect(() => {
    SpecialistService.index().then(
      (response) => {
        const p = response.data.specialists;
        let createOption = p.map((pp) => {
          return {
            value: pp.id,
            label: pp.name,
          };
        });
        setSpecialists(createOption);
        if (createOption.length > 0 && specialist) {
          const find = specialists.find((s) => parseInt(s.value) == parseInt(specialist));
          if (find && Object.keys(find).length > 0) {
            setSpecialistValue(find);
          }
        }
      },
      (error) => {
        setError(error.meta.message);
      },
    );
  }, [props.departmentId]);

  const onChangeGender = (e) => {
    setGender(e.target.value);
  };

  const onChangeDepartment = (e) => {
    setDepartment(e.target.value);
  };

  const onSelectInternal = (e) => {
    toggleInternal(e);
  };

  const onChangeLanguage = (e) => {
    const val = e.target.value;
    setLanguage(val);
  };

  const onChangeTreatment = (e) => {
    const val = e.target.value;
    setTreatment(val);
  };

  const onChangeSpecialist = (e) => {
    const val = e.value;
    setSpecialist(val);
    setSpecialistValue({ label: e.label, value: e.value });
  };

  const onChangeAcademicTitle = (e) => {
    const val = e.target.value;
    setAcademicTitle(val);
  };

  const onChangeCompanyName = (e) => {
    const val = e.target.value;
    setCompanyName(val);
  };

  const onChangeMeetingPoint = (e) => {
    const val = e.target.value;
    setMeetingPoint(val);
  };

  const onChangeZip = (e) => {
    const val = e.target.value;
    setZip(val);
  };

  const onChangeHouseNumber = (e) => {
    const val = e.target.value;
    setHouseNumber(val);
  };

  const onChangeEmail = (e) => {
    const val = e.target.value;
    setEmail(val);
    if (phoneNumber.length > 0 && val.length > 0) {
      setCommunicationOptions([I18n.t("enumerize.defaults.communication_path.email")]);
      setCommunication("email");
    } else if (val.length > 0) {
      setCommunicationOptions([I18n.t("enumerize.defaults.communication_path.email")]);
      setCommunication("email");
    } else if (phoneNumber.length > 0) {
      setCommunicationOptions([I18n.t("enumerize.defaults.communication_path.email")]);
      setCommunication("sms");
    } else if (phoneNumber.length == 0 && val.length == 0) {
      setCommunicationOptions(["post"]);
      setCommunication("post");
    }
  };

  const onChangePhoneNumber = (val) => {
    if (val == undefined) {
      val = "";
      toastMessage.error(I18n.t("form.common_error.area_code_blank"), "area_code_blank");
    }
    setPhoneNumber(val);

    if (val.length > 0 && email.length > 0) {
      setCommunicationOptions([I18n.t("enumerize.defaults.communication_path.email")]);
      setCommunication("email");
    } else if (email.length > 0) {
      setCommunicationOptions([I18n.t("enumerize.defaults.communication_path.email")]);
      setCommunication("email");
    } else if (val.length > 0) {
      setCommunicationOptions([I18n.t("enumerize.defaults.communication_path.email")]);
      setCommunication("sms");
    } else if (val.length == 0 && email.length == 0) {
      setCommunicationOptions([I18n.t("enumerize.defaults.communication_path.email")]);
      setCommunication("post");
    }
  };
  const onChangePhoneDirect = (val) => {
    if (val == undefined) {
      val = "";
      toastMessage.error(I18n.t("form.common_error.area_code_blank"), "area_code_blank");
    }
    setPhoneNumberDirect(val);
  };

  const onChangeInternalCreateType = (e) => {
    setPatientActive(e.currentTarget.checked);
  };

  const onChangeStreetNumber = (e) => {
    const id = e.target.value;
    setStreetNumber(id);
  };

  const onChangePlace = (e) => {
    const date = e.target.value;
    setPlace(date);
  };

  const onChangeFirstName = (e) => {
    const name = e.target.value;
    if (name.length == 0 && lastName.length == 0) {
      setGender("");
    } else {
      if (gender == "") {
        setGender("male");
      }
    }
    setFirstName(name);
  };

  const onChangeLastName = (e) => {
    const name = e.target.value;
    if (name.length == 0 && firstName.length == 0) {
      setGender("");
    } else {
      if (gender == "") {
        setGender("male");
      }
    }
    setLastName(name);
  };

  const onChangeCommunication = (e) => {
    const val = e.target.value;
    setCommunication(val);
  };

  const handleCreate = (e) => {
    e.preventDefault();

    let data;
    if (showInternal) {
      data = {
        service_provider: {
          service_type: "internal",
          first_name: firstName,
          last_name: lastName,
          phone_number: phoneNumber.substring(0, 1) == "+" ? phoneNumber.substring(1, phoneNumber.length) : phoneNumber,
          email: email,
          meeting_point: meetingPoint,
          academic_title_id: academicTitle,
          company_name: companyName,
          gender: gender,
          treatment_id: treatment,
          specialist_id: specialist,
        },
      };
    } else {
      data = {
        service_provider: {
          service_type: "external",
          first_name: firstName,
          last_name: lastName,
          company_name: companyName,
          phone_number: phoneNumber.substring(0, 1) == "+" ? phoneNumber.substring(1, phoneNumber.length) : phoneNumber,
          language_id: language || languages[0]?.id,
          email: email,
          meeting_point: meetingPoint,
          zip: zip,
          street_number: streetNumber,
          house_number: houseNumber,
          place: place,
          patient_appointment_reminder: patientActive ? "yes" : "no",
          communication_path: communication,
          gender: gender,
          department: department,
          treatment_id: treatment,
          academic_title_id: academicTitle,
          phone_number_direct:
            phoneNumberDirect.substring(0, 1) == "+"
              ? phoneNumberDirect.substring(1, phoneNumberDirect.length)
              : phoneNumberDirect,
          specialist_id: specialist,
        },
      };
    }

    if (props.providerId) {
      ServiceProviderService.update(props.providerId, data).then(
        (res) => {
          navigate.push({
            pathname: `/service_providers/${props.providerId}/profile`,
            state: { name: "actionSuccess", message: res.data.message },
          });
        },
        (error) => {
          toastMessage.error(error.meta.message);
        },
      );
    } else {
      if (goBack) {
        data["appointment_id"] = props.location.state.appointmentId;
      }
      ServiceProviderService.create(data).then(
        (res) => {
          if (goBack) {
            navigate.push({
              pathname: goBack,
              state: { appointmentId: props.location.state.appointmentId, id: res.data.service_provider.id },
            });
          } else {
            navigate.push({
              pathname: `/service_providers`,
            });

            toastMessage.success(res.data.message);
          }
        },
        (error) => {
          toastMessage.error(error.meta.message);
        },
      );
    }
  };

  const onCloseForm = () => {
    if (goBack) {
      navigate.push({ pathname: goBack, state: { appointmentId: props.location.state.appointmentId } });
    } else {
      navigate.push(props.providerId ? `/service_providers/${props.providerId}/profile` : "/service_providers");
    }
  };

  const handleCreateSpecialist = async (inputValue) => {
    try {
      setIsLoadingSpecialist(true);
      const response = await SpecialistService.create({ name: inputValue });
      const newData = response.data.specialist;
      toastMessage.success(response.data.message);

      const newOption = { value: newData.id, label: newData.name };
      setSpecialists([newOption, ...specialists]);
      setSpecialist(newData.id);
      setSpecialistValue(newOption);
      setIsLoadingSpecialist(false);
    } catch (e) {
      console.log(e);
      toastMessage.error(error.meta.message);
      setIsLoadingSpecialist(false);
    }
  };

  return (
    <div className="row">
      <div className="col-md-1"></div>
      <div className="col-md-10 mt-5">
        {error.length > 0 && <AlertMessage variant="danger" message={error} />}
        <div id="patient-create">
          <div className="patient-search-box">
            <div className="box-header">
              <span></span>
              <span>{props.title}</span>
              <CloseOutlined onClick={() => onCloseForm()} />
            </div>
            <div className="create-type mt-3">
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  checked={showInternal}
                  value={showInternal}
                  onChange={() => onSelectInternal(true)}
                  type="radio"
                  id="customRadioInline1"
                  name="customRadioInline1"
                  className="custom-control-input"
                />
                <LoginOutlined />
                <label className="custom-control-label" htmlFor="customRadioInline1">
                  {I18n.t("form.service_provider.internal")}
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  checked={!showInternal}
                  value={!showInternal}
                  onChange={() => onSelectInternal(false)}
                  type="radio"
                  id="customRadioInline2"
                  name="customRadioInline1"
                  className="custom-control-input"
                />
                <LogoutOutlined />
                <label className="custom-control-label" htmlFor="customRadioInline2">
                  {I18n.t("form.service_provider.external")}
                </label>
              </div>
            </div>
            {showInternal && (
              <div className="box-body row">
                <div className="input">
                  <div className="col-md-5 label">
                    <span>{I18n.t("activerecord.attributes.service_provider.first_name")}</span>
                  </div>
                  <div className="col-md-7">
                    <input type="text" value={firstName} onChange={onChangeFirstName} />
                  </div>
                </div>
                <div className="input">
                  <div className="col-md-5 label">
                    <span>{I18n.t("activerecord.attributes.service_provider.last_name")}</span>
                  </div>
                  <div className="col-md-7">
                    <input type="text" onChange={onChangeLastName} value={lastName} />
                  </div>
                </div>
                <div className="input">
                  <div className="col-md-5 label">
                    <span>{I18n.t("activerecord.attributes.patient.gender")}</span>
                  </div>
                  <div className="col-md-7">
                    <select name="gender" className="selection form-control" onChange={onChangeGender} value={gender}>
                      <option value="male">{I18n.t("form.patient.male")}</option>
                      <option value="female">{I18n.t("form.patient.female")}</option>
                      <option value="other">{I18n.t("form.patient.other")}</option>
                    </select>
                  </div>
                </div>
                <div className="input">
                  <div className="col-md-5 label">
                    <span>{I18n.t("activerecord.attributes.service_provider.company_name")}</span>
                  </div>
                  <div className="col-md-7">
                    <input type="text" onChange={onChangeCompanyName} value={companyName} />
                  </div>
                </div>
                <div className="input">
                  <div className="col-md-5 label">
                    <span>{I18n.t("activerecord.attributes.service_provider.academic_title")}</span>
                  </div>
                  <div className="col-md-7">
                    <select
                      name="treatments"
                      className="selection form-control"
                      value={academicTitle}
                      onChange={onChangeAcademicTitle}
                    >
                      <option value={""}></option>
                      {academicTitles.map((l) => (
                        <option key={l.id} value={l.id}>
                          {l.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="input">
                  <div className="col-md-5 label">
                    <span>{I18n.t("activerecord.attributes.service_provider.phone_number")}</span>
                  </div>
                  <div className="col-md-7">
                    <PhoneInput
                      international
                      defaultCountry="CH"
                      value={phoneNumber}
                      onChange={(val) => onChangePhoneNumber(val)}
                    />
                  </div>
                </div>

                <div className="input">
                  <div className="col-md-5 label">
                    <span>{I18n.t("activerecord.attributes.service_provider.email")}</span>
                  </div>
                  <div className="col-md-7">
                    <input type="email" onChange={onChangeEmail} value={email} />
                  </div>
                </div>
                <div className="input-textarea">
                  <div className="col-md-5 label">
                    <span>{I18n.t("activerecord.attributes.service_provider.meeting_point")}</span>
                  </div>
                  <div className="col-md-7">
                    <textarea onChange={onChangeMeetingPoint} value={meetingPoint} />
                  </div>
                </div>
                <div className="input">
                  <div className="col-md-5 label">
                    <span>{I18n.t("activerecord.attributes.service_provider.treatment")}</span>
                  </div>
                  <div className="col-md-7">
                    <CreatableSelect
                      value={specialistValue}
                      options={specialists}
                      onChange={onChangeSpecialist}
                      className="react-select-specialist"
                      classNamePrefix="react-select"
                      placeholder=""
                      onCreateOption={handleCreateSpecialist}
                      isDisabled={isLoadingSpecialist}
                      isLoading={isLoadingSpecialist}
                      formatCreateLabel={(userInput) => `${I18n.t("form.react_select_create")} "${userInput}"`}
                    />
                  </div>
                </div>
              </div>
            )}

            {!showInternal && (
              <div className="box-body row">
                <div className="input">
                  <div className="col-md-5 label">
                    <span>{I18n.t("activerecord.attributes.service_provider.first_name")}</span>
                  </div>
                  <div className="col-md-7">
                    <input type="text" value={firstName} onChange={onChangeFirstName} />
                  </div>
                </div>
                <div className="input">
                  <div className="col-md-5 label">
                    <span>{I18n.t("activerecord.attributes.service_provider.last_name")}</span>
                  </div>
                  <div className="col-md-7">
                    <input type="text" onChange={onChangeLastName} value={lastName} />
                  </div>
                </div>
                <div className="input">
                  <div className="col-md-5 label">
                    <span>{I18n.t("activerecord.attributes.service_provider.company_name")}</span>
                  </div>
                  <div className="col-md-7">
                    <input type="text" onChange={onChangeCompanyName} value={companyName} />
                  </div>
                </div>

                <div className="input">
                  <div className="col-md-5 label">
                    <span>{I18n.t("activerecord.attributes.service_provider.department")}</span>
                  </div>
                  <div className="col-md-7">
                    <input type="text" onChange={onChangeDepartment} value={department} />
                  </div>
                </div>
                <div className="input">
                  <div className="col-md-5 label">
                    <span>{I18n.t("activerecord.attributes.service_provider.street_number")}</span>
                  </div>
                  <div className="col-md-7">
                    <input type="text" className="form-control" value={streetNumber} onChange={onChangeStreetNumber} />
                  </div>
                </div>
                <div className="input">
                  <div className="col-md-5 label">
                    <span>{I18n.t("activerecord.attributes.service_provider.house_number")}</span>
                  </div>
                  <div className="col-md-7">
                    <input type="text" className="form-control" value={houseNumber} onChange={onChangeHouseNumber} />
                  </div>
                </div>
                <div className="input">
                  <div className="col-md-5 label">
                    <span>{I18n.t("activerecord.attributes.service_provider.zip")}</span>
                  </div>
                  <div className="col-md-7">
                    <input type="text" onChange={onChangeZip} value={zip} />
                  </div>
                </div>
                <div className="input">
                  <div className="col-md-5 label">
                    <span>{I18n.t("activerecord.attributes.service_provider.place")}</span>
                  </div>
                  <div className="col-md-7">
                    <input type="text" className="form-control" value={place} onChange={onChangePlace} />
                  </div>
                </div>

                <div className="input">
                  <div className="col-md-5 label">
                    <span>{I18n.t("activerecord.attributes.service_provider.phone_number_central")}</span>
                  </div>
                  <div className="col-md-7">
                    <PhoneInput
                      international
                      defaultCountry="CH"
                      value={phoneNumber}
                      onChange={(val) => onChangePhoneNumber(val)}
                    />
                  </div>
                </div>

                <div className="input">
                  <div className="col-md-5 label">
                    <span>{I18n.t("activerecord.attributes.service_provider.phone_number_direct")}</span>
                  </div>
                  <div className="col-md-7">
                    <PhoneInput
                      international
                      defaultCountry="CH"
                      value={phoneNumberDirect}
                      onChange={(val) => onChangePhoneDirect(val)}
                    />
                  </div>
                </div>

                <div className="input">
                  <div className="col-md-5 label">
                    <span>{I18n.t("activerecord.attributes.service_provider.email")}</span>
                  </div>
                  <div className="col-md-7">
                    <input type="email" onChange={onChangeEmail} value={email} />
                  </div>
                </div>
                <div className="input">
                  <div className="col-md-5 label">
                    <span>{I18n.t("activerecord.attributes.service_provider.communication_path")}</span>
                  </div>
                  <div className="col-md-7">
                    <select
                      name="communication"
                      className="selection form-control text-uppercase"
                      onChange={onChangeCommunication}
                      value={communication}
                    >
                      {communicationOptions.map((c) => (
                        <option key={c}>{c}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="input">
                  <div className="col-md-5 label">
                    <span>{I18n.t("activerecord.attributes.service_provider.language")}</span>
                  </div>
                  <div className="col-md-7">
                    <select
                      name="language"
                      className="selection form-control"
                      value={language}
                      onChange={onChangeLanguage}
                    >
                      {languages.map((l) => (
                        <option key={l.id} value={l.id}>
                          {l.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="input">
                  <div className="col-md-5 label">
                    <span>{I18n.t("activerecord.attributes.service_provider.academic_title")}</span>
                  </div>
                  <div className="col-md-7">
                    <select
                      name="treatments"
                      className="selection form-control"
                      value={academicTitle}
                      onChange={onChangeAcademicTitle}
                    >
                      <option value={""}></option>
                      {academicTitles.map((l) => (
                        <option key={l.id} value={l.id}>
                          {l.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="input">
                  <div className="col-md-5 label">
                    <span>{I18n.t("activerecord.attributes.patient.gender")}</span>
                  </div>
                  <div className="col-md-7">
                    {(firstName.length > 0 || lastName.length > 0) && (
                      <select name="gender" className="selection form-control" onChange={onChangeGender} value={gender}>
                        <option value="male">{I18n.t("form.patient.male")}</option>
                        <option value="female">{I18n.t("form.patient.female")}</option>
                        <option value="other">{I18n.t("form.patient.other")}</option>
                      </select>
                    )}
                  </div>
                </div>

                <div className="input">
                  <div className="col-md-5 label">
                    <span>{I18n.t("activerecord.attributes.service_provider.treatment")}</span>
                  </div>
                  <div className="col-md-7">
                    <CreatableSelect
                      value={specialistValue}
                      options={specialists}
                      onChange={onChangeSpecialist}
                      className="react-select-specialist"
                      classNamePrefix="react-select"
                      placeholder=""
                      onCreateOption={handleCreateSpecialist}
                      isDisabled={isLoadingSpecialist}
                      isLoading={isLoadingSpecialist}
                    />
                  </div>
                </div>

                <div className="input-textarea">
                  <div className="col-md-5 label">
                    <span>{I18n.t("activerecord.attributes.service_provider.meeting_point")}</span>
                  </div>
                  <div className="col-md-7">
                    <textarea onChange={onChangeMeetingPoint} value={meetingPoint} />
                  </div>
                </div>

                <div className="form-check form-switch">
                  <label className="form-check-label" htmlFor="patientActive">
                    {I18n.t("activerecord.attributes.service_provider.patient_appointment_reminder")}
                  </label>
                  <input
                    className="form-check-input"
                    onChange={onChangeInternalCreateType}
                    type="checkbox"
                    role="switch"
                    value={patientActive}
                    id="patientActive"
                    defaultChecked={patientActive}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {
          <div
            className="create-patient d-flex align-items-baseline justify-content-between btn btn-lg mt-3 fw-bolder float-end bg-green"
            onClick={handleCreate}
          >
            <span>{props.submitLabel}</span>
          </div>
        }
      </div>
      <div className="col-md-1"></div>
    </div>
  );
};

export default ServiceProviderForm;
