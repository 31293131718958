const setTenants = (tenants) => {
    localStorage.setItem("tenants", JSON.stringify(tenants))
};

const all = () => {
    try {
        return JSON.parse(localStorage.getItem("tenants")) || []
    } catch (e) {
        console.log(e)
        return []
    }
};

const clear = () => {
    localStorage.removeItem("tenants")
};

const TenantCache = {
    setTenants,
    all,
    clear,
};

export default TenantCache;