import React, { useState, useEffect } from "react";
import SurveyService from "../../services/survey";
import "./index.scss";
import { useHistory, useParams } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import toastMessage from "../../components/toast-message";

const SurveyDetail = () => {
  const { id } = useParams();
  const [form, setForm] = useState(undefined);
  const [reload, setReload] = useState(false);
  const [checkedList, setCheckedList] = useState({});
  const [title, setTitle] = useState("");
  const navigate = useHistory();

  useEffect(() => {
    if (id) {
      SurveyService.show(id).then(
        (response) => {
          const data = response.data.survey;
          setForm(data.form);

          const _checkList = {};
          data.form.forEach((d) => {
            if (d.answer.length > 0) {
              d.answer.forEach((a) => {
                _checkList[a[0]] = a[2];
              });
            } else {
              d.sub_question.forEach((a) => {
                a[2].forEach((aa) => {
                  _checkList[aa[0]] = aa[2];
                });
              });
            }
          });
          setCheckedList(_checkList);
          setTitle(data.title);
        },
        (error) => {
          toastMessage.error(error.meta.message);
        },
      );
    }
  }, [reload]);

  const handleSubmit = async () => {
    const enableWarning = Object.keys(checkedList).filter((key) => checkedList[key]);
    SurveyService.updateWarning(id, enableWarning).then(
      (res) => {
        setReload(!reload);
        toastMessage.success(res.data.message);
      },
      (error) => {
        toastMessage.error(error.meta.message);
      },
    );
  };

  const handleRadioChange = (e, answerId) => {
    const _checkedList = { ...checkedList };
    _checkedList[answerId] = e.target.checked;
    setCheckedList(_checkedList);
  };

  return (
    form?.length > 0 && (
      <div className="container mt-5 survey-show">
        <button
          onClick={() => navigate.push("/surveys")}
          className="btn btn-secondary btn-block d-flex align-items-center justify-content-between mb-3"
        >
          <LeftOutlined /> {I18n.t("devise.shared.links.back")}
        </button>
        <div className="d-flex flex-column justify-content-center">
          <div className="card p-5">
            <h1 className="text-center mb-5">{title}</h1>
            <div className="d-flex justify-content-end text-muted">
              <div className="legend">
                <h5>{I18n.t("traffic_light.legend")}:</h5>
                <ul>
                  <li>
                    {" "}
                    <div className="li-circle"></div>
                    <span>{I18n.t("survey.guide")}</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
            {form &&
              form.map((q, index) => {
                return (
                  <div className="content" key={q.id}>
                    <div className="question">
                      <h5>
                        {index + 1}. {q.question}
                      </h5>
                      {q.sub_question && (
                        <div className="sub-question">
                          <div className="row mb-2">
                            <div className="col-5"></div>
                            <div className="col-7">
                              <div className="answer-group">
                                <div className="row">
                                  {q.sub_question[0][2].map((a) => {
                                    return (
                                      <div className="col" key={a[0]}>
                                        <label htmlFor={`answer-${a[0]}-${q.id}`}>{a[1]}</label>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                          {q.sub_question.map((sq) => {
                            return (
                              <div className="container" key={sq.id}>
                                <div className="row">
                                  <div className="col-5">
                                    <p>{sq[1]}</p>
                                  </div>
                                  <div className="col-7 text-center">
                                    <div className="answer-inline row">
                                      {sq[2].map((a, index) => {
                                        return (
                                          <div className="input col" key={a[0]}>
                                            <input
                                              id={`answer-${a[0]}-${sq[0]}`}
                                              name={`answer-${sq[0]}[]`}
                                              type="checkbox"
                                              defaultChecked={checkedList[a[0]]}
                                              value={a[0]}
                                              onChange={(e) => handleRadioChange(e, a[0])}
                                            />
                                            <label
                                              className={`${checkedList[a[0]] == true ? "text-warning" : ""}`}
                                              htmlFor={`answer-${a[0]}-${sq[0]}`}
                                            >
                                              {index + 1}
                                            </label>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}

                      {!q.sub_question && (
                        <div className="answer">
                          {q.answer.map((a) => {
                            return (
                              <div className="input" key={a[0]}>
                                <input
                                  id={`answer-${a[0]}-${q.id}`}
                                  name={`answer-${q.id}[]`}
                                  type="checkbox"
                                  value={a[0]}
                                  defaultChecked={checkedList[a[0]]}
                                  onChange={(e) => handleRadioChange(e, a[0])}
                                />
                                <label
                                  className={`${checkedList[a[0]] == true ? "text-warning" : ""}`}
                                  htmlFor={`answer-${a[0]}-${q.id}`}
                                >
                                  {a[1]}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    <hr></hr>
                  </div>
                );
              })}
            <div className="d-flex align-items-center m-3">
              <button onClick={handleSubmit} className="btn btn-primary btn-lg">
                {I18n.t("form.update")}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default SurveyDetail;
