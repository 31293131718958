import httpClient from "../utils/http-common";

const API_URL = "/patients";

const index = (patienId, filter = "") => {
  return httpClient.get(`${API_URL}/${patienId}/appointments?query=${filter}`);
};

const create = (patientId, data) => {
  return httpClient.post(`${API_URL}/${patientId}/appointments`, data);
};

const show = (patientId, appointmentId) => {
  return httpClient.get(`${API_URL}/${patientId}/appointments/${appointmentId}`);
};

const update = (patientId, appointmentId, data) => {
  return httpClient.put(`${API_URL}/${patientId}/appointments/${appointmentId}`, data);
};

const swapOrder = (patientId, appointmentId, data) => {
  return httpClient.put(`${API_URL}/${patientId}/appointments/${appointmentId}/swap_order`, data);
};

const removeWarning = (patientId, appointmentId) => {
  return httpClient.post(`${API_URL}/${patientId}/appointments/${appointmentId}/remove_warning`);
};

const destroy = (patientId, appointmentId) => {
  return httpClient.delete(`${API_URL}/${patientId}/appointments/${appointmentId}`);
};

const uploadDocuments = (patientId, appointmentId, data) => {
  return httpClient.post(API_URL + `/${patientId}/appointments/${appointmentId}` + "/upload_document", data);
};

const updateServiceProvider = (patientId, appointmentId, data) => {
  return httpClient.put(API_URL + `/${patientId}/appointments/${appointmentId}` + "/update_service_provider", data);
};

const updateTime = (patientId, appointmentId, data) => {
  return httpClient.put(API_URL + `/${patientId}/appointments/${appointmentId}` + "/update_time", data);
};

const remindPatientAppointment = (patientId, appointmentId) => {
  return httpClient.post(API_URL + `/${patientId}/appointments/${appointmentId}` + "/patient_remind_appointment");
};

const activePathway = (patientId, pathwayId = "") => {
  return httpClient.post(API_URL + `/${patientId}/appointments/active_pathway`, { pathwayId });
};

const PatientAppointmentService = {
  index,
  show,
  create,
  update,
  destroy,
  swapOrder,
  uploadDocuments,
  remindPatientAppointment,
  updateServiceProvider,
  updateTime,
  activePathway,
  removeWarning,
};

export default PatientAppointmentService;
