import React, { useState, useEffect } from 'react'
import Table from 'react-bootstrap/Table';
import TenantService from '../../services/tenant';
import AlertMessage from '../../components/alert-message';
import {
    Link,
    useLocation
} from "react-router-dom";
import AuthService from '../../services/auth';
import Pagination from '../../components/pagination';
import TreatmentService from '../../services/treatment-pathway';

export default (props) => {
    const [message, setMessage] = useState({});
    const [reload, setReload] = useState(undefined);

    const [currentPage, setCurrentPage] = useState(1);
    const [treatments, setTreatments] = useState([]);
    const [meta, setPaginations] = useState({});

    const location = useLocation();

    useEffect(() => {
        if (location.state?.name == 'actionSuccess') {
            setMessage({ content: location.state.message, variant: 'success' })
        }
    }, [location]);

    useEffect(() => {
        TreatmentService.index().
            then(
                (response) => {
                    setTreatments(response.data.treatment_pathways);
                    setPaginations(response.data.meta)
                },
                (error) => { setMessage({ content: error.meta.message, variant: 'danger' }) }
            );
    }, [reload, currentPage, props.departmentId]);

    const handleDelete = async (id) => {
        if (window.confirm(I18n.t('form.tenant.confirm_delete'))) {
            try {
                const response = await TreatmentService.destroy(id, props.departmentId)
                setReload(!reload)
                setMessage({ content: response.data.message, variant: 'success' })
            } catch (error) {
                setReload(!reload)
                setMessage({ content: error.meta.message, variant: 'danger' })
            }
        }
    };

    return (
        <div className="card container p-5 mt-5">
            <h1 className="text-center">{I18n.t('activerecord.models.treatment_pathway')}</h1>
            {Object.keys(message).length > 0 && <AlertMessage variant={message.variant} message={message.content} />}
            <div className="">
                {AuthService.isSuperAdmin() && <Link className="btn btn-primary mb-3" to="/treatment_pathway_create">{I18n.t('form.treatment_pathway.new_treatment_pathway')}</Link>}

                <Table bordered hover responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{I18n.t('form.treatment_pathway.name')}</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {treatments.map(t => {
                            return (
                                <tr key={t.id}>
                                    <td>{t.id}</td>
                                    <td>{t.name_de}</td>
                                    < td >
                                        <Link to={`/treatment_pathways/${t.id}/edit`}>Edit</Link>
                                        <a href="#!" onClick={(e) => { e.preventDefault(); handleDelete(t.id) }}> Delete</a>
                                    </td>
                                </tr>)
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    )
};
