import React, { useState, useEffect } from "react";
import DashboardChartSelectedFilter from "./_chart_selected_filters";

const DashboardChartFilter = ({ selectedFilters, setSelectedFilters, ...props }) => {
  const [queries, setQueries] = useState({});

  return (
    <div className="patient-dashboard-filter container">
      <div className="row row-cols-3 g-2">
        {selectedFilters.map((s) => {
          return (
            <DashboardChartSelectedFilter
              key={s.key}
              handleSearch={() => handleSearch()}
              queries={queries}
              setQueries={(e) => setQueries(e)}
              selectedFilter={s}
              filterType={s.key}
              setDateFilter={props.setDateFilter}
              setPathwayFilter={props.setPathwayFilter}
              setProviderId={props.setProviderId}
              setSelectedFilters={(e) => setSelectedFilters(e)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default DashboardChartFilter;
