import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Logo from "images/logo-color-no-bg.svg";
import "./index.scss";
import { CalendarOutlined, CheckSquareOutlined, LogoutOutlined } from "@ant-design/icons";
import Dashboard from "images/Dashboard.png";
import Todo from "images/caution.png";
import Patient from "images/Patient";
import DashboardService from "../../services/dashboard";
import toastMessage from "../../components/toast-message";

export default (props) => {
  let navigate = useHistory();

  const [checkTodo, setCheckTodo] = useState(false);
  useEffect(() => {
    DashboardService.checkTodo().then(
      (response) => {
        setCheckTodo(Object.values(response.data.check).includes(true));
      },
      (error) => {
        toastMessage.error(error.meta.message);
      },
    );
  }, []);

  return (
    <div className="dashboard">
      <img className="img-fluid mx-auto d-block mb-3 logo" src={Logo} />
      <div className="header container mb-5">
        <div className="row row-cols-3 g-3">
          <div className="col">
            <div className="dashboard-item" onClick={() => navigate.push("/dashboard/todo")}>
              <div className="icon">
                <img src={Todo} width="35" height="35" />
              </div>
              <div className={`content ${checkTodo ? "notice" : ""}`}>Todo</div>
            </div>
          </div>
          <div className="col">
            <div className="dashboard-item" onClick={() => navigate.push("/dashboard/chart")}>
              <div className="icon">
                <img src={Dashboard} width="35" height="35" />
              </div>
              <div className="content">{I18n.t("dashboard.embedded")}</div>
            </div>
          </div>
          <div className="col">
            <div className="dashboard-item" onClick={() => navigate.push("/dashboard/patient")}>
              <div className="icon">
                <img src={Patient} width="40" height="40" />
              </div>
              <div className="content">Patienten</div>
            </div>
          </div>
          <div className="col">
            <div className="dashboard-item" onClick={() => navigate.push("/dashboard/service_provider")}>
              <div className="icon">
                <LogoutOutlined />
              </div>
              <div className="content">{I18n.t("activerecord.models.service_provider")}</div>
            </div>
          </div>
          <div className="col">
            <div className="dashboard-item" onClick={() => navigate.push("/dashboard/top_service_provider")}>
              <div className="icon">
                <LogoutOutlined />
              </div>
              <div className="content">Top 3 {I18n.t("activerecord.models.service_provider")}</div>
            </div>
          </div>

          <div className="col">
            <div className="dashboard-item" onClick={() => navigate.push("/dashboard/agenda")}>
              <div className="icon">
                <CalendarOutlined />
              </div>
              <div className="content">Agenda</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
