import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthService from "../../services/auth";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import { CopyOutlined, DeleteOutlined, EditOutlined, EyeFilled } from "@ant-design/icons";
import toastMessage from "../../components/toast-message";
import SurveyService from "../../services/survey";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default (props) => {
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(undefined);
  const [copied, setCopied] = useState(false);

  const [surveys, setSurveys] = useState([]);

  const tableColunms = [
    {
      dataField: "id",
      text: "#",
      sort: true,
    },
    {
      dataField: "title",
      text: "Web Formular",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "Edit Delete",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <div style={{ gap: 20 }} className="d-flex align-items-center">
            <Link to={`/surveys/${row.id}/detail`}>
              <EyeFilled />
            </Link>

            <Link to={`/surveys/${row.id}/edit`}>
              <EditOutlined />
            </Link>

            <a
              href="#!"
              onClick={(e) => {
                e.preventDefault();
                handleDelete(row.id);
              }}
            >
              <DeleteOutlined />
            </a>

            <CopyToClipboard
              text={window.location.origin + "/surveys/" + row.id}
              onCopy={() => toastMessage.success("Copied to Clipboard")}
            >
              <Link>
                <CopyOutlined />
                Copy link to Clipboard
              </Link>
            </CopyToClipboard>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    SurveyService.index().then(
      (response) => {
        setSurveys(response.data.surveys);
        setLoading(false);
      },
      (error) => {
        toastMessage.error(error.meta.message);
      },
    );
  }, [reload, props.departmentId]);

  const handleDelete = async (id) => {
    if (window.confirm("Delete Web Formula?")) {
      try {
        const response = await SurveyService.destroy(id, props.departmentId);
        setReload(!reload);
        toastMessage.success(response.data.message);
      } catch (error) {
        setReload(!reload);
        toastMessage.error(error.meta.message);
      }
    }
  };

  return (
    !loading && (
      <div className="row mt-5 survey-index">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <div className="card p-5">
            <h1 className="text-center">Web Formulare</h1>
            <Link style={{ width: "25%" }} className="btn btn-primary mb-3" to="/survey_create">
              Formular
            </Link>
            <BootstrapTable keyField="id" data={surveys} columns={tableColunms} />
          </div>
        </div>
      </div>
    )
  );
};
