import React, { useState, useEffect, useCallback } from "react";
import { SearchOutlined, RightOutlined, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import PatientService from "../../services/patient";
import AuthService from "../../services/auth";
import "./patient-search.scss";
import moment from "moment";
import ManIcon from "../../components/icons/male-icon";
import FemaleIcon from "../../components/icons/female-icon";
import OtherGenderIcon from "../../components/icons/other-gender-icon";
import Logo from "images/logo-color-no-bg.svg";
import toastMessage from "../../components/toast-message";
import { debounce } from "lodash";

export default (props) => {
  const [query, setQuery] = useState("");
  const [patients, setPatents] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [notActive, setNotActive] = useState([]);

  const debounceSearch = useCallback(
    debounce((nextValue) => search(nextValue), 800),
    [],
  );

  const search = (nextQuery) => {
    if (nextQuery?.length == 0) {
      setPatents([]);
      setNotActive([]);
      setSupervisors([]);
      return;
    }
    PatientService.index(nextQuery).then(
      (response) => {
        const _patients = response.data.patients.filter((p) => p.role == "patient" && p.active);
        setPatents(_patients);
        setNotActive(response.data.patients.filter((p) => p.role == "patient" && !p.active));
        setSupervisors(response.data.patients.filter((p) => p.role != "patient"));
      },
      (error) => {
        toastMessage.error(error.meta.message);
      },
    );
  };

  useEffect(() => {
    setPatents([]);
    setSupervisors([]);
    setNotActive([]);
    setQuery("");
  }, [props.tenantId, props.departmentId, props.pathwayId]);

  const onChangeQuery = (e) => {
    const q = e.target.value;
    setQuery(q);
    debounceSearch(q);
  };

  const handleKey = (key) => {
    if (key.code == "Enter") {
      search();
    }
  };

  return (
    <div id="patient-search">
      <img className="img-fluid mx-auto d-block mb-3" src={Logo} width="45%" />
      <div className="box-end mb-3">
        <Link
          className="create-patient d-flex align-items-baseline justify-content-between btn btn-lg mt-3 fw-bolder float-end bg-green"
          to="/patient_create"
        >
          <PlusOutlined />
          <span>{I18n.t("form.patient.new_patient")}</span>
        </Link>
      </div>
      <div className="patient-search-box">
        <div className="box-header">
          <span className="search-icon">
            <SearchOutlined />
          </span>
          <input
            placeholder={I18n.t("form.patient.search")}
            onKeyDown={handleKey}
            autoFocus
            className="search-box"
            type="text"
            value={query}
            onChange={onChangeQuery}
          ></input>
        </div>
        {(patients.length > 0 || supervisors.length > 0 || notActive.length > 0) && (
          <div className="box-body">
            {patients.length > 0 && (
              <div className="content">
                <div className="top">{I18n.t("activerecord.models.patient")}</div>
                <ul>
                  {patients.map((p) => (
                    <li key={p.id} className="user-info">
                      <span className={`avatar ${p.gender}`}>
                        {p.gender == "male" && <ManIcon style={{ marginLeft: "6px" }} color="#ADD8E6" />}
                        {p.gender == "female" && <FemaleIcon color="#ADD8E6" />}
                        {p.gender == "other" && <OtherGenderIcon color="#ADD8E6" />}
                        <span
                          className="username c-pointer"
                          onClick={() => {
                            const link = document.getElementById(`link-${p.id}`);
                            if (link) {
                              link.click();
                            }
                          }}
                        >
                          {p.last_name} {p.first_name}{" "}
                          {p.birthday ? " | " + moment(p.birthday).format("DD/MM/YYYY") : ""}{" "}
                          {p.place ? " | " + p.place : ""} {" | "}{" "}
                          <span className="fw-bold">{p.treatment_pathway?.name_de}</span>
                        </span>
                      </span>
                      <span className="action">
                        <Link
                          id={`link-${p.id}`}
                          className="patient-profile-btn"
                          to={`/patients/${p.id}/profile`}
                        >
                          {I18n.t("form.patient.patient_profile")}
                        </Link>
                        <RightOutlined />
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {supervisors.length > 0 && (
              <div className="content">
                <div className="top">{I18n.t("activerecord.models.supervisor")}</div>
                <ul>
                  {supervisors.map((p) => (
                    <li key={p.id} className="user-info">
                      <span className={`avatar ${p.gender}`}>
                        {p.gender === "male" && <ManIcon color="#ADD8E6" />}
                        {p.gender === "female" && <FemaleIcon color="#ADD8E6" />}
                        {p.gender === "other" && <OtherGenderIcon color="#ADD8E6" />}
                        <span
                          className="username c-pointer"
                          id={`username-${p.id}`}
                          onClick={() => {
                            const link = document.getElementById(`link-${p.id}`);
                            if (link) {
                              link.click();
                            }
                          }}
                        >
                          {p.last_name} {p.first_name}{" "}
                          {p.birthday ? " | " + moment(p.birthday).format("DD/MM/YYYY") : ""}{" "}
                          {p.place ? " | " + p.place : ""}
                        </span>
                      </span>
                      <span className="action">
                        <Link
                          id={`link-${p.id}`}
                          className="patient-profile-btn"
                          to={{ pathname: `/patients/${p.patient?.id}/profile`, query: { expendSupervisor: true } }}
                        >
                          {I18n.t("form.patient.supervisor_profile")}
                        </Link>
                        <RightOutlined />
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {notActive.length > 0 && (
              <div className="content">
                <div className="top">{I18n.t("form.patient.not_active")}</div>
                <ul>
                  {notActive.map((p) => (
                    <li key={p.id} className="user-info">
                      <span className={`avatar ${p.gender}`}>
                        {p.gender == "male" && <ManIcon color="#ADD8E6" />}
                        {p.gender == "female" && <FemaleIcon color="#ADD8E6" />}
                        {p.gender == "other" && <OtherGenderIcon color="#ADD8E6" />}
                        <span
                          className="username c-pointer"
                          onClick={() => {
                            const link = document.getElementById(`link-${p.id}`);
                            if (link) {
                              link.click();
                            }
                          }}
                        >
                          {p.last_name} {p.first_name}{" "}
                          {p.birthday ? " | " + moment(p.birthday).format("DD/MM/YYYY") : ""}{" "}
                          {p.place ? " | " + p.place : ""}
                        </span>
                      </span>
                      <span className="action">
                        <Link id={`link-${p.id}`} className="patient-profile-btn" to={`/patients/${p.id}/profile`}>
                          {I18n.t("form.patient.patient_profile")}
                        </Link>
                        <RightOutlined />
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
