import React, { useState, useRef, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import TenantService from "../../services/tenant";
import { LANGUAGE_OPTIONS } from "../../constants/index"
import AlertMessage from '../../components/alert-message';

const TenantForm = (props) => {
    let navigate = useHistory();

    const form = useRef();
    const checkBtn = useRef();


    const [error, setError] = useState("");
    const [tenantId, setTenantId] = useState(props.tenantId);
    const [companyName, setCompanyName] = useState("");
    const [logo, setLogo] = useState(undefined);
    const [companyShortName, setCompanyShortName] = useState("");
    const [companyZsr, setCompanyZsr] = useState("");
    const [language, setLanguage] = useState(LANGUAGE_OPTIONS[0]['value']);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (tenantId) {
            TenantService.show(tenantId).then(
                (response) => {
                    const tenant = response.data.tenant
                    setCompanyName(tenant.company_name)
                    setCompanyShortName(tenant.short_name)
                    setLanguage(tenant.language)
                    setCompanyZsr(tenant.zsr)
                },
                (error) => { setError(error.meta.message) }
            )
        }
    }, []);

    const onChangeCompanyName = (e) => {
        const name = e.target.value;
        setCompanyName(name)
    };

    const onChangeCompanyShortName = (e) => {
        const short_name = e.target.value;
        setCompanyShortName(short_name)
    };

    const onChangeLanguage = (e) => {
        const language = e.target.value;
        setLanguage(language)
    };

    const onChangeLogo = (e) => {
        const logo = e.target.files[0];
        setLogo(logo)
    };

    const onChangeCompanyZsr = (e) => {
        const zsr = e.target.value;
        setCompanyZsr(zsr)
    };

    const handleCreate = (e) => {
        e.preventDefault();

        setMessage("");
        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            const formData = new FormData()
            formData.append('company_name', companyName);
            formData.append('short_name', companyShortName);
            formData.append('language', language);
            if (logo) {
                formData.append('logo', logo);
            }
            if(companyZsr) {
                formData.append('zsr', companyZsr);
            }
            if (tenantId) {
                TenantService.update(tenantId, formData).then(
                    (res) => {
                        navigate.push({ pathname: "/tenants", state: { name: 'actionSuccess', message: res.data.message } });
                    },
                    (error) => {
                        setLoading(false);
                        setMessage(error.meta.message);
                    }
                );
            } else {
                TenantService.create(formData).then(
                    (res) => {
                        navigate.push({ pathname: "/tenants", state: { name: 'actionSuccess', message: res.data.message } });
                    },
                    (error) => {
                        setLoading(false);
                        setMessage(error.meta.message);
                    }
                );
            }
        } else {
            setLoading(false);
        }
    };

    return (
      <div className="mt-5">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <h1>{props.title}</h1>
          {error.length > 0 && <AlertMessage variant="danger" message={error} />}
          <div className="card card-container w-50">
            <Form onSubmit={handleCreate} ref={form} className="p-3">
              <div className="form-group">
                <label htmlFor="company_name">{I18n.t("activerecord.attributes.tenant.company_name")}</label>
                <Input
                  type="text"
                  className="form-control"
                  name="company_name"
                  value={companyName}
                  onChange={onChangeCompanyName}
                  validations={[]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="company_short_name">{I18n.t("activerecord.attributes.tenant.short_name")}</label>
                <Input
                  type="text"
                  className="form-control"
                  name="company_short_name"
                  value={companyShortName}
                  onChange={onChangeCompanyShortName}
                  validations={[]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="language">{I18n.t("activerecord.attributes.tenant.language")}</label>
                <Select
                  name="lang"
                  className="form-control"
                  title="Language"
                  value={language}
                  validations={[]}
                  onChange={onChangeLanguage}
                >
                  {LANGUAGE_OPTIONS.map((l) => (
                    <option key={l.value} value={l.value}>
                      {l.label}
                    </option>
                  ))}
                </Select>
              </div>

              <div className="form-group">
                <label htmlFor="logo">{I18n.t("activerecord.attributes.tenant.logo")}</label>
                <Input type="file" className="form-control" name="logo" onChange={onChangeLogo} validations={[]} />
              </div>

              <div className="form-group">
                <label htmlFor="company_zsr">{I18n.t("activerecord.attributes.tenant.zsr")}</label>
                <Input
                  type="text"
                  className="form-control"
                  name="company_zsr"
                  value={companyZsr}
                  onChange={onChangeCompanyZsr}
                  validations={[]}
                />
              </div>

              <div className="form-group">
                <button className="btn btn-primary btn-block mt-3" disabled={loading}>
                  {loading && <span className="spinner-border spinner-border-sm"></span>}
                  <span>{I18n.t("form.update")}</span>
                </button>

                <button onClick={() => navigate.push("/tenants")} className="btn btn-secondary mt-3 abort-btn">
                  <span>{I18n.t("form.abort")}</span>
                </button>
              </div>

              {message && (
                <div className="form-group mt-3">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
          </div>
        </div>
      </div>
    );
};

export default TenantForm;