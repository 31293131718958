import httpClient from "../utils/http-common";
import httpPublicClient from "../utils/http-common-public";

const API_URL = "/surveys";

const index = (page) => {
  if (page == undefined) {
    page = 1;
  }
  return httpClient.get(`${API_URL}?page=${page}`);
};

const create = (data) => {
  return httpPublicClient.post(API_URL, data);
};

const createAdmin = (data) => {
  return httpClient.post(API_URL, data);
};

const updateWarning = (id, list) => {
  return httpClient.put(API_URL + `/${id}/warning`, list);
};

const show = (id) => {
  return httpClient.get(`${API_URL}/${id}`);
};

const update = (id, data) => {
  return httpClient.put(`${API_URL}/${id}`, data);
};

const destroy = (id) => {
  return httpClient.delete(`${API_URL}/${id}`);
};

const SurveyService = {
  index,
  show,
  create,
  update,
  destroy,
  createAdmin,
  updateWarning,
};

export default SurveyService;
