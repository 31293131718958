import React from "react";
import "./user-profile";
import { Link } from "react-router-dom";
import UserProfile from "./user-profile";
import AuthService from "../../services/auth";
import AdminFilter from "../admin-filter2";
import Setting from "./setting";
import MpaLayout from "./mpa-layout";

export default (props) => {
  const onChangeTenantId = (id) => {
    props.onChangeTenantId(id);
  };

  const onChangeDepartmentId = (id) => {
    props.onChangeDepartmentId(id);
  };

  const onChangePathwayId = (id) => {
    props.onChangePathwayId(id);
  };

  return (
    <section className="p-3 header">
      <div className="brand-wrapper">
        <MpaLayout />
      </div>
      {!props.hideFilter && (
        <AdminFilter
          noLabel={true}
          onChangeTenantId={onChangeTenantId}
          onChangePathwayId={onChangePathwayId}
          onChangeDepartmentId={onChangeDepartmentId}
          reloadTenants={!!props.reloadTenants}
        />
      )}
      {AuthService.isLoggedIn() && (
        <section className="configuration">
          <UserProfile />
          <Setting />
        </section>
      )}
    </section>
  );
};
