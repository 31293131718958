import React, { useState, useEffect } from 'react'
import Table from 'react-bootstrap/Table';
import AlertMessage from '../../components/alert-message';
import {
    Link,
    useLocation
} from "react-router-dom";
import UserService from '../../services/user';
import Pagination from '../../components/pagination';


export default (props) => {
    const [message, setMessage] = useState({});
    const [destroyedUserId, setDestroyUserId] = useState(undefined);
    const [currentPage, setCurrentPage] = useState(1);
    const [meta, setPaginations] = useState({});
    const [users, setUsers] = useState([]);

    const location = useLocation();

    useEffect(() => {
        if (location.state?.name == 'actionSuccess') {
            setMessage({ content: location.state.message, variant: 'success' })
        }
    }, [location]);

    useEffect(() => {
        UserService.index(currentPage).
            then(
                (response) => {
                    setUsers(response.data.users);
                    setPaginations(response.data.meta)
                },
                (error) => {
                    setMessage({ content: error.meta.message, variant: 'danger' })
                }
            );

    }, [props.tenantId, props.departmentId, destroyedUserId, currentPage]);

    const handleDeleteUser = async (id) => {
        if (window.confirm(I18n.t('form.user.confirm_delete'))) {
            try {
                const response = await UserService.destroy(id, props.departmentId)
                setDestroyUserId(id)
                setMessage({ content: response.data.message, variant: 'success' })
            } catch (error) {
                setMessage({ content: error.meta.message, variant: 'danger' })
            }

        }
    };

    const onChangePage = (page) => {
        if (page != currentPage) {
            setCurrentPage(page)
        }
    }

    return (
        <div className="card container p-5 mt-5" >
            <h1 className="text-center">{I18n.t('activerecord.models.user.one')}</h1>
            {Object.keys(message).length > 0 && <AlertMessage variant={message.variant} message={message.content} />}
            <div>
                <Link className="btn btn-primary mb-3" to="/users/new">{I18n.t('form.user.new_user')}</Link>
                <Table bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{I18n.t('activerecord.attributes.user.role')}</th>
                            <th>{I18n.t('activerecord.models.tenant')}</th>
                            <th>{I18n.t('activerecord.models.department')}</th>
                            <th>{I18n.t('activerecord.attributes.user.email')}</th>
                            <th>{I18n.t('activerecord.attributes.user.language')}</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(t => {
                            return (
                                <tr key={t.id}>
                                    <td>{t.id}</td>
                                    <td className="text-capitalize">{t.role}</td>
                                    <td>{t.tenant ? t.tenant.company_name : ''}</td>
                                    <td>{t.department ? t.department.company_name : ''}</td>
                                    <td>{t.email}</td>
                                    <td className="text-capitalize">{I18n.t(`enumerize.defaults.language.${t.language}`)}</td>
                                    <td>
                                        <Link to={`/users/${t.id}/edit`}>Edit</Link>
                                        <a href="#!" onClick={(e) => { e.preventDefault(); handleDeleteUser(t.id) }}> Delete</a>
                                    </td>
                                </tr>)
                        })}
                    </tbody>
                </Table>

                <Pagination meta={meta} onChangePage={onChangePage} />
            </div>
        </div >
    )
};