import React, { useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Logfile from "./_logfile";

export default (props) => {
  return (
    <div className="container-fluid">
      <div className="row mt-5">
        <div className="col-md-12">
          <div className="card p-5">
            <h1 className="text-center mb-5">Logfile</h1>
            <Logfile all={props.all || true} {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};
