import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import "./appointment-form.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  CalendarTwoTone,
  ClockCircleTwoTone,
  ConsoleSqlOutlined,
  EditOutlined,
  FilePdfFilled,
  FilePdfOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import AlertMessage from "../../components/alert-message";
import ToastMessage from "../../components/toast-message";
import PatientAppointmentService from "../../services/patient_appointment";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import TreatmentService from "../../services/treatment-pathway";
import ServiceProviderSelection from "../../components/form/service_provider_selection";
import Select, { components } from "react-select";
import Utils from "../../utils";
import DepartmentService from "../../services/department";
import moment from "moment/moment";
import toastMessage from "../../components/toast-message";
import { Tooltip, TooltipWrapper } from "react-tooltip";
import Spinner from "react-bootstrap/Spinner";


const { Option, MultiValue } = components;

const MultiValueOption = (props) => {
  return (
    <MultiValue {...props}>
      <div className="d-flex align-items-center">
        <FilePdfOutlined style={{ marginRight: "5px", fontSize: "1.2rem" }} />
        {props.data.label}
      </div>
    </MultiValue>
  );
};
const IconOption = (props) => (
  <Option {...props}>
    <div className="d-flex align-items-center">
      <FilePdfOutlined style={{ marginRight: "5px", fontSize: "1.2rem" }} />
      {props.data.label}
    </div>
  </Option>
);

const AppointmentModalForm = ({
  appointmentId,
  appointmentTime: time,
  appointmentDate: date,
  appointmentName: aName,
  treatmentId,
  defaultProviderSelection,
  reload,
  isConsultationAppointment,
  noDocument,
  ...props
}) => {
  const modalRef = useRef(null);
  const [error, setError] = useState("");
  const [appointmentName, setAppointmentName] = useState(aName);
  const [appointmentNeedResult, setAppointmentNeedResult] = useState(false);
  const [isKomPackage, setIsKomPackage] = useState(false);
  const [komPackageNumber, setKomPackageNumber] = useState(undefined);
  const [appointmentDate, setAppointmentDate] = useState(date || "");
  const [appointmentTime, setAppointmentTime] = useState(time || "");
  const [provider, setProvider] = useState(props.provider);
  const [files, _setFiles] = useState([]);
  const [patient, setPatient] = useState(props.patient);
  const [attachments, setAttachments] = useState([]);
  const [attachmentOptions, setAttachmentOptions] = useState([]);
  const [treatmentFiles, setTreatmentFiles] = useState([]);
  const [serverFiles, setServerFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isInternalType, setIsInternalType] = useState(props.provider?.is_internal);

  useEffect(() => {
    setPatient(props.patient);
  }, [props.patient]);

  useEffect(() => {
    setAppointmentName(aName);
  }, [aName]);

  useEffect(() => {
    if (props.show) {
      setLoading(true);
      TreatmentService.show(patient.treatment_pathway.id).then(
        (response) => {
          const files = response.data.treatment_pathway.documents;
          setTreatmentFiles(files);
          const departmentId = window.cookies.cookies.last_department_id;
          if (departmentId) {
            DepartmentService.show(departmentId).then(
              (response) => {
                const p = response.data.department;

                const svFiles = [];

                const options = p.department_setting.documents.map((f) => {
                  if (files.map((t) => t.sum).includes(f.sum)) {
                    svFiles.push({
                      value: f.id,
                      label: f.filename,
                    });
                  }
                  return {
                    value: f.id,
                    label: f.filename,
                  };
                });
                setAttachmentOptions(options);
                if (!noDocument) {
                  setServerFiles(svFiles);
                  setAttachments(svFiles);
                }
                setLoading(false);
              },
              (error) => {
                setError(error.meta.message);
              },
            );
          }
        },
        (error) => {
          setError(error.meta.message);
        },
      );
    }
  }, [props.show]);

  const handleForm = (e) => {
    e.preventDefault();

    if (isKomPackage == true && (komPackageNumber == null || komPackageNumber == undefined || komPackageNumber == 0)) {
      toastMessage.error(I18n.t("tooltip.kom_package"));
      return;
    }

    const formData = new FormData();
    let hours;
    let minutes;
    if (appointmentTime) {
      hours = appointmentTime.getHours();
      minutes = appointmentTime.getMinutes();
    } else if (appointmentDate) {
      hours = appointmentDate.getHours();
      minutes = appointmentDate.getMinutes();
    }

    const time = appointmentDate;

    if (time) {
      time.setHours(hours || 0);
      time.setMinutes(minutes || 0);
      formData.append("appointment[appointment_time]", time);
    }

    formData.append("is_consultation", isConsultationAppointment);
    formData.append("appointment[name]", appointmentName);
    formData.append("appointment[patient_id]", patient.id);

    if (komPackageNumber) {
      formData.append("appointment[kom_package_number]", komPackageNumber);
    }

    formData.append(
      "appointment[service_provider_id]",
      provider?.id || provider?.value || provider || defaultProviderSelection.value,
    );
    formData.append("appointment[need_result]", appointmentNeedResult);
    attachments.forEach((a) => {
      formData.append("appointment[attachment_ids][]", a.value);
    });

    if (appointmentTime && !appointmentDate) {
      toastMessage.error(I18n.t("form.appointment.enter_valid_date"));
      return;
    }
    if (!time) {
      toastMessage.error(I18n.t("form.appointment.enter_valid_datetime"));
      return;
    }

    if (time.getHours() == 0 && isInternalType) {
      toastMessage.error(I18n.t("form.appointment.enter_valid_time"));
      return;
    }

    if (!appointmentId) {
      PatientAppointmentService.create(props.patient.id, formData).then(
        (res) => {
          ToastMessage.success(res.data.message);
          window.location.reload();
        },
        (error) => {
          ToastMessage.error(error.meta.message);
        },
      );
    } else {
      PatientAppointmentService.update(props.patient.id, appointmentId, formData).then(
        (res) => {
          ToastMessage.success(res.data.message);
          window.location.reload();
        },
        (error) => {
          ToastMessage.error(error.meta.message);
        },
      );
    }
  };

  const onDateChange = (val) => {
    setAppointmentDate(val);
    if (val && (!appointmentTime || appointmentTime == "")) {
      // setAppointmentTime(setHours(setMinutes(new Date(), 0), 0));
    }
  };

  const onChangeNeedResult = (e) => {
    setAppointmentNeedResult(e.target.checked);
  };

  const onFocusTime = () => {
    if (!appointmentTime || appointmentTime == "") {
      setAppointmentTime(setHours(setMinutes(new Date(), 0), 6));
    }
  };

  return (
    props.patient &&
    Object.keys(props.patient).length > 0 && (
      <Modal {...props} size="lg" ref={modalRef} aria-labelledby="contained-modal-title-vcenter" centered>
        <Tooltip id="tooltip-kom-package" content={I18n.t("tooltip.kom_package")} />
        <Modal.Header closeButton className="modal-header">
          <Modal.Title id={`${appointmentName}-modal-title-vcenter`}>
            {!appointmentId && I18n.t("form.appointment.new_appointment")}
            {appointmentId && (
              <div style={{ gap: "5px" }} className="d-flex align-items-center">
                <EditOutlined />
                {I18n.t("form.appointment.name")}
              </div>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!loading ? (
            <div className="appointment-create-box">
              <div className="box-body">
                <div className="attachment">
                  <span className="label">{I18n.t("form.appointment.for_patient")}</span>
                </div>
                <div className="input row">
                  <div className="col-md-4 label">
                    <span className="text-capitalize">
                      {I18n.t(`enumerize.defaults.gender.${props.patient.gender}`)}
                    </span>
                  </div>
                  <div className="col-md-7">
                    <input readOnly type="text" defaultValue={`${patient.first_name}, ${patient.last_name}`} />
                  </div>
                </div>
                <div className="attachment">
                  <span className="label">
                    <PaperClipOutlined />
                  </span>
                  <Select
                    defaultValue={serverFiles}
                    className="attachment-multi-select"
                    menuPortalTarget={document.body}
                    options={attachmentOptions}
                    closeMenuOnSelect={true}
                    onChange={(choice) => setAttachments(choice)}
                    isMulti
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    components={{
                      Option: IconOption,
                      MultiValue: MultiValueOption,
                    }}
                    placeholder=""
                  />
                </div>
                {files.length > 0 && (
                  <div className="row mb-3">
                    <ul>
                      {files.map((f) => (
                        <li key={f}>{f}</li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="input row">
                  <div className="col-md-4 label">
                    <span>{I18n.t("activerecord.attributes.appointment.attachments")}</span>
                  </div>
                  <div className="col-md-7">
                    <ServiceProviderSelection
                      className="selection form-control"
                      isInternal={isConsultationAppointment || patient.treatment_pathway?.is_hybrid_prom}
                      setProvider={setProvider}
                      setIsInternalType={(v) => setIsInternalType(v)}
                      provider={
                        defaultProviderSelection
                          ? defaultProviderSelection
                          : provider
                          ? { label: provider.display_name, id: provider.id }
                          : null
                      }
                    />
                  </div>
                </div>

                <div className="input row">
                  <div className="col-md-4 label">
                    <span className="text-capitalize">{I18n.t("activerecord.attributes.appointment.name")}</span>
                  </div>
                  <div className="col-md-7">
                    {isConsultationAppointment && <input disabled type="text" value={appointmentName} />}
                    {!isConsultationAppointment && (
                      <input type="text" value={appointmentName} onChange={(e) => setAppointmentName(e.target.value)} />
                    )}
                  </div>
                </div>

                {!isInternalType && (
                  <div className="attachment">
                    <span className="label">{I18n.t("form.appointment.new_forecast_date")}</span>
                  </div>
                )}

                <div className="row">
                  <div className="col-md-6 datepicker datepicker-date">
                    <CalendarTwoTone />
                    <DatePicker
                      placeholderText="TT.MM.JJJJ"
                      dropdownMode="select"
                      minDate={new Date()}
                      dateFormat={"dd.MM.yyyy"}
                      // filterDate={Utils.isWeekday}
                      className="form-control"
                      selected={appointmentDate}
                      onChange={onDateChange}
                    />
                  </div>
                  {isInternalType && (
                    <div className="col-md-6 datepicker datepicker-time">
                      <ClockCircleTwoTone />
                      <DatePicker
                        showTimeSelect
                        showTimeSelectOnly
                        placeholderText="00:00"
                        timeIntervals={30}
                        timeCaption="Time"
                        timeFormat="HH:mm"
                        dateFormat="HH:mm"
                        minTime={setHours(setMinutes(new Date(), 0), 6)}
                        maxTime={setHours(setMinutes(new Date(), 0), 22)}
                        className="form-control"
                        selected={appointmentTime}
                        onChange={(date) => setAppointmentTime(date)}
                        onFocus={onFocusTime}
                      />
                    </div>
                  )}
                </div>

                <div className="row">
                  <div className="col-md-6 d-flex align-items-center">
                    <input
                      onChange={onChangeNeedResult}
                      style={{ marginRight: 5 }}
                      defaultChecked={appointmentNeedResult}
                      value={appointmentNeedResult}
                      id="appointmentNeedResult"
                      type="checkbox"
                    />
                    <label htmlFor="appointmentNeedResult">
                      {I18n.t("activerecord.attributes.treatment.need_result")}
                    </label>
                  </div>

                  {!isConsultationAppointment && (
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6 d-flex align-items-center">
                          <input
                            onChange={(e) => setIsKomPackage(e.target.checked)}
                            style={{ marginRight: 5 }}
                            defaultChecked={false}
                            value={isKomPackage}
                            id="komPackage"
                            type="checkbox"
                          />
                          <TooltipWrapper place="bottom" tooltipId="tooltip-kom-package">
                            <label style={{ cursor: "help" }} htmlFor="komPackage">
                              KOM Paket
                            </label>
                          </TooltipWrapper>
                        </div>
                        {isKomPackage && (
                          <div className="col-md-6 input mb-0">
                            <input
                              onChange={(e) => setKomPackageNumber(e.target.value)}
                              type="number"
                              min={1}
                              pattern="[1-9]*"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div className="row">{error.length > 0 && <AlertMessage variant="danger" message={error} />}</div>
              </div>
            </div>
          ) : (
            <div className="text-center">
              <Spinner className="mt-5" animation="border" variant="primary" />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div
            onClick={handleForm}
            className="create-patient d-flex align-items-baseline justify-content-between btn btn-lg mt-3 fw-bolder float-end bg-green"
          >
            <span>{I18n.t("form.create")}</span>
          </div>
        </Modal.Footer>
      </Modal>
    )
  );
};

export default AppointmentModalForm;
