import React, { useState, useRef, useEffect } from "react";
import { useHistory, useParams } from 'react-router-dom';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import { COUNTRY_OPTIONS, LANGUAGE_OPTIONS } from "../../constants/index"
import DepartmentService from "../../services/department"
import Utils from "../../utils";
import TenantService from "../../services/tenant";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import './form.scss'
import DocumentUpload from './_upload'

const DepartmentForm = (props) => {
    let navigate = useHistory();

    const form = useRef();
    const checkBtn = useRef();

    const [error, setError] = useState("");
    const [tenants, setTenants] = useState([]);
    const [tenant, setTenant] = useState({});
    const [departmentId, setDepartmentId] = useState(props.departmentId);
    const [tenantId, setTenantId] = useState(undefined);
    const [companyName, setCompanyName] = useState("");
    const [logo, setLogo] = useState(undefined);
    const [companyShortName, setCompanyShortName] = useState("");
    const [zip, setZip] = useState("");
    const [streetNumber, setStreetNumber] = useState("");
    const [houseNumber, setHouseNumber] = useState("");
    const [place, setPlace] = useState("");
    const [phoneNumberTechnic, setPhoneNumberTechnic] = useState("");
    const [phoneNumberClinic, setPhoneNumberClinic] = useState("");
    const [emailClinic, setEmailClinic] = useState("");
    const [emailTechnic, setEmailTechnic] = useState("");
    const [language, setLanguage] = useState(LANGUAGE_OPTIONS[0]['value']);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [icons, setIcons] = useState([]);
    const [country, setCountry] = useState(COUNTRY_OPTIONS[0]['value']);

    useEffect(() => {
        if (props.departmentId == undefined) {
            TenantService.index().
                then(
                    (response) => {
                        setTenants(response.data.tenants)
                        setTenantId(response.data.tenants[0]?.id)
                    },
                    (error) => { setError(error.meta.message) }
                );
        }
    }, []);

    useEffect(() => {
        if (departmentId) {
            DepartmentService.show(departmentId).
                then(
                    (response) => {
                        const department = response.data.department
                        setTenantId(department.tenant_id)
                        setTenant(department.tenant)
                        setCompanyName(department.company_name)
                        setCompanyShortName(department.short_name)
                        setZip(department.zip)
                        setStreetNumber(department.street_number)
                        setPhoneNumberClinic(Utils.formatPhoneNumber(department.phone_number_clinic))
                        setPhoneNumberTechnic(Utils.formatPhoneNumber(department.phone_number_technic))
                        setEmailTechnic(department.email_technic)
                        setEmailClinic(department.email_clinic)
                        setLanguage(department.language)
                        setPlace(department.place)
                        setHouseNumber(department.house_number)
                        setCountry(department.country)

                        setDocuments(department.department_setting?.documents)
                        setIcons(department.department_setting?.icons)
                    },
                    (error) => { setError(error.meta.message) }
                );
        }

    }, [reload]);

    const onChangeCompanyName = (e) => {
        const name = e.target.value;
        setCompanyName(name);
    };

    const onChangeCompanyShortName = (e) => {
        const short_name = e.target.value;
        setCompanyShortName(short_name);
    };

    const onChangeHouseNumber = (e) => {
        const short_name = e.target.value;
        setHouseNumber(short_name);
    };

    const onChangePlace = (e) => {
        const short_name = e.target.value;
        setPlace(short_name);
    };

    const onChangeLanguage = (e) => {
        const language_val = e.target.value;
        setLanguage(language_val);
    };

    const onChangeLogo = (e) => {
        const logo = e.target.files[0];
        setLogo(logo);
    };

    const onChangeStreetNumber = (e) => {
        const streetNumber = e.target.value;
        setStreetNumber(streetNumber);
    };

    const onChangeZip = (e) => {
        const zip = e.target.value;
        setZip(zip);
    };

    const onChangeTenantId = (e) => {
        const id = e.target.value;
        setTenantId(id);
    };

    const onChangePhoneNumberTechnic = (val) => {
        if (val == undefined) {
            val = ''
        }
        setPhoneNumberTechnic(val)
    };
    const onChangePhoneNumberClinic = (val) => {
        if (val == undefined) {
            val = ''
        }
        setPhoneNumberClinic(val)
    };
    const onChangeEmailClinic = (e) => {
        const email = e.target.value;
        setEmailClinic(email);
    };
    const onChangeEmailTechnic = (e) => {
        const email = e.target.value;
        setEmailTechnic(email);
    };

    const toggleReload = () => {
        setReload(!reload)
    }

    const handleCreate = (e) => {
        e.preventDefault();

        setMessage("");
        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            const formData = new FormData()
            formData.append('tenant_id', tenantId);
            formData.append('company_name', companyName);
            formData.append('short_name', companyShortName);
            formData.append('language', language);
            formData.append('country', country);
            if (logo) {
                formData.append('logo', logo);
            }

            const submitPhoneTech = phoneNumberTechnic.substring(0, 1) == '+' ? phoneNumberTechnic.substring(1, phoneNumberTechnic.length) : phoneNumberTechnic;
            const submitPhoneClinic = phoneNumberClinic.substring(0, 1) == '+' ? phoneNumberClinic.substring(1, phoneNumberClinic.length) : phoneNumberClinic;
            formData.append('phone_number_technic', submitPhoneTech);
            formData.append('phone_number_clinic', submitPhoneClinic);
            formData.append('email_clinic', emailClinic);
            formData.append('email_technic', emailTechnic);
            formData.append('zip', zip);
            formData.append('street_number', streetNumber);
            formData.append('house_number', houseNumber);
            formData.append('place', place);

            if (departmentId) {

                DepartmentService.update(departmentId, formData).then(
                    (res) => {
                        navigate.push({ pathname: "/departments", state: { name: 'actionSuccess', message: res.data.message } });
                    },
                    (error) => {
                        setLoading(false);
                        setMessage(error.meta.message);
                    }
                );
            } else {
                DepartmentService.create(formData).then(
                    (res) => {
                        navigate.push({ pathname: "/departments", state: { name: 'actionSuccess', message: res.data.message } });
                    },
                    (error) => {
                        setLoading(false);
                        setMessage(error.meta.message);
                    }
                );
            }
        } else {
            setLoading(false);
        }
    };

    return (
        <div className="d-flex mt-5 justify-content-center department-wrapper">
            <div style={{ padding: departmentId ? '0' : '0 400px' }} className="department-form">
                <h1 className="text-center">{I18n.t('form.department.name')}</h1>
                <div className="card card-container">
                    <Form onSubmit={handleCreate} ref={form} className="p-3">

                        <div className="form-group">
                            <label htmlFor="tenant">{I18n.t('form.tenant.name')}</label>
                            <Select
                                name="tenant"
                                className="form-control"
                                title="Tenants"
                                value={tenant.id}
                                onChange={onChangeTenantId}
                            >
                                {props.departmentId == undefined && tenants.map(t => <option value={t.id} key={t.id}>{t.company_name}</option>)}
                                {props.departmentId !== undefined && <option value={tenant.id}>{tenant.company_name}</option>}
                            </Select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="company_name">{I18n.t('activerecord.attributes.department.company_name')}</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="company_name"
                                value={companyName}
                                onChange={onChangeCompanyName}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="company_short_name">{I18n.t('activerecord.attributes.department.short_name')}</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="company_short_name"
                                value={companyShortName}
                                onChange={onChangeCompanyShortName}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="street_number">{I18n.t('activerecord.attributes.department.street_number')}</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="street_number"
                                value={streetNumber}
                                onChange={onChangeStreetNumber}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="house_number">{I18n.t('activerecord.attributes.department.house_number')}</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="house_number"
                                value={houseNumber}
                                onChange={onChangeHouseNumber}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="zip">{I18n.t('activerecord.attributes.department.zip')}</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="zip"
                                value={zip}
                                onChange={onChangeZip}
                            />
                        </div>


                        <div className="form-group">
                            <label htmlFor="place">{I18n.t('activerecord.attributes.department.place')}</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="place"
                                value={place}
                                onChange={onChangePlace}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="email_clinic">{I18n.t('activerecord.attributes.department.email_technic')}</label>
                            <Input
                                type="email"
                                className="form-control"
                                name="email_technic"
                                value={emailTechnic}
                                onChange={onChangeEmailTechnic}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="email_clinic">{I18n.t('activerecord.attributes.department.email_clinic')}</label>
                            <Input
                                type="email"
                                className="form-control"
                                name="email_clinic"
                                value={emailClinic}
                                onChange={onChangeEmailClinic}
                            />
                        </div>

                        <div className="">
                            <label htmlFor="phone_number_technic">{I18n.t('activerecord.attributes.department.phone_number_technic')}</label>
                            <PhoneInput
                                className=""
                                defaultCountry="CH"
                                international
                                value={phoneNumberTechnic}
                                onChange={(val) => onChangePhoneNumberTechnic(val)} />
                        </div>

                        <div className="">
                            <label htmlFor="phone_number_clinic">{I18n.t('activerecord.attributes.department.phone_number_clinic')}</label>
                            <PhoneInput
                                className=""
                                defaultCountry="CH"
                                international
                                value={phoneNumberClinic}
                                onChange={(val) => onChangePhoneNumberClinic(val)} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="language">{I18n.t('activerecord.attributes.department.language')}</label>
                            <Select
                                name="lang"
                                className="form-control"
                                title="Language"
                                value={language}
                                onChange={onChangeLanguage}
                            >
                                {LANGUAGE_OPTIONS.map(l => <option key={l.value} value={l.value}>{l.label}</option>)}
                            </Select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="logo">{I18n.t('activerecord.attributes.department.logo')}</label>
                            <Input
                                type="file"
                                className="form-control"
                                name="logo"
                                onChange={onChangeLogo}
                                validations={[]}
                            />
                        </div>

                        <div className="form-group">
                            <button className="btn btn-primary btn-block mt-3" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span>{I18n.t('form.update')}</span>
                            </button>
                            <button onClick={() => navigate.push('/departments')} className="btn btn-secondary mt-3 abort-btn">
                                <span>{I18n.t('form.abort')}</span>
                            </button>
                        </div>

                        {message && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            </div>
                        )}
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                </div>
            </div>
            {departmentId && <div className="department-documents">
                <h1 className="text-center">{I18n.t('form.treatment.document')}</h1>
                <div>
                    <DocumentUpload
                        key="document_documents"
                        serverFiles={documents}
                        path={`/department_settings/upload_document`}
                        reload={() => toggleReload()}
                        accept=".pdf"
                    />
                </div>
            </div>}
            {departmentId && <div className="department-icons">
                <h1 className="text-center">Icon</h1>
                <div>
                    <DocumentUpload
                        key="document_icons"
                        serverFiles={icons}
                        path={`/department_settings/upload_icon`}
                        reload={() => toggleReload()}
                        accept="image/png, image/jpeg"
                    />
                </div>
            </div>}
        </div>
    );
};

export default DepartmentForm;