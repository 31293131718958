import React, { useState } from "react";

const TraffictLightLegend = () => {
    return (
        <div className="legend-wrapper">
            <div className="legend">
                <h5>
                    {I18n.t('traffic_light.legend')}:
                </h5>
                <ul>
                    <li> <div className="li-circle poll-grey"></div><span>{I18n.t('traffic_light.grey')}</span> </li>
                    <li> <div className="li-circle poll-orange"></div><span>{I18n.t('traffic_light.orange')}</span> </li>
                    <li> <div className="li-circle poll-blue"></div><span>{I18n.t('traffic_light.blue')}</span> </li>
                    <li> <div className="li-circle poll-red"></div><span>{I18n.t('traffic_light.red')}</span> </li>
                    <li> <div className="li-circle poll-green"></div><span>{I18n.t('traffic_light.green')}</span> </li>
                </ul>
            </div>

        </div>
    )
}

export default TraffictLightLegend;