import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import toastMessage from "./toast-message";
import authClient from "../utils/auth-common";

const ResetPassword = () => {
  let navigate = useHistory();

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [loading, setLoading] = useState(false);

  const handleForgotPassword = (e) => {
    setLoading(true);

    authClient
      .updatePassword({ password, password_confirmation: passwordConfirm })
      .then((res) => {
        navigate.push("/");
        window.location.reload();
        toastMessage.success(res.data.message);
      })
      .fail((error) => {
        setLoading(false);
        toastMessage.error(error.data?.errors?.full_messages?.[0] || error.data.message || error.reason);
      });
  };

  return (
    <div className="row mt-5">
      <div className="col-md-4"></div>
      <div className="col-md-4 card card-container p-3">
        <h4 className="text-center">{I18n.t("devise.passwords.new.forgot_your_password")}</h4>
        <div className="form-group">
          <label htmlFor="email">{I18n.t("devise.passwords.edit.new_password")}</label>
          <input
            type="password"
            className="form-control"
            name="email"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">{I18n.t("devise.passwords.edit.confirm_new_password")}</label>
          <input
            type="password"
            className="form-control"
            name="email"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />
        </div>

        <div className="form-group">
          <button onClick={handleForgotPassword} className="btn btn-primary mt-3" disabled={loading}>
            {loading && <span className="spinner-border spinner-border-sm"></span>}
            <span>{I18n.t("form.update")}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
