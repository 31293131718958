import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import './create.scss'
import PatientForm from "./_form";

const PatientUpdate = (props) => {

    const { id } = useParams();
    const [patientId, _] = useState(id);

    return (
        <PatientForm
            {...props}
            title={I18n.t('form.patient.update')}
            patientId={patientId}
        />
    );
};

export default PatientUpdate;
