import React, { useState } from "react";
import Alert from 'react-bootstrap/Alert';

function AlertMessage(props) {
    const [show, setShow] = useState(true);

    return (
        show && <Alert variant={props.variant} onClose={() => setShow(false)} dismissible>
            {props.message}
        </Alert>
    );
}

export default AlertMessage;