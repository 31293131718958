import React from "react";
import { useHistory } from "react-router-dom";
import Patient from "images/Patient";
import Dashboard from "images/Dashboard.png";
import PatientSearch from "../../screens/patient/patient-search";
import AuthService from "../../services/auth";

const MpaLayout = ({ hide }) => {
  let navigate = useHistory();

  return (
    !hide &&
    AuthService.isLoggedIn() && (
      <div className="navigation-icon">
        <div className="icon">
          <img src={Patient} width="50" height="50" onClick={() => navigate.push("/patients")} />
          <img src={Dashboard} width="45" height="45" onClick={() => navigate.push("/dashboard")} />
        </div>
      </div>
    )
  );
};

export default MpaLayout;
