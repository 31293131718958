import React, { useState, useEffect } from "react";
import AuthService from '../services/auth'
import LoginForm from '../components/login'
import { useHistory } from "react-router-dom";

export default (props) => {
    if (!AuthService.isLoggedIn()) {
        return (
            <LoginForm />
        )
    } else {
        let navigate = useHistory();
        navigate.push("/patients");
    }
}
