import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import AlertMessage from "../../components/alert-message";
import { Link, useLocation } from "react-router-dom";
import Pagination from "../../components/pagination";
import PatientLanguageService from "../../services/patient_language";

export default (props) => {
  const [message, setMessage] = useState({});
  const [languages, setLanguages] = useState([]);
  const [reload, setReload] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [meta, setPaginations] = useState({});
  const location = useLocation();

  useEffect(() => {
    if (location.state?.name == "actionSuccess") {
      setMessage({ content: location.state.message, variant: "success" });
    }
  }, [location]);

  useEffect(() => {
    PatientLanguageService.index(currentPage).then(
      (response) => {
        setLanguages(response.data.patient_languages);
        setPaginations(response.data.meta);
      },
      (error) => {
        setMessage({ content: error.response.data.message, variant: "danger" });
      },
    );
  }, [props.tenantId, props.departmentId, props.pathwayId, reload, currentPage]);

  const handleDelete = async (id) => {
    if (window.confirm(I18n.t("form.patient_language.confirm_delete"))) {
      const response = await PatientLanguageService.destroy(id);
      setReload(!reload);
      setMessage({ content: response.data.message, variant: "success" });
    }
  };

  const onChangePage = (page) => {
    if (page != currentPage) {
      setCurrentPage(page);
    }
  };

  const toggleActive = async (id) => {
    const response = await PatientLanguageService.toggleActive(id);
    setReload(!reload);
    setMessage({ content: response.data.message, variant: "success" });
  };

  return (
    <div className="card container p-5 mt-5">
      <h1 className="text-center">{I18n.t("activerecord.models.patient_language")}</h1>
      {Object.keys(message).length > 0 && <AlertMessage variant={message.variant} message={message.content} />}
      <div>
        <Link className="btn btn-primary mb-3" to="/patient_language_create">
          {I18n.t("form.patient_language.new_patient_language")}
        </Link>

        <Table bordered hover responsive className="text-center">
          <thead>
            <tr>
              <th>#</th>
              <th>{I18n.t("activerecord.attributes.patient.active")}</th>
              <th>{I18n.t("activerecord.attributes.patient_language.name")}</th>
              <th>{I18n.t("activerecord.attributes.patient_language.short_name")}</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {languages.map((t) => {
              return (
                <tr key={t.id}>
                  <td>{t.id}</td>
                  <td>
                    <input
                      onChange={() => toggleActive(t.id)}
                      className="form-check-input"
                      value={t.active}
                      type="checkbox"
                      id="activeCheck"
                      defaultChecked={t.active}
                    />
                  </td>
                  <td>{t.name}</td>
                  <td>{t.short_name}</td>
                  <td>
                    <Link to={`/patient_languages/${t.id}/edit`}>Edit</Link>
                    <br></br>
                    <a
                      href="#!"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDelete(t.id);
                      }}
                    >
                      {" "}
                      Delete
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>

        <div className="mt-3">
          <Pagination meta={meta} onChangePage={onChangePage} />
        </div>
      </div>
    </div>
  );
};
