import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AlertMessage from "../../components/alert-message";
import SpecialistService from "../../services/specialist";
import toastMessage from "../../components/toast-message";
import SurveyService from "../../services/survey";

const SurveyForm = (props) => {
  let navigate = useHistory();

  const form = useRef();
  const checkBtn = useRef();

  const [error, setError] = useState("");
  const [titleId, _] = useState(props.titleId);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (titleId) {
      SurveyService.show(titleId).then(
        (response) => {
          const data = response.data.survey;
          setName(data.title);
          setDescription(data.description);
        },
        (error) => {
          setError(error.meta.message);
        },
      );
    }
  }, []);

  const handleCreate = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      const formData = new FormData();
      formData.append("title", name);
      formData.append("description", description);
      if (titleId) {
        SurveyService.update(titleId, formData).then(
          (res) => {
            toastMessage.success(res.data.message);
            navigate.push({ pathname: "/surveys" });
          },
          (error) => {
            setLoading(false);
            toastMessage.error(error.meta.message);
          },
        );
      } else {
        SurveyService.createAdmin(formData).then(
          (res) => {
            toastMessage.success(res.data.message);
            navigate.push({ pathname: "/surveys" });
          },
          (error) => {
            setLoading(false);
            console.log(error);
            toastMessage.error(error.message);
          },
        );
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="mt-5">
      <div className="d-flex flex-column align-items-center justify-content-center">
        <h1>Web Formulare</h1>
        {error.length > 0 && <AlertMessage variant="danger" message={error} />}
        <div className="card card-container w-50">
          <Form onSubmit={handleCreate} ref={form} className="p-3">
            <div className="form-group">
              <label htmlFor="name">Web Formula</label>
              <Input
                type="text"
                className="form-control"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                validations={[]}
              />
            </div>

            <div className="form-group">
              <label htmlFor="name">Description</label>
              <Input
                type="text"
                className="form-control"
                name="name"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                validations={[]}
              />
            </div>

            <div className="form-group">
              <button className="btn btn-primary btn-block mt-3" disabled={loading}>
                {loading && <span className="spinner-border spinner-border-sm"></span>}
                <span>{I18n.t("form.update")}</span>
              </button>

              <button onClick={() => navigate.push("/surveys")} className="btn btn-secondary mt-3 abort-btn">
                <span>{I18n.t("form.abort")}</span>
              </button>
            </div>

            {message && (
              <div className="form-group mt-3">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default SurveyForm;
