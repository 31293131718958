export const LANGUAGE_OPTIONS = [
    { value: "de", label: "Deutsch" },
    { value: "en", label: "Englisch" },
    { value: "fr", label: "Französisch" },
    { value: "it", label: "Italienisch" },
]

export const COUNTRY_OPTIONS = [
    { value: "de", label: "Deutsch" },
    { value: "en", label: "Englisch" },
    { value: "fr", label: "Französisch" },
    { value: "it", label: "Italienisch" },
]

export const ROLE_OPTIONS = [
    { value: "mpa", label: "MPA" },
    { value: "admin", label: "Admin" },
]

export const countryListAllIsoData = [
    ['Serbian', 'српски језик', 'sr', 'srp', 'srp'],
    ['Romanian, Moldavian, Moldovan', 'Română', 'ro', 'ron', 'rum'],
    ['Sichuan Yi, Nuosu', 'ꆈꌠ꒿ Nuosuhxop', 'ii', 'iii', 'iii'],
    ['Tahitian', 'Reo Tahiti', 'ty', 'tah', 'tah'],
    ['Tagalog', 'Wikang Tagalog', 'tl', 'tgl', 'tgl'],
    ['Yiddish', 'ייִדיש', 'yi', 'yid', 'yid'],
    ['Akan', 'Akan', 'ak', 'aka', 'aka'],
    ['Malay', 'Bahasa Melayu, بهاس ملايو‎', 'ms', 'msa', 'may'],
    ['Arabic', 'العربية', 'ar', 'ara', 'ara'],
    ['Norwegian', 'Norsk', 'no', 'nor', 'nor'],
    ['Ojibwa', 'ᐊᓂᔑᓈᐯᒧᐎᓐ', 'oj', 'oji', 'oji'],
    ['Fulah', 'Fulfulde, Pulaar, Pular', 'ff', 'ful', 'ful'],
    ['Persian', 'فارسی', 'fa', 'fas', 'per'],
    ['Albanian', 'Shqip', 'sq', 'sqi', 'alb'],
    ['Aymara', 'aymar aru', 'ay', 'aym', 'aym'],
    ['Azerbaijani', 'azərbaycan dili', 'az', 'aze', 'aze'],
    ['Chinese', '中文 (Zhōngwén), 汉语, 漢語', 'zh', 'zho', 'chi'],
    ['Cree', 'ᓀᐦᐃᔭᐍᐏᐣ', 'cr', 'cre', 'cre'],
    ['Estonian', 'eesti, eesti keel', 'et', 'est', 'est'],
    ['Guaraní', 'Avañe\'ẽ', 'gn', 'grn', 'grn'],
    ['Inupiaq', 'Iñupiaq, Iñupiatun', 'ik', 'ipk', 'ipk'],
    ['Inuktitut', 'ᐃᓄᒃᑎᑐᑦ', 'iu', 'iku', 'iku'],
    ['Kanuri', 'Kanuri', 'kr', 'kau', 'kau'],
    ['Komi', 'коми кыв', 'kv', 'kom', 'kom'],
    ['Kongo', 'Kikongo', 'kg', 'kon', 'kon'],
    ['Kurdish', 'Kurdî, کوردی‎', 'ku', 'kur', 'kur'],
    ['Latvian', 'latviešu valoda', 'lv', 'lav', 'lav'],
    ['Malagasy', 'fiteny malagasy', 'mg', 'mlg', 'mlg'],
    ['Mongolian', 'Монгол хэл', 'mn', 'mon', 'mon'],
    ['Oromo', 'Afaan Oromoo', 'om', 'orm', 'orm'],
    ['Pashto, Pushto', 'پښتو', 'ps', 'pus', 'pus'],
    ['Quechua', 'Runa Simi, Kichwa', 'qu', 'que', 'que'],
    ['Sardinian', 'sardu', 'sc', 'srd', 'srd'],
    ['Swahili', 'Kiswahili', 'sw', 'swa', 'swa'],
    ['Uzbek', 'Oʻzbek, Ўзбек, أۇزبېك‎', 'uz', 'uzb', 'uzb'],
    ['Zhuang, Chuang', 'Saɯ cueŋƅ, Saw cuengh', 'za', 'zha', 'zha'],
    ['Bislama', 'Bislama', 'bi', 'bis', 'bis'],
    ['Norwegian Bokmål', 'Norsk Bokmål', 'nb', 'nob', 'nob'],
    ['Norwegian Nynorsk', 'Norsk Nynorsk', 'nn', 'nno', 'nno'],
    ['Indonesian', 'Bahasa Indonesia', 'id', 'ind', 'ind'],
    ['Twi', 'Twi', 'tw', 'twi', 'twi'],
    ['Esperanto', 'Esperanto', 'eo', 'epo', 'epo'],
    ['Interlingua', 'Interlingua', 'ia', 'ina', 'ina'],
    ['Interlingue', 'Originally called Occidental; then Interlingue after WWII', 'ie', 'ile', 'ile'],
    ['Ido', 'Ido', 'io', 'ido', 'ido'],
    ['Volapük', 'Volapük', 'vo', 'vol', 'vol'],
    ['Bihari languages', 'भोजपुरी', 'bh', 'bih', 'bih'],
    ['Hebrew (modern)', 'עברית', 'he', 'heb', 'heb'],
    ['Sanskrit', 'संस्कृतम्', 'sa', 'san', 'san'],
    ['Church Slavic, Church Slavonic, Old Church Slavonic, Old Slavonic, Old Bulgarian', 'ѩзыкъ словѣньскъ', 'cu', 'chu', 'chu'],
    ['Pali', 'पाऴि', 'pi', 'pli', 'pli'],
    ['Avestan', 'avesta', 'ae', 'ave', 'ave'],
    ['Latin', 'latine, lingua latina', 'la', 'lat', 'lat'],
    ['Armenian', 'Հայերեն', 'hy', 'hye', 'arm'],
    ['Swati', 'SiSwati', 'ss', 'ssw', 'ssw'],
    ['Tibetan', 'བོད་ཡིག', 'bo', 'bod', 'tib'],
    ['South Ndebele', 'isiNdebele', 'nr', 'nbl', 'nbl'],
    ['Slovene', 'Slovenski Jezik, Slovenščina', 'sl', 'slv', 'slv'],
    ['Oriya', 'ଓଡ଼ିଆ', 'or', 'ori', 'ori'],
    ['North Ndebele', 'isiNdebele', 'nd', 'nde', 'nde'],
    ['Nauru', 'Dorerin Naoero', 'na', 'nau', 'nau'],
    ['Maori', 'te reo Māori', 'mi', 'mri', 'mao'],
    ['Marathi', 'मराठी', 'mr', 'mar', 'mar'],
    ['Luba-Katanga', 'Kiluba', 'lu', 'lub', 'lub'],
    ['Rundi', 'Ikirundi', 'rn', 'run', 'run'],
    ['Central Khmer', 'ខ្មែរ, ខេមរភាសា, ភាសាខ្មែរ', 'km', 'khm', 'khm'],
    ['Western Frisian', 'Frysk', 'fy', 'fry', 'fry'],
    ['Bengali', 'বাংলা', 'bn', 'ben', 'ben'],
    ['Avaric', 'авар мацӀ, магӀарул мацӀ', 'av', 'ava', 'ava'],
    ['Abkhazian', 'аҧсуа бызшәа, аҧсшәа', 'ab', 'abk', 'abk'],
    ['Afar', 'Afaraf', 'aa', 'aar', 'aar'],
    ['Afrikaans', 'Afrikaans', 'af', 'afr', 'afr'],
    ['Amharic', 'አማርኛ', 'am', 'amh', 'amh'],
    ['Aragonese', 'aragonés', 'an', 'arg', 'arg'],
    ['Assamese', 'অসমীয়া', 'as', 'asm', 'asm'],
    ['Bambara', 'bamanankan', 'bm', 'bam', 'bam'],
    ['Bashkir', 'башҡорт теле', 'ba', 'bak', 'bak'],
    ['Basque', 'euskara, euskera', 'eu', 'eus', 'baq'],
    ['Belarusian', 'беларуская мова', 'be', 'bel', 'bel'],
    ['Bosnian', 'bosanski jezik', 'bs', 'bos', 'bos'],
    ['Breton', 'brezhoneg', 'br', 'bre', 'bre'],
    ['Bulgarian', 'български език', 'bg', 'bul', 'bul'],
    ['Burmese', 'ဗမာစာ', 'my', 'mya', 'bur'],
    ['Catalan, Valencian', 'català, valencià', 'ca', 'cat', 'cat'],
    ['Chamorro', 'Chamoru', 'ch', 'cha', 'cha'],
    ['Chechen', 'нохчийн мотт', 'ce', 'che', 'che'],
    ['Chichewa, Chewa, Nyanja', 'chiCheŵa, chinyanja', 'ny', 'nya', 'nya'],
    ['Chuvash', 'чӑваш чӗлхи', 'cv', 'chv', 'chv'],
    ['Cornish', 'Kernewek', 'kw', 'cor', 'cor'],
    ['Corsican', 'corsu, lingua corsa', 'co', 'cos', 'cos'],
    ['Croatian', 'hrvatski jezik', 'hr', 'hrv', 'hrv'],
    ['Czech', 'čeština, český jazyk', 'cs', 'ces', 'cze'],
    ['Danish', 'dansk', 'da', 'dan', 'dan'],
    ['Divehi, Dhivehi, Maldivian', 'ދިވެހި', 'dv', 'div', 'div'],
    ['Dutch, Flemish', 'Nederlands, Vlaams', 'nl', 'nld', 'dut'],
    ['Dzongkha', 'རྫོང་ཁ', 'dz', 'dzo', 'dzo'],
    ['English', 'English', 'en', 'eng', 'eng'],
    ['Ewe', 'Eʋegbe', 'ee', 'ewe', 'ewe'],
    ['Faroese', 'føroyskt', 'fo', 'fao', 'fao'],
    ['Fijian', 'vosa Vakaviti', 'fj', 'fij', 'fij'],
    ['Finnish', 'suomi, suomen kieli', 'fi', 'fin', 'fin'],
    ['French', 'français, langue française', 'fr', 'fra', 'fre'],
    ['Galician', 'Galego', 'gl', 'glg', 'glg'],
    ['Georgian', 'ქართული', 'ka', 'kat', 'geo'],
    ['German', 'Deutsch', 'de', 'deu', 'ger'],
    ['Greek (modern)', 'ελληνικά', 'el', 'ell', 'gre'],
    ['Gujarati', 'ગુજરાતી', 'gu', 'guj', 'guj'],
    ['Haitian, Haitian Creole', 'Kreyòl ayisyen', 'ht', 'hat', 'hat'],
    ['Hausa', '(Hausa) هَوُسَ', 'ha', 'hau', 'hau'],
    ['Herero', 'Otjiherero', 'hz', 'her', 'her'],
    ['Hindi', 'हिन्दी, हिंदी', 'hi', 'hin', 'hin'],
    ['Hiri Motu', 'Hiri Motu', 'ho', 'hmo', 'hmo'],
    ['Hungarian', 'magyar', 'hu', 'hun', 'hun'],
    ['Irish', 'Gaeilge', 'ga', 'gle', 'gle'],
    ['Igbo', 'Asụsụ Igbo', 'ig', 'ibo', 'ibo'],
    ['Icelandic', 'Íslenska', 'is', 'isl', 'ice'],
    ['Italian', 'Italiano', 'it', 'ita', 'ita'],
    ['Japanese', '日本語 (にほんご)', 'ja', 'jpn', 'jpn'],
    ['Javanese', 'ꦧꦱꦗꦮ, Basa Jawa', 'jv', 'jav', 'jav'],
    ['Kalaallisut, Greenlandic', 'kalaallisut, kalaallit oqaasii', 'kl', 'kal', 'kal'],
    ['Kannada', 'ಕನ್ನಡ', 'kn', 'kan', 'kan'],
    ['Kashmiri', 'कश्मीरी, كشميري‎', 'ks', 'kas', 'kas'],
    ['Kazakh', 'қазақ тілі', 'kk', 'kaz', 'kaz'],
    ['Kikuyu, Gikuyu', 'Gĩkũyũ', 'ki', 'kik', 'kik'],
    ['Kinyarwanda', 'Ikinyarwanda', 'rw', 'kin', 'kin'],
    ['Kirghiz, Kyrgyz', 'Кыргызча, Кыргыз тили', 'ky', 'kir', 'kir'],
    ['Korean', '한국어', 'ko', 'kor', 'kor'],
    ['Kuanyama, Kwanyama', 'Kuanyama', 'kj', 'kua', 'kua'],
    ['Luxembourgish, Letzeburgesch', 'Lëtzebuergesch', 'lb', 'ltz', 'ltz'],
    ['Ganda', 'Luganda', 'lg', 'lug', 'lug'],
    ['Limburgan, Limburger, Limburgish', 'Limburgs', 'li', 'lim', 'lim'],
    ['Lingala', 'Lingála', 'ln', 'lin', 'lin'],
    ['Lao', 'ພາສາລາວ', 'lo', 'lao', 'lao'],
    ['Lithuanian', 'lietuvių kalba', 'lt', 'lit', 'lit'],
    ['Manx', 'Gaelg, Gailck', 'gv', 'glv', 'glv'],
    ['Macedonian', 'македонски јазик', 'mk', 'mkd', 'mac'],
    ['Malayalam', 'മലയാളം', 'ml', 'mal', 'mal'],
    ['Maltese', 'Malti', 'mt', 'mlt', 'mlt'],
    ['Marshallese', 'Kajin M̧ajeļ', 'mh', 'mah', 'mah'],
    ['Navajo, Navaho', 'Diné bizaad', 'nv', 'nav', 'nav'],
    ['Nepali', 'नेपाली', 'ne', 'nep', 'nep'],
    ['Ndonga', 'Owambo', 'ng', 'ndo', 'ndo'],
    ['Occitan', 'occitan, lenga d\'òc', 'oc', 'oci', 'oci'],
    ['Ossetian, Ossetic', 'ирон æвзаг', 'os', 'oss', 'oss'],
    ['Panjabi, Punjabi', 'ਪੰਜਾਬੀ', 'pa', 'pan', 'pan'],
    ['Polish', 'język polski, polszczyzna', 'pl', 'pol', 'pol'],
    ['Portuguese', 'Português', 'pt', 'por', 'por'],
    ['Romansh', 'Rumantsch Grischun', 'rm', 'roh', 'roh'],
    ['Russian', 'русский', 'ru', 'rus', 'rus'],
    ['Sindhi', 'सिन्धी, سنڌي، سندھی‎', 'sd', 'snd', 'snd'],
    ['Northern Sami', 'Davvisámegiella', 'se', 'sme', 'sme'],
    ['Samoan', 'gagana fa\'a Samoa', 'sm', 'smo', 'smo'],
    ['Sango', 'yângâ tî sängö', 'sg', 'sag', 'sag'],
    ['Gaelic, Scottish Gaelic', 'Gàidhlig', 'gd', 'gla', 'gla'],
    ['Shona', 'chiShona', 'sn', 'sna', 'sna'],
    ['Sinhala, Sinhalese', 'සිංහල', 'si', 'sin', 'sin'],
    ['Slovak', 'Slovenčina, Slovenský Jazyk', 'sk', 'slk', 'slo'],
    ['Somali', 'Soomaaliga, af Soomaali', 'so', 'som', 'som'],
    ['Southern Sotho', 'Sesotho', 'st', 'sot', 'sot'],
    ['Spanish, Castilian', 'Español', 'es', 'spa', 'spa'],
    ['Sundanese', 'Basa Sunda', 'su', 'sun', 'sun'],
    ['Swedish', 'Svenska', 'sv', 'swe', 'swe'],
    ['Tamil', 'தமிழ்', 'ta', 'tam', 'tam'],
    ['Telugu', 'తెలుగు', 'te', 'tel', 'tel'],
    ['Tajik', 'тоҷикӣ, toçikī, تاجیکی‎', 'tg', 'tgk', 'tgk'],
    ['Thai', 'ไทย', 'th', 'tha', 'tha'],
    ['Tigrinya', 'ትግርኛ', 'ti', 'tir', 'tir'],
    ['Turkmen', 'Türkmen, Түркмен', 'tk', 'tuk', 'tuk'],
    ['Tswana', 'Setswana', 'tn', 'tsn', 'tsn'],
    ['Tongan (Tonga Islands)', 'Faka Tonga', 'to', 'ton', 'ton'],
    ['Turkish', 'Türkçe', 'tr', 'tur', 'tur'],
    ['Tsonga', 'Xitsonga', 'ts', 'tso', 'tso'],
    ['Tatar', 'татар теле, tatar tele', 'tt', 'tat', 'tat'],
    ['Uighur, Uyghur', 'ئۇيغۇرچە‎, Uyghurche', 'ug', 'uig', 'uig'],
    ['Ukrainian', 'Українська', 'uk', 'ukr', 'ukr'],
    ['Urdu', 'اردو', 'ur', 'urd', 'urd'],
    ['Venda', 'Tshivenḓa', 've', 'ven', 'ven'],
    ['Vietnamese', 'Tiếng Việt', 'vi', 'vie', 'vie'],
    ['Walloon', 'Walon', 'wa', 'wln', 'wln'],
    ['Welsh', 'Cymraeg', 'cy', 'cym', 'wel'],
    ['Wolof', 'Wollof', 'wo', 'wol', 'wol'],
    ['Xhosa', 'isiXhosa', 'xh', 'xho', 'xho'],
    ['Yoruba', 'Yorùbá', 'yo', 'yor', 'yor'],
    ['Zulu', 'isiZulu', 'zu', 'zul', 'zul']
];