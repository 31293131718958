import httpClient from '../utils/http-common';

const API_URL = '/treatment_pathways';

const index = (isMaster) => {
  if (isMaster == undefined) {
    isMaster = false
  }
  return httpClient.get(API_URL + `/?is_master=${isMaster}`);
};

const show = (id) => {
  return httpClient.get(API_URL + `/${id}`);
};

const create = (data) => {
  return httpClient.post(API_URL, data);
};

const update = (id, data) => {
  return httpClient.put(API_URL + `/${id}`, data);
};

const destroy = (id) => {
  return httpClient.delete(`${API_URL}/${id}`);
};

const TreatmentService = {
  index,
  create,
  show,
  update,
  destroy,
};

export default TreatmentService;
