import httpClient from '../utils/http-common';

const API_URL = '/appointment_notifications';

const index = (page) => {
  if (page == undefined) {
    page = 1
  }
  return httpClient.get(`${API_URL}?page=${page}`);
};

const create = (data) => {
  return httpClient.post(API_URL, data);
};

const createBatch = (data) => {
  return httpClient.post(`${API_URL}/batch`, data);
};

const show = (token) => {
  return httpClient.get(`/appointments?${token}`);
};

const update = (token, data) => {
  const customHttp = httpClient;
  customHttp.defaults.baseURL = process.env.APP_URL
  return customHttp.put(`/appointments?token=${token}`, data);
};

const destroy = (id) => {
  return httpClient.delete(`${API_URL}/${id}`);
};

const AppointmentNotificationService = {
  index,
  show,
  create,
  update,
  destroy,
  createBatch,
};

export default AppointmentNotificationService;
