import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import AcademicTitleForm from "./_form";

const SpecialistUpdate = (props) => {
    const { id } = useParams()
    const [titleId, setTitleId] = useState(id);
    return (
        <AcademicTitleForm
            {...props}
            titleId={titleId}
            title={I18n.t('form.academic_title.new_academic_title')}
            submitLabel={I18n.t('form.update')}
        />
    );
};

export default SpecialistUpdate;