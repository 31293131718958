import React, { useState, useEffect } from "react";
import { UserOutlined, PoweroffOutlined, SettingOutlined } from "@ant-design/icons";
import { Dropdown } from "react-bootstrap";
import AuthService from "../../services/auth";
import { useHistory } from "react-router-dom";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    style={{ color: "#4B4583" }}
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <span className="small">{AuthService.getCurrentUser().email}</span>
    <UserOutlined />
    {children}
  </a>
));

export default () => {
  let navigate = useHistory();

  const [currentUser, setCurrentUser] = useState(undefined);
  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      setCurrentUser(AuthService.getCurrentUser());
    }
  }, []);

  const handleLogout = (e) => {
    AuthService.logout();
    setCurrentUser(null);
    navigate.push("/");
    window.location.reload();
  };
  const goToSetting = (e) => {
    navigate.push("/user-setting");
  };

  return (
    currentUser && (
      <Dropdown className="dropdown-user-profile">
        <Dropdown.Toggle as={CustomToggle} />
        <Dropdown.Menu>
          <Dropdown.Item onClick={goToSetting} eventKey="1">
            <div className="d-flex align-items-center justify-content-between w-100">
              <span>Setting</span>
              <SettingOutlined />
            </div>
          </Dropdown.Item>
          <Dropdown.Item onClick={handleLogout} eventKey="1">
            <div className="d-flex align-items-center justify-content-between w-100">
              <span>{I18n.t("devise.sessions.log_out")}</span>
              <PoweroffOutlined />
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  );
};
