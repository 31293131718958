import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import toastMessage from "../../components/toast-message";
import SettingService from "../../services/setting";
import Spinner from "react-bootstrap/Spinner";

const Setting = (props) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    SettingService.index().then(
      (response) => {
        setData(response.data.data);
        setLoading(false);
      },
      (error) => {
        toastMessage.error(error);
        setLoading(false);
      },
    );
  }, []);

  const onChange = (id, value) => {
    const _data = { ...data };
    if (value == false || value == true) {
      // Checkbox
      _data[id] = value;
    } else {
      _data[id] = parseInt(value);
    }
    setData(_data);
  };

  const handleSubmit = () => {
    setLoading(true);
    SettingService.create(data).then(
      (res) => {
        setLoading(false);
        setData(res.data.data);
        toastMessage.success(res.data.message);
      },
      (error) => {
        toastMessage.error(error.meta.message);
      },
    );
  };

  return (
    <div className="container mb-5">
      <div className="row mt-5">
        <h1 className="text-center mb-5">Reminder Setting</h1>
        {loading && (
          <div className="text-center">
            <Spinner className="mt-5" animation="border" variant="primary" />
          </div>
        )}
        <div className="col"></div>
        <div className="col-10">
          <Form>
            <Form.Group className="mb-3" controlId="r56_email">
              <div className="row">
                <div className="col-8">
                  <Form.Label>{I18n.t("setting.r56_email")}</Form.Label>
                </div>
                <div className="col">
                  <Form.Control
                    onChange={(e) => onChange("r56_email", e.target.value)}
                    type="number"
                    value={data["r56_email"]}
                  />
                </div>
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="r56_sms">
              <div className="row">
                <div className="col-8">
                  <Form.Label>{I18n.t("setting.r56_sms")}</Form.Label>
                </div>
                <div className="col">
                  <Form.Control
                    onChange={(e) => onChange("r56_sms", e.target.value)}
                    type="number"
                    value={data["r56_sms"]}
                  />
                </div>
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="r50_email">
              <div className="row">
                <div className="col-8">
                  <Form.Label>{I18n.t("setting.r50_email")}</Form.Label>
                </div>
                <div className="col">
                  <Form.Control
                    onChange={(e) => onChange("r50_email", e.target.value)}
                    type="number"
                    value={data["r50_email"]}
                  />
                </div>
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="r51_email">
              <div className="row">
                <div className="col-8">
                  <Form.Label>{I18n.t("setting.r51_email")}</Form.Label>
                </div>
                <div className="col">
                  <Form.Control
                    onChange={(e) => onChange("r51_email", e.target.value)}
                    type="number"
                    value={data["r51_email"]}
                  />
                </div>
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="r52_email">
              <div className="row">
                <div className="col-8">
                  <Form.Label>{I18n.t("setting.r52_email")}</Form.Label>
                </div>
                <div className="col">
                  <Form.Control
                    onChange={(e) => onChange("r52_email", e.target.value)}
                    type="number"
                    value={data["r52_email"]}
                  />
                </div>
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="r53_email">
              <div className="row">
                <div className="col-8">
                  <Form.Label>{I18n.t("setting.r53_email")}</Form.Label>
                </div>
                <div className="col">
                  <Form.Control
                    onChange={(e) => onChange("r53_email", e.target.value)}
                    type="number"
                    value={data["r53_email"]}
                  />
                </div>
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="r54_email">
              <div className="row">
                <div className="col-8">
                  <Form.Label>{I18n.t("setting.r54_email")}</Form.Label>
                </div>
                <div className="col">
                  <Form.Control
                    onChange={(e) => onChange("r54_email", e.target.value)}
                    type="number"
                    value={data["r54_email"]}
                  />
                </div>
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="r55_email">
              <div className="row">
                <div className="col-8">
                  <Form.Label>{I18n.t("setting.r55_email")}</Form.Label>
                </div>
                <div className="col">
                  <Form.Control
                    onChange={(e) => onChange("r55_email", e.target.value)}
                    type="number"
                    value={data["r55_email"]}
                  />
                </div>
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="r57_email">
              <div className="row">
                <div className="col-8">
                  <Form.Label>{I18n.t("setting.r57_email")}</Form.Label>
                </div>
                <div className="col">
                  <Form.Control
                    onChange={(e) => onChange("r57_email", e.target.value)}
                    type="number"
                    value={data["r57_email"]}
                  />
                </div>
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="r58_email">
              <div className="row">
                <div className="col-8">
                  <Form.Label>{I18n.t("setting.r58_email")}</Form.Label>
                </div>
                <div className="col">
                  <Form.Control
                    onChange={(e) => onChange("r58_email", e.target.value)}
                    type="number"
                    value={data["r58_email"]}
                  />
                </div>
              </div>
            </Form.Group>
          </Form>
        </div>
        <div className="col"></div>
      </div>
      <div className="row mt-5">
        <h1 className="text-center mb-5">PROM's Setting</h1>
        {loading && (
          <div className="text-center">
            <Spinner className="mt-5" animation="border" variant="primary" />
          </div>
        )}
        <div className="col"></div>
        <div className="col-10">
          <Form>
            <Form.Group className="mb-3" controlId="r50_before_email">
              <div className="row">
                <div className="col-8">
                  <Form.Label>{I18n.t("setting.r50_before_email")}</Form.Label>
                </div>
                <div className="col">
                  <Form.Control
                    onChange={(e) => onChange("r50_before_email", e.target.value)}
                    type="number"
                    value={data["r50_before_email"]}
                  />
                </div>
              </div>
            </Form.Group>
          </Form>
        </div>
        <div className="col"></div>
      </div>
      <div className="row mt-5">
        <h1 className="text-center mb-5">Admin Setting</h1>
        {loading && (
          <div className="text-center">
            <Spinner className="mt-5" animation="border" variant="primary" />
          </div>
        )}
        <div className="col"></div>
        <div className="col-10">
          <Form>
            <Form.Group className="mb-3" controlId="r56_email">
              <div className="row">
                <div className="col-8">
                  <Form.Label>{I18n.t("setting.pathway_setting_enable")}</Form.Label>
                </div>
                <div className="col">
                  <Form.Check
                    onChange={(e) => onChange("enable_pathway_setting", e.target.checked)}
                    type="checkbox"
                    defaultChecked={data["enable_pathway_setting"]}
                    value={data["enable_pathway_setting"]}
                  />
                </div>
              </div>
            </Form.Group>
          </Form>
        </div>
        <div className="col"></div>
        <div className="text-center mt-5">
          <hr></hr>
          <Button onClick={handleSubmit} className="text-center mb-5" size="lg" variant="primary" type="button">
            {I18n.t("form.update")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Setting;
