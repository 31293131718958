import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import TreatmentPathwayService from "../../services/treatment-pathway";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import TreatmentService from "../../services/treatment";

const LastTreatmentModal = ({ setLastTreatmentId, ...props }) => {
  const [labels, setLabels] = useState([]);
  const [selectedLabel, setSeletedLabel] = useState(undefined);
  const [selectedTreatmentId, setSelectedTreatmentId] = useState(undefined);
  const [allTreatmentPathway, setAllTreatmentPathways] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    if (props.show) {
      TreatmentPathwayService.index().then(
        (response) => {
          const labels = response.data.treatment_pathways.map((t) => {
            return {
              id: t.id,
              name: t.name_de,
            };
          });
          setAllTreatmentPathways(response.data.treatment_pathways);
          setLabels(labels);
          setSeletedLabel(labels[0]?.id);
          setloading(false);
        },
        (error) => {
          setloading(false);
          setMessage({ content: error.meta.message, variant: "danger" });
        },
      );
    }
  }, [props.show]);

  useEffect(() => {
    if (props.show && selectedLabel) {
      TreatmentService.index(selectedLabel).then(
        (response) => {
          setTreatments(response.data.treatments);
          setloading(false);
        },
        (error) => {
          setloading(false);
          setMessage({ content: error.meta.message, variant: "danger" });
        },
      );
    }
  }, [selectedLabel]);

  const onChangeLabel = (id) => {
    setSeletedLabel(id);
  };

  const onSelectTreatment = (id) => {
    setSelectedTreatmentId(id);

    setLastTreatmentId(id);
    props.onHide();
  };

  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton className="modal-header">
        <Modal.Title id="contained-modal-title-vcenter">{I18n.t("form.treatment_pathway.treatment")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          <>
            <div className="treatment-pathway-selection text-center mb-3">
              {labels.map((l, index) => (
                <span key={l.id}>
                  <Button
                    active={l.id == selectedLabel}
                    onClick={() => onChangeLabel(l.id)}
                    variant={l.id == selectedLabel ? "primary" : "outline-primary"}
                  >
                    {l.name}
                  </Button>{" "}
                </span>
              ))}
            </div>
            <p className="text-center">{I18n.t("form.treatment_pathway.select_first_treatment")}</p>
            {!loading && (
              <div className="treatments mt-3 d-grid gap-3">
                {treatments.map((t) => {
                  return (
                    <Button
                      size="sm"
                      key={t.id}
                      onClick={() => onSelectTreatment(t.id)}
                      variant={t.id == selectedTreatmentId ? "secondary" : "outline-secondary"}
                    >
                      <img width="20" src={t.icon?.url} />
                      {t.name}
                    </Button>
                  );
                })}
              </div>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default LastTreatmentModal;
