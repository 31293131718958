import React, { useState } from "react";
import TreatmentService from "../../services/treatment-pathway";
import toastMessage from "../../components/toast-message";
import Modal from "react-bootstrap/Modal";

const NewTreatmentPathway = ({ reloadAll, pathwayId, setActivePathway, ...props }) => {
  const [name, setName] = useState("");

  const handleCreate = async () => {
    try {
      const data = {
        treatment_pathway: {
          name_de: name,
        },
      };
      const response = await TreatmentService.create(data);
      setActivePathway(response.data.treatment_pathway);
      toastMessage.success(response.data.message);
      props.onHide();
      reloadAll();
    } catch (e) {
      toastMessage.error(e.meta.message);
    }
  };

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title> {I18n.t("form.treatment_pathway.new_treatment_pathway")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="input row">
          <div className="col-md-4 label">
            <label htmlFor="name">{I18n.t("form.treatment_pathway.name")}</label>
          </div>

          <div className="col-md-8 value">
            <input
              onChange={(e) => setName(e.target.value)}
              id="name"
              value={name}
              type="text"
              className="form-control"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={props.onHide} className="btn btn-secondary">
          {I18n.t("form.abort")}
        </button>
        <button onClick={handleCreate} className="btn btn-primary btn-sma">
          {I18n.t("form.create")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewTreatmentPathway;
