import React, { useState, useEffect } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";

const ServiceProviderTable = ({ filterType, ...props }) => {
  const tableColunms = [
    {
      dataField: "name",
      text: "",
      sort: true,
      headerStyle: (colum, colIndex) => {
        if (props.viewType == "overview") {
          return { width: "20%", textAlign: "center" };
        } else {
          return undefined;
        }
      },
    },
    {
      dataField: "actual",
      text: I18n.t("dashboard.provider_actual"),
      sort: true,
      formatter: (cellContent, row) => {
        if (Array.isArray(row.actual)) {
          return row.actual.map((r, i) => {
            return (
              <p style={{ marginBottom: 0 }}>
                {i + 1}. {r}
              </p>
            );
          });
        } else {
          return row.actual;
        }
      },
    },
    {
      dataField: "previous_period",
      text: I18n.t("dashboard.provider_previous_period"),
      sort: true,
      formatter: (cellContent, row) => {
        if (Array.isArray(row.previous_period)) {
          return row.previous_period.map((r, i) => {
            return (
              <p style={{ marginBottom: 0 }}>
                {i + 1}. {r}
              </p>
            );
          });
        } else {
          return row.previous_period;
        }
      },
    },
    {
      dataField: "confirm_date",
      text: I18n.t("dashboard.provider_confirm_date"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        if (props.viewType == "overview") {
          return { width: "20%", textAlign: "center" };
        } else {
          return undefined;
        }
      },
      formatter: (cellContent, row) => {
        if (Array.isArray(row.confirm_date)) {
          return row.confirm_date.map((r, i) => {
            return (
              <p style={{ marginBottom: 0 }}>
                {i + 1}. {r}
              </p>
            );
          });
        } else {
          return row.confirm_date;
        }
      },
    },
    {
      dataField: "confirm_appointment",
      text: I18n.t("dashboard.provider_confirm_appointment"),
      sort: true,
      formatter: (cellContent, row) => {
        if (Array.isArray(row.confirm_appointment)) {
          return row.confirm_appointment.map((r, i) => {
            return (
              <p style={{ marginBottom: 0 }}>
                {i + 1}. {r}
              </p>
            );
          });
        } else {
          return row.confirm_appointment;
        }
      },
    },
    {
      dataField: "confirm_result",
      text: I18n.t("dashboard.provider_confirm_result"),
      sort: true,
      formatter: (cellContent, row) => {
        if (Array.isArray(row.confirm_result)) {
          return row.confirm_result.map((r, i) => {
            return (
              <p style={{ marginBottom: 0 }}>
                {i + 1}. {r}
              </p>
            );
          });
        } else {
          return row.confirm_result;
        }
      },
    },
  ];

  return <BootstrapTable keyField="uid" data={props.data} columns={tableColunms} />;
};

export default ServiceProviderTable;
