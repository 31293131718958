import axios from 'axios';
import authClient from './auth-common';

const axiosClient = axios.create({
    baseURL: `${process.env.APP_URL}/api/v1`,
    timeout: 10000,
    headers: Object.assign({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Tenant-Id': {
            toString() {
                return localStorage.getItem('X-Tenant-Id')
            }
        },
        'X-Pathway-Id': {
          toString() {
              return localStorage.getItem('X-Pathway-Id')
          }
      }
    }, authClient.retrieveData('authHeaders'))
});

axiosClient.interceptors.response.use(
    response => response,
    error => {
        const msg = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
        const details = (error.response && error.response.data && error.response.data.errors) || []
        error.meta = { message: msg, details: details }

        if (error.response.status == 401) {
            localStorage.removeItem('user')
            document.location.href = "/";
        }

        return Promise.reject(error);
    });

export default axiosClient;
