import httpClient from '../utils/http-common';

const API_URL = '/settings';

const index = () => {
  return httpClient.get(`${API_URL}`);
};

const create = (data) => {
  return httpClient.post(API_URL, data);
};



const SettingService = {
  index,
  create,
};

export default SettingService;
