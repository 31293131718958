import React from "react";

const ManIcon = ({ color }) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="23px" viewBox="0 0 225.000000 225.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill={color ? color : '#4B4583'} stroke="none">
                <path d="M1560 2135 l0 -105 150 0 c83 0 150 -3 150 -8 0 -4 -93 -100 -207
-214 l-208 -207 -69 44 c-81 52 -190 96 -300 120 -106 24 -319 16 -421 -14
-313 -94 -543 -329 -626 -642 -31 -116 -31 -317 0 -434 47 -179 162 -359 301
-472 81 -66 240 -146 345 -174 105 -28 316 -31 419 -6 416 103 696 452 696
868 0 175 -49 343 -140 482 l-48 75 206 206 207 206 3 -150 3 -150 109 0 110
0 0 340 0 340 -340 0 -340 0 0 -105z m-490 -590 c113 -30 219 -92 306 -179 68
-68 89 -97 127 -176 57 -120 71 -181 70 -300 -1 -161 -54 -308 -156 -429 -262
-311 -743 -321 -1019 -23 -110 119 -166 254 -175 418 -9 169 43 331 150 465
89 111 244 203 396 235 78 16 220 11 301 -11z"/>
            </g>
        </svg >
    )

}

export default ManIcon;

