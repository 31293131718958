import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import Input from "react-validation/build/input";
import toastMessage from "./toast-message";
import AuthService from "../services/auth";
import authClient from "../utils/auth-common";

const ForgotPassword = () => {
  let navigate = useHistory();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const handleForgotPassword = (e) => {
    setLoading(true);

    authClient
      .requestPasswordReset({ email })
      .then((res) => {
        navigate.push("/");
        console.log(res);
        toastMessage.success(res.message);
        // window.location.reload();
      })
      .fail((error) => {
        setLoading(false);
        toastMessage.error(error.data.message || error.reason);
      });
  };

  return (
    <div className="row mt-5">
      <div className="col-md-4"></div>
      <div className="col-md-4 card card-container p-3">
        <h4 className="text-center">{I18n.t("devise.passwords.new.forgot_your_password")}</h4>
        <div className="form-group">
          <label htmlFor="email">{I18n.t("activerecord.attributes.user.email")}</label>
          <input type="text" className="form-control" name="email" value={email} onChange={onChangeEmail} />
        </div>

        <button onClick={handleForgotPassword} className="btn btn-primary mt-3" disabled={loading}>
          {loading && <span className="spinner-border spinner-border-sm"></span>}
          <span>{I18n.t("devise.passwords.new.send_me_reset_password_instructions")}</span>
        </button>
      </div>
    </div>
  );
};

export default ForgotPassword;
