import React, { } from "react";
import AcademicTitleForm from "./_form";

const AcademicTitleCreate = (props) => {
    return (
        <AcademicTitleForm
            {...props}
            title={I18n.t('form.academic_title.new_academic_title')}
            submitLabel={I18n.t('form.create')}
        />
    );
};

export default AcademicTitleCreate;