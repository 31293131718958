import React, { } from "react";
import SpecialistForm from "./_form";

const SpecialistCreate = (props) => {
    return (
        <SpecialistForm
            {...props}
            title={I18n.t('form.specialist.new')}
            submitLabel={I18n.t('form.create')}
        />
    );
};

export default SpecialistCreate;