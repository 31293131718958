import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import TreatmentPathwayService from "../../services/treatment-pathway";
import TreatmentService from "../../services/treatment";
import ServiceProviderService from "../../services/service_provider";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { LoginOutlined, LogoutOutlined } from "@ant-design/icons";
import "./form.scss";
import toastMessage from "../../components/toast-message";
import DataStorage from "../../components/data-storage";
import DepartmentService from "../../services/department";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle, index) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  // background: isDragging ? "lightgrey" : ([0, 1, 2].includes(index) ? "#48F0C6" : "white"),
  background: isDragging ? "lightgrey" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: "lightblue",
  padding: grid,
  width: 350,
  minHeight: 500,
});

const TreatmentPathwayForm = (props) => {
  let navigate = useHistory();

  const form = useRef();
  const checkBtn = useRef();
  const inputTreatmentRoot = useRef();

  const [treatmentId, _] = useState(props.treatmentId);
  const [reload, setReload] = useState(false);
  const [error, setError] = useState("");
  const [name_de, setName] = useState("");
  const [onFocusItem, setOnFocusItem] = useState(false);
  const [onFocusRootItem, setOnFocusRootItem] = useState(false);
  const [inputTreatments, setInputTreatments] = useState({});
  const [treatmentsDb, setTreatmentsDb] = useState([]);
  const [serviceProvidersDb, setServiceProvidersDb] = useState({});
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [rootDocuments, setRootDocuments] = useState([]);

  const [selectProviders, setSelectProviders] = useState([]);
  const [providers, setProviders] = useState([]);
  const [attachmentOptions, setAttachmentOptions] = useState([]);
  const [attachmentImgOptions, setAttachmentImgOptions] = useState([]);

  useEffect(() => {
    if (treatmentId) {
      TreatmentPathwayService.show(treatmentId).then(
        (response) => {
          setName(response.data.treatment_pathway.name_de);
          const treatments = response.data.treatment_pathway.treatments;
          setRootDocuments(response.data.treatment_pathway.documents);
          if (treatments.length > 0) {
            setTreatmentsDb(treatments);
            const treatment = {};
            treatments.forEach((t) => {
              treatment[t.order] = {};
              treatment[t.order]["id"] = t.id;
              treatment[t.order]["value"] = t.name;
              treatment[t.order]["service_providers"] = t.service_providers;
              treatment[t.order]["check_service_providers"] = {};
              treatment[t.order]["icon"] = t.icon;
              treatment[t.order]["documents"] = t.documents;
              treatment[t.order]["need_result"] = t.need_result;
              treatment[t.order]["working_day"] = t.working_day;
              treatment[t.order]["is_after_operation"] = t.is_after_operation;

              t.service_providers_treatments.forEach((sp) => {
                treatment[t.order]["check_service_providers"][sp.service_provider_id] = true;
              });
              const providerDb = { ...serviceProvidersDb };
              providerDb[t.id] = t.selected_service_providers;
              setServiceProvidersDb(providerDb);
            });
            setInputTreatments(treatment);
          }

          setSelectProviders(response.data.treatment_pathway.service_providers || []);
        },
        (error) => {
          toastMessage.error(error.meta.message);
        },
      );
    }
  }, [props.departmentId, reload]);

  useEffect(() => {
    ServiceProviderService.index().then(
      (response) => {
        const p = response.data.service_providers;
        setProviders(p);
      },
      (error) => {
        setError(error.meta.message);
      },
    );
  }, [props.departmentId, name_de]);

  const addTreatment = (e) => {
    e.preventDefault();

    const value = { ...inputTreatments };
    const max = Object.keys(inputTreatments).length + 1;
    value[max] = {
      id: undefined,
      value: "",
      service_providers: [...providers],
      check_service_providers: {},
      icon: "",
      need_result: false,
      working_day: "",
      is_after_operation: false,
    };

    setInputTreatments(value);
  };

  const removeTreatment = (e) => {
    e.preventDefault();

    const max = Object.keys(inputTreatments).length;
    let value = Object.assign({}, inputTreatments);
    delete value[max];
    setInputTreatments(value);
  };

  const onChangeInputTreatments = (e) => {
    const value = { ...inputTreatments };
    value[e.target.name]["value"] = e.target.value;

    setInputTreatments(value);
  };

  const onChangeWorkingDayTreatments = (e) => {
    const value = { ...inputTreatments };
    value[e.target.name]["working_day"] = e.target.value;

    setInputTreatments(value);
  };

  const onChangeAfterOperation = (e) => {
    const value = { ...inputTreatments };
    value[e.target.name]["is_after_operation"] = e.target.checked;

    setInputTreatments(value);
  };

  const onChangeCheckbox = (e) => {
    const value = { ...inputTreatments };
    if (value[e.target.name]["check_service_providers"][e.target.value]) {
      value[e.target.name]["check_service_providers"][e.target.value] =
        !value[e.target.name]["check_service_providers"][e.target.value];
    } else {
      value[e.target.name]["check_service_providers"][e.target.value] = true;
    }
    setInputTreatments(value);
  };

  const onChangeNeedResult = (e) => {
    const value = { ...inputTreatments };
    value[e.target.name]["need_result"] = e.target.checked;
    setInputTreatments(value);
  };

  const onInputFocus = (e) => {
    setOnFocusItem(e.target.name);
    setOnFocusRootItem(false);
  };

  const onInputRootFocus = (e) => {
    setOnFocusRootItem(true);
  };

  const handleCreate = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      const treatments_update = Object.keys(inputTreatments).map((k) => {
        const id = treatmentsDb.find((t) => t.order == k)?.id;

        const sp = [];
        const keysCheck = Object.keys(inputTreatments[k]["check_service_providers"]);
        keysCheck.forEach((kCheck) => {
          if (inputTreatments[k]["check_service_providers"][kCheck]) {
            if (Object.keys(sp).length < 3) {
              const first3 = inputTreatments[k]["service_providers"].slice(0, 3);

              const findIndex = first3.findIndex((sp) => sp.id == kCheck);
              if (findIndex > -1) {
                sp.push({
                  ...inputTreatments[k]["service_providers"].slice(0, 3)[findIndex],
                  order: findIndex,
                });
              }
            }
          }
        });
        return {
          name: inputTreatments[k]["value"],
          order: k,
          id,
          service_providers_treatments_attributes: sp.map((s, index) => ({
            service_provider_id: s.id,
            order: s.order,
          })),
          icon: document.getElementById(`${k}-icon`).files[0],
          need_result: inputTreatments[k]["need_result"],
          working_day: inputTreatments[k]["working_day"],
          is_after_operation: inputTreatments[k]["is_after_operation"],
        };
      });

      const treatments_delete = [];
      if (treatmentId !== undefined) {
        treatmentsDb.forEach((t) => {
          if (!treatments_update.map((u) => u.id).find((u) => u == t.id)) {
            treatments_delete.push({
              id: t.id,
              _destroy: true,
            });
          }
        });
      }

      const formData = new FormData();
      formData.append("treatment_pathway[name_de]", name_de);
      const treatments = [...treatments_update, ...treatments_delete];
      treatments.forEach((attr) => {
        if (attr["id"]) {
          formData.append("treatment_pathway[treatments_attributes][][id]", attr["id"]);
        }
        if (attr["icon"]) {
          formData.append("treatment_pathway[treatments_attributes][][icon]", attr["icon"]);
        }
        formData.append("treatment_pathway[treatments_attributes][][name]", attr["name"]);
        formData.append("treatment_pathway[treatments_attributes][][order]", attr["order"]);

        formData.append(
          "treatment_pathway[treatments_attributes][][need_result]",
          attr["need_result"] !== undefined ? attr["need_result"] : false,
        );
        formData.append(
          "treatment_pathway[treatments_attributes][][working_day]",
          attr["working_day"] !== undefined ? attr["working_day"] : false,
        );
        formData.append(
          "treatment_pathway[treatments_attributes][][is_after_operation]",
          attr["is_after_operation"] !== undefined ? attr["is_after_operation"] : false,
        );
        attr["service_providers_treatments_attributes"].forEach((sp) => {
          formData.append(
            "treatment_pathway[treatments_attributes][][service_providers_treatments_attributes][][service_provider_id]",
            sp["service_provider_id"],
          );
          formData.append(
            "treatment_pathway[treatments_attributes][][service_providers_treatments_attributes][][order]",
            sp["order"],
          );
        });
      });

      if (treatmentId == undefined) {
        TreatmentPathwayService.create(formData).then(
          (res) => {
            window.location.reload();
          },
          (error) => {
            setLoading(false);
            toastMessage.error(error.meta.message);
          },
        );
      } else {
        TreatmentPathwayService.update(treatmentId, formData).then(
          (res) => {
            window.location.reload();
          },
          (error) => {
            setLoading(false);
            toastMessage.error(error.meta.message);
          },
        );
      }
    } else {
      setLoading(false);
    }
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      inputTreatments[onFocusItem]["service_providers"],
      result.source.index,
      result.destination.index,
    );
    // setProviders(items)

    const value = { ...inputTreatments };
    value[onFocusItem]["service_providers"] = items;
    setInputTreatments(value);
  };

  const handleAddIcon = (e) => {
    document.getElementById(e).click();
  };

  const setFile = (e, id) => {
    const file = e.target.files[0];
    const updateInput = { ...inputTreatments };
    updateInput[id]["icon"] = file;

    setInputTreatments(updateInput);
  };

  const toggleReload = () => {
    console.log("co vao");
    setReload(!reload);
  };

  const displayProviderName = (item) => {
    if (
      (!item.first_name && !item.last_name) ||
      (item.first_name && item.last_name && item.first_name.length == 0 && item.last_name.length == 0)
    ) {
      return item.company_name;
    } else {
      return item.first_name + " " + item.last_name;
    }
  };

  useEffect(() => {
    if (props.departmentId) {
      DepartmentService.show(props.departmentId).then(
        (response) => {
          const p = response.data.department;
          const all = p.department_setting.documents;
          setAttachmentOptions(all);
          setAttachmentImgOptions(p.department_setting.icons);
        },
        (error) => {
          setError(error.meta.message);
        },
      );
    }
  }, [props.departmentId]);

  return (
    <div style={{ gap: "50px" }} className="d-flex align-items-start justify-content-center mt-5">
      <div className="treatment-pathway">
        <h1 className="text-center">{I18n.t("form.treatment_pathway.name")}</h1>
        <h3 className="text-center">{name_de}</h3>
        <div className="card">
          <Form onSubmit={handleCreate} ref={form} className="p-3">
            <div className="form-group">
              <label htmlFor="name">{I18n.t("form.treatment_pathway.name")}</label>
              <Input
                type="text"
                ref={inputTreatmentRoot}
                className="form-control"
                name="name"
                value={name_de}
                onChange={(e) => setName(e.target.value)}
                onFocus={onInputRootFocus}
              />
            </div>

            {Object.keys(inputTreatments).map((t) => {
              return (
                <div key={`name-input-${t}`} className="form-group mt-2 treatment-input">
                  <label htmlFor="service_providers">{t + ". " + I18n.t("form.treatment_pathway.treatment")}</label>
                  <div className="d-flex align-items-center" style={{ gap: "10px" }}>
                    <input
                      type="text"
                      style={{ width: "90%" }}
                      className="form-control"
                      name={t}
                      value={inputTreatments[t]?.value}
                      onChange={onChangeInputTreatments}
                      onFocus={onInputFocus}
                    />
                    <input
                      onChange={(e) => setFile(e, t)}
                      style={{ display: "none" }}
                      accept="image/*"
                      type="file"
                      id={`${t}-icon`}
                      name={`${t}-icon`}
                    />
                    <DataStorage
                      serverFiles={inputTreatments[t]["icon"] ? [inputTreatments[t]["icon"]] : []}
                      departmentId={props.departmentId}
                      multiple={false}
                      id={inputTreatments[t].id}
                      api={TreatmentService}
                      documentType="image"
                      attachmentOptions={attachmentImgOptions}
                      reload={() => setReload(!reload)}
                    />
                  </div>

                  <div className="mt-2 ml-5 row">
                    <div className="col-md-6">
                      <input
                        onChange={onChangeNeedResult}
                        style={{ marginRight: 5 }}
                        defaultChecked={inputTreatments[t]["need_result"]}
                        name={t}
                        id={`${t}-need-result`}
                        value={inputTreatments[t]["need_result"]}
                        type="checkbox"
                      />
                      <label htmlFor={`${t}-need-result`}>
                        {I18n.t("activerecord.attributes.treatment.need_result")}
                      </label>
                    </div>

                    <div className="col-md-6">
                      <input
                        onChange={onChangeAfterOperation}
                        style={{ marginRight: 5 }}
                        defaultChecked={inputTreatments[t]["is_after_operation"]}
                        name={t}
                        id={`${t}-after-operation`}
                        value={inputTreatments[t]["is_after_operation"]}
                        type="checkbox"
                      />
                      <label htmlFor={`${t}-after-operation`}>
                        {I18n.t("activerecord.attributes.treatment.is_after_operation")}
                      </label>
                    </div>
                  </div>

                  <div className="ml-5">
                    <label htmlFor={`${t}-working-day`}>
                      {I18n.t("activerecord.attributes.treatment.working_day")}
                    </label>
                    <input
                      type="text"
                      pattern="[0-9]*"
                      style={{ width: "90%" }}
                      className="form-control"
                      name={t}
                      id={`${t}-working-day`}
                      value={inputTreatments[t]?.working_day}
                      onChange={onChangeWorkingDayTreatments}
                      onFocus={onInputFocus}
                    />
                  </div>
                </div>
              );
            })}

            <button onClick={addTreatment} className="btn btn-outline-info mt-3 btn-sm">
              {I18n.t("form.treatment.new_treatment")}
            </button>
            <button onClick={removeTreatment} className="btn btn-outline-danger mt-3 btn-sm float-end">
              {I18n.t("form.treatment.remove_treatment")}
            </button>

            <hr></hr>

            <div className="form-group">
              <button className="btn btn-primary btn-block mt-3" disabled={loading}>
                {loading && <span className="spinner-border spinner-border-sm"></span>}
                <span>{props.treatmentId ? I18n.t("form.update") : I18n.t("form.create")}</span>
              </button>

              <button onClick={() => navigate.push("/treatment_pathways")} className="btn btn-secondary mt-3 abort-btn">
                <span>{I18n.t("form.abort")}</span>
              </button>
            </div>

            {message && (
              <div className="form-group mt-5">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>
        </div>
      </div>
      {!onFocusRootItem && (
        <div className="service-provider-select">
          <h1 className="text-center">{I18n.t("form.service_provider.name")}</h1>
          <h3 className="text-center">{inputTreatments[onFocusItem]?.value}</h3>
          {parseInt(onFocusItem) > 0 && (
            <DragDropContext className="dd" onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {inputTreatments[onFocusItem].service_providers.map((item, index) => (
                      <Draggable
                        className="service-provider-dd"
                        key={`${item.id}-${onFocusItem}-drag`}
                        draggableId={`${item.id}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, index)}
                          >
                            <div className="provider-item">
                              <input
                                onChange={onChangeCheckbox}
                                style={{ marginRight: 5 }}
                                defaultChecked={inputTreatments[onFocusItem]["check_service_providers"][item.id]}
                                name={onFocusItem}
                                value={item.id}
                                type="checkbox"
                              />
                              {item.is_internal ? <LoginOutlined /> : <LogoutOutlined />}
                              <label htmlFor={`${onFocusItem}-checkbox`}>
                                {" " + displayProviderName(item)}{" "}
                                {item.treatment?.name ? `- ${item.treatment.name}` : ""}
                              </label>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
      )}

      {!onFocusRootItem && (
        <div className="treatment-documents">
          <h1 className="text-center">{I18n.t("form.treatment.document")}</h1>
          <h3 className="text-center">{inputTreatments[onFocusItem]?.value}</h3>
          {inputTreatments[onFocusItem]?.id && treatmentId && (
            <DataStorage
              serverFiles={inputTreatments[onFocusItem]["documents"]}
              departmentId={props.departmentId}
              setFileIds={(ids) => setFileIds(ids)}
              multiple={true}
              id={inputTreatments[onFocusItem].id}
              api={TreatmentService}
              attachmentOptions={attachmentOptions}
              documentType="pdf"
              reload={() => toggleReload()}
              preview={true}
              openIcon="upload"
            />
          )}
        </div>
      )}

      {onFocusRootItem && treatmentId && (
        <div className="treatment-documents">
          <h1 className="text-center">{I18n.t("form.treatment.document")}</h1>
          <h3 className="text-center">{name_de}</h3>
          {treatmentId && (
            <DataStorage
              serverFiles={rootDocuments}
              departmentId={props.departmentId}
              setFileIds={(ids) => setFileIds(ids)}
              multiple={true}
              id={treatmentId}
              documentType="pdf"
              api={TreatmentPathwayService}
              reload={() => toggleReload()}
              attachmentOptions={attachmentOptions}
              preview={true}
              openIcon="upload"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TreatmentPathwayForm;
