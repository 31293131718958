import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import TreatmentPathwayService from "../../services/treatment-pathway";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import TreatmentService from "../../services/treatment";
import PatientAppointmentService from "../../services/patient_appointment";
import PatientService from "../../services/patient";
import toastMessage from "../../components/toast-message";

const PathwaySelection = ({ patientId, ...props }) => {
  const [labels, setLabels] = useState([]);
  const [selectedLabel, setSeletedLabel] = useState(undefined);
  const [selectedTreatmentId, setSelectedTreatmentId] = useState(undefined);
  const [allTreatmentPathway, setAllTreatmentPathways] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    if (props.show) {
      TreatmentPathwayService.index().then(
        (response) => {
          const notDefault = response.data.treatment_pathways.filter((t) => !t.is_default);
          const labels = notDefault.map((t) => {
            return {
              id: t.id,
              name: t.name_de,
            };
          });
          setAllTreatmentPathways(notDefault);
          setLabels(labels);
          setSeletedLabel(labels[0]?.id);
          setloading(false);
        },
        (error) => {
          setloading(false);
          toastMessage.error({ content: error.meta.message, variant: "danger" });
        },
      );
    }
  }, [props.show]);

  useEffect(() => {}, [selectedLabel]);

  const onChangeLabel = (id) => {
    setSeletedLabel(id);
  };

  const updatePathway = async () => {
    PatientService.update(patientId, { treatment_pathway_id: selectedLabel }).then(
      (res) => {
        toastMessage.success(res.data.message);
        props.onHide();
        props.reloadPatient();
        props.reload();
      },
      (error) => {
        toastMessage.error(error.meta.message);
        props.onHide();
      },
    );
  };

  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton className="modal-header">
        <Modal.Title id="contained-modal-title-vcenter">{I18n.t("form.treatment_pathway.name")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          <>
            <div className="treatment-pathway-selection text-center mb-3">
              {labels.map((l, index) => (
                <p key={l.id}>
                  <Button
                    active={l.id == selectedLabel}
                    onClick={() => onChangeLabel(l.id)}
                    variant={l.id == selectedLabel ? "primary" : "outline-primary"}
                  >
                    {l.name}
                  </Button>{" "}
                </p>
              ))}
            </div>
            <p className="text-center">{I18n.t("form.treatment_pathway.selection_pathway")}</p>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={updatePathway}>{I18n.t("form.update")}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PathwaySelection;
