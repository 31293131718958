import React, { useState, useRef, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import { LANGUAGE_OPTIONS, ROLE_OPTIONS } from "../../constants/index"
import UserService from "../../services/user"
import TenantService from "../../services/tenant";
import AuthService from "../../services/auth";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const UserCreate = (props) => {
    let navigate = useHistory();

    const form = useRef();
    const checkBtn = useRef();

    const [error, setError] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState("");
    const [language, setLanguage] = useState(LANGUAGE_OPTIONS[0]['value']);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [tenants, setTenants] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [tenantId, setTenantId] = useState(undefined);
    const [departmentId, setDepartmentId] = useState(undefined);

    useEffect(() => {
        TenantService.index().
            then(
                (response) => {
                    setTenants(response.data.tenants);
                    if (response.data.tenants.length > 0) {
                        const id = response.data.tenants[0].id;
                        setTenantId(id)

                        const departments = response.data.tenants.find(i => i.id == id).departments
                        setDepartments(departments);
                        setDepartmentId(departments[0]?.id);

                    }
                },
                (error) => { setError(error.meta.message) }
            );
    }, []);
    const onChangeEmail = (e) => {
        const name = e.target.value;
        setEmail(name);
    };

    const onChangePassword = (e) => {
        const name = e.target.value;
        setPassword(name);
    };

    const onChangeLanguage = (e) => {
        const lang = e.target.value;
        setLanguage(lang);
    };

    const onChangeRole = (e) => {
        const role = e.target.value;
        setRole(role);
    };

    const onChangeTenant = (e) => {
        setTenantId(e.target.value);

        const departments = tenants.find(t => t.id == e.target.value)?.departments
        if (departments.length == 0) {
            setDepartments([]);
        } else {
            setDepartments(departments);
            setDepartmentId(departments[0].id)
        }
    }

    const handleCreate = (e) => {
        e.preventDefault();

        setMessage("");
        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            const formData = new FormData()
            formData.append('email', email);
            formData.append('password', password);
            formData.append('role', role);
            formData.append('language', language);
            formData.append('department_id', departmentId);
            formData.append('tenant_id', tenantId);

            UserService.create(formData).then(
                (res) => {
                    navigate.push({ pathname: "/users", state: { name: 'actionSuccess', message: res.data.message } });
                },
                (error) => {
                    setLoading(false);
                    setMessage(error.meta.message);
                }
            );
        } else {
            setLoading(false);
        }
    };

    return (
        < div className="d-flex align-items-center justify-content-center flex-column" >
            <h1>{I18n.t('form.user.new_user')}</h1>
            <div className="card card-container w-50">
                <Form onSubmit={handleCreate} ref={form} className="p-3">
                    <div className="form-group">
                        <label htmlFor="Tenant">{I18n.t('activerecord.models.tenant')}</label>
                        <Select
                            name="lang"
                            className="form-control"
                            title="Tenant"
                            value={tenantId}
                            disabled={AuthService.isSuperAdmin() ? false : true}
                            validations={[required]}
                            onChange={onChangeTenant}
                        >
                            {tenants.map(l => <option key={l.id} value={l.id}>{l.company_name}</option>)}
                        </Select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="department">{I18n.t('activerecord.models.department')}</label>
                        <Select
                            name="lang"
                            className="form-control"
                            title="department"
                            value={departmentId}
                            validations={[required]}
                            onChange={(val) => setDepartmentId(val.target.value)}
                        >
                            {departments.map(l => <option key={l.id} value={l.id}>{l.company_name}</option>)}
                        </Select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="email">{I18n.t('activerecord.attributes.user.email')}</label>
                        <Input
                            type="text"
                            className="form-control"
                            name="email"
                            value={email}
                            onChange={onChangeEmail}
                            validations={[required]}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">{I18n.t('activerecord.attributes.user.password')}</label>
                        <Input
                            type="password"
                            className="form-control"
                            name="password"
                            value={password}
                            onChange={onChangePassword}
                            validations={[required]}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="language">{I18n.t('activerecord.attributes.department.language')}</label>
                        <Select
                            name="lang"
                            className="form-control"
                            title="Language"
                            value={language}
                            validations={[required]}
                            onChange={onChangeLanguage}
                        >
                            <option value> -- Select Language -- </option>
                            {LANGUAGE_OPTIONS.map(l => <option key={l.value} value={l.value}>{l.label}</option>)}
                        </Select>
                    </div>


                    <div className="form-group">
                        <label htmlFor="role">{I18n.t('activerecord.attributes.user.role')}</label>
                        <Select
                            name="role"
                            className="form-control"
                            title="Role"
                            value={role}
                            validations={[required]}
                            onChange={onChangeRole}
                        >
                            <option value> -- Select Role -- </option>
                            {ROLE_OPTIONS.map(l => <option key={l.value} value={l.value}>{l.label}</option>)}
                        </Select>
                    </div>

                    <div className="form-group">
                        <button className="btn btn-primary btn-block mt-3" disabled={loading}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            <span>{I18n.t('form.create')}</span>
                        </button>

                        <button onClick={() => navigate.push('/users')} className="btn btn-secondary mt-3 abort-btn">
                            <span>{I18n.t('form.abort')}</span>
                        </button>
                    </div>

                    {message && (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {message}
                            </div>
                        </div>
                    )}
                    <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </Form>
            </div >
        </div >
    );
};

export default UserCreate;