import React, { useState, useEffect } from "react";
import "../toast-message";
import toastMessage from "../toast-message";
import ServiceProviderService from "../../services/service_provider";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import "./form.scss";

const ServiceProviderSelection = ({
  data,
  createNew,
  groupData,
  provider,
  setProvider,
  goBack,
  appointmentId,
  isDisabled,
  isInternal,
  ...props
}) => {
  const [defaultValue, setDefaultValue] = useState(provider);
  const [groupProviders, _setGroupProviders] = useState(
    (groupData || []).map((p) => {
      return {
        value: p.id,
        label: p.display_name,
      };
    }),
  );
  const [isInternalType, setIsInternalType] = useState(true);
  const [providers, setProviders] = useState(data || []);
  const [options, setOptions] = useState([]);

  const navigate = useHistory();

  useEffect(() => {
    if (providers.length == 0) {
      ServiceProviderService.index().then(
        (response) => {
          let p = response.data.service_providers;
          if (isInternal == true) {
            p = p.filter((p) => p.is_internal);
          }
          setProviders(p);
          let createOption = p.map((pp) => {
            return {
              value: pp.id,
              label: pp.display_name,
            };
          });

          if (!provider) {
            setProvider({ value: p[0]?.id });
          }
          if (groupProviders && groupProviders.length > 0) {
            createOption = createOption.filter((sp) => {
              return groupProviders.findIndex((gp) => gp.value == sp.value) == -1;
            });
            setOptions([
              { label: I18n.t("form.service_provider.checked_provider"), options: groupProviders },
              { label: I18n.t("form.service_provider.available_provider"), options: createOption },
            ]);
          } else {
            setOptions(createOption);
          }
        },
        (error) => {
          toastMessage.error(error.meta.message);
        },
      );
    } else {
      let customProviders = providers;
      if (isInternal == true) {
        customProviders = providers.filter((p) => p.is_internal);
      }
      let createOption = customProviders.map((pp) => {
        return {
          value: pp.id,
          label: pp.display_name,
        };
      });
      if (!provider) {
        setProvider({ value: customProviders[0]?.id });
      }
      if (groupProviders) {
        createOption = createOption.filter((sp) => {
          return groupProviders.findIndex((gp) => gp.value == sp.value) == -1;
        });
        setOptions([
          { label: I18n.t("form.service_provider.checked_provider"), options: groupProviders },
          { label: I18n.t("form.service_provider.available_provider"), options: createOption },
        ]);
      } else {
        setOptions(createOption);
      }
    }
  }, []);

  const onChange = (e) => {
    if (e["__isNew__"] == true) {
      navigate.push({
        pathname: "/service_provider_create",
        state: { goBack: goBack || false, appointmentId, name: e.value },
      });
    } else {
      setProvider({ value: e.value });
      setIsInternalType(providers.find((p) => p.id == e.value).is_internal);

      const p = providers.find((p) => p.id == e.value).is_internal;
      // console.log("pp", p);
      // props?.setIsInternalType(providers.find((p) => p.id == e.value).is_internal);
    }
  };

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
    </div>
  );

  return createNew ? (
    groupProviders.length == 0 ? (
      <CreatableSelect
        style={{ width: "100%" }}
        defaultValue={provider}
        onChange={onChange}
        options={options}
        className="react-select-provider"
        classNamePrefix="react-select"
        placeholder=""
        isDisabled={isDisabled}
        formatCreateLabel={(userInput) => `${I18n.t("form.react_select_create")} "${userInput}"`}
      />
    ) : (
      <CreatableSelect
        style={{ width: "100%" }}
        defaultValue={provider}
        onChange={onChange}
        options={options}
        className="react-select-provider"
        classNamePrefix="react-select"
        placeholder=""
        formatGroupLabel={formatGroupLabel}
        isDisabled={isDisabled}
        formatCreateLabel={(userInput) => `${I18n.t("form.react_select_create")} "${userInput}"`}
      />
    )
  ) : groupProviders.length == 0 ? (
    <Select
      style={{ width: "100%" }}
      defaultValue={provider}
      onChange={onChange}
      options={options}
      className="react-select-provider"
      classNamePrefix="react-select"
      placeholder=""
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      isDisabled={isDisabled}
    />
  ) : (
    <Select
      style={{ width: "100%" }}
      defaultValue={provider}
      onChange={onChange}
      options={options}
      className="react-select-provider"
      classNamePrefix="react-select"
      placeholder=""
      formatGroupLabel={formatGroupLabel}
      isDisabled={isDisabled}
    />
  );
};

export default ServiceProviderSelection;
