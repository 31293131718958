import httpClient from "../utils/http-common";

const API_URL = "/service_provider_overview";

const overview = (query, page = 1) => {
  return httpClient.post(API_URL + "/overview" + `?page=${page}`, { query });
};

const topOverview = (query, page = 1) => {
  return httpClient.post(API_URL + "/top_overview" + `?page=${page}`, { query });
};

const patient = (query, page = 1) => {
  return httpClient.post(API_URL + "/patient" + `?page=${page}`, { query });
};

const DashboardProviderService = {
  overview,
  topOverview,
  patient,
};

export default DashboardProviderService;
