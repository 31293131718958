import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./appointment-form.scss";
import "react-datepicker/dist/react-datepicker.css";
import Logfile from "../logfile";

const LogfileModal = (props) => {
  return (
    <Modal {...props} size="lg" fullscreen={true} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton className="modal-header">
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Logfile target_id={props.target_id} type={props.type} all={false} />
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default LogfileModal;
