import React, { useState, useEffect } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { FolderOpenOutlined } from "@ant-design/icons";

const LogfileMailer = (props) => {
  const tableColunms = [
    {
      dataField: "updated_at",
      text: "At",
      sort: true,
    },
    {
      dataField: "user.email",
      text: I18n.t("activerecord.models.user.other"),
      sort: true,
    },
    {
      dataField: "email",
      text: "To",
      sort: true,
    },
    {
      dataField: "subject",
      text: "Subject",
      sort: true,
    },
    {
      dataField: "event",
      text: "Event",
    },
    {
      dataField: "audited_changes",
      text: "Detail",
      headerStyle: (colum, colIndex) => {
        return { width: "35%" };
      },
      formatter: (cellContent, row) => {
        let href = "";
        if (row.contentable_type == "Patient") {
          href = `/patients/${row.contentable_id}/profile?scrollId=${row.appointment_id}`;
        } else if (row.contentable_type == "ServiceProvider") {
          href = `/service_providers/${row.contentable_id}/profile`;
        } else if (row.contentable_type == "User") {
          href = `/users/${row.contentable_id}/edit`;
        }

        return (
          <div>
            <a style={{ gap: 20, cursor: "pointer" }} className="d-flex align-items-base" target="_blank" href={href}>
              <p>
                {row.contentable_type}: {row.contentable_id}{" "}
              </p>
              <FolderOpenOutlined />
            </a>
            {row.email_type && <p> {row.email_type}</p>}
            {row.treatment_name && (
              <p>
                {" "}
                {I18n.t("form.treatment_pathway.treatment")}: {row.treatment_name}
              </p>
            )}
            {row.service_provider_name && (
              <p>
                <a
                  style={{ gap: 20, cursor: "pointer" }}
                  className="d-flex align-items-base"
                  target="_blank"
                  href={`/service_providers/${row.service_provider_id}/profile`}
                >
                  <p>
                    {I18n.t("activerecord.attributes.service_provider.first_name")}: {row.service_provider_name}
                  </p>
                  <FolderOpenOutlined />
                </a>
              </p>
            )}
          </div>
        );
      },
    },
  ];

  return <BootstrapTable keyField="id" data={props.data} columns={tableColunms} />;
};

export default LogfileMailer;
