import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const DownloadBackupCode = ({ backupCodes, ...props }) => {
  const [bttnText, setBttnText] = useState("Copy");

  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([document.getElementById("backup-codes-txt").value], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "backup-codes.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const copyCode = () => {
    navigator.clipboard
      .writeText(backupCodes)
      .then(() => {
        setBttnText("Copied");
        setTimeout(function () {
          setBttnText("Copy");
        }, 3000);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton className="modal-header">
        <Modal.Title id="contained-modal-title-vcenter">Backup codes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="backup-code-guide-1 mb-4">{I18n.t("form.user.backup_code_guide_1")}</div>
        <input className="d-none" id="backup-codes-txt" value={backupCodes} />
        <div
          style={{ backgroundColor: "#f8f8f8", fontFamily: "AvenirNextLTPro Bold" }}
          className="download-backup-body text-center p-3 fs-5"
        >
          {backupCodes.length > 0 &&
            backupCodes.map((c) => {
              return <p key={c}>{c}</p>;
            })}

          <hr />

          <div className="fs-5 action d-flex align-items-center justify-content-center">
            <span onClick={() => copyCode()} style={{ cursor: "pointer" }} className="text-primary p-2">
              {bttnText}
            </span>
            <span onClick={() => downloadTxtFile()} style={{ cursor: "pointer" }} className="text-primary p-3">
              Download as .txt file
            </span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <div className="backup-code-guide-2 mb-3">{I18n.t("form.user.backup_code_guide_2")}</div>
      </Modal.Footer>
    </Modal>
  );
};

export default DownloadBackupCode;
