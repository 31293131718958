import httpClient from '../utils/http-common';

const API_URL = '/service_providers';

const index = (query) => {
  if (query == undefined) {
    query = ""
  }
  return httpClient.get(`${API_URL}?query=${query}`);
};

const create = (data) => {
  return httpClient.post(API_URL, data);
};

const show = (id) => {
  return httpClient.get(`${API_URL}/${id}`);
};

const update = (id, data) => {
  return httpClient.put(`${API_URL}/${id}`, data);
};

const destroy = (id, departmentId) => {
  return httpClient.delete(`${API_URL}/${id}?department_id=${departmentId}`);
};

const ServiceProviderService = {
  index,
  show,
  create,
  update,
  destroy,
};

export default ServiceProviderService;
