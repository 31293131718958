import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import TenantForm from './_form'

const TenantUpdate = (props) => {
    const { id } = useParams()
    const [tenantId, setTenantId] = useState(id);

    return (
        <TenantForm
            {...props}
            tenantId={tenantId}
            title={I18n.t('form.tenant.name')}
            submitLabel={I18n.t('form.update')}
        />
    );
};

export default TenantUpdate;