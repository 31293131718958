import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import DepartmentService from "../services/department";
import {
  FilePdfOutlined,
  FileImageOutlined,
  UploadOutlined,
  DeleteOutlined,
  ConsoleSqlOutlined,
} from "@ant-design/icons";
import toastMessage from "./toast-message";
import StorageService from "../services/storage";
import Spinner from "react-bootstrap/Spinner";

function DataStorage({
  api,
  id,
  serverFiles,
  setFileIds,
  departmentId,
  multiple,
  documentType,
  preview,
  openIcon,
  ...props
}) {
  const [attachmentOptions, setAttachmentOptions] = useState(props.attachmentOptions || []);
  const [selected, setSelected] = useState([]);
  const [modalDocumentShow, setModalDocumentShow] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (attachmentOptions.length == 0) {
      setLoading(true);
      const departmentId = cookies.cookies["last_department_id"];
      DepartmentService.show(departmentId).then(
        (response) => {
          const p = response.data.department;
          if (documentType && documentType == "image") {
            setAttachmentOptions(p.department_setting.icons);
          } else {
            const pdf = [...p.department_setting.documents];
            setAttachmentOptions(pdf);
          }
          setLoading(false);
        },
        (error) => {
          console.log(error);
          setLoading(false);
        },
      );
    } else {
      if (documentType && documentType != "image") {
        setAttachmentOptions(props.attachmentOptions.filter((a) => a.filetype == "application/pdf"));
      } else {
        setAttachmentOptions(props.attachmentOptions.filter((a) => a.filetype != "application/pdf"));
      }
    }
  }, [modalDocumentShow]);

  useEffect(() => {
    if (attachmentOptions.length > 0) {
      setLoading(true);
      const selectedFile = [];
      const serverFileSum = serverFiles.map((s) => s.sum);
      attachmentOptions.forEach((a) => {
        if (serverFileSum.includes(a.sum)) {
          selectedFile.push(a);
        }
      });

      setSelected(selectedFile);
      setLoading(false);
    }
  }, [departmentId, serverFiles, attachmentOptions]);

  const onChecked = (e) => {
    const id = e.target.value;
    const file = attachmentOptions.find((a) => a.id == id);
    const select = [...selected];
    if (e.target.checked) {
      if (multiple) {
        select.push(file);
        setSelected(Array.from(new Set(select)));
      } else {
        setSelected([file]);
      }
    } else {
      if (multiple) {
        const newSelect = select.filter((s) => s.id != id);
        setSelected(Array.from(new Set(newSelect)));
      } else {
        setSelected([]);
      }
    }
  };

  const saveDocument = async () => {
    try {
      const formData = new FormData();
      selected.forEach((a) => {
        formData.append("attachment_ids[]", a.id);
      });
      if (documentType == "image") {
        formData.append("type", "icon");
      }
      const response = await api.uploadDocuments(id, formData);
      toastMessage.success(response.data.message);
      setModalDocumentShow(false);
      props.reload();
    } catch (error) {
      toastMessage.error(error.meta.message);
      props.reload();
      setSelected([]);
      initSelectedFiles(attachmentOptions);
      setModalDocumentShow(false);
    }
  };

  const removeFileServer = async (sum) => {
    const file = serverFiles.find((s) => s.sum == sum);
    if (file) {
      const response = await StorageService.destroy(file.id);
      toastMessage.success(response.data.message);
      props.reload();
    }
  };

  return !loading ? (
    <div className="data-storage">
      <div onClick={() => setModalDocumentShow(true)} className="text-center" style={{ cursor: "pointer" }}>
        {openIcon == "upload" ? (
          <UploadOutlined className="text-center" style={{ fontSize: "1.5rem", color: "#0d6efd" }} />
        ) : (
          <FileImageOutlined
            className="text-center"
            style={{ fontSize: "1.5rem", color: serverFiles.length > 0 ? "#0d6efd" : "" }}
          />
        )}
      </div>
      <Modal
        onHide={() => setModalDocumentShow(false)}
        show={modalDocumentShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {attachmentOptions.length > 0 &&
            attachmentOptions.map((a) => {
              return (
                <div key={a.id} className="document-item d-flex align-items-center overflow-auto">
                  <input
                    onChange={onChecked}
                    style={{ marginRight: 5 }}
                    value={a.id}
                    id={a.id}
                    type="checkbox"
                    defaultChecked={serverFiles.find((s) => s.sum == a.sum)}
                  />
                  {a.filetype == "application/pdf" ? <FilePdfOutlined /> : <FileImageOutlined />}
                  <label style={{ marginLeft: 5 }}>{a.filename}</label>
                </div>
              );
            })}
          {attachmentOptions.length == 0 && <p>No files yet</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={saveDocument}>{I18n.t("form.update")}</Button>
        </Modal.Footer>
      </Modal>

      {selected.length > 0 && preview && (
        <ul className="card p-3 mt-3">
          <div className="existed-files">
            {selected.map((f) => {
              return (
                <div key={`${f.id}`} className="preview-item">
                  <li>{f.filename}</li>
                  <DeleteOutlined onClick={() => removeFileServer(f.sum)} className="mr-3" />
                </div>
              );
            })}
          </div>
        </ul>
      )}
    </div>
  ) : (
    <div className="text-center">
      <Spinner className="mt-5" animation="border" variant="primary" />
    </div>
  );
}

export default DataStorage;
