import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { LoginOutlined, LogoutOutlined } from "@ant-design/icons";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
const getItemStyle = (isDragging, draggableStyle, index) => ({
  userSelect: "none",
  background: isDragging ? "#48F0C6" : "#f7f7fa",
  ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({});

const ServiceProviderDD = ({ selectedProviders, setSelectedProviders, ...props }) => {
  const [localSelectedProviders, setLocalSelectedProviders] = useState(props?.selectedProviders || []);
  const [providers, setProviders] = useState(props?.providers || []);
  const [checked, setChecked] = useState({});

  useEffect(() => {
    setProviders(props.providers);
    setLocalSelectedProviders(props.selectedProviders);
  }, [selectedProviders, props.providers]);

  useEffect(() => {
    const _checked = {};
    providers.forEach((p) => {
      if (selectedProviders.map((s) => s.id).includes(p.id)) {
        _checked[p.id] = true;
      } else {
        _checked[p.id] = false;
      }
    });

    setChecked(_checked);
  }, [selectedProviders, props.providers]);

  const displayProviderName = (item) => {
    if (
      (!item.first_name && !item.last_name) ||
      (item.first_name && item.last_name && item.first_name.length == 0 && item.last_name.length == 0)
    ) {
      return item.company_name;
    } else {
      return item.first_name + " " + item.last_name;
    }
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(providers, result.source.index, result.destination.index);
    setProviders(items);
    onChangeCheckbox(undefined, items);
  };

  const onChangeCheckbox = (e, localProviders = providers) => {
    const _checked = { ...checked };
    if (e) {
      const providerId = e.target.value;
      _checked[providerId] = e.target.checked;
      setChecked(_checked);
    }

    const selected = [];
    localProviders.slice(0, 3).forEach((p, index) => {
      if (_checked[p.id] == true) {
        selected.push({ service_provider_id: p.id, order: index });
      }
    });

    setSelectedProviders(selected);
  };

  return (
    <DragDropContext className="dd" onDragEnd={onDragEnd}>
      <Droppable droppableId="droppableProvider">
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
            {providers.map((item, index) => (
              <Draggable
                className="service-provider-dd"
                key={`${item.id}-drag`}
                draggableId={`${item.id}`}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, index)}
                  >
                    <div className="provider-item">
                      <input
                        onChange={onChangeCheckbox}
                        style={{ marginRight: 5 }}
                        defaultChecked={selectedProviders.map((s) => s.id).includes(item.id)}
                        value={item.id}
                        name={`provider-${item.id}`}
                        type="checkbox"
                      />
                      {item.is_internal ? <LoginOutlined /> : <LogoutOutlined />}
                      <label htmlFor={`checkbox`}>{" " + item.display_name}</label>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ServiceProviderDD;
