import React, { useState, useEffect, useRef } from "react";
import {
  EditOutlined,
  PaperClipOutlined,
  FilePdfOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../patient_treatment/appointment-form.scss";
import Select, { components } from "react-select";
import TreatmentService from "../../services/treatment-pathway";
import DepartmentService from "../../services/department";
import AlertMessage from "../../components/alert-message";
import toastMessage from "../../components/toast-message";

const { Option, MultiValue } = components;

const MultiValueOption = (props) => {
  return (
    <MultiValue {...props}>
      <div className="d-flex align-items-center">
        <FilePdfOutlined style={{ marginRight: "5px", fontSize: "1.2rem" }} />
        {props.data.label}
      </div>
    </MultiValue>
  );
};
const IconOption = (props) => (
  <Option {...props}>
    <div className="d-flex align-items-center">
      <FilePdfOutlined style={{ marginRight: "5px", fontSize: "1.2rem" }} />
      {props.data.label}
    </div>
  </Option>
);

const TreatmentPathwayItem = (props) => {
  const onlyPromRef = useRef(null);
  const hybridPromRef = useRef(null);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);

  const [treatment, setTreatment] = useState(props.treatment);
  const [name, setName] = useState("");
  const [isDefault, setIsDefault] = useState(false);
  const [onlyProm, setOnlyProm] = useState(false);
  const [hybridProm, setHybridProm] = useState(false);

  const [files, _setFiles] = useState([]);
  const [attachmentOptions, setAttachmentOptions] = useState([]);
  const [treatmentFiles, setTreatmentFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [serverFiles, setServerFiles] = useState([]);
  const [departmentDocuments, setDepartmentDocuments] = useState([]);

  useEffect(() => {
    setTreatment(treatment);
    setName(treatment.name_de || "");
    setIsDefault(treatment.is_default || false);
    setOnlyProm(treatment.only_prom || false);
    setHybridProm(treatment.is_hybrid_prom || false);
  }, [treatment]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleForm = async () => {
    const formData = new FormData();
    formData.append("treatment_pathway[name_de]", name);
    formData.append("treatment_pathway[is_default]", isDefault);
    formData.append("treatment_pathway[only_prom]", onlyProm);
    formData.append("treatment_pathway[is_hybrid_prom]", hybridProm);
    attachments.forEach((a) => {
      formData.append("attachment_ids[]", a.value);
    });
    TreatmentService.update(props.treatment.id, formData).then(
      (res) => {
        toastMessage.success(res.data.message);
        setTreatment(res.data.treatment_pathway);

        // Reload Document
        const svFiles = [];
        const options = departmentDocuments.map((f) => {
          if (res.data.treatment_pathway.documents.map((t) => t.sum).includes(f.sum)) {
            svFiles.push({
              value: f.id,
              label: f.filename,
            });
          }
          return {
            value: f.id,
            label: f.filename,
          };
        });

        setAttachmentOptions(options);
        setServerFiles(svFiles);
        setAttachments(svFiles);
        // End Reload Document

        handleClose();
      },
      (error) => {
        toastMessage.error(error.meta.message);
      },
    );
  };

  const handleDelete = async () => {
    TreatmentService.destroy(props.treatment.id).then(
      (res) => {
        toastMessage.success(res.data.message);
        window.location.reload();
        handleClose();
      },
      (error) => {
        toastMessage.error(error.meta.message);
      },
    );
  };

  useEffect(() => {
    if (show && departmentDocuments.length == 0) {
      const files = treatment.documents;
      setTreatmentFiles(files);
      const departmentId = window.cookies.cookies.last_department_id || 1 // TODO: some way to get the correct departmentId (cookies were not set on my local);
      if (departmentId) {
        DepartmentService.show(departmentId).then(
          (response) => {
            const p = response.data.department;

            setDepartmentDocuments(p.department_setting.documents);
            const svFiles = [];
            const options = p.department_setting.documents.map((f) => {
              if (files.map((t) => t.sum).includes(f.sum)) {
                svFiles.push({
                  value: f.id,
                  label: f.filename,
                });
              }
              return {
                value: f.id,
                label: f.filename,
              };
            });
            setAttachmentOptions(options);
            setServerFiles(svFiles);
            setAttachments(svFiles);
            setLoading(false);
          },
          (error) => {
            setError(error.meta.message);
          },
        );
      }
    }
  }, [show]);

  const switchPathway = () => {
    props.setActivePathway(props.treatment);
  };

  const handleOnlyPromChange = (e) => {
    // Update state based on checkbox
    setOnlyProm(e.target.checked);

    // Directly uncheck hybridProm and update its state if onlyProm is checked
    if (e.target.checked) {
      hybridPromRef.current.checked = false; // Direct DOM manipulation
      setHybridProm(false); // State update
    }
  };

  const handleHybridPromChange = (e) => {
    // Update state based on checkbox
    setHybridProm(e.target.checked);

    // Directly uncheck onlyProm and update its state if hybridProm is checked
    if (e.target.checked) {
      onlyPromRef.current.checked = false; // Direct DOM manipulation
      setOnlyProm(false); // State update
    }
  };

  return (
    Object.keys(props.treatment).length > 0 && (
      <div className="treatment-pathway-item">
        <div className="name-wrap d-flex align-items-center m-2 f]ex-row">
          <Button
            disabled={props.loading}
            size="lg"
            onClick={switchPathway}
            variant={props.isSelected ? "primary" : "outline-secondary"}
          >
            {name}
          </Button>
          <EditOutlined onClick={handleShow} />
        </div>

        <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton className="modal-header">
            <Modal.Title>
              <div style={{ gap: "5px" }} className="d-flex align-items-center">
                <EditOutlined />
                {I18n.t("activerecord.models.treatment_pathway")}
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!loading && (
              <div className="appointment-create-box">
                <div className="box-body">
                  <div className="input row">
                    <div className="col-md-4 label">
                      <span className="text-capitalize">{I18n.t("activerecord.models.treatment_pathway")}</span>
                    </div>
                    <div className="col-md-7">
                      <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                  </div>

                  <div className="attachment">
                    <span className="label">
                      <PaperClipOutlined />
                    </span>
                    <Select
                      defaultValue={serverFiles}
                      className="attachment-multi-select"
                      menuPortalTarget={document.body}
                      options={attachmentOptions}
                      closeMenuOnSelect={true}
                      onChange={(choice) => setAttachments(choice)}
                      isMulti
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      components={{
                        Option: IconOption,
                        MultiValue: MultiValueOption,
                      }}
                      placeholder=""
                    />
                  </div>
                  {files.length > 0 && (
                    <div className="row mb-3">
                      <ul>
                        {files.map((f) => (
                          <li key={f}>{f}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-7">
                      <p className="guide">{I18n.t("form.treatment_pathway.guide_document")}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 label">
                      {I18n.t("activerecord.attributes.treatment_pathway.is_default")}{" "}
                      <input
                        type="checkbox"
                        defaultChecked={isDefault}
                        value={isDefault}
                        onChange={(e) => setIsDefault(e.target.checked)}
                      />
                    </div>
                    <div className="col-md-4 label">
                      {I18n.t("form.treatment_pathway.prom_only")}{" "}
                      <input
                        type="checkbox"
                        value={onlyProm}
                        defaultChecked={onlyProm}
                        ref={onlyPromRef}
                        onChange={handleOnlyPromChange}
                      />
                    </div>

                    <div className="col-md-4 label">
                      {I18n.t("form.treatment_pathway.hybrid_prom")}{" "}
                      <input
                        type="checkbox"
                        value={hybridProm}
                        defaultChecked={hybridProm}
                        ref={hybridPromRef}
                        onChange={handleHybridPromChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="row">{error.length > 0 && <AlertMessage variant="danger" message={error} />}</div>
          </Modal.Body>
          <Modal.Footer>
            <div
              className="delete-pathway"
              style={{ fontSize: "1.5rem", color: "red", cursor: "pointer", position: "absolute", left: 20 }}
              onClick={handleDelete}
            >
              <DeleteOutlined />
            </div>
            <div
              onClick={handleForm}
              className="create-patient d-flex align-items-baseline justify-content-between btn btn-lg mt-3 fw-bolder float-end bg-green"
            >
              <span>{I18n.t("form.update")}</span>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    )
  );
};

export default TreatmentPathwayItem;
