import axios, { AxiosRequestConfig } from 'axios';
import fs from 'fs';

import authClient from '../utils/auth-common';

const axiosClient = axios.create({
    baseURL: `${process.env.APP_URL}/api/v1`,
    timeout: 10000,
    headers: Object.assign({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Tenant-Id': {
            toString() {
                return localStorage.getItem('X-Tenant-Id')
            }
        },
        'X-Pathway-Id': {
          toString() {
              return localStorage.getItem('X-Pathway-Id')
          }
      }
    }, authClient.retrieveData('authHeaders'))
});


export const downloadXLSFile = async (url, query={}, type="csv") => {
  let method = 'GET'
  if (url.includes('service_provider')) {
    method = 'POST'
  }

    
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = {'Content-Type': 'blob',};
    const config = {method, url, responseType: 'arraybuffer', headers, params: query};
    
    try {
        const response = await axiosClient(config);
        
        const outputFilename = `${Date.now()}.${type}`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();

        // OR you can save/write file locally.
        // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
        throw Error(error);
    }
}