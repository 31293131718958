import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthService from "../../services/auth";
import AcademicTitleService from "../../services/academic_title";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import toastMessage from "../../components/toast-message";

export default (props) => {
  const [reload, setReload] = useState(undefined);

  const [academicTitles, setAcademicTitles] = useState([]);

  useEffect(() => {
    AcademicTitleService.index().then(
      (response) => {
        setAcademicTitles(response.data.service_provider_academic_titles);
      },
      (error) => {
        toastMessage.error(error.meta.message);
      },
    );
  }, [reload, props.departmentId]);

  const handleDelete = async (id) => {
    if (window.confirm(I18n.t("form.academic_title.confirm_delete"))) {
      try {
        const response = await AcademicTitleService.destroy(
          id,
          props.departmentId,
        );
        setReload(!reload);
        toastMessage.success(response.data.message);
      } catch (error) {
        setReload(!reload);
        toastMessage.error(error.meta.message);
      }
    }
  };

  const tableColunms = [
    {
      dataField: "id",
      text: "#",
      sort: true,
    },
    {
      dataField: "name",
      text: I18n.t("activerecord.attributes.academic_title.name"),
      sort: true,
    },
    {
      dataField: "Edit Delete",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <div style={{ gap: 20 }} className="d-flex align-items-center">
            <Link to={`/academic_titles/${row.id}/edit`}>
              <EditOutlined />
            </Link>
            <a
              href="#!"
              onClick={(e) => {
                e.preventDefault();
                handleDelete(row.id);
              }}
            >
              <DeleteOutlined />
            </a>
          </div>
        );
      },
    },
  ];

  return (
    <div className="container">
      <div className="row p-5 mt-5">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <div className="card p-5">
            <h1 className="text-center">
              {I18n.t("activerecord.models.academic_title")}
            </h1>
            {AuthService.isSuperAdmin() && (
              <Link
                style={{ width: "40%" }}
                className="btn btn-primary mb-3"
                to="/academic_title_create"
              >
                {I18n.t("form.academic_title.new_academic_title")}
              </Link>
            )}
            <BootstrapTable
              keyField="id"
              data={academicTitles}
              columns={tableColunms}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
