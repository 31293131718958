import React, { useState, useEffect } from "react";
import toastMessage from "../../components/toast-message";
import ChangelogService from "../../services/changelog";
import ReactMarkdown from "react-markdown";

const ReleaseNoteIndex = (props) => {
  const [content, setContent] = useState("");

  useEffect(() => {
    ChangelogService.index().then(
      (response) => {
        setContent(response.data.data);
      },
      (error) => {
        toastMessage.error(error.response?.data?.message);
      },
    );
  }, []);

  return (
    <div className="changelog">
      <h1 className="text-center">Changelog</h1>
      <div className="card container p-5 mt-5">
        <ReactMarkdown>{content}</ReactMarkdown>
      </div>
    </div>
  );
};

export default ReleaseNoteIndex;
